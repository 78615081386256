import * as React from 'react'
import { useEmployeeEnrollmentInfoQuery } from '../enrollment/gql/employeeEnrollmentInfo_gen'
import { useTrackingContext } from './TrackingProvider'

/**
 * A hook to track _changes_ to the user's account status within a session. This is useful for
 * tracking the user's progress through the enrollment flow without relying on a particular set
 * of user actions
 */
const useTrackAccountStatus = () => {
  const [trackingContext, setTrackingProperty] = useTrackingContext()
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const accountStatus = employeeEnrollmentData?.employee?.enrollmentInfo?.accountStatus
  const trackedAccountStatuses = trackingContext.accountStatuses
  React.useEffect(() => {
    if (accountStatus && !trackedAccountStatuses?.includes(accountStatus)) {
      setTrackingProperty('accountStatuses', [...(trackedAccountStatuses || []), accountStatus])
    }
  }, [trackedAccountStatuses, accountStatus, setTrackingProperty])
}

export const TrackAccountStatus = () => {
  useTrackAccountStatus()
  return null
}
