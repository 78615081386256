import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@alice-financial/pretext-ui'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardKycStatus } from '../../../graphql/generated.types'
import { ConnectPaymentMethodStep } from '../ConnectPaymentMethodStep'
import { KYCButton } from '../KYC/KYCButton'
import { RequestKYCHelpInfo } from '../KYC/RequestKYCHelpForm'
import { AliceCardProgramSetupHelp } from './AliceCardProgramSetupHelp'
import { AliceCardProgramSetupInfo } from './AliceCardProgramSetupInfo'
import { UnifiedFundingForm } from './UnifiedFundingForm'

export const AliceCardProgramSetup = ({ org }: { org: OrganizationDetailFragment }) => {
  const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
  const fundingConnected = org.aliceCardProgram.hasFundingConnected
  const fundingStepDisabled = !kycComplete || fundingConnected
  const fundingStarted = (org.aliceCardFunding.availablePaymentMethods || []).length > 0
  const hasUnifiedBanking = Boolean(org.aliceCardProgram.hasUnifiedBanking)
  return (
    <>
      <AliceCardProgramSetupInfo />
      <Paper component={List} disablePadding sx={{ marginBottom: 2 }}>
        <ListItem divider>
          <ListItemIcon>
            {kycComplete ? <CheckCircleIcon color="primary" /> : <ArrowCircleRightIcon color="secondary" />}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                color={kycComplete ? 'text.disabled' : 'inherit'}
                gutterBottom
                sx={{ textDecoration: kycComplete ? 'line-through' : 'none' }}
              >
                Provide company representative information
              </Typography>
            }
            secondary={
              !kycComplete && (
                <>
                  <Typography variant="caption" component="p" gutterBottom>
                    Federal regulations require information on a Company Representative. The representative
                    must be a US resident with a valid SSN.
                  </Typography>
                  <Typography variant="caption" component="p" gutterBottom>
                    <RequestKYCHelpInfo org={org} />
                  </Typography>
                </>
              )
            }
          />
          <Box>
            <KYCButton org={org} />
          </Box>
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            {fundingConnected ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <ArrowCircleRightIcon color={kycComplete ? 'secondary' : 'disabled'} />
            )}
          </ListItemIcon>

          <Grid container>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color={fundingStepDisabled ? 'text.disabled' : 'inherit'}
                  gutterBottom
                >
                  Provide payroll bank account information{' '}
                  {fundingStarted && !hasUnifiedBanking && (
                    <Typography variant="body2" fontWeight="bold" component="span">
                      for each pay group
                    </Typography>
                  )}
                </Typography>
              }
              secondary={
                <>
                  <Typography variant="caption" component="p" gutterBottom>
                    Employees make pretax contributions to fund their purchases on Alice Card. Each week Alice
                    sweeps those contributions to their Alice Cards. These transfers are not costs for your
                    organization. This step is not for setting up billing for Alice&apos;s services.
                  </Typography>
                </>
              }
            />
            {!fundingStepDisabled && (
              <Box width="100%">
                <UnifiedFundingForm />
                <ConnectPaymentMethodStep org={org} />
              </Box>
            )}
          </Grid>
        </ListItem>
        <ListItem disablePadding disableGutters>
          <AliceCardProgramSetupHelp />
        </ListItem>
      </Paper>
    </>
  )
}
