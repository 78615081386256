import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useEmployeeEnrollmentInfoQuery } from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { AddressInput } from '../../../graphql/generated.types'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { useEmployeeQuery } from '../../gql/employee_gen'
import { UpdateAddressMutation, useUpdateAddressMutation } from './gql/updateAddress_gen'

export const useUpdateAddress = (
  mutationOptions: UseGqlMutationCallbacks<UpdateAddressMutation, AddressInput> = {}
) => {
  const { data: employeeData } = useEmployeeQuery()
  const employeeId = employeeData?.employee?.id
  const { mutateAsync: updateAddress } = useUpdateAddressMutation()
  return useMutationWithInvalidation(
    (mailingAddress: AddressInput) => {
      if (!employeeId) throw new Error('Employee ID not found')
      return updateAddress({ employeeId, mailingAddress })
    },
    mutationOptions,
    [useCurrentUserQuery.getKey(), useEmployeeQuery.getKey(), useEmployeeEnrollmentInfoQuery.getKey()]
  )
}
