import { List } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EligibleTxnListItems } from './EligibleTxnListItems'
import { UnconfirmedTxnListItems } from './UnconfirmedTxnListItems'
import { MissingTxnListItem } from './missingTxn/MissingTransaction'

/**
 * A client-side filterable list of transactions
 */
export const TxnList = () => {
  // All children of TxnListLayout should render as <li> elements
  return (
    <List>
      <UnconfirmedTxnListItems divider />
      <EligibleTxnListItems />
      <MissingTxnListItem />
    </List>
  )
}
