import {
  UseGqlMutationCallbacks,
  composeMutationCallbacks,
  useMutationWithInvalidation,
} from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { useIntl } from 'react-intl'
import { prettyTelString } from '../../utils/formatters/telFormat'
import { useEmployeeEnrollmentInfoQuery } from '../enrollment/gql/employeeEnrollmentInfo_gen'
import { CreatePhoneVerificationCodeInput } from '../graphql/generated.types'
import {
  CreatePhoneVerificationCodeMutation,
  useCreatePhoneVerificationCodeMutation,
} from './gql/createPhoneVerificationCode_gen'
import { useCurrentUserQuery } from './gql/currentUser_gen'

type RequestCodeMutationOptions = UseGqlMutationCallbacks<
  CreatePhoneVerificationCodeMutation,
  CreatePhoneVerificationCodeInput
>
/**
 * Hook to manage the _request_ of the confirmation code.
 */
export const useRequestPhoneVerification = (mutationOptions?: RequestCodeMutationOptions) => {
  const intl = useIntl()
  const { notifySubmit, notifyError, clearNotif } = useResponseNotification()
  const optionsWithNotifications = composeMutationCallbacks(mutationOptions, {
    onMutate: ({ tel }) => {
      notifySubmit(
        intl.formatMessage({ id: 'enroll.phone_verification.sending_code_to' }, { tel: prettyTelString(tel) })
      )
    },
    onError: (err) => notifyError(err.message),
    onSuccess: () => {
      clearNotif()
    },
  })
  const { mutateAsync: requestCode } = useCreatePhoneVerificationCodeMutation()
  // must invalidate any queries that expose the user's phone number, which will be updated by this mutation
  return useMutationWithInvalidation(({ tel }) => requestCode({ input: { tel } }), optionsWithNotifications, [
    useEmployeeEnrollmentInfoQuery.getKey(),
    useCurrentUserQuery.getKey(),
  ])
}
