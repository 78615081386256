import * as React from 'react'

/**
 * a hook for loading an external JS script and firing a callback on load.
 *
 * The hook does _not_ unmount the script, but will immediately fire the supplied
 * `onLoad` callback if an element with the supplied ID already exists in the DOM.
 *
 * _Note: it is up to the caller to supply a unique ID, but it generally doesn't
 * need to be human-readable_
 */
export const useLoadScript = (id: string, src: string, onLoad: () => void) => {
  React.useEffect(() => {
    const existingScript = document.getElementById(id)
    if (existingScript) {
      // short circuit if the script was loaded previously
      onLoad()
      return
    }

    const script = document.createElement('script')
    script.src = src
    script.id = id
    document.body.appendChild(script)
    script.addEventListener('load', onLoad)
    return () => {
      script.removeEventListener('load', onLoad)
    }
  }, [src, onLoad, id])
}
