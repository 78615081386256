import * as Types from '../../../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ReconnectOmnyMutationVariables = Types.Exact<{
  input: Types.ReconnectOmnyConnectionInput
}>

export type ReconnectOmnyMutation = {
  __typename?: 'Mutation'
  reconnectOmnyConnection?: {
    __typename?: 'ReconnectOMNYConnectionPayload'
    connection?: { __typename?: 'BankingConnection'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const ReconnectOmnyDocument = `
    mutation ReconnectOMNY($input: ReconnectOMNYConnectionInput!) {
  reconnectOmnyConnection(input: $input) {
    connection {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useReconnectOmnyMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<ReconnectOmnyMutation, TError, ReconnectOmnyMutationVariables, TContext>
) => {
  return useMutation<ReconnectOmnyMutation, TError, ReconnectOmnyMutationVariables, TContext>(
    ['ReconnectOMNY'],
    (variables?: ReconnectOmnyMutationVariables) =>
      fetchGql<ReconnectOmnyMutation, ReconnectOmnyMutationVariables>(ReconnectOmnyDocument, variables)(),
    options
  )
}
