import { ActionLayout, Button, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { EmploymentStartDateInputController } from './EmploymentStartDateInput'
import { useCompleteEnrollmentRequirements } from './useCompleteEnrollmentRequirements'

/**
 * This form handles input for any user data that has not been set previously. Currently
 * it handles
 *
 * 1. Setting a password
 * 2. Sending us a start date, if known
 */
export const CompleteEnrollmentRequirements = () => {
  const { onSubmit, control, watch, setValue, isLoading, isSuccess } = useCompleteEnrollmentRequirements()

  const alreadyStarted = watch('alreadyStarted')
  const setAlreadyStarted = (value: boolean) => setValue('alreadyStarted', value)
  const disabled = isLoading || isSuccess

  return (
    <form onSubmit={onSubmit} data-testid="complete-enrollment">
      <EmploymentStartDateInputController
        control={control}
        alreadyStartedState={[alreadyStarted, setAlreadyStarted]}
      />

      <ActionLayout
        primary={
          <Button variant="contained" fullWidth type="submit" disabled={disabled}>
            <FormattedMessage id="common.continue" />
          </Button>
        }
      />
    </form>
  )
}

export const CompleteEnrollmentRequirementsForm = () => (
  <>
    <Typography variant="h3" gutterBottom>
      <FormattedMessage id="enroll.summary.need_employment_start.heading" />
    </Typography>

    <CompleteEnrollmentRequirements />
  </>
)
