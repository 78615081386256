import { HealthcheckItem, HealthcheckItemProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { Transaction } from '../../spending/types'
import { useTransactionsListQuery } from '../../spending/useTransactionsListQuery'

const RECENT_DAYS_OF_TXNS = 90

/**
 * Check whether the user has 'recent' transactions - should be all clear
 * if there are recent transactions and none are 'unconfirmed'
 */
const evaluateRecentSpendingHealth = (txns: Array<Transaction>, intl: IntlShape): HealthcheckItemProps => {
  if (txns.length === 0) {
    return {
      to: '/cards',
      icon: '💰',
      status: 'incomplete',
      primary: intl.formatMessage({
        id: 'employee_dashboard.healthcheck_recent_spending.none_primary',
      }),
      secondary: intl.formatMessage({
        id: 'employee_dashboard.healthcheck_recent_spending.none_secondary',
      }),
    }
  }
  if (txns.every((txn) => txn.status.value !== 'unconfirmed')) {
    return {
      to: '/spending',
      status: 'complete',
      primary: intl.formatMessage({
        id: 'employee_dashboard.healthcheck_recent_spending.all_complete_primary',
      }),
      secondary: intl.formatMessage(
        {
          id: 'employee_dashboard.healthcheck_recent_spending.all_complete_secondary',
        },
        { txnsLength: txns.length }
      ),
    }
  }
  return {
    to: '/spending',
    status: 'incomplete',
    icon: '💰',
    primary: intl.formatMessage({
      id: 'employee_dashboard.healthcheck_recent_spending.some_incomplete_primary',
    }),
    secondary: intl.formatMessage({
      id: 'employee_dashboard.healthcheck_recent_spending.some_incomplete_secondary',
    }),
  }
}

export const RecentSpendingCheckItem = () => {
  const { data: txnsData, isLoading } = useTransactionsListQuery({ days: RECENT_DAYS_OF_TXNS })
  const intl = useIntl()

  const healthcheckProps = evaluateRecentSpendingHealth(txnsData.txns, intl)

  return <HealthcheckItem isLoading={isLoading} {...healthcheckProps} />
}
