import {
  composeMutationCallbacks,
  UseGqlMutationCallbacks,
  useMutationOptionsWithInvalidation,
} from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { CreateWorkLocationInput } from '../../graphql/generated.types'
import { WorkLocationFormInputs } from './types'
import {
  CreateWorkLocationMutationVariables,
  useCreateWorkLocationMutation,
} from '../../organization/onboarding/gql/createWorkLocation_gen'
import { useUpdateWorkLocationLogo } from './useUpdateWorkLocationLogo'
import { useUpdateWorkLocationMutation } from '../../organization/onboarding/gql/updateWorkLocation_gen'

export type LocationData = Omit<CreateWorkLocationInput, 'orgId'>

type UseManageWorkLocationInput = WorkLocationFormInputs & { logoChanged: boolean }
type UseCreateLocationMutationOptions = UseGqlMutationCallbacks<
  { id: number | string },
  UseManageWorkLocationInput
>

const locationToInput = (orgId: number, locationData: LocationData): CreateWorkLocationMutationVariables => {
  return { input: { ...locationData, orgId } }
}

// This hook is meant to take a form state and determine, based on the presence of an id field,
// whether to execute an update or a create mutation. In either case, it will also fire a second
// updateWorkLocationLogo mutation after success of the original write - this is because logo
// upload actually exists on a separate endpoint.
export const useManageWorkLocation = (
  orgId: number,
  invalidationKeys: QueryKey[],
  mutationOptions?: UseCreateLocationMutationOptions
) => {
  const { notifyError } = useResponseNotification()
  const composedMutationOptions = composeMutationCallbacks(mutationOptions, {
    onSuccess: (data) => {
      if (!data.id) {
        notifyError('Could not create/update location')
      }
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    composedMutationOptions,
    invalidationKeys
  )

  const { mutateAsync: createWorkLocation } = useCreateWorkLocationMutation()
  const { mutateAsync: updateWorkLocation } = useUpdateWorkLocationMutation()
  const { mutateAsync: updateLocationLogo } = useUpdateWorkLocationLogo()

  return useMutation(
    ['manageWorkLocation'],
    async (values) => {
      const locationData: LocationData = {
        name: values.name,
        nickname: values.nickname,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,
      }
      const currentWorkLocationId = values.id
      const locationInputData = locationToInput(orgId, locationData)
      const newLogo = values.logo?.item(0)
      const mutationPromise = currentWorkLocationId
        ? updateWorkLocation({ input: { id: currentWorkLocationId, ...locationData } }).then(
            () => currentWorkLocationId
          )
        : createWorkLocation(locationInputData).then((result) => result.createWorkLocation?.workLocation?.id)

      return mutationPromise.then((workLocationId) => {
        if (!workLocationId) throw new Error('Work location not created')
        return values.logoChanged
          ? updateLocationLogo({ logo: newLogo || null, id: parseInt(workLocationId?.toString(), 10) }).then(
              () => ({ id: workLocationId })
            )
          : { id: workLocationId }
      })
    },
    mutationOptionsWithInvalidation
  )
}
