import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateAddressMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input']
  mailingAddress: Types.AddressInput
}>

export type UpdateAddressMutation = {
  __typename?: 'Mutation'
  updateEmployee?: {
    __typename?: 'UpdateEmployeePayload'
    employee?: { __typename?: 'Employee'; id: number } | null
  } | null
}

export const UpdateAddressDocument = `
    mutation UpdateAddress($employeeId: Int!, $mailingAddress: AddressInput!) {
  updateEmployee(
    input: {employeeId: $employeeId, mailingAddress: $mailingAddress}
  ) {
    employee {
      id
    }
  }
}
    `

export const useUpdateAddressMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<UpdateAddressMutation, TError, UpdateAddressMutationVariables, TContext>
) => {
  return useMutation<UpdateAddressMutation, TError, UpdateAddressMutationVariables, TContext>(
    ['UpdateAddress'],
    (variables?: UpdateAddressMutationVariables) =>
      fetchGql<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, variables)(),
    options
  )
}
