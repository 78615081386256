import * as Types from '../../../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type StripeCompanyAccountQueryVariables = Types.Exact<{ [key: string]: never }>

export type StripeCompanyAccountQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    aliceCardProgram: {
      __typename?: 'AliceCardProgram'
      companyAccount?: {
        __typename?: 'StripeCompanyAccount'
        id: number
        stripeConnectedAccountId?: string | null
      } | null
    }
  } | null
}

export const StripeCompanyAccountDocument = `
    query StripeCompanyAccount {
  organization {
    id
    aliceCardProgram {
      companyAccount {
        id
        stripeConnectedAccountId
      }
    }
  }
}
    `

export const useStripeCompanyAccountQuery = <TData = StripeCompanyAccountQuery, TError = Error>(
  variables?: StripeCompanyAccountQueryVariables,
  options?: UseQueryOptions<StripeCompanyAccountQuery, TError, TData>
) => {
  return useQuery<StripeCompanyAccountQuery, TError, TData>(
    variables === undefined ? ['StripeCompanyAccount'] : ['StripeCompanyAccount', variables],
    fetchGql<StripeCompanyAccountQuery, StripeCompanyAccountQueryVariables>(
      StripeCompanyAccountDocument,
      variables
    ),
    options
  )
}

useStripeCompanyAccountQuery.getKey = (variables?: StripeCompanyAccountQueryVariables) =>
  variables === undefined ? ['StripeCompanyAccount'] : ['StripeCompanyAccount', variables]
