import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SubmitOnboardingDocumentsMutationVariables = Types.Exact<{
  input: Types.SubmitOnboardingDocumentsInput
}>

export type SubmitOnboardingDocumentsMutation = {
  __typename?: 'Mutation'
  submitOnboardingDocuments?: {
    __typename?: 'SubmitOnboardingDocumentsPayload'
    success: boolean
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const SubmitOnboardingDocumentsDocument = `
    mutation SubmitOnboardingDocuments($input: SubmitOnboardingDocumentsInput!) {
  submitOnboardingDocuments(input: $input) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useSubmitOnboardingDocumentsMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    SubmitOnboardingDocumentsMutation,
    TError,
    SubmitOnboardingDocumentsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SubmitOnboardingDocumentsMutation,
    TError,
    SubmitOnboardingDocumentsMutationVariables,
    TContext
  >(
    ['SubmitOnboardingDocuments'],
    (variables?: SubmitOnboardingDocumentsMutationVariables) =>
      fetchGql<SubmitOnboardingDocumentsMutation, SubmitOnboardingDocumentsMutationVariables>(
        SubmitOnboardingDocumentsDocument,
        variables
      )(),
    options
  )
}
