import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

export type PageSizePagingState = {
  first?: number | null
  last?: number | null
  before?: string | null
  after?: string | null
}
const PARAM_KEY: Record<keyof PageSizePagingState, string> = {
  first: 'first',
  last: 'last',
  before: 'before',
  after: 'after',
}

const pagingStateFromParams = (searchParams: URLSearchParams): PageSizePagingState => ({
  first: PARAM_KEY.first ? parseInt(searchParams.get(PARAM_KEY.first) || '', 10) : undefined,
  last: PARAM_KEY.last ? parseInt(searchParams.get(PARAM_KEY.last) || '', 10) : undefined,
  before: searchParams.get(PARAM_KEY.before),
  after: searchParams.get(PARAM_KEY.after),
})

/**
 * Curried utility function to set a single parameter in the URLSearchParams based on the requested parameter value
 * Note: the `searchParams` object will be mutated
 */
const getSetParam = (searchParams: URLSearchParams) => {
  const currentPagingState = pagingStateFromParams(searchParams)
  return (param: keyof PageSizePagingState, newValue: string | number | null | undefined) => {
    const paramKey = PARAM_KEY[param]
    if (newValue) {
      if (newValue !== currentPagingState[param]) searchParams.set(paramKey, newValue.toString())
    } else {
      searchParams.delete(paramKey)
    }
  }
}

type PageSizePagingContextType =
  | readonly [PageSizePagingState, (pagingState: PageSizePagingState) => void]
  | undefined
/**
 * The PageSizePagingContext allows any child element to access the current pagination arguments
 * that can be used for `useBankingTransactionsList` - this is mainly used for the TxnPager
 * to access `setPaging` setter
 */
const PageSizePagingContext = React.createContext<PageSizePagingContextType>(undefined)

type PagingContextProviderProps = {
  children: React.ReactNode
}
export const PageSizePagingContextProvider = ({ children }: PagingContextProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setPaging = React.useCallback(
    (pagingState: PageSizePagingState) => {
      const { first, last, before, after } = pagingState
      const setParam = getSetParam(searchParams)

      setParam('first', first)
      setParam('last', last)
      setParam('before', before)
      setParam('after', after)

      setSearchParams(searchParams)
    },
    [setSearchParams, searchParams]
  )
  const pagingState: PageSizePagingContextType = [pagingStateFromParams(searchParams), setPaging]

  return <PageSizePagingContext.Provider value={pagingState}>{children}</PageSizePagingContext.Provider>
}

export const usePageSizePagingContext = (pageSize?: number) => {
  const context = React.useContext(PageSizePagingContext)
  if (!context) throw new Error('usePageSizingContext called outside PageSizePagingContextProvider')
  if (pageSize) {
    const [pagingState, setPaging] = context
    if (pagingState.before) {
      const pagingStateWithPageSize = { ...pagingState, last: pageSize }
      return [pagingStateWithPageSize, setPaging] as const
    }
    const pagingStateWithPageSize = { ...pagingState, first: pageSize }
    return [pagingStateWithPageSize, setPaging] as const
  }
  return context
}
