/**
 * This modulej provides an interface to a unique REST endpoint that aggregates Pay Period Records
 * related a Tilda-specific 'Pay Group' object.
 *
 * Important: The Pay Group model is _not_ the same as the 'pay group' concept in payroll systems, but
 * is intended to be a way of connecting pay period records across pay groups (employers) that share a
 * common pay schedule.
 *
 * Payroll reports are used by a small number of organizations to manually upload benefit information to
 * their payroll provider - it is essentially a legacy feature that might never migrate to GraphQL
 */
import { fetchRest } from '@alice-financial/api'
import { GridPaginationModel } from '@alice-financial/pretext-ui'
import { useQuery } from '@tanstack/react-query'

// app/api/entities/payroll/periods_pay_group_entity.rb
export type PayPeriodPayGroup = {
  id: number
  name: string // usually something like 'Weekly', 'Bi-weekly', 'Monthly'
  employee_count: number
}
// app/api/entities/payroll/pay_group_pay_period_entity.rb
export type PayGroupPayPeriod = {
  start_date: string
  end_date: string
  payday: string
  payroll_deadline: string
  employee_count: number
  company_count: number
  employee_deductions_dollars: number
  employee_savings_dollars: number
  employer_gross_savings_dollars: number
  employer_fee_dollars: number
  employer_subsidies_dollars: number
  pay_groups: Array<PayPeriodPayGroup>
}

type PayGroupPayPeriodsResponse = {
  count: number
  pay_periods: Array<PayGroupPayPeriod>
}

const fetchPayGroupPayPeriods = async ({ page, per_page = 10 }: { page: number; per_page?: number }) => {
  const params = new URLSearchParams({ page: page.toString(), per_page: per_page.toString() })
  return fetchRest<PayGroupPayPeriodsResponse>(`/api/organization/reports/payroll?${params}`)
}

/**
 * `page` is zero-based index, but the API is 1-based
 */
export const usePayGroupPayPeriodsQuery = ({ page, pageSize }: GridPaginationModel) =>
  useQuery(['payGroupPayPeriods', page, pageSize], () =>
    fetchPayGroupPayPeriods({ page: page + 1, per_page: pageSize })
  )
