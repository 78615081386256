import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type DisconnectConnectionMutationVariables = Types.Exact<{
  input: Types.DisconnectConnectionInput
}>

export type DisconnectConnectionMutation = {
  __typename?: 'Mutation'
  disconnectConnection?: {
    __typename?: 'DisconnectConnectionPayload'
    connection?: { __typename?: 'BankingConnection'; id: number } | null
  } | null
}

export const DisconnectConnectionDocument = `
    mutation DisconnectConnection($input: DisconnectConnectionInput!) {
  disconnectConnection(input: $input) {
    connection {
      id
    }
  }
}
    `

export const useDisconnectConnectionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    DisconnectConnectionMutation,
    TError,
    DisconnectConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<DisconnectConnectionMutation, TError, DisconnectConnectionMutationVariables, TContext>(
    ['DisconnectConnection'],
    (variables?: DisconnectConnectionMutationVariables) =>
      fetchGql<DisconnectConnectionMutation, DisconnectConnectionMutationVariables>(
        DisconnectConnectionDocument,
        variables
      )(),
    options
  )
}
