import * as React from 'react'
import { EmptyTxnListItems } from './EmptyTxnListItems'
import { TxnFilterDisplay } from './filters/TxnFilterDisplay'
import { useFilter } from './filters/useFilter'
import { TxnListItem } from './TransactionListItem'
import { useDatePagingContext } from '../../../utils/paging/datePaging/DatePagingContext'
import { getNumberOfSpecifiedFilters } from './txnUtils'
import { useTransactionsListQuery } from './useTransactionsListQuery'

type EligibleTxnListItemsProps = { limit?: number; hideUnconfirmed?: boolean }
export const EligibleTxnListItems = ({ limit, hideUnconfirmed }: EligibleTxnListItemsProps) => {
  const [paging] = useDatePagingContext()
  const {
    data: { txns: _txns, pageInfo },
    isLoading,
  } = useTransactionsListQuery(paging)
  const { filter, setFilter, applyFilter } = useFilter()
  const prefiltered = hideUnconfirmed ? _txns.filter((txn) => txn.status.value !== 'unconfirmed') : _txns
  const filtered = prefiltered.length > 0 && getNumberOfSpecifiedFilters(filter) > 0

  const filteredTxns = prefiltered.filter(applyFilter).slice(0, limit)
  if (isLoading) {
    const items = Array(limit || 3)
      .fill(undefined)
      .map((_, idx) => <TxnListItem key={idx} />)
    return <>{items}</>
  }

  return (
    <>
      {!limit && pageInfo && (
        <TxnFilterDisplay filter={filter} setFilter={setFilter} txns={prefiltered} pageInfo={pageInfo} />
      )}

      {filteredTxns.length > 0 ? (
        filteredTxns.map((txn) => <TxnListItem key={txn.id} txn={txn} />)
      ) : (
        <EmptyTxnListItems filtered={filtered} />
      )}
    </>
  )
}
