import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateAdminKycLinkMutationVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
}>

export type CreateAdminKycLinkMutation = {
  __typename?: 'Mutation'
  createAdminKycLink?: {
    __typename?: 'CreateAdminKYCLinkPayload'
    adminKycLink?: string | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateAdminKycLinkDocument = `
    mutation CreateAdminKYCLink($orgId: Int!) {
  createAdminKycLink(input: {orgId: $orgId}) {
    adminKycLink
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateAdminKycLinkMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateAdminKycLinkMutation,
    TError,
    CreateAdminKycLinkMutationVariables,
    TContext
  >
) => {
  return useMutation<CreateAdminKycLinkMutation, TError, CreateAdminKycLinkMutationVariables, TContext>(
    ['CreateAdminKYCLink'],
    (variables?: CreateAdminKycLinkMutationVariables) =>
      fetchGql<CreateAdminKycLinkMutation, CreateAdminKycLinkMutationVariables>(
        CreateAdminKycLinkDocument,
        variables
      )(),
    options
  )
}
