import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { FieldValues, UseFormSetError } from 'react-hook-form'
import { GqlInputErrorException } from './gql'

type InvalidatableQueryKeys = Array<QueryKey> | 'all'
type Callbacks<TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown> = NonNullable<
  Pick<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'onMutate' | 'onError' | 'onSuccess' | 'onSettled'
  >
>

export const composeMutationCallbacks = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  target: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> = {}, // we don't ever supply mutationFn in mutationOptions
  callbacks: Callbacks<TData, TError, TVariables, TContext>
): typeof target => ({
  ...target,
  onSuccess: (...args) => {
    callbacks.onSuccess && callbacks.onSuccess(...args)
    return target?.onSuccess && target.onSuccess(...args)
  },
  onError: (...args) => {
    callbacks.onError && callbacks.onError(...args)
    return target?.onError && target.onError(...args)
  },
  onMutate: (...args) => {
    callbacks.onMutate && callbacks.onMutate(...args)
    return target?.onMutate && target.onMutate(...args)
  },
  onSettled: (...args) => {
    callbacks.onSettled && callbacks.onSettled(...args)
    return target?.onSettled && target.onSettled(...args)
  },
})

/**
 * This hook merges the supplied mutation options with an onSuccess callback that invalidates the
 * supplied query keys. The returned mutation options can then be passed to `useMutation`
 */
export const useMutationOptionsWithInvalidation = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  callbacks: Callbacks<TData, TError, TVariables, TContext> = {},
  queryKeys: InvalidatableQueryKeys
): typeof callbacks => {
  const queryClient = useQueryClient()

  const onSuccess: typeof callbacks.onSuccess = () => {
    queryKeys === 'all'
      ? queryClient.invalidateQueries()
      : queryKeys.forEach((queryKey) => queryClient.invalidateQueries(queryKey))
  }
  return composeMutationCallbacks(callbacks, { onSuccess })
}

/**
 * This is a light wrapper around `useMutation`, adding a third argument that is an array of query keys
 * that will be invalidated in the onSuccess callback of the mutation. Use it as a convenient replacement
 * for `useMutation` whenever you have queries that need to be invalidated
 */
export const useMutationWithInvalidation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  callbacks: Callbacks<TData, TError, TVariables, TContext> = {},
  queryKeys: InvalidatableQueryKeys
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const _mutationOptions: typeof callbacks = useMutationOptionsWithInvalidation(callbacks, queryKeys)
  return useMutation(mutationFn, _mutationOptions)
}

export const getGqlInputErrorHandler =
  <TFieldValues extends FieldValues>(
    setError: UseFormSetError<TFieldValues>,
    rootFields: Array<string> = []
  ) =>
  <TVariables extends Record<string, unknown>>(error: GqlInputErrorException<TVariables>) => {
    error.inputErrors.forEach(({ argument, message }) => {
      if (typeof argument === 'string' && rootFields.includes(argument)) {
        argument = `root.${argument}`
      }
      setError(argument as Parameters<typeof setError>[0], { message }, { shouldFocus: true })
    })
  }
