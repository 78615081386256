import {
  Chip,
  ChipProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { BenefitType } from '../../graphql/generated.types'
import { useOrgId } from '../useOrgId'
import { PayPeriodRecordFragment, usePayPeriodRecordsQuery } from './gql/payPeriodRecords_gen'

const sortByBenefitType = (a: PayPeriodRecordFragment, b: PayPeriodRecordFragment) =>
  (a.benefitType || '').localeCompare(b.benefitType || '')
const sortByEmployeeName = (a: PayPeriodRecordFragment, b: PayPeriodRecordFragment) =>
  (a.employee.user?.firstName || '').localeCompare(b.employee.user?.firstName || '')

const BenefitLabel: Record<BenefitType, string> = {
  [BenefitType.MassTransit]: 'Mass transit',
  [BenefitType.Parking]: 'Parking',
  [BenefitType.HealthcareFsa]: 'HFSA',
  [BenefitType.HealthcareHsa]: 'HHSA',
  [BenefitType.LimitedPurposeFsa]: 'LPFSA',
  [BenefitType.DependentCareFsa]: 'DCFSA',
}
const BenefitColor: Record<BenefitType, ChipProps['color']> = {
  [BenefitType.MassTransit]: 'info',
  [BenefitType.Parking]: 'secondary',
  [BenefitType.HealthcareFsa]: 'warning',
  [BenefitType.HealthcareHsa]: 'error',
  [BenefitType.LimitedPurposeFsa]: 'success',
  [BenefitType.DependentCareFsa]: 'primary',
}

const BenefitChip = ({ benefitType }: { benefitType: BenefitType | null | undefined }) => {
  if (!benefitType) return null
  return <Chip label={BenefitLabel[benefitType]} color={BenefitColor[benefitType]} size="small" />
}
const fullName = ({ firstName, lastName }: { firstName?: string | null; lastName?: string | null }) =>
  `${firstName} ${lastName}`

type PayPeriodRecordsListProps = {
  payPeriod: { id: number; totalEmployeeDeduction: number; totalEmployeeReimbursement: number }
}
export const PayPeriodRecordsList = ({ payPeriod }: PayPeriodRecordsListProps) => {
  const { data: pprData } = usePayPeriodRecordsQuery({ orgId: useOrgId(), id: payPeriod.id })
  const pprs = pprData?.organization?.payPeriod?.payPeriodRecords
  if (!pprs) return null
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>employee</TableCell>
            <TableCell>benefit</TableCell>
            <TableCell align="right">deduction</TableCell>
            <TableCell align="right">reimbursement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pprs
            .sort(sortByBenefitType)
            .sort(sortByEmployeeName)
            .map((ppr) => (
              <TableRow key={ppr.id}>
                <TableCell width="100%">{ppr.employee.user && fullName(ppr.employee.user)}</TableCell>
                <TableCell>
                  <BenefitChip benefitType={ppr.benefitType} />
                </TableCell>
                <TableCell align="right">
                  {ppr.deduction ? formatCentsAsDollars(ppr.deduction, false) : '–'}
                </TableCell>
                <TableCell align="right">
                  {ppr.reimbursement ? formatCentsAsDollars(ppr.reimbursement, false) : '–'}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell width="100%"></TableCell>
            <TableCell></TableCell>
            <TableCell
              align="right"
              sx={{ borderTop: '2px solid black', fontWeight: 'bold', fontSize: '1.1rem' }}
            >
              {formatCentsAsDollars(payPeriod.totalEmployeeDeduction, false)}
            </TableCell>
            <TableCell
              align="right"
              sx={{ borderTop: '2px solid black', fontWeight: 'bold', fontSize: '1.1rem' }}
            >
              {formatCentsAsDollars(payPeriod.totalEmployeeReimbursement, false)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
