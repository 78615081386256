import { Grid, ToggleChip, Typography } from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { ReadableTxnCategory, ReadableTxnStatus, isCategory, isStatus } from '../txnUtils'

type FilterSelectProps<TFilterVal extends string> = {
  title: string
  valuesToShow: Array<TFilterVal>
  setValuesToShow: (values: Array<TFilterVal>) => void
  possibleValues: Array<TFilterVal>
}
export const TxnFilterSelect = <TFilterVal extends string>({
  title,
  valuesToShow,
  setValuesToShow,
  possibleValues,
}: FilterSelectProps<TFilterVal>) => {
  const numValues = possibleValues.length
  const intl = useIntl()

  // If there are no possible values, or only one, then filtering doesn't make sense - don't display the filter at all.
  if (numValues <= 1) return null

  return (
    <>
      <Typography variant="overline" component="h3" color="text.secondary">
        {title}
      </Typography>
      <div>
        <Grid container spacing={2} sx={{ marginBottom: '2rem' }}>
          {possibleValues.map((value) => {
            const label =
              (isStatus(value) && intl.formatMessage({ id: ReadableTxnStatus[value] })) ||
              (isCategory(value) && intl.formatMessage({ id: ReadableTxnCategory[value] })) ||
              value
            return (
              label && (
                <Grid item key={value}>
                  <ToggleChip
                    key={value}
                    label={label}
                    icon={<AddIcon />}
                    selected={valuesToShow.includes(value) ?? false}
                    onChange={(selected) => {
                      if (selected) setValuesToShow([...valuesToShow, value])
                      if (!selected) setValuesToShow(valuesToShow.filter((s) => s !== value))
                    }}
                  />
                </Grid>
              )
            )
          })}
        </Grid>
      </div>
    </>
  )
}
