import { datadogRum } from '@datadog/browser-rum'
import { fetcher } from '../fetch'
import { GqlInputError } from '../types'
import { GQL_PATH } from './config'
import { GqlInputErrorException } from './responseErrors'
import { ApiRootException } from '../responseErrors'

type GqlDataType = string | boolean | null | number | Array<GqlDataType> | Record<string, unknown>
type GqlDataWithInputErrors<TVariables extends Record<string, unknown>> = {
  inputErrors: Array<GqlInputError<TVariables>>
}
export type GqlData = Record<string, GqlDataType>
type GqlRootError = Record<'message', string>
export type GqlResponse<TData extends GqlData> = {
  data: TData
  errors?: [GqlRootError, ...Array<GqlRootError>]
  error: string
}

const isDataWithInputErrors = <TVariables extends Record<string, unknown>>(
  value: GqlDataType
): value is GqlDataWithInputErrors<TVariables> =>
  typeof value === 'object' && Boolean((value as GqlDataWithInputErrors<TVariables> | null)?.inputErrors)

const handleInputError = <TData extends GqlData, TVariables extends Record<string, unknown>>(data: TData) => {
  const inputErrors = Object.values(data).find(isDataWithInputErrors<TVariables>)?.inputErrors || []

  if (inputErrors.length > 0) {
    // if there are input errors, throw an error that can be caught and displayed in the UI
    throw new GqlInputErrorException(inputErrors)
  }

  return data
}

const isMutationQuery = (query: string) =>
  query.split('\n').some((line) => line.trim().startsWith('mutation'))
const extractQueryOrMutationName = (query: string) => {
  const [queryName] = query.match(/(query|mutation) (\w+)/) || []
  return queryName || (query.split('\n')[0] || query).trim()
}

/**
 * GraphQL can error in a few ways
 * 1. Top level error - unrecoverable, e.g. a server error that breaks out of the GQL execution
 * 2. Nested errors - recoverable errors that are returned alongside the query data, e.g. authz errors
 * 3. Input errors - recoverable errors that are returned inside the mutation data (specific to mutations in Tilda)
 */
const handleGqlError = <TData extends GqlData, TVariables extends Record<string, unknown>>(
  json: GqlResponse<TData>,
  query: string
): TData => {
  if (json.error) {
    // top level error, unrecoverable
    throw new Error(json.error)
  }
  if (json.errors) {
    // any errors in mutations should propagate - errors in queries can _usually_ be ignored, e.g. permissions errors
    const allowErrors = !isMutationQuery(query)
    // nested error - can still use data, but error should be available to display (currently swallowing error)
    const { message } = json.errors[0]
    datadogRum.addError(message)
    if (!allowErrors) throw new ApiRootException(message, extractQueryOrMutationName(query))
  }

  return handleInputError<TData, TVariables>(json.data)
}

/**
 * Higher-order function that accepts the query and any input variables, returning
 * a function that can be called at any time to initiate the fetch call
 *
 * Note that GraphQL doesn't like to return error codes - it will almost always return a 200.
 * For that reason, we pass the response body to `handleGqlError` to determine if the response
 * qualifies as an error that needs to be handled by the caller in an `onError` handler
 */
export const fetchGql =
  <TData extends GqlData, TVariables extends Record<string, unknown>>(
    query: string,
    variables?: TVariables
  ) =>
  (): Promise<TData> =>
    fetcher<GqlResponse<TData>>(GQL_PATH, {
      method: 'POST',
      body: JSON.stringify({ query, variables }),
    }).then((responseBody) => handleGqlError<TData, TVariables>(responseBody, query))
