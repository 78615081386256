import createPalette from '@mui/material/styles/createPalette'

const GREEN = '#08A686'
const GOLD = '#A68608' // Triadic of GREEN
const BLUE = '#0877A6'
const _PURPLE = '#8608A6' // Triadic of GREEN and GOLD
const WHITE = '#fff'
const BLACK_ALPHA = 'rgba(0, 0, 0, 0.8)'
const BLACK_ALPHA_LIGHT = 'rgba(0, 0, 0, 0.67)'
const GRAY_ALPHA = 'rgba(0, 0, 0, 0.45)'

export const palette = createPalette({
  common: {
    black: '#333',
  },
  primary: {
    main: GREEN,
  },
  secondary: {
    main: GOLD,
  },
  info: {
    main: BLUE,
  },
  background: {
    default: WHITE,
    paper: WHITE,
  },
  text: {
    primary: BLACK_ALPHA,
    secondary: BLACK_ALPHA_LIGHT,
    disabled: GRAY_ALPHA,
  },
  action: {
    hover: GREEN,
    hoverOpacity: 0.1,
  },
})
