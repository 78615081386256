import { ActionLayout, Button, Container, Link, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { dateStringFormat } from '../../utils/formatters/dateFormat'
import { CardConnectionsList } from '../employeeDashboard/cards/CardsIndex'
import { EmployeeEnrollmentRequirementStatus } from '../graphql/generated.types'
import { CompleteEnrollmentRequirementsForm } from './CompleteEnrollmentRequirements'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { useEnrolledToday } from './useEnrollmentStatus'

export const NewEnrollmentSummary = () => {
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const enrolledToday = useEnrolledToday()

  const employee = employeeEnrollmentData?.employee
  const requirements = employee?.enrollmentInfo.requirements
  const enrollmentDate = employee?.enrollmentInfo?.enrollmentDate

  if (
    requirements?.spendingConnection === EmployeeEnrollmentRequirementStatus.Complete &&
    requirements.employmentStartDate === EmployeeEnrollmentRequirementStatus.Incomplete
  ) {
    return (
      <PageBody>
        <Container>
          <CompleteEnrollmentRequirementsForm />
        </Container>
      </PageBody>
    )
  }

  return (
    <PageBody>
      <Container>
        {enrollmentDate && enrolledToday && (
          <>
            <Typography variant="h3" gutterBottom>
              <FormattedMessage id="enroll.summary.all_set.heading" />
            </Typography>
            <Typography gutterBottom>
              <FormattedMessage
                id="enroll.summary.all_set"
                values={{ enrollmentDate: dateStringFormat.mediumNoYear(enrollmentDate) }}
              />
            </Typography>
          </>
        )}
        <Typography gutterBottom>
          <FormattedMessage id="enroll.summary.start_spending" />
        </Typography>

        <CardConnectionsList />

        <ActionLayout
          primary={
            <Button variant="contained" color="primary" fullWidth component={Link} to="/">
              <FormattedMessage id="common.continue" />
            </Button>
          }
        />
      </Container>
    </PageBody>
  )
}
