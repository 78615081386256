import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreatePhoneVerificationCodeMutationVariables = Types.Exact<{
  input: Types.CreatePhoneVerificationCodeInput
}>

export type CreatePhoneVerificationCodeMutation = {
  __typename?: 'Mutation'
  createPhoneVerificationCode?: {
    __typename?: 'CreatePhoneVerificationCodePayload'
    success: boolean
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreatePhoneVerificationCodeDocument = `
    mutation CreatePhoneVerificationCode($input: CreatePhoneVerificationCodeInput!) {
  createPhoneVerificationCode(input: $input) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreatePhoneVerificationCodeMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreatePhoneVerificationCodeMutation,
    TError,
    CreatePhoneVerificationCodeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreatePhoneVerificationCodeMutation,
    TError,
    CreatePhoneVerificationCodeMutationVariables,
    TContext
  >(
    ['CreatePhoneVerificationCode'],
    (variables?: CreatePhoneVerificationCodeMutationVariables) =>
      fetchGql<CreatePhoneVerificationCodeMutation, CreatePhoneVerificationCodeMutationVariables>(
        CreatePhoneVerificationCodeDocument,
        variables
      )(),
    options
  )
}
