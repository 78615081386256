import * as React from 'react'

import { styled, Typography } from '@alice-financial/pretext-ui'
import { FormattedMessage } from 'react-intl'
import { useQuerySpendingConnections } from '../../../employeeDashboard/cards/useQuerySpendingConnections'
import { AliceCardFundingStatus } from '../../../graphql/generated.types'
import { useEnrollmentEmployer } from '../../useEnrollmentEmployer'
import aliceCardPlaceholder from './assets/alice-card-placeholder.png'
import creditCardAltPlaceholder from './assets/credit-placeholder-alt.png'
import creditCardPlaceholder from './assets/credit-placeholder.png'
import { CarouselSlide } from './CarouselSlide'

const CardBehind = styled('img')(({ theme }) => ({
  height: 240,
  width: 150,
  position: 'absolute',
  top: 'calc(50% - 120px)',
  left: 'calc(50% - 100px)',
  zIndex: 1,
  transform: 'rotate(0deg) translate(40px, 0)',
  transition: theme.transitions.create('transform', { easing: theme.transitions.easing.easeInOut }),
  transitionDelay: '0.3s', // wait for swipe animation to finish
  '.active-slide &': {
    transform: 'rotate(23deg) translate(65px, 0)',
  },
}))
const CardFront = styled('img')(({ theme }) => ({
  height: 250,
  width: 160,
  position: 'absolute',
  top: 'calc(50% - 120px)',
  left: 'calc(50% - 80px)',
  zIndex: 2,
  transform: 'rotate(0) translate(0, 0)',
  transition: theme.transitions.create('transform', { easing: theme.transitions.easing.easeInOut }),
  transitionDelay: '0.3s', // wait for swipe animation to finish
  '.active-slide &': {
    transform: 'rotate(-5deg) translate(-30px, 0)',
  },
}))

export const CardAnimation = () => {
  const { data: employerData } = useEnrollmentEmployer()
  const {
    data: { aliceCardFundingConnected },
  } = useQuerySpendingConnections()
  const employer = employerData?.enrollmentEmployer
  const aliceCardFunded =
    aliceCardFundingConnected || employer?.aliceCardFundingStatus === AliceCardFundingStatus.Connected
  return (
    <div style={{ display: 'block', position: 'relative', height: '100%', overflow: 'hidden' }}>
      <CardBehind src={creditCardAltPlaceholder} alt="Credit card" height={300} />
      <CardFront
        height={260}
        src={aliceCardFunded ? aliceCardPlaceholder : creditCardPlaceholder}
        alt="Alice card"
      />
    </div>
  )
}

export const Spend = ({ isActive }: { isActive: boolean }) => (
  <CarouselSlide
    title={<FormattedMessage id="enroll.how_it_works.spend_on_commuting" />}
    graphic={<CardAnimation />}
    isActive={isActive}
  >
    <Typography variant="body2" gutterBottom>
      <FormattedMessage id="enroll.how_it_works.spend_on_alice_card" />
    </Typography>
    <Typography variant="body2">
      <FormattedMessage id="enroll.how_it_works.spend_on_personal_card" />
    </Typography>
  </CarouselSlide>
)
