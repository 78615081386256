import { ApiRootException, RestApiError, RestInputErrorException } from '@alice-financial/api'
import { List, ListItem, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'

export const AddressFormError = ({ error }: { error: RestApiError }) => {
  if (error instanceof ApiRootException) {
    return <Typography color="error">{error.message}</Typography>
  }
  if (error instanceof RestInputErrorException) {
    return (
      <List>
        {error.inputErrors.map(({ params, messages }) => {
          const fieldname = params.join(', ')
          return (
            <ListItem key={fieldname} disableGutters>
              <Typography color="error">
                {fieldname}: <em>{messages.join(', ')}</em>
              </Typography>
            </ListItem>
          )
        })}
      </List>
    )
  }
  return null // ignore all other error types
}
