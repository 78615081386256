import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { useMatches } from 'react-router'
import { UserWebRouteObject } from '../../app/routes/normalizeRoutes'
import { useAnalytics } from '../../app/telemetry/AnalyticsProvider'
import { trackAdminPageNavigationEvent } from './trackEventsApi'

/**
 * This hook should be called _once_ near the root of the application, just
 * inside the Router provided by React Router. It will watch for _all_ route
 * changes, and send tracking calls to any 3rd party integrations that
 * are interested in navigation through the application
 */
const useTrackView = () => {
  const analytics = useAnalytics()
  // concatenate all relevant `handle.pathPattern` values in matched routes
  const pathPattern = useMatches().reduce((acc, match) => {
    const handle = match.handle as UserWebRouteObject['handle']
    // ignore pathless matches and wildcard matches
    if (!handle?.pathPattern || handle.pathPattern === '*') return acc

    acc += `/${handle.pathPattern}`
    return acc
  }, '')
  const userId = analytics.getUser().id

  // keep a reference to currently-tracked pattern to avoid double-calling
  const currentlyTrackedPattern = React.useRef('')

  React.useEffect(() => {
    if (currentlyTrackedPattern.current === pathPattern) return // user hasn't actually moved
    datadogRum.startView(pathPattern)
    analytics.page('', { path: pathPattern })
    trackAdminPageNavigationEvent({ path: pathPattern, owner_id: userId })
    currentlyTrackedPattern.current = pathPattern
    console.debug(pathPattern)
  }, [pathPattern, analytics])
}

type ViewTrackerProps = {
  children: React.ReactNode
}
export const ViewTracker = ({ children }: ViewTrackerProps) => {
  useTrackView()
  return <>{children}</>
}
