import { Button, Container, FullDialog, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployeeAccountStatusOverride } from '../../graphql/generated.types'
import { EmployeeRow } from './types'
import { useUpdateEmployees } from './updateEmployees'

type OptOutConfirmationProps = {
  open?: boolean
  onClose: () => void
  employees: Array<EmployeeRow>
}
export const OptOutConfirmation = ({ open = false, onClose, employees }: OptOutConfirmationProps) => {
  const employeeIds = employees.map((e) => e.id)
  const { mutate: updateEmployees } = useUpdateEmployees(employeeIds, { onSuccess: onClose })
  const onConfirm = () => updateEmployees({ accountStatus: EmployeeAccountStatusOverride.OptedOut })

  return (
    <FullDialog
      open={open}
      title={<Typography variant="h2">Confirm opt out</Typography>}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Confirm disabling benefits for {employees.length} employee(s)
          </Button>
        </>
      }
    >
      <Container>
        <Typography variant="body2" gutterBottom>
          You are about to opt-out the {employees.length} selected employee(s) who will no longer be able to
          see new spending, confirm past or future eligible spending, or receive any future deductions or
          reimbursements.
        </Typography>
        <Typography variant="body2" gutterBottom>
          This action does NOT terminate the employee(s) in your payroll system or in any other system.
        </Typography>
        <Typography variant="body2" gutterBottom>
          You will be able to return to this dashboard to re-enable Alice for them.
        </Typography>
      </Container>
    </FullDialog>
  )
}
