import { Box, Button } from '@alice-financial/pretext-ui'
import LaunchIcon from '@mui/icons-material/Launch'
import * as React from 'react'
import { useCollectBankAccountForSetupMutation } from './useCollectBankAccountForSetupMutation'

type ConnectBankAccountFormProps = {
  clientSecret: string
  onSuccess: () => void
}
export const ConnectBankAccountForm = ({ clientSecret, onSuccess }: ConnectBankAccountFormProps) => {
  const { mutate: connectBankAccount } = useCollectBankAccountForSetupMutation({ onSuccess })
  return (
    <Box textAlign="center">
      <Button
        variant="contained"
        onClick={() => connectBankAccount({ clientSecret })}
        endIcon={<LaunchIcon />}
      >
        Verify through Stripe
      </Button>
    </Box>
  )
}
