import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material'
import * as React from 'react'
import { theme } from './theme'

type ThemeProviderProps = {
  children: React.ReactNode
}
export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)
