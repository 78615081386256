import * as React from 'react'
import { useLoadScript } from '../../../utils/useLoadScript'

/**
 * @see https://developer.helpscout.com/beacon-2/web/javascript-api
 */
type BeaconInit = (method: 'init', key: string) => void
type BeaconCommand = (method: 'open' | 'close' | 'toggle' | 'reset' | 'destroy') => void
type BeaconLogout = (method: 'logout', options?: { endActiveChat: boolean }) => void
type BeaconSearch = (method: 'search', queryString: string) => void
type BeaconArticle = (
  method: 'article',
  articleId: string,
  data?: { type: 'inline' | 'sidebar' | 'modal' }
) => void
type BeaconNavigate = (method: 'navigate', path: string) => void
type BeaconConfig = (method: 'config', options: { display: { style: 'manual' } }) => void
type BeaconIdentify = (method: 'identify', options: { email: string; signature: string }) => void
type BeaconCall = BeaconInit &
  BeaconCommand &
  BeaconIdentify &
  BeaconSearch &
  BeaconArticle &
  BeaconNavigate &
  BeaconConfig &
  BeaconLogout

type BeaconType = BeaconCall & {
  readyQueue?: Array<{ method: unknown; options: unknown; data: unknown }>
}

declare global {
  interface Window {
    Beacon?: BeaconType
  }
}

const onBeaconLoad = (callback?: (beacon: Window['Beacon']) => void) => {
  const checkForAvailability = setInterval(() => {
    if (!window.Beacon || !window) return
    clearInterval(checkForAvailability)

    if (callback) callback(window.Beacon)
  })
}

/**
 * Access the HelpScout Beacon widget to show/hide/navigate our customer support interface
 * @returns BeaconType | null
 */
export const useHelpScout = () => {
  const [beacon, setBeacon] = React.useState<Window['Beacon'] | null>(() => window.Beacon || null)
  const callback = React.useCallback(() => {
    if (beacon) return
    onBeaconLoad((Beacon) => setBeacon(() => Beacon))
  }, [beacon])

  if (!window.Beacon) {
    // The Beacon initialization code seems to expect this dummy global function to exist. It will fail to
    // load if not done in precisely this order. The code here closely follows the minified js they provide.
    window.Beacon = function (method, options, data) {
      window.Beacon?.readyQueue && window.Beacon.readyQueue.push({ method, options, data })
    } as BeaconType
    window.Beacon.readyQueue = []
  }
  useLoadScript('help-scout-beacon', 'https://beacon-v2.helpscout.net', callback)
  return beacon
}

export const HelpScoutBeacon = () => {
  const beacon = useHelpScout()
  React.useEffect(() => {
    if (!beacon) return

    beacon('init', process.env.HELP_SCOUT_BEACON_ID || '1e3ebc29-34e0-4179-ac63-5ccce616c554')
    beacon('config', { display: { style: 'manual' } }) // ensure the beacon is hidden until it is explicitly called for
  }, [beacon])

  return null
}
