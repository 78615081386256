import * as React from 'react'

type StripeConnectComponentsProviderProps = {
  orgId: number
  children: React.ReactNode
}
const INTERNAL_StripeConnectComponentsProvider = React.lazy(() =>
  import('./StripeConnectComponentsProvider_lazy').then((module) => ({
    default: module.INTERNAL_StripeConnectComponentsProvider,
  }))
)

export const StripeConnectComponentsProvider = ({
  orgId,
  children,
}: StripeConnectComponentsProviderProps) => (
  <React.Suspense fallback={null}>
    <INTERNAL_StripeConnectComponentsProvider orgId={orgId}>
      {children}
    </INTERNAL_StripeConnectComponentsProvider>
  </React.Suspense>
)
