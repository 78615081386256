import { csrf } from './auth'
import { GqlData, GqlResponse } from './gql/fetchGql'
import { ApiRootException, NotFoundError, SessionExpiredError, UnauthorizedError } from './responseErrors'
import { ApiErrorResponse } from './types'

const AUTH_ENDPOINTS = ['/sign_in', '/sign_out']

/**
 * Type guard to parse an HTTP response body for error information. Note that we also
 * make sure that `data` does not exist, which would indicate that the response is from
 * a GraphQL call that is returning at least _some_ usable data.
 */
const isApiErrorResponse = <TResponse>(resBody?: TResponse | ApiErrorResponse): resBody is ApiErrorResponse =>
  Boolean(resBody) &&
  typeof resBody === 'object' &&
  !Array.isArray(resBody) &&
  Boolean((resBody as ApiErrorResponse).error && !(resBody as GqlResponse<GqlData>).data)

const INVALID_AUTH_TOKEN_MESSAGES = ['Invalid Authenticity Token', "Can't verify CSRF token authenticity."]
const isInvalidAuthTokenError = <TResponse>(json: TResponse | ApiErrorResponse) =>
  isApiErrorResponse(json) &&
  typeof json.error === 'string' &&
  INVALID_AUTH_TOKEN_MESSAGES.includes(json.error)

const isSessionExpiredError = <TResponse>(
  json: TResponse | ApiErrorResponse
): json is ApiErrorResponse<string> =>
  isApiErrorResponse(json) && json.error === 'Your session expired. Please sign in again to continue.'

export const fetchCsrf = () => fetcher('/api/csrf')
/**
 * This is a little hacky, I know, but it works as a recovery mechanism when a user makes
 * a REST API request with a stale CSRF, this function will send a fresh API request in
 * order to get a new CSRF token before retrying the request.
 */
const recoverFromStaleAuthenticityToken = <TResponse>(
  pathname: string,
  fetchOptions?: RequestInit
): Promise<TResponse> => fetchCsrf().then(() => fetcher<TResponse>(pathname, fetchOptions, true))

// Default API calls to current domain, but allow override
const apiBase = process.env.ALICE_ORIGIN || ''

export const HTTP_HEADER_CSRF_TOKEN = 'x-csrf-token'
const handleAuthenticityToken = <T extends Response>(response: T): T => {
  if (response.headers.has(HTTP_HEADER_CSRF_TOKEN)) {
    csrf.set(response.headers.get(HTTP_HEADER_CSRF_TOKEN) || '')
  }
  return response
}

const _getCsrfBody = (body?: BodyInit | null): BodyInit => {
  const csrfToken = csrf.get() || ''
  const authorizedBody = { authenticity_token: csrfToken }
  if (typeof body === 'string') {
    const bodyObj = JSON.parse(body)
    return JSON.stringify({ ...bodyObj, ...authorizedBody })
  }
  if (body instanceof URLSearchParams) {
    body.set('authenticity_token', csrfToken)
    return body
  }
  if (body instanceof FormData) {
    body.set('authenticity_token', csrfToken)
    return body
  }

  return JSON.stringify(authorizedBody)
}

/**
 * Return a request config object that will
 * 1. pass CSRF validation on the back end. Currently, this is done by injecting a value into
 * the request body
 * 2. Satisfy expected headers
 */
const adaptFetchOptions = (fetchOptions: RequestInit): RequestInit => {
  const adaptedFetchOptions: RequestInit = {
    credentials: process.env.NODE_ENV !== 'production' ? 'include' : 'same-origin',
    ...fetchOptions,
  }

  const headers: Record<string, string> = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    ...(fetchOptions.headers as Record<string, string>),
  }
  if (fetchOptions.body instanceof FormData) {
    delete headers['Content-Type'] // https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
  }
  adaptedFetchOptions.headers = headers

  if (adaptedFetchOptions.method && adaptedFetchOptions.method.toLowerCase() !== 'get') {
    adaptedFetchOptions.body = _getCsrfBody(fetchOptions.body)
  }
  return adaptedFetchOptions
}

/**
 * 404s sometimes have a JSON body with user friendly error messages that we can set on the NotFoundError,
 * but they should _always_ result in a NotFoundError
 */
const handle404Response = <TResponse>(response: Response, pathname: string): Promise<never> => {
  const genericNotFound = new NotFoundError(response.statusText || `No result for ${pathname}`, pathname)
  return response.json().then(
    (json: TResponse | ApiErrorResponse) => {
      if (isApiErrorResponse(json)) throw new NotFoundError(json, pathname)
      throw genericNotFound
    },
    () => {
      // ignore json parsing error
      throw genericNotFound
    }
  )
}

/**
 * This function will handle any response that is expected to have a valid JSON
 * body. It can also handle unparseable JSON but will generally throw an error in that case
 */
const parseJsonResponse = <TResponse>(response: Response): Promise<TResponse> =>
  response.json().then((json: TResponse | ApiErrorResponse) => {
    if (isApiErrorResponse(json)) throw new ApiRootException(json, response.url)

    return json
  })
/**
 * The root level function for correctly fetching from the Tilda API - this
 * should generally not be called directly, but wrapped by other functions that
 * target REST or GraphQL endpoints
 */
export const fetcher = <TResponse>(
  pathname: string,
  fetchOptions: RequestInit = {},
  skipRecovery?: boolean // avoid infinite loop if the CSRF token is persistently invalid for some reason
): Promise<TResponse> => {
  const adaptedFetchOptions = adaptFetchOptions(fetchOptions)

  return fetch(`${apiBase}${pathname}`, adaptedFetchOptions)
    .then(handleAuthenticityToken)
    .then((response) => {
      if (response.status === 404) return handle404Response(response, pathname)
      if (response.status === 204) return response.text() as TResponse // NO CONTENT, e.g. for /sign_out
      if (response.status === 403) throw new UnauthorizedError(response.statusText, pathname)
      if (response.status === 401 && !AUTH_ENDPOINTS.includes(pathname)) {
        const unauthedError = new UnauthorizedError(response.statusText, pathname)
        // This handler is only for non-auth endpoints, which will return a 401 for bad input - we need to
        // handle that case separately in order to display an actionable error message to the user

        // we might be able to automatically recover from a 401 if it's just from stale credentials
        if (skipRecovery) throw unauthedError

        // check the body of the response for the specific message related to a recoverable error state
        return response.json().then((json: TResponse | ApiErrorResponse) => {
          if (isInvalidAuthTokenError(json)) {
            return recoverFromStaleAuthenticityToken<TResponse>(pathname, fetchOptions)
          }
          if (isSessionExpiredError(json)) {
            throw new SessionExpiredError(json.error, pathname) // specific kind of 401 that is clearly actionable
          }
          throw unauthedError
        })
      }

      return parseJsonResponse<TResponse>(response)
    })
}
