import { List, ListItem, ListItemButton, ListItemText } from '@alice-financial/pretext-ui'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import * as React from 'react'
import { useIntl } from 'react-intl'

const DocumentListContent = ({ name }: { name: string }) => {
  return (
    <>
      <ListItemText primary={name} primaryTypographyProps={{ lineHeight: 1.2 }} />
      <OpenInNewOutlinedIcon color="primary" />
    </>
  )
}

export const EmployeeDocumentList = () => {
  const intl = useIntl()

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton href="https://stripe.com/issuing/e-sign-disclosure/legal" target="_blank">
          <DocumentListContent name={intl.formatMessage({ id: 'cards.alice.stripe-card-agreement' })} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton href="https://stripe.com/legal/issuing/celtic-authorized-user-terms" target="_blank">
          <DocumentListContent name={intl.formatMessage({ id: 'cards.alice.stripe-card-user-terms' })} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton
          href="https://stripe.com/legal/issuing/celtic/apple-payment-platform-program-manager-customer-terms-and-conditions"
          target="_blank"
        >
          <DocumentListContent name={intl.formatMessage({ id: 'cards.alice.apple-pay-terms' })} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton href="/docs/esign-consent" target="_blank">
          <DocumentListContent
            name={intl.formatMessage({
              id: 'enroll.register.legal_agreement.esign_disclosure_and_consent',
            })}
          />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton href="/docs/tos-employee" target="_blank">
          <DocumentListContent
            name={intl.formatMessage({ id: 'enroll.register.legal_agreement.terms_and_conditions' })}
          />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton href="/docs/privacy_policy" target="_blank">
          <DocumentListContent name={intl.formatMessage({ id: 'footer.privacy_policy' })} />
        </ListItemButton>
      </ListItem>
    </List>
  )
}
