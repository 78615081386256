import { AliceWordmarkGreen, Grid, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { SwitchLanguage } from '../../../../translations/LocalizationProvider'
import { useEnrollmentEmployer } from '../../useEnrollmentEmployer'
import valuePropGraphicUrl from './assets/value-prop.png'
import { CarouselSlide, SlideImage } from './CarouselSlide'

export const Landing = ({ isActive }: { isActive: boolean }) => {
  const { data: employerData } = useEnrollmentEmployer()
  const employer = employerData?.enrollmentEmployer
  const employerName = employer?.marketingFriendlyName
  return (
    <CarouselSlide
      isActive={isActive}
      title={
        <>
          <Typography variant="h1" gutterBottom>
            <AliceWordmarkGreen height={32} width={200} title="Alice" />
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            textAlign="center"
            fontWeight={500}
            maxWidth={500}
            marginX="auto"
          >
            <FormattedMessage id="enroll.landing.get_ready_to_save" />
          </Typography>
        </>
      }
      graphic={<SlideImage src={valuePropGraphicUrl} />}
    >
      <Grid
        container
        justifyContent="center"
        textAlign="center"
        marginBottom={2}
        sx={(theme) => ({
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
        })}
      >
        <SwitchLanguage />
      </Grid>
      <Typography gutterBottom data-testid="enrollment-employer">
        <FormattedMessage id="enroll.landing.alice_has_partnered_with" values={{ employerName }} />{' '}
      </Typography>

      {employer && employer.enrolledCount > 2 && (
        <Typography gutterBottom>
          <FormattedMessage
            id="enroll.landing.num_employees_enrolled"
            values={{ employerName, employeeCount: employer.enrolledCount }}
          />
        </Typography>
      )}
    </CarouselSlide>
  )
}
