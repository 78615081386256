import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import * as React from 'react'

export const DocumentsPage = () => {
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Documents
      </Typography>
      <List>
        <ListItem disablePadding divider>
          <ListItemButton href="/docs/tos-organization" target="_blank">
            <ListItemIcon>
              <DescriptionOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Organization Terms and Conditions" />
            <OpenInNewOutlinedIcon color="primary" />
          </ListItemButton>
        </ListItem>
      </List>
    </PageBody>
  )
}
