import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { LoginFormValues } from './types'

const submitCredentials = (loginFormValues: LoginFormValues) => {
  return fetchRest<''>(`/sign_in`, {
    method: 'POST',
    body: JSON.stringify({ user: loginFormValues }),
  })
}
type UseSubmitCredentialsMutationOptions = UseRestMutationCallbacks<'', LoginFormValues>

export const useSubmitCredentials = (mutationOptions: UseSubmitCredentialsMutationOptions = {}) => {
  const intl = useIntl()
  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'auth.login.logging_in' }),
      onSuccess: false,
    },
    mutationOptions
  )
  return useMutationWithInvalidation(
    submitCredentials,
    _mutationOptions,
    'all' // on login, _everything_ needs to be reloaded
  )
}
