import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ReportMissingAliceCardMutationVariables = Types.Exact<{
  aliceCardId: Types.Scalars['Int']['input']
}>

export type ReportMissingAliceCardMutation = {
  __typename?: 'Mutation'
  reportMissingAliceCard?: { __typename?: 'ReportMissingAliceCardPayload'; success: boolean } | null
}

export const ReportMissingAliceCardDocument = `
    mutation ReportMissingAliceCard($aliceCardId: Int!) {
  reportMissingAliceCard(input: {aliceCardId: $aliceCardId}) {
    success
  }
}
    `

export const useReportMissingAliceCardMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    ReportMissingAliceCardMutation,
    TError,
    ReportMissingAliceCardMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ReportMissingAliceCardMutation,
    TError,
    ReportMissingAliceCardMutationVariables,
    TContext
  >(
    ['ReportMissingAliceCard'],
    (variables?: ReportMissingAliceCardMutationVariables) =>
      fetchGql<ReportMissingAliceCardMutation, ReportMissingAliceCardMutationVariables>(
        ReportMissingAliceCardDocument,
        variables
      )(),
    options
  )
}
