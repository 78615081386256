import { Box, Callout, Collapse, Container, Link, PII, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLoginPath } from '../../../auth/useLoginPath'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { CreateOrgAdminForm } from './CreateOrgAdminForm'

const LoginCallout = () => {
  const loginPath = useLoginPath()

  return (
    <Callout gutterBottom>
      <FormattedMessage id="onboarding.already_have_account" />
      <Link to={loginPath}>
        {' '}
        <FormattedMessage id="organization.onboarding.login" />
      </Link>
    </Callout>
  )
}

export const CreateOrgAdminStep = () => {
  const { data: currentUserData, isLoading: disabled } = useCurrentUserQuery()
  const currentUser = currentUserData?.currentUser
  return (
    <Container>
      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="organization.onboarding.setup_account_heading" />
      </Typography>
      {currentUser && (
        <Typography gutterBottom>
          <PII>
            <strong>
              {currentUser.firstName} {currentUser.lastName}
            </strong>{' '}
            ({currentUser.email})
          </PII>
        </Typography>
      )}
      <Collapse in={!currentUser}>
        <LoginCallout />
        <Box component={Typography} gutterBottom>
          <FormattedMessage id="organization.onboarding.setup_account_instructions" />
        </Box>
        <CreateOrgAdminForm disabled={disabled} />
      </Collapse>
    </Container>
  )
}
