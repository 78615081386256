import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../../../utils/useMutationNotifier'
import { useEmployeeEnrollmentInfoQuery } from '../../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { CreateFinicityConnectionInput } from '../../../../graphql/generated.types'
import { useConnectedCardsQuery } from '../../gql/connectedCardsQuery_gen'
import {
  CreateFinicityConnectionMutation,
  useCreateFinicityConnectionMutation,
} from './gql/createFinicityConnection_gen'
import { useEmployeeQuery } from '../../../gql/employee_gen'

type UseCreateFinicityConnectionOptions = UseGqlMutationCallbacks<
  CreateFinicityConnectionMutation,
  Omit<CreateFinicityConnectionInput, 'employeeId'>
>

export const useCreateFinicityConnection = (mutationOptions: UseCreateFinicityConnectionOptions) => {
  const { data: employeeData } = useEmployeeQuery()
  const employeeId = employeeData?.employee?.id
  const { mutateAsync: createFinicityConnection } = useCreateFinicityConnectionMutation()
  const optionsWithNotification = useMutationNotifier({}, mutationOptions)

  return useMutationWithInvalidation(
    ({ institutionPlatformId }) => {
      if (!employeeId) throw new Error('Employee not found')
      return createFinicityConnection({ input: { employeeId, institutionPlatformId } })
    },
    optionsWithNotification,
    [
      useConnectedCardsQuery.getKey(),
      useEmployeeEnrollmentInfoQuery.getKey(), // because card connections affect account_status
    ]
  )
}
