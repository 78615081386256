import { Skeleton, styled } from '@mui/material'

/**
 * A variant placeholder 'Skeleton' element that will flow inline with paragraph
 * text - use this as a fallback for specific data display content before it is
 * available.
 *
 * @example
 *
 * <Typography>Hello {user?.name || <InlineSkeleton width='6em' />}</Typography>
 */
export const InlineSkeleton = styled(Skeleton)({ display: 'inline-block', minWidth: '3em' })
