import {
  ActionLayout,
  Button,
  Grid,
  InlineEmailInputController,
  InlineInputController,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { GqlUserFragment, useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { AdminSignupFormValues, useSignup } from '../useSignup'

const getUserValues = (user: GqlUserFragment): Partial<AdminSignupFormValues> => {
  return {
    first_name: user?.firstName || undefined,
    last_name: user?.lastName || undefined,
    preferred_name: user?.preferredName || undefined,
    email: user?.email || undefined,
  }
}
const useDefaultAdminValues = (user?: GqlUserFragment | null): Partial<AdminSignupFormValues> => {
  return {
    first_name: user?.firstName ?? '',
    last_name: user?.lastName ?? '',
    preferred_name: user?.preferredName ?? '',
    email: user?.email ?? '',
    password: '',
    password_confirmation: '',
    accept_legal: false,
    accept_employer_tos: false,
  }
}

type CreateOrgAdminFormProps = {
  disabled?: boolean
}

export const CreateOrgAdminForm = ({ disabled }: CreateOrgAdminFormProps) => {
  const intl = useIntl()
  const { data: currentUserData } = useCurrentUserQuery()
  const currentUser = currentUserData?.currentUser

  const defaultValues = useDefaultAdminValues(currentUser)
  const { control, handleSubmit, watch, formState, reset, getValues } = useForm<AdminSignupFormValues>({
    defaultValues,
  })
  // If the onboardingUser is authenticated before submission, the form should populate with existing values
  React.useEffect(() => {
    if (!currentUser || formState.isSubmitted) return
    reset({ ...getValues(), ...getUserValues(currentUser) })
  }, [currentUser, reset, getValues, formState.isSubmitted])

  const password = watch('password')
  const { mutate: signup } = useSignup()
  const onSubmit = handleSubmit((values) => signup(values))
  const formDisabled = Boolean(disabled || (currentUser && !currentUser.hasDummyPassword))
  return (
    <>
      <form data-testid="create-org-admin" onSubmit={onSubmit}>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12} sm={6}>
            <InlineInputController
              control={control}
              label="First name"
              name="first_name"
              autoComplete="given-name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.name.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InlineInputController
              control={control}
              label="Last name"
              name="last_name"
              autoComplete="family-name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.name.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InlineInputController
              control={control}
              label="Preferred name"
              name="preferred_name"
              autoComplete="given-name"
              helperText="What should we call you?"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.name.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InlineEmailInputController
              name="email"
              required
              control={control}
              label="Work email"
              autoComplete="username"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InlineInputController
              control={control}
              label="New password"
              name="password"
              type="password"
              autoComplete="new-password"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.password.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InlineInputController
              control={control}
              label="Confirm password"
              name="password_confirmation"
              type="password"
              autoComplete="new-password"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={formDisabled}
              rules={{
                validate: (value) =>
                  value === password || intl.formatMessage({ id: 'auth.reset_password.validation.no_match' }),
              }}
            />
          </Grid>
        </Grid>
        <ActionLayout
          mb={0}
          primary={
            <Button type="submit" variant="contained" fullWidth>
              Create account
            </Button>
          }
        />
      </form>
    </>
  )
}
