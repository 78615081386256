import * as Types from '../generated.types'

export type ErrorFragment = { __typename?: 'Error'; message?: string | null; code?: string | null }

export const ErrorFragmentDoc = `
    fragment Error on Error {
  message
  code
}
    `
