import { RestApiError } from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { UseCardConnectorConfig } from './types'

const formatErrorMessage = (error: RestApiError | string | undefined, intl: IntlShape) => {
  switch (typeof error) {
    case 'string':
      return error
    case 'object':
      return error.message
    default:
      return intl.formatMessage({ id: 'connections.error_with_connection' })
  }
}

export const useConnectorConfig = ({ onComplete }: { onComplete: () => void }): UseCardConnectorConfig => {
  const { notifyError } = useResponseNotification()
  const intl = useIntl()
  const onError = React.useCallback(
    (error?: RestApiError | string) => {
      const errorMessage = formatErrorMessage(error, intl)
      notifyError(errorMessage)
      onComplete()
      if (error) datadogRum.addError(error)
    },
    [intl, onComplete, notifyError]
  )
  return {
    onCancel: onComplete,
    onSuccess: onComplete,
    onError,
  }
}
