import { useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { useAnalytics } from '../telemetry/AnalyticsProvider'
import { useLogoutMutation } from './gql/logout_gen'

/**
 * A hook that will end the current user session and clear local state.
 */
export const useLogout = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { mutate: logout, isLoading } = useLogoutMutation({
    onSuccess: () => {
      queryClient.clear()
      analytics.reset() // clear analytics tracking IDs from local storage/cookies
      window.location.assign('/') // hard refresh to root of application
    },
  })
  React.useEffect(() => {
    if (isLoading) return
    logout({})
  }, [logout, isLoading])
}
