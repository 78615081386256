import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateIssuingSetupIntentMutationVariables = Types.Exact<{
  input: Types.CreateIssuingSetupIntentInput
}>

export type CreateIssuingSetupIntentMutation = {
  __typename?: 'Mutation'
  createIssuingSetupIntent?: {
    __typename?: 'CreateIssuingSetupIntentPayload'
    clientSecret?: string | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateIssuingSetupIntentDocument = `
    mutation CreateIssuingSetupIntent($input: CreateIssuingSetupIntentInput!) {
  createIssuingSetupIntent(input: $input) {
    clientSecret
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateIssuingSetupIntentMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateIssuingSetupIntentMutation,
    TError,
    CreateIssuingSetupIntentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateIssuingSetupIntentMutation,
    TError,
    CreateIssuingSetupIntentMutationVariables,
    TContext
  >(
    ['CreateIssuingSetupIntent'],
    (variables?: CreateIssuingSetupIntentMutationVariables) =>
      fetchGql<CreateIssuingSetupIntentMutation, CreateIssuingSetupIntentMutationVariables>(
        CreateIssuingSetupIntentDocument,
        variables
      )(),
    options
  )
}
