import { AppBar, AppBarProps, Box, Typography } from '@mui/material'
import * as React from 'react'
import { AliceWordmarkGreen, AliceWordmarkWhite } from '../../assets'
import { Grid } from '../../layout'
import { Link } from '../../navigation'
import { Toolbar } from '../../surfaces'

export type AppType = 'user' | 'employee' | 'admin'
// `default` when the user is logged in and `primary` when the user is logged out
export type HeaderColor = 'default' | 'primary'

const APP_COLOR: Record<AppType, HeaderColor> = {
  user: 'primary',
  employee: 'default',
  admin: 'default',
}

export const HeaderTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h2" component="h1" margin={0} noWrap>
    {children}
  </Typography>
)

// Properties are optional because the Header component is called from different
// places such as the Demo component where no props are passed and defaults are used,
// whereas when called from the RouteChrome component all props passed.
export interface HeaderProps extends AppBarProps {
  type?: AppType
  pageTitle?: React.ReactNode
  titleOffset?: number
  homepagePath?: string
  children?: React.ReactNode
  breadcrumbs?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({
  type = 'user',
  pageTitle,
  titleOffset = 0,
  homepagePath = '/',
  children,
  breadcrumbs,
  ...appBarProps
}: HeaderProps) => {
  const color = APP_COLOR[type] || 'default'
  return (
    <AppBar
      color={color}
      position="relative"
      {...appBarProps}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: (theme) =>
          type === 'admin' ? `2px solid ${theme.palette.secondary.light}` : undefined,
        ...appBarProps.sx,
      }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Grid container alignItems="center" columnGap={0} wrap="nowrap">
          <Grid item sx={{ width: titleOffset || undefined }}>
            {color === 'default' ? (
              <Link to={homepagePath} sx={{ textDecoration: 'none', display: 'block' }}>
                <AliceWordmarkGreen height={20} width={95} />
              </Link>
            ) : (
              <Link to={homepagePath}>
                <AliceWordmarkWhite height={20} width={100} />
              </Link>
            )}
            {breadcrumbs && (
              <Box height="1em" marginBottom="-0.8em" marginTop="-0.3em">
                {breadcrumbs}
              </Box>
            )}
          </Grid>
          <Grid item flexGrow={1} overflow="hidden">
            {pageTitle}
          </Grid>

          {children}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
