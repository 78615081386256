import { Box, FormLabel, Select, SelectChangeEvent } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useParams } from 'react-router'
import { isNotNull } from '../../../utils/typeUtils'
import { useOrg } from '../organization/useOrg'
import { useFilter } from './useFilter'

const isString = (value: string): value is string => typeof value === 'string'

export const usePaygroupFilter = () => {
  const { employerId } = useParams()
  const [paygroupId, setPaygroupIds] = useFilter('paygroup_id', { typeGuard: isString, override: employerId })
  const setPaygroupId = (paygroupId: string | undefined) => setPaygroupIds(paygroupId)
  return [paygroupId, setPaygroupId] as const
}

const ALL = '_all_'
export const PaygroupFilter = () => {
  const { employerId } = useParams()
  const org = useOrg()
  const [paygroupId, updatePaygroupId] = usePaygroupFilter()

  if (!org) return null
  const disabled = Boolean(employerId)

  const onChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value
    updatePaygroupId(value && value === ALL ? undefined : value)
  }
  return (
    <Box display="flex" flexDirection="column">
      <FormLabel disabled={disabled}>Paygroup</FormLabel>
      <Select
        name="paygroup_id"
        variant="outlined"
        native
        onChange={onChange}
        defaultValue={paygroupId?.toString()}
        disabled={disabled}
      >
        <option value={ALL}>all</option>
        {org.employers.filter(isNotNull).map((paygroup) => (
          <option key={paygroup.id} value={paygroup.id}>
            {paygroup.name}
          </option>
        ))}
      </Select>
    </Box>
  )
}
