import * as Types from '../../../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EphemeralKeyQueryVariables = Types.Exact<{
  cardId: Types.Scalars['Int']['input']
  nonce?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type EphemeralKeyQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    spendingConnectionInfo: {
      __typename?: 'SpendingConnectionInfo'
      aliceCard?: { __typename?: 'AliceCard'; id: number; ephemeralKey?: any | null } | null
    }
  } | null
}

export const EphemeralKeyDocument = `
    query EphemeralKey($cardId: Int!, $nonce: String) {
  employee {
    spendingConnectionInfo {
      aliceCard(id: $cardId) {
        id
        ephemeralKey(nonce: $nonce)
      }
    }
  }
}
    `

export const useEphemeralKeyQuery = <TData = EphemeralKeyQuery, TError = Error>(
  variables: EphemeralKeyQueryVariables,
  options?: UseQueryOptions<EphemeralKeyQuery, TError, TData>
) => {
  return useQuery<EphemeralKeyQuery, TError, TData>(
    ['EphemeralKey', variables],
    fetchGql<EphemeralKeyQuery, EphemeralKeyQueryVariables>(EphemeralKeyDocument, variables),
    options
  )
}

useEphemeralKeyQuery.getKey = (variables: EphemeralKeyQueryVariables) => ['EphemeralKey', variables]
