import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateBillingConfigMutationVariables = Types.Exact<{
  input: Types.UpdateBillingConfigInput
}>

export type UpdateBillingConfigMutation = {
  __typename?: 'Mutation'
  updateBillingConfig?: {
    __typename?: 'UpdateBillingConfigPayload'
    organization?: { __typename?: 'Organization'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const UpdateBillingConfigDocument = `
    mutation UpdateBillingConfig($input: UpdateBillingConfigInput!) {
  updateBillingConfig(input: $input) {
    organization {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useUpdateBillingConfigMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBillingConfigMutation,
    TError,
    UpdateBillingConfigMutationVariables,
    TContext
  >
) => {
  return useMutation<UpdateBillingConfigMutation, TError, UpdateBillingConfigMutationVariables, TContext>(
    ['UpdateBillingConfig'],
    (variables?: UpdateBillingConfigMutationVariables) =>
      fetchGql<UpdateBillingConfigMutation, UpdateBillingConfigMutationVariables>(
        UpdateBillingConfigDocument,
        variables
      )(),
    options
  )
}
