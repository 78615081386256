import { PageBody, RawHtml } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useScrapeHtml } from './useHtml'

export const CustodialAccountAgreement = () => {
  const { data: __html } = useScrapeHtml('https://app.thisisalice.com/tos')
  if (!__html) return null
  return (
    <PageBody>
      <RawHtml dangerouslySetInnerHTML={{ __html }} />
    </PageBody>
  )
}
