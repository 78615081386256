const centsToDollars = (value: number) => value / 100
const dollarsToCents = (value: number) => Math.round(value * 100)

export const convertMoneyUnit = (value: number, unit: 'cents' | 'dollars'): number => {
  if (unit === 'cents') return centsToDollars(value)
  if (unit === 'dollars') return dollarsToCents(value)
  throw new Error(`money unit ${unit} not supported`)
}

const FORMAT_NO_CENTS = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format
const FORMAT_WITH_CENTS = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format

export const formatDollar = (value = 0, removeZeroCents = true): string =>
  removeZeroCents && Number.isInteger(value) ? FORMAT_NO_CENTS(value) : FORMAT_WITH_CENTS(value)
export const formatCentsAsDollars = (value: string | number | undefined, removeZeroCents = true) => {
  if (value === undefined || value === '') return '$0'
  return formatDollar(convertMoneyUnit(parseFloat(value.toString()), 'cents'), removeZeroCents)
}
