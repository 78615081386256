import { Button, Grid, Typography } from '@alice-financial/pretext-ui'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { PageInfoFragment } from '../../../app/graphql/fragments/PageInfoFragment_gen'
import { usePageSizePagingContext } from './PageSizePagingContext'

type PageSizePagerControlsProps = {
  pageInfo: PageInfoFragment
  children?: React.ReactNode
}
export const PageSizePagerControls = ({ pageInfo, children }: PageSizePagerControlsProps) => {
  const [_paging, setPaging] = usePageSizePagingContext()

  if (!pageInfo.endCursor || !pageInfo.startCursor) return null

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        component={Typography}
        fontWeight="bold"
        textAlign="center"
      >
        <Button
          disabled={!pageInfo.hasPreviousPage}
          onClick={() => setPaging({ before: pageInfo.startCursor })}
        >
          <ChevronLeftIcon />
          Newer
        </Button>
        {children}
        <Button disabled={!pageInfo.hasNextPage} onClick={() => setPaging({ after: pageInfo.endCursor })}>
          Older
          <ChevronRightIcon />
        </Button>
      </Grid>
    </>
  )
}
