import * as Types from '../../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateFinicityConnectionMutationVariables = Types.Exact<{
  input: Types.CreateFinicityConnectionInput
}>

export type CreateFinicityConnectionMutation = {
  __typename?: 'Mutation'
  createFinicityConnection?: {
    __typename?: 'CreateFinicityConnectionPayload'
    success: boolean
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateFinicityConnectionDocument = `
    mutation CreateFinicityConnection($input: CreateFinicityConnectionInput!) {
  createFinicityConnection(input: $input) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateFinicityConnectionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFinicityConnectionMutation,
    TError,
    CreateFinicityConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateFinicityConnectionMutation,
    TError,
    CreateFinicityConnectionMutationVariables,
    TContext
  >(
    ['CreateFinicityConnection'],
    (variables?: CreateFinicityConnectionMutationVariables) =>
      fetchGql<CreateFinicityConnectionMutation, CreateFinicityConnectionMutationVariables>(
        CreateFinicityConnectionDocument,
        variables
      )(),
    options
  )
}
