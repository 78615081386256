import CheckIcon from '@mui/icons-material/Check'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { StepIconProps, styled, Theme } from '@mui/material'
import * as React from 'react'

type StepState = 'active' | 'error' | 'completed' | 'incomplete'
const STEP_COLOR: Record<StepState, (theme: Theme) => string> = {
  active: (theme) => theme.palette.primary.main,
  completed: (theme) => theme.palette.primary.main,
  error: (theme) => theme.palette.error.main,
  incomplete: (theme) => theme.palette.action.disabled,
}
type StepCheckIconRootProps = { ownerState: { active?: boolean; error?: boolean; completed?: boolean } }
/**
 * A wrapper for any icon - provides a circular background that will center and size the icon
 * appropriately
 */
const StepCheckIconRoot = styled('div')<StepCheckIconRootProps>(
  ({ theme, ownerState: { active, error, completed } }) => {
    const isIncomplete = !active && !completed

    if (isIncomplete) {
      return {
        borderRadius: '100%',
        height: '100%',
        width: '100%',
        border: `3px inset ${STEP_COLOR.incomplete(theme)}`,
      }
    }

    const getColor =
      (active && STEP_COLOR.active) ||
      (completed && STEP_COLOR.completed) ||
      (error && STEP_COLOR.error) ||
      STEP_COLOR.incomplete
    const backgroundColor = getColor(theme)
    return {
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
      borderRadius: '100%',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        height: '80%',
        width: '80%',
      },
    }
  }
)

/**
 * The preferred StepIconComponent for `StepLabel` - this is assigned as the
 * default step icon component, but can be overridden
 */
export const StepIcon = ({ active, error, completed }: StepIconProps) => (
  <StepCheckIconRoot ownerState={{ active, error, completed }}>
    {error ? <PriorityHighIcon /> : active || completed ? <CheckIcon /> : null}
  </StepCheckIconRoot>
)
