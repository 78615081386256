import { UseGqlMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useMutationNotifier } from '../../../../utils/useMutationNotifier'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { EmployeeRole } from '../../../graphql/generated.types'
import { useCreateOrgAdminMutation } from '../../homepage/gql/createOrgAdmin_gen'
import {
  NotifyAdminKycMutation,
  NotifyAdminKycMutationVariables,
  useNotifyAdminKycMutation,
} from '../../homepage/gql/notifyAdminKYC_gen'

type RequestKYCHelpFormValues = {
  firstName: string
  lastName: string
  email: string
}

type NotifyAdminKycMutationOptions = UseGqlMutationCallbacks<
  NotifyAdminKycMutation,
  NotifyAdminKycMutationVariables
>

export type UseRequestKYCHelpOptions = NotifyAdminKycMutationOptions & { org: OrganizationDetailFragment }

export const useNotifyAdminKyc = ({ org, ...mutationOptions }: UseRequestKYCHelpOptions) => {
  const notifyAdminKycOptions: NotifyAdminKycMutationOptions = useMutationNotifier(
    { onSuccess: 'Admininistrator has been invited to help complete Alice Card setup' },
    mutationOptions
  )
  const { mutateAsync: notifyAdminKyc } = useNotifyAdminKycMutation(notifyAdminKycOptions)
  return useMutation<unknown, unknown, { adminId: number }>(['notifyAdminKyc'], ({ adminId }) =>
    notifyAdminKyc({ adminId, orgId: org.id })
  )
}

export const useAddAdminAndNotifyKyc = ({ org, onSuccess }: UseRequestKYCHelpOptions) => {
  const { handleSubmit, ...form } = useForm<RequestKYCHelpFormValues>({
    defaultValues: { firstName: '', lastName: '', email: '' },
  })
  const { mutate: notifyAdminKyc } = useNotifyAdminKyc({ org, onSuccess })
  const { mutate: createAdminAndNotifyKyc } = useCreateOrgAdminMutation({
    onSuccess: (data) => {
      if (!data.createEmployee?.employee) throw new Error('Invalid admin data')
      notifyAdminKyc({ adminId: data.createEmployee.employee.id })
    },
  })
  const onSubmit = handleSubmit(({ firstName, lastName, email }) =>
    createAdminAndNotifyKyc({
      input: { role: EmployeeRole.OrganizationAdmin, firstName, lastName, email },
    })
  )

  return { onSubmit, ...form }
}
