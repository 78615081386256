import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useOrg } from '../../organization/useOrg'
import {
  UpdateAliceCardProgramMutation,
  useUpdateAliceCardProgramMutation,
} from './gql/updateAliceCardProgram_gen'
import { useOrgId } from '../../useOrgId'

type UseSetUnifiedFundingMutationOptions = UseGqlMutationCallbacks<
  UpdateAliceCardProgramMutation,
  { hasUnifiedBanking: boolean }
>
export const useSetUnifiedFunding = (
  { orgId }: { orgId?: number },
  mutationOptions?: UseSetUnifiedFundingMutationOptions
) => {
  const { mutateAsync: updateAliceCardProgram } = useUpdateAliceCardProgramMutation()
  const mutateOptionsWithInvalidation = useMutationOptionsWithInvalidation(mutationOptions, [
    useOrg.getKey({ orgId: useOrgId() }),
  ])
  return useMutation(
    ['setUnifiedBanking'],
    ({ hasUnifiedBanking }) => {
      if (!orgId) throw new Error('Must wait until org is loaded')
      return updateAliceCardProgram({ input: { orgId, hasUnifiedBanking } })
    },
    mutateOptionsWithInvalidation
  )
}
