import { List, ListItem, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { isNotNull } from '../../../utils/typeUtils'
import { EmployerSummaryFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { BillableBilling } from './BillableBilling'

type WithId = { id: number }
const sortById = (a: WithId, b: WithId) => {
  if (a.id < b.id) {
    return -1
  }
  if (a.id > b.id) {
    return 1
  }
  return 0
}

export const PaygroupBillingList = ({ employers }: { employers: Array<EmployerSummaryFragment> }) => {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Pay group billing accounts
      </Typography>
      <List sx={{ width: '100%' }}>
        {employers
          .filter(isNotNull)
          .sort(sortById)
          .map((employer) => (
            <ListItem key={employer.id} divider>
              <BillableBilling billable={employer} />
            </ListItem>
          ))}
      </List>
    </>
  )
}
