import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateWorkLocationMutationVariables = Types.Exact<{
  input: Types.UpdateWorkLocationInput
}>

export type UpdateWorkLocationMutation = {
  __typename?: 'Mutation'
  updateWorkLocation?: {
    __typename?: 'UpdateWorkLocationPayload'
    id: number
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const UpdateWorkLocationDocument = `
    mutation UpdateWorkLocation($input: UpdateWorkLocationInput!) {
  updateWorkLocation(input: $input) {
    id
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useUpdateWorkLocationMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateWorkLocationMutation,
    TError,
    UpdateWorkLocationMutationVariables,
    TContext
  >
) => {
  return useMutation<UpdateWorkLocationMutation, TError, UpdateWorkLocationMutationVariables, TContext>(
    ['UpdateWorkLocation'],
    (variables?: UpdateWorkLocationMutationVariables) =>
      fetchGql<UpdateWorkLocationMutation, UpdateWorkLocationMutationVariables>(
        UpdateWorkLocationDocument,
        variables
      )(),
    options
  )
}
