import { Box, Container, Section, SectionCTA, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { useIsEnrolling } from '../../enrollment/useEnrollmentStatus'
import { PageBody } from '../../routes/PageBody'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { CardConnectionsList } from '../cards/CardsIndex'
import { CommutingSpendingSummary } from '../spending/CommutingSpendingSummary'
import { HomepageAutoConfirmationAlert } from './alerts/HomepageAutoConfirmationAlert'
import { EmployeeHealthcheck } from './healthcheck/EmployeeHealthcheck'
import { HomepageSavingsSection } from './HomepageSavingsSection'
import { HomepageSpending } from './HomepageSpending'

/**
 * The employee homepage takes two forms:
 * - newly-enrolled user after completing registration but before connecting cards
 * - returning user checking balances and status of transactions
 *
 */
export const EmployeeHome = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser
  const isEnrolling = useIsEnrolling()

  if (!user) return null

  // If user is enrolling, they should not see the dashboard
  if (isEnrolling) {
    return <Navigate to="/enroll" replace />
  }

  return (
    <PageBody>
      <Container component="section" sx={{ mb: '1em' }}>
        <Typography gutterBottom>
          <FormattedMessage
            id="employee_dashboard.main.welcome_back"
            values={{ name: user.preferredName || user.firstName }}
          />
        </Typography>
      </Container>
      <Section>
        <HomepageAutoConfirmationAlert />
      </Section>
      <Section>
        <EmployeeHealthcheck />
      </Section>
      <Section>
        <SectionHeading to="/spending">
          <FormattedMessage id="spending.heading" />
        </SectionHeading>
        <HomepageSpending />
        <SectionCTA to="/spending">
          <FormattedMessage id="employee_dashboard.main.review_eligible_spending" />
        </SectionCTA>
      </Section>
      <HomepageSavingsSection />
      <Box my={3} component={CommutingSpendingSummary} />
      <Section>
        <SectionHeading gutterBottom>
          <FormattedMessage id="employee_dashboard.main.accounts" />
        </SectionHeading>
        <CardConnectionsList />
      </Section>
    </PageBody>
  )
}
