import { Container, InfoDrawer, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

const ANCHOR_NAME = 'spending-connection-info'

const InfoContent = () => (
  <>
    <Container>
      <>
        <Typography gutterBottom>
          <FormattedMessage id="spending.connection.connect_your_spending_info" />{' '}
          <FormattedMessage id="spending.connection.connect_alice_card_or_personal" />
        </Typography>
        <ul>
          <Typography component="li">
            <FormattedMessage id="spending.connection.add_to_wallet" />
          </Typography>
          <Typography component="li">
            <FormattedMessage id="spending.connection.request_physical_card" />
          </Typography>
          <Typography component="li">
            <FormattedMessage id="spending.connection.link_your_own_cards" />
          </Typography>
        </ul>
      </>
    </Container>
  </>
)

type EnrollmentRequirementsInfoProps = {
  children: React.ReactNode
}
export const EnrollmentRequirementsInfo = ({ children }: EnrollmentRequirementsInfoProps) => {
  return (
    <InfoDrawer
      noIcon
      title={<Typography variant="h2">Enrollment requirements</Typography>}
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent />}
    >
      {children}
    </InfoDrawer>
  )
}
