import { Container, InfoDrawer, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'

const ANCHOR_NAME = 'funding-account-info'

const InfoContent = () => (
  <Container>
    <Typography variant="h3">Account description</Typography>
    <Typography variant="caption" component="p" gutterBottom>
      When your employees make contributions to Alice Card from their pretax wages, that money remains in your
      payroll bank account(s) when payroll runs.
    </Typography>
    <Typography variant="caption" component="p" gutterBottom>
      Alice sweeps these employee contributions from your payroll bank account to your company&apos;s Alice
      Card Funding Account at Stripe.
    </Typography>
    <Typography variant="caption" component="p" gutterBottom>
      Employees spend these pretax contributions on parking and transit with Alice Card. Alice Card spend is
      automatically transferred regularly from your payroll bank account to your Alice Card Funding Account.
    </Typography>

    <Typography variant="h3">Account activity</Typography>
    <Typography variant="caption" component="p" gutterBottom>
      The account activity shown below includes all withdrawals from your Payroll Bank Accounts for the
      following:
    </Typography>
    <Typography variant="caption" component="ol" gutterBottom>
      <Typography variant="caption" component="li" gutterBottom>
        Employees&apos; Alice Card spend on commuting, which are funded from employees&apos; pretax
        contributions from their paychecks; and
      </Typography>
      <Typography variant="caption" component="li" gutterBottom>
        Initial Alice Card account opening transfers, which are funded by your company in the amount of
        approximately one week&apos;s cost of transit in that employee&apos;s region
      </Typography>
    </Typography>
    <Typography variant="caption" component="p" gutterBottom>
      Alice Card Funding Account will maintain the minimum required balance level for the number of Alice
      Cards then in use by your employees.
    </Typography>
    <Typography variant="caption" component="p" gutterBottom>
      If the required balance increases due to new locations or increased enrollment, Alice will issue new
      opening transfers to cover the expected increase in spend.
    </Typography>
    <Typography variant="caption" component="p" gutterBottom>
      If the required balance decreases due to terminations or location closings, the surplus funds will be
      available for withdrawal back to your company.
    </Typography>
    <Typography variant="caption" component="p" gutterBottom>
      All funds in the Alice Card Funding Account belong to your company, and will be returned to you within
      30 days of your offboarding date should you choose to terminate your contract.
    </Typography>
  </Container>
)

type FundingAccountInfoProps = {
  children: React.ReactNode
}
export const FundingAccountInfo = ({ children }: FundingAccountInfoProps) => {
  return (
    <InfoDrawer
      modal
      noIcon
      title={<Typography variant="h2">Alice Card Funding Account</Typography>}
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent />}
    >
      {children}
    </InfoDrawer>
  )
}
