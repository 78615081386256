import { Alert } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEmployeeQuery } from '../../gql/employee_gen'

/**
 * This alert signals to the EEs that we're auto-confirming their expenses.
 */
export const HomepageAutoConfirmationAlert: React.FC = () => {
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  if (!employee) return null

  if (!employee.spendingInfo.hasRecentAutoConfirmedTxns) return null

  return (
    <Alert severity="info">
      <FormattedMessage id="employee_dashboard.auto_confirmation_alert" />
    </Alert>
  )
}
