import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreatePaymentMethodConnectionMutationVariables = Types.Exact<{
  input: Types.CreatePaymentMethodConnectionInput
}>

export type CreatePaymentMethodConnectionMutation = {
  __typename?: 'Mutation'
  createAliceCardPaymentMethodConnection?: {
    __typename?: 'CreatePaymentMethodConnectionPayload'
    organization?: { __typename?: 'Organization'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreatePaymentMethodConnectionDocument = `
    mutation CreatePaymentMethodConnection($input: CreatePaymentMethodConnectionInput!) {
  createAliceCardPaymentMethodConnection(input: $input) {
    organization {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreatePaymentMethodConnectionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreatePaymentMethodConnectionMutation,
    TError,
    CreatePaymentMethodConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreatePaymentMethodConnectionMutation,
    TError,
    CreatePaymentMethodConnectionMutationVariables,
    TContext
  >(
    ['CreatePaymentMethodConnection'],
    (variables?: CreatePaymentMethodConnectionMutationVariables) =>
      fetchGql<CreatePaymentMethodConnectionMutation, CreatePaymentMethodConnectionMutationVariables>(
        CreatePaymentMethodConnectionDocument,
        variables
      )(),
    options
  )
}
