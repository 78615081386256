import { useStripe } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

export type ConfirmBankAccountSetupResponse = {
  paymentMethodId: string
  verificationUrl?: string
}
const confirmUsBankAccountSetup = (
  stripe: Stripe | null,
  clientSecret: string
): Promise<ConfirmBankAccountSetupResponse> => {
  if (!stripe) throw new Error('Stripe has not loaded')
  return stripe.confirmUsBankAccountSetup(clientSecret).then((res) => {
    console.log('confirmUsBankAccountSetup', res)
    if (res.setupIntent?.status === 'requires_payment_method') {
      // Confirmation failed. Attempt again with a different payment method.
      throw new Error('Requires payment method')
    } else if (res.setupIntent?.status === 'succeeded') {
      // Confirmation succeeded! The account is now saved.
      // Display a message to customer.
      const paymentMethodId = res.setupIntent.payment_method // string like "pm_1OVK8DPtj1XiosCtJ6u2HdeO"
      console.info('Account added in Stripe', paymentMethodId)

      if (typeof paymentMethodId !== 'string') {
        throw new Error('payment method not configured correctly')
      }
      return { paymentMethodId }
    } else if (res.setupIntent?.next_action?.type === 'verify_with_microdeposits') {
      // The account needs to be verified via microdeposits.
      // Display a message to consumer with next steps (consumer waits for
      // microdeposits, then enters an amount on a page sent to them via email).
      const paymentMethodId = res.setupIntent.payment_method // string like "pm_1OVK8DPtj1XiosCtJ6u2HdeO"
      const verificationUrl =
        // @ts-expect-error - Stripe has not documented verify_with_microdeposits yet
        res.setupIntent.next_action.verify_with_microdeposits.hosted_verification_url
      console.info('Microdeposit setup', paymentMethodId)

      if (typeof paymentMethodId !== 'string') {
        throw new Error('payment method not configured correctly')
      }
      return { paymentMethodId, verificationUrl }
    } else {
      throw res.error
    }
  })
}

type UseConfirmUsBankAccountSetupMutationOptions = UseMutationOptions<
  ConfirmBankAccountSetupResponse,
  unknown,
  { clientSecret: string }
>
/**
 * This hook wraps useMutation to call Stripe's confirmUsBankAccountSetup method. It should generally
 * not be called directly, but only by the `useAcceptACHMandate` hook, which will call a specific
 * handler for the response from Stripe.
 */
export const useConfirmUsBankAccountSetup = (
  mutationOptions?: UseConfirmUsBankAccountSetupMutationOptions
) => {
  const stripe = useStripe()
  return useMutation(
    ['confirmUsBankAccountSetup'],
    ({ clientSecret }) => confirmUsBankAccountSetup(stripe, clientSecret),
    mutationOptions
  )
}
