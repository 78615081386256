import { useEmployeeQuery } from '../../gql/employee_gen'

export const useQueryAddress = () => {
  const { data: employeeData, ...queryStatus } = useEmployeeQuery()
  return {
    data: employeeData?.employee?.mailingAddress,
    ...queryStatus,
  }
}
useQueryAddress.getKey = () => useEmployeeQuery.getKey()
