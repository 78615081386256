import { ApiClientProvider } from '@alice-financial/api'
import { GlobalNotificationProvider, ThemeProvider } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { HelpScoutBeacon } from './app/auth/helpScout/beacon'
import { SessionManager } from './app/auth/SessionManager'
import { Router } from './app/routes/Router'
import { AnalyticsProvider } from './app/telemetry/AnalyticsProvider'
import { TrackingContextProvider } from './app/telemetry/TrackingProvider'
import { LocalizationProvider } from './translations/LocalizationProvider'

export const AppRoot: React.FC = () => {
  return (
    <React.StrictMode>
      <ApiClientProvider>
        <TrackingContextProvider>
          <AnalyticsProvider>
            <LocalizationProvider>
              <ThemeProvider>
                <GlobalNotificationProvider>
                  <SessionManager>
                    <Router />
                    <HelpScoutBeacon />
                  </SessionManager>
                </GlobalNotificationProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </AnalyticsProvider>
        </TrackingContextProvider>
      </ApiClientProvider>
    </React.StrictMode>
  )
}
