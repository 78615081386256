import * as Types from '../../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type StripeSetupIntentSecretQueryVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
}>

export type StripeSetupIntentSecretQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    aliceCardProgram: {
      __typename?: 'AliceCardProgram'
      companyAccount?: {
        __typename?: 'StripeCompanyAccount'
        id: number
        stripeAccountId?: string | null
        stripeSetupIntentSecret?: string | null
      } | null
    }
  } | null
}

export const StripeSetupIntentSecretDocument = `
    query StripeSetupIntentSecret($orgId: Int!) {
  organization(id: $orgId) {
    id
    aliceCardProgram {
      companyAccount {
        id
        stripeAccountId
        stripeSetupIntentSecret
      }
    }
  }
}
    `

export const useStripeSetupIntentSecretQuery = <TData = StripeSetupIntentSecretQuery, TError = Error>(
  variables: StripeSetupIntentSecretQueryVariables,
  options?: UseQueryOptions<StripeSetupIntentSecretQuery, TError, TData>
) => {
  return useQuery<StripeSetupIntentSecretQuery, TError, TData>(
    ['StripeSetupIntentSecret', variables],
    fetchGql<StripeSetupIntentSecretQuery, StripeSetupIntentSecretQueryVariables>(
      StripeSetupIntentSecretDocument,
      variables
    ),
    options
  )
}

useStripeSetupIntentSecretQuery.getKey = (variables: StripeSetupIntentSecretQueryVariables) => [
  'StripeSetupIntentSecret',
  variables,
]
