import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { useEmployeeQuery } from '../../employeeDashboard/gql/employee_gen'

export const useOffboardDate = () => {
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  if (employee === undefined) return undefined
  const offboardingDate = employee?.employer.offboardingDate
  if (!offboardingDate) return null

  return dateStringFormat.dateFromDateString(offboardingDate)
}
