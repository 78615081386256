import * as React from 'react'
import { Typography } from '../../typography'
import { StatusNotification, StatusVariant } from './StatusNotification'

type NotificationProps = {
  variant: StatusVariant
  open?: boolean
  message: string
}

const GlobalNotificationContext = React.createContext<{
  notify: (notifProps: NotificationProps) => undefined
} | null>(null)

export const useNotify = () => {
  const notifyContext = React.useContext(GlobalNotificationContext)
  if (!notifyContext) throw new Error('useNotify called outside of GlobalNotificationContext')
  return notifyContext
}

type GlobalNotificationProviderProps = {
  children: React.ReactNode
}
export const GlobalNotificationProvider = ({ children }: GlobalNotificationProviderProps) => {
  const [notificationProps, setNotificationProps] = React.useState<NotificationProps>({
    open: false,
    variant: 'success',
    message: '',
  })
  const notify = React.useCallback((notifProps: NotificationProps) => {
    setNotificationProps(notifProps)
    return undefined // required so that notifications can be used in mutation callbacks
  }, [])
  const onClose = React.useCallback(
    () => setNotificationProps({ ...notificationProps, open: false }),
    [notificationProps, setNotificationProps]
  )

  const { message, ...statusNotifProps } = notificationProps
  return (
    <GlobalNotificationContext.Provider value={{ notify }}>
      {children}
      <StatusNotification {...statusNotifProps} onClose={onClose}>
        <Typography variant="body2" fontWeight="bold">
          {message}
        </Typography>
      </StatusNotification>
    </GlobalNotificationContext.Provider>
  )
}
