import { List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@alice-financial/pretext-ui'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import * as React from 'react'
import { EmployerSummaryFragment } from '../../graphql/fragments/OrganizationFragment_gen'

type StatBoxProps = {
  children: React.ReactNode
  Icon: typeof SvgIcon
}

const StatBox = ({ children, Icon }: StatBoxProps) => (
  <ListItem divider sx={{ alignItems: 'center' }}>
    <ListItemIcon>
      <Icon color="secondary" fontSize="large" />
    </ListItemIcon>
    <ListItemText sx={{ alignSelf: 'center' }}>{children}</ListItemText>
  </ListItem>
)

type StatFigureProps = {
  children: React.ReactNode
}
const StatFigure = ({ children }: StatFigureProps) => (
  <Typography color="primary" fontSize="2.5em" fontWeight="bolder" lineHeight={1}>
    {children}
  </Typography>
)

type EngagementSummaryProps = {
  employer: EmployerSummaryFragment
}
export const EngagementSummary = ({ employer }: EngagementSummaryProps) => {
  return (
    <List>
      <StatBox Icon={EmojiEventsIcon}>
        <StatFigure>{employer.employees.enrolledCount} </StatFigure>
        <Typography variant="body2" fontWeight="bold" color="text.secondary">
          enrolled (of {employer.employees.reachableCount})
        </Typography>
      </StatBox>
      <StatBox Icon={AttachMoneyIcon}>
        <StatFigure>{Math.round(employer.employees.totalSavings / 100)}</StatFigure>
        <Typography variant="body2" fontWeight="bold" color="text.secondary">
          total savings
        </Typography>
      </StatBox>
    </List>
  )
}
