import { UseGqlMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useCreatePhoneLoginCodeMutation, CreatePhoneLoginCodeMutation } from './gql/createPhoneLoginCode_gen'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { useIntl } from 'react-intl'

type CreatePhoneLoginCodeProps = { tel: string }

type UseCreatePhoneLoginCodeMutationOptions = UseGqlMutationCallbacks<
  CreatePhoneLoginCodeMutation,
  CreatePhoneLoginCodeProps
>

export const useCreatePhoneLoginCode = (mutationOptions?: UseCreatePhoneLoginCodeMutationOptions) => {
  const intl = useIntl()

  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'auth.login.requesting_code' }),
      onSuccess: false,
    },
    mutationOptions
  )

  const { mutateAsync: createPhoneLoginCode } = useCreatePhoneLoginCodeMutation()
  return useMutation(['createPhoneLoginCode'], (input) => createPhoneLoginCode({ input }), _mutationOptions)
}
