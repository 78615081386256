import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SetPaymentCardMutationVariables = Types.Exact<{
  input: Types.SetPaymentCardInput
}>

export type SetPaymentCardMutation = {
  __typename?: 'Mutation'
  setPaymentCard?: {
    __typename?: 'SetPaymentCardPayload'
    paymentCard?: { __typename?: 'PaymentCard'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const SetPaymentCardDocument = `
    mutation SetPaymentCard($input: SetPaymentCardInput!) {
  setPaymentCard(input: $input) {
    paymentCard {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useSetPaymentCardMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<SetPaymentCardMutation, TError, SetPaymentCardMutationVariables, TContext>
) => {
  return useMutation<SetPaymentCardMutation, TError, SetPaymentCardMutationVariables, TContext>(
    ['SetPaymentCard'],
    (variables?: SetPaymentCardMutationVariables) =>
      fetchGql<SetPaymentCardMutation, SetPaymentCardMutationVariables>(SetPaymentCardDocument, variables)(),
    options
  )
}
