import { fetcher } from '../fetch'
import { RestInputErrorException, isBadInput } from './responseErrors'

const handleInputError = <TResponse>(json: TResponse, pathname: string) => {
  if (isBadInput(json)) return Promise.reject(new RestInputErrorException(json, pathname)) // Promise.reject to triggering error tracker
  return json
}
/**
 * This is the primary 'fetch' function for any REST API endpoint that is expected
 * to return JSON. It should generally be called from inside the callback to React
 * Query's `useQuery` hook. It _must_ be provided with a generic type argument
 * (TResponse) that specifies what the successful API request will return.
 *
 * In general, it should never be used outside of `useQuery` or `useMutation`
 *
 * @example
 *
 * const { data: myCoolData, isLoading, error } = useQuery(
 *   ['coolData'],
 *   () => fetchRest<CoolDataResponse>('/api/cool/data')
 * )
 */
export const fetchRest = <TResponse>(pathname: string, fetchOptions?: RequestInit): Promise<TResponse> =>
  fetcher<TResponse>(pathname, fetchOptions).then((response) => handleInputError(response, pathname))
