import { fetchRest, UseRestMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useLocaleState } from '../../translations/LocaleProvider'
import { SupportedLangCode } from '../../translations/types'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { ForgotPasswordFormValues } from './types'

type SubmitForgotPasswordInput = ForgotPasswordFormValues & { language: SupportedLangCode }
const submitForgotPassword = ({ email, language }: SubmitForgotPasswordInput) => {
  return fetchRest(`/password`, {
    method: 'POST',
    body: JSON.stringify({ user: { email } }),
    headers: { Locale: language },
  })
}

type UseSubmitForgotPasswordMutationOptions = UseRestMutationCallbacks<unknown, ForgotPasswordFormValues>
export const useSubmitForgotPassword = (mutationOptions: UseSubmitForgotPasswordMutationOptions = {}) => {
  const intl = useIntl()
  const [language] = useLocaleState()

  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'auth.forgot_password.notification.requesting_password' }),
      onSuccess: intl.formatMessage({ id: 'auth.forgot_password.notification.requested_password' }),
    },
    mutationOptions
  )
  return useMutation((values) => submitForgotPassword({ ...values, language }), _mutationOptions)
}
