import { styled, Box } from '@mui/material'
import { FOOTER_MAX_HEIGHT } from '../theme'

export const PageBody = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: FOOTER_MAX_HEIGHT,
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))
