import { HealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { locationToRedirectToParams } from '../../../routes/routeUtils'
import {
  useAliceCardFeatureEnabled,
  useSpendingConnectionSummary,
} from '../../cards/useQuerySpendingConnections'

const SpendingConnectionHealthcheckError = () => {
  const intl = useIntl()
  return (
    <HealthcheckItem
      to="/cards/personal"
      status="incomplete"
      primary={intl.formatMessage({ id: 'employee_dashboard.healthcheck_account.personal_error_primary' })}
      secondary={intl.formatMessage({
        id: 'employee_dashboard.healthcheck_account.personal_error_secondary',
      })}
    />
  )
}
/**
 * User should have at least one bank account connected. If there are problems, the healthcheck
 * will show a link to the banking connections page, with a redirect_to param to return to the
 * current page
 */
export const BankingAcccountsHealthCheckItem = () => {
  const intl = useIntl()
  const redirectLocation = useLocation()
  const { hasConnectedBankingConnections, hasReconnectionRequests, isLoading, error } =
    useSpendingConnectionSummary()

  if (isLoading) return null
  if (error) return <SpendingConnectionHealthcheckError />

  const to = `/cards/personal${redirectLocation ? locationToRedirectToParams(redirectLocation) : ''}`
  if (!hasConnectedBankingConnections) {
    return (
      <HealthcheckItem
        to={to}
        status="incomplete"
        icon="🏦"
        primary={intl.formatMessage({ id: 'employee_dashboard.healthcheck_account.personal_none_primary' })}
        secondary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.personal_none_secondary',
        })}
      />
    )
  }
  if (hasReconnectionRequests) {
    return (
      <HealthcheckItem
        to={to}
        status="incomplete"
        icon="🏦"
        primary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.personal_with_errors_primary',
        })}
        secondary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.personal_with_errors_secondary',
        })}
      />
    )
  }
  // all connections are 'Connected' or 'Pending'
  return (
    <HealthcheckItem
      to={to}
      status="complete"
      primary={intl.formatMessage({ id: 'employee_dashboard.healthcheck_account.personal_complete_primary' })}
      secondary={intl.formatMessage({
        id: 'employee_dashboard.healthcheck_account.personal_complete_secondary',
      })}
    />
  )
}

/**
 * User should have at least one Alice Card available to use - ideally two, but
 * that's currently not considered critical.
 */
export const AliceCardHealthCheckItem = () => {
  const intl = useIntl()
  const { hasAliceCards, hasActiveSynapseAccount, hasAcceptedAliceCardTos, isLoading } =
    useSpendingConnectionSummary()
  const mayRequestStripeAliceCard =
    useAliceCardFeatureEnabled() && hasActiveSynapseAccount && !hasAcceptedAliceCardTos

  if (isLoading) return null

  if (!hasAliceCards) {
    return (
      <HealthcheckItem
        to="/cards/alice"
        icon="💳"
        status="incomplete"
        primary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.alice_card_unrequested_primary',
        })}
        secondary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.alice_card_unrequested_secondary',
        })}
      />
    )
  }

  if (mayRequestStripeAliceCard) {
    return (
      <HealthcheckItem
        to="/cards/alice"
        icon="💳"
        status="incomplete"
        primary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.alice_card_migrate_stripe_primary',
        })}
        secondary={intl.formatMessage({
          id: 'employee_dashboard.healthcheck_account.alice_card_migrate_stripe_secondary',
        })}
      />
    )
  }

  return (
    <HealthcheckItem
      to="/cards/alice"
      status="complete"
      primary="Alice Card"
      secondary={intl.formatMessage({
        id: 'employee_dashboard.healthcheck_account.alice_card_all_requested_secondary',
      })}
    />
  )
}
