import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../../../../utils/useMutationNotifier'
import { useEmployeeEnrollmentInfoQuery } from '../../../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { useQuerySpendingConnections } from '../../../useQuerySpendingConnections'
import {
  ReconnectOmnyMutation,
  ReconnectOmnyMutationVariables,
  useReconnectOmnyMutation,
} from './gql/reconnectOMNYCard_gen'

const CONNECTION_QUERIES = [
  useQuerySpendingConnections.getKey(),
  useEmployeeEnrollmentInfoQuery.getKey(), // because card connections affect account_status
]

export type OMNYCredentials = {
  username: string
  password: string
}

type ReconnectOMNYMutationOptions = UseGqlMutationCallbacks<
  ReconnectOmnyMutation,
  ReconnectOmnyMutationVariables
>
export const useReconnectOMNY = (mutationOptions?: ReconnectOMNYMutationOptions) => {
  const options = useMutationOptionsWithInvalidation(
    useMutationNotifier({}, mutationOptions),
    CONNECTION_QUERIES
  )
  return useReconnectOmnyMutation(options)
}
