import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateEmailPreferencesMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input']
  emailPreferences: Types.EmailPreferencesInput
}>

export type UpdateEmailPreferencesMutation = {
  __typename?: 'Mutation'
  updateEmployee?: {
    __typename?: 'UpdateEmployeePayload'
    employee?: { __typename?: 'Employee'; id: number } | null
  } | null
}

export const UpdateEmailPreferencesDocument = `
    mutation UpdateEmailPreferences($employeeId: Int!, $emailPreferences: EmailPreferencesInput!) {
  updateEmployee(
    input: {employeeId: $employeeId, emailPreferences: $emailPreferences}
  ) {
    employee {
      id
    }
  }
}
    `

export const useUpdateEmailPreferencesMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateEmailPreferencesMutation,
    TError,
    UpdateEmailPreferencesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateEmailPreferencesMutation,
    TError,
    UpdateEmailPreferencesMutationVariables,
    TContext
  >(
    ['UpdateEmailPreferences'],
    (variables?: UpdateEmailPreferencesMutationVariables) =>
      fetchGql<UpdateEmailPreferencesMutation, UpdateEmailPreferencesMutationVariables>(
        UpdateEmailPreferencesDocument,
        variables
      )(),
    options
  )
}
