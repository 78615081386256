import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout?: {
    __typename?: 'LogoutPayload'
    success: boolean
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const LogoutDocument = `
    mutation Logout {
  logout(input: {}) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useLogoutMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>
) => {
  return useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
    ['Logout'],
    (variables?: LogoutMutationVariables) =>
      fetchGql<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
    options
  )
}
