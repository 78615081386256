import {
  ActionLayout,
  Button,
  Collapse,
  Container,
  FullDialog,
  ToggleChip,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ContactSupport } from '../../../ContactSupport'
import { AddressLoading, UserAddressDisplay } from '../../../profile/address/AddressDisplay'
import { AddressInput } from '../../../profile/address/AddressInput'
import { useHeadlessAddressEdit } from '../../../profile/address/useHeadlessAddressEdit'
import { CardItemProps, CardProps, ReportTypeKey } from '../types'
import { useReportMissingCard } from './useReportMissingCard'

type ReportMissingCardProps = CardItemProps & {
  isMissing: ReportTypeKey | boolean
  setIsMissing: (isMissing: ReportTypeKey | boolean) => void
}
const ReportMissingCard = ({ card, isMissing, setIsMissing }: ReportMissingCardProps) => {
  const intl = useIntl()
  const [isAddressCorrect, setIsAddressCorrect] = React.useState(true)
  const [isReportedMissing, setIsReportedMissing] = React.useState(false)
  const { mutate: reportMissingCard } = useReportMissingCard({
    onSuccess: () => {
      setIsMissing(false)
      setIsReportedMissing(true)
    },
  })
  const { isLoading, contenteditable, isSubmitting, control, onEdit, onSubmit, onCancel } =
    useHeadlessAddressEdit()
  const addressContent = (isLoading && <AddressLoading gutterBottom />) ||
    ((!contenteditable || isSubmitting) && <UserAddressDisplay gutterBottom />) || (
      <AddressInput control={control} />
    )

  const isReportTypeSpecified = typeof isMissing !== 'boolean'
  return (
    <FullDialog
      title={
        <Typography variant="h2">
          <FormattedMessage id="cards.alice.report_missing.heading" />
        </Typography>
      }
      open={Boolean(isMissing)}
      onClose={() => {
        setIsMissing(false)
        onCancel()
      }}
    >
      <Container>
        {isMissing === true && (
          <Typography variant="body2" gutterBottom>
            <ToggleChip
              label={intl.formatMessage({ id: 'cards.alice.report_missing.never_arrived' })}
              onChange={() => setIsMissing('cards.alice.report_missing.report_type.not_arrived')}
            />
            <em> {intl.formatMessage({ id: 'common.or' })} </em>
            <ToggleChip
              label={intl.formatMessage({ id: 'cards.alice.report_missing.stolen' })}
              onChange={() => setIsMissing('cards.alice.report_missing.report_type.stolen')}
            />
            <em> {intl.formatMessage({ id: 'common.or' })} </em>
            <ToggleChip
              label={intl.formatMessage({ id: 'cards.alice.report_missing.missing' })}
              onChange={() => setIsMissing('cards.alice.report_missing.report_type.missing')}
            />
          </Typography>
        )}
        {isMissing !== true && <Typography gutterBottom>Sorry to hear that! </Typography>}
        <Collapse in={isReportTypeSpecified}>
          {!contenteditable && (
            <Typography fontWeight="bold" gutterBottom>
              {isMissing === 'cards.alice.report_missing.report_type.not_arrived'
                ? intl.formatMessage({ id: 'cards.alice.report_missing.card_was_mailed_to' })
                : intl.formatMessage({ id: 'cards.alice.report_missing.card_will_be_mailed_to' })}
            </Typography>
          )}
          {contenteditable && (
            <Typography gutterBottom>
              <FormattedMessage id="cards.alice.report_missing.tell_us_where_to_send" />
            </Typography>
          )}
          <form
            onSubmit={async (event) => {
              event.preventDefault()
              if (!isAddressCorrect) {
                setIsAddressCorrect(true)
                await onSubmit(event)
              }
              reportMissingCard({ aliceCardId: card.id })
            }}
          >
            {addressContent}

            <ActionLayout
              autoWidth
              mt={0}
              primary={
                <Button fullWidth variant="contained" type="submit">
                  {contenteditable || !isReportTypeSpecified
                    ? intl.formatMessage({ id: 'cards.alice.report_missing.update_address_and_send_card' })
                    : intl.formatMessage(
                        { id: 'cards.alice.report_missing.report_with_report_type' },
                        { reportType: intl.formatMessage({ id: isMissing }) }
                      )}
                </Button>
              }
              secondary={
                <Button
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    setIsAddressCorrect(false)
                    onEdit()
                  }}
                  disabled={contenteditable}
                >
                  <FormattedMessage id="cards.alice.report_missing.change_address_label" />
                </Button>
              }
            />
          </form>
          <Collapse in={isReportedMissing}>
            <Typography gutterBottom>
              <FormattedMessage
                id="cards.alice.report_missing.what_happens_next"
                values={{ last4: card.last4 }}
              />
            </Typography>
          </Collapse>
        </Collapse>
        <ContactSupport />
      </Container>
    </FullDialog>
  )
}

export const ReportMissingButton = ({ card }: CardProps) => {
  const [isMissing, setIsMissing] = React.useState<ReportTypeKey | boolean>(false)
  return (
    <>
      <Button color="error" variant="outlined" size="small" onClick={() => setIsMissing(true)}>
        <FormattedMessage id="cards.alice.report_missing_label" />
      </Button>
      <ReportMissingCard card={card} isMissing={isMissing} setIsMissing={setIsMissing} />
    </>
  )
}
