import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type DeleteWorkLocationMutationVariables = Types.Exact<{
  input: Types.DeleteWorkLocationInput
}>

export type DeleteWorkLocationMutation = {
  __typename?: 'Mutation'
  deleteWorkLocation?: {
    __typename?: 'DeleteWorkLocationPayload'
    workLocationId: number
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const DeleteWorkLocationDocument = `
    mutation DeleteWorkLocation($input: DeleteWorkLocationInput!) {
  deleteWorkLocation(input: $input) {
    workLocationId
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useDeleteWorkLocationMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteWorkLocationMutation,
    TError,
    DeleteWorkLocationMutationVariables,
    TContext
  >
) => {
  return useMutation<DeleteWorkLocationMutation, TError, DeleteWorkLocationMutationVariables, TContext>(
    ['DeleteWorkLocation'],
    (variables?: DeleteWorkLocationMutationVariables) =>
      fetchGql<DeleteWorkLocationMutation, DeleteWorkLocationMutationVariables>(
        DeleteWorkLocationDocument,
        variables
      )(),
    options
  )
}
