import { Container } from '@mui/material'
import { styled } from '@mui/material/styles'

/**
 * Wrapper intended to display raw HTML markup in a themed style
 */
export const RawHtml = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& h1': {
    marginBottom: 0,
  },
  '& h3': {
    marginTop: 0,
  },
  '& div': {
    margin: theme.spacing(2, 0),
  },
  '& .intro_copy': {
    marginTop: 0,
  },
  '& i': {
    color: theme.palette.primary.main,
    display: 'block',
    padding: theme.spacing(1, 2),
    borderLeft: `3px solid`,
  },
  '& ul, & ol': {
    paddingLeft: theme.spacing(3),
  },
  '& li': {
    margin: theme.spacing(1, 0),
  },
}))
