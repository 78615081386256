import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TrnKey } from '../../../translations/types'

export type FeatureItem = {
  Icon: typeof SvgIcon
  messageId: TrnKey
}
interface FeatureListProps {
  features: Array<FeatureItem>
}

export const FeatureList = ({ features }: FeatureListProps) => (
  <List disablePadding>
    {features.map(({ Icon, messageId }) => (
      <ListItem key={messageId} disablePadding>
        <ListItemIcon sx={{ minWidth: '1.2em' }}>
          <Icon color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={<FormattedMessage id={messageId} />}
          primaryTypographyProps={{
            variant: 'body2',
            gutterBottom: true,
          }}
        />
      </ListItem>
    ))}
  </List>
)
