import CloseIcon from '@mui/icons-material/Close'
import { IconButton, IconButtonProps, styled } from '@mui/material'
import * as React from 'react'

const CloseButtonRoot = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}))

export const CloseButton = (props: IconButtonProps) => (
  <CloseButtonRoot color="primary" aria-label="dismiss" {...props}>
    <CloseIcon color="primary" />
  </CloseButtonRoot>
)
