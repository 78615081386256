import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type FormTemplatesQueryVariables = Types.Exact<{
  templateType: Types.Scalars['String']['input']
}>

export type FormTemplatesQuery = {
  __typename?: 'Query'
  formTemplate?: {
    __typename?: 'FormsTemplate'
    id: number
    templateType: string
    displayOrder: Array<number>
    questions: Array<{
      __typename?: 'FormsQuestion'
      id: number
      text: string
      subtitle?: string | null
      placeholder?: string | null
      questionType: Types.QuestionType
      options?: Array<string> | null
    }>
  } | null
}

export const FormTemplatesDocument = `
    query FormTemplates($templateType: String!) {
  formTemplate(templateType: $templateType) {
    id
    templateType
    displayOrder
    questions {
      id
      text
      subtitle
      placeholder
      questionType
      options
    }
  }
}
    `

export const useFormTemplatesQuery = <TData = FormTemplatesQuery, TError = Error>(
  variables: FormTemplatesQueryVariables,
  options?: UseQueryOptions<FormTemplatesQuery, TError, TData>
) => {
  return useQuery<FormTemplatesQuery, TError, TData>(
    ['FormTemplates', variables],
    fetchGql<FormTemplatesQuery, FormTemplatesQueryVariables>(FormTemplatesDocument, variables),
    options
  )
}

useFormTemplatesQuery.getKey = (variables: FormTemplatesQueryVariables) => ['FormTemplates', variables]
