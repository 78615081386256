import { Container, ListItem, ListItemProps, styled, alpha } from '@mui/material'
import * as React from 'react'
import { iconGridStyle } from '../layout'

const MaybeClickableListItem = styled(ListItem)(({ theme, onClick }) => ({
  '&:hover': {
    backgroundColor: onClick
      ? alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity)
      : 'initial',
    cursor: onClick ? 'pointer' : 'auto',
  },
}))

/**
 * A 3 column CSS grid-based ListItem that has a fixed-width
 * left column for displaying an icon, logo, or small illustration
 * The third column does not have to be used (just make sure
 * `grid-row-end is '3'` in the child element), but will flex
 * with the container width.
 */
const IconListItemContent = styled(Container)(iconGridStyle)
export const IconListItem = ({ children, ...props }: ListItemProps) => (
  <MaybeClickableListItem {...props}>
    <IconListItemContent>{children}</IconListItemContent>
  </MaybeClickableListItem>
)
