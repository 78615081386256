import { useFinchConnect } from '@tryfinch/react-connect'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCreateFinchConnectionMutation } from './gql/FinchAuthGateway_gen'
import { PayrollPlatformFinchCode } from './types'

type UseFinchIframeParams = {
  returnWith: (returnParams: URLSearchParams) => void
  payrollProvider: PayrollPlatformFinchCode
}

type FinchConnectReturnParam = 'iframe_closed' | 'error_occurred' | 'completed'

const getFinchRequestedScopes = (payrollProvider: PayrollPlatformFinchCode) => {
  // seems like we might not have access to SSNs in Gusto yet
  const GUSTO_SCOPES = ['company', 'individual', 'employment', 'payment', 'pay_statement', 'directory']

  if (payrollProvider === 'gusto') {
    return ['company', 'individual', 'employment', 'payment', 'pay_statement', 'directory']
  }

  return [...GUSTO_SCOPES, 'ssn']
}

/**
 This hook handles the Finch Connect iFrame. It'll display the iFrame and also
 take the corresponding actions after
 • the user logs in (it'll exchange the authorization token for an access token via request to the backend)
 • the user closes the iFrame
 • Finch returns an error when trying to authenticate.
 */
export const useFinchIframe = ({ payrollProvider, returnWith }: UseFinchIframeParams) => {
  const { employerId } = useParams()

  if (!process.env.FINCH_CLIENT_ID) {
    throw new Error('FinchAuthGateway: process.env.FINCH_CLIENT_ID not defined')
  }

  // Unlikely given this component is rendered under a route that requires the employerId
  if (!employerId) {
    throw new Error('FinchAuthGateway: employerId not defined')
  }

  // navigates to the referrer page with the finch_connect query param
  const navigateBack = (finch_connect: FinchConnectReturnParam) =>
    returnWith(new URLSearchParams({ finch_connect }))

  const { mutate: exchangeAuthorizationTokenMutation, isLoading: isTokenExchangeLoading } =
    useCreateFinchConnectionMutation({
      onSuccess: () => navigateBack('completed'),
      onError: (error) => {
        console.error(error)
        navigateBack('error_occurred')
      },
    })
  const [finchAuthorizationToken, setFinchAuthorizationToken] = useState<string | null>(null)

  const onSuccessFinchIframe = ({ code }: { code: string }) => {
    setFinchAuthorizationToken(code)
    exchangeAuthorizationTokenMutation({ authorizationCode: code, employerId: parseInt(employerId) })
  }
  const onErrorFinchIframe = ({ errorMessage }: { errorMessage: string }) => {
    console.error(errorMessage)
    navigateBack('error_occurred')
  }
  const onCloseFinchIframe = () => navigateBack('iframe_closed')

  const { open: openFinchIframe } = useFinchConnect({
    clientId: process.env.FINCH_CLIENT_ID,
    products: getFinchRequestedScopes(payrollProvider),
    sandbox: process.env.APP_ENV === 'production' ? false : 'finch',
    onSuccess: onSuccessFinchIframe,
    payrollProvider,
    onError: onErrorFinchIframe,
    onClose: onCloseFinchIframe,
  })

  // Open the Finch Connect iframe when invoked
  useEffect(() => {
    // if the user is already connected to Finch, we don't need to open the iframe,
    // this is required because otherwise for some reason the iframe would re-open
    // after the user is redirected back to the page if in web-apps
    if (finchAuthorizationToken) return
    if (openFinchIframe) openFinchIframe()
  }, [openFinchIframe, finchAuthorizationToken])

  return { isTokenExchangeLoading }
}
