import {
  composeMutationCallbacks,
  UseGqlMutationCallbacks,
  useMutationOptionsWithInvalidation,
} from '@alice-financial/api'
import { datadogRum } from '@datadog/browser-rum'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { ConfirmBankAccountSetupResponse, useConfirmUsBankAccountSetup } from './useConfirmBankAccount'

const STRIPE_CONNECT_ERROR_MESSAGE =
  'Stripe could not connect bank account. Please refresh the page and try again or contact Alice suppport if the error persists'

type AcceptACHMandateMutationOptions = UseGqlMutationCallbacks<unknown, { clientSecret: string }>
/**
 * This hook wraps useMutation to
 * 1. Tell Stripe that the user has accepted the ACH mandate (confirmUsBankAccountSetup)
 * 2. Handle the response from Stripe in order to save the confirmed payment method in our back end
 *
 * Billing and banking both use this hook to save payment methods in different contexts
 */
export const useAcceptACHMandate = <TResponse = unknown>(
  handleConfirmationResponse: (response: ConfirmBankAccountSetupResponse) => TResponse,
  invalidationKeys: Array<QueryKey> = [],
  mutationOptions?: AcceptACHMandateMutationOptions
) => {
  const mutationOptionWithInvalidation = useMutationOptionsWithInvalidation(
    useMutationNotifier(
      { onError: STRIPE_CONNECT_ERROR_MESSAGE },
      composeMutationCallbacks(mutationOptions, { onError: (error) => datadogRum.addError(error) })
    ),
    invalidationKeys
  )

  const { mutateAsync: confirmUsBankAccountSetup } = useConfirmUsBankAccountSetup()

  return useMutation(
    ['acceptACHMandateAndCreatePaymentMethod'],
    ({ clientSecret }) => confirmUsBankAccountSetup({ clientSecret }).then(handleConfirmationResponse),
    mutationOptionWithInvalidation
  )
}
