import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type PayPeriodRecordsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
  id: Types.Scalars['Int']['input']
}>

export type PayPeriodRecordsQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    payPeriod?: {
      __typename?: 'PayPeriod'
      id: number
      payPeriodRecords: Array<{
        __typename?: 'PayPeriodRecord'
        id: number
        reimbursement?: number | null
        deduction?: number | null
        benefitType?: Types.BenefitType | null
        employee: {
          __typename?: 'Employee'
          id: number
          user: { __typename?: 'User'; id: number; firstName?: string | null; lastName?: string | null }
        }
      }>
    } | null
  } | null
}

export type PayPeriodWithPpRsFragment = {
  __typename?: 'PayPeriod'
  id: number
  payPeriodRecords: Array<{
    __typename?: 'PayPeriodRecord'
    id: number
    reimbursement?: number | null
    deduction?: number | null
    benefitType?: Types.BenefitType | null
    employee: {
      __typename?: 'Employee'
      id: number
      user: { __typename?: 'User'; id: number; firstName?: string | null; lastName?: string | null }
    }
  }>
}

export type PayPeriodRecordFragment = {
  __typename?: 'PayPeriodRecord'
  id: number
  reimbursement?: number | null
  deduction?: number | null
  benefitType?: Types.BenefitType | null
  employee: {
    __typename?: 'Employee'
    id: number
    user: { __typename?: 'User'; id: number; firstName?: string | null; lastName?: string | null }
  }
}

export const PayPeriodRecordFragmentDoc = `
    fragment PayPeriodRecord on PayPeriodRecord {
  id
  reimbursement
  deduction
  benefitType
  employee {
    id
    user {
      id
      firstName
      lastName
    }
  }
}
    `
export const PayPeriodWithPpRsFragmentDoc = `
    fragment PayPeriodWithPPRs on PayPeriod {
  id
  payPeriodRecords {
    ...PayPeriodRecord
  }
}
    `
export const PayPeriodRecordsDocument = `
    query PayPeriodRecords($orgId: Int!, $id: Int!) {
  organization(id: $orgId) {
    payPeriod(id: $id) {
      ...PayPeriodWithPPRs
    }
  }
}
    ${PayPeriodWithPpRsFragmentDoc}
${PayPeriodRecordFragmentDoc}`

export const usePayPeriodRecordsQuery = <TData = PayPeriodRecordsQuery, TError = Error>(
  variables: PayPeriodRecordsQueryVariables,
  options?: UseQueryOptions<PayPeriodRecordsQuery, TError, TData>
) => {
  return useQuery<PayPeriodRecordsQuery, TError, TData>(
    ['PayPeriodRecords', variables],
    fetchGql<PayPeriodRecordsQuery, PayPeriodRecordsQueryVariables>(PayPeriodRecordsDocument, variables),
    options
  )
}

usePayPeriodRecordsQuery.getKey = (variables: PayPeriodRecordsQueryVariables) => [
  'PayPeriodRecords',
  variables,
]
