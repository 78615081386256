import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { QueryKey } from '@tanstack/react-query'

type OrganizationLogoDeleteValues = {
  id: number
}

const deleteOrganizationLogo = ({ id }: OrganizationLogoDeleteValues) => {
  return fetchRest(`/api/organization/${id}/logo`, {
    method: 'DELETE',
  })
}

type UseDeleteOrganizationLogoMutationOptions = UseRestMutationCallbacks<
  unknown,
  OrganizationLogoDeleteValues
>

export const useDeleteOrganizationLogo = (
  mutationOptions: UseDeleteOrganizationLogoMutationOptions = {},
  invalidationKeys: QueryKey[] = []
) => {
  const intl = useIntl()
  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'organization.onboarding.locations.deletion_notice' }),
      onSuccess: false,
    },
    mutationOptions
  )

  return useMutationWithInvalidation(deleteOrganizationLogo, _mutationOptions, invalidationKeys)
}
