import {
  ActionLayout,
  Button,
  ExternalLink,
  Grid,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useConnectACHForm } from './useConnectACHForm'
import { ACHNumbers } from './types'

type ConnectACHFormProps = {
  clientSecret: string
  onSuccess: (achNumbers: ACHNumbers) => void
  onError: () => void
}

export const ConnectACHForm = ({ clientSecret, onSuccess, onError }: ConnectACHFormProps) => {
  const intl = useIntl()
  const { control, onSubmit, formState } = useConnectACHForm(clientSecret, { onSuccess, onError })

  if (!onSubmit) return null

  const required = intl.formatMessage({ id: 'common.validation.required' })

  // ACH test numbers
  // 000123456789	110000000	The automatic bank account validation succeeds.
  // 000111111114	110000000	The automatic bank account validation fails.

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={1} alignItems="end" sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            name="routingNumber"
            label="Routing number"
            required
            fullWidth
            rules={{ required }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            name="accountNumber"
            label="Account number"
            required
            fullWidth
            rules={{ required }}
          />
        </Grid>
      </Grid>
      {formState.errors.root && (
        <>
          <Typography variant="body2" color="error" gutterBottom>
            We were unable to verify your account information. Please check you entered the numbers correctly.
          </Typography>
          <Typography variant="body2">
            If this problem persists, please reach out to us for support at{' '}
            <ExternalLink href="mailto:support@thisisalice.com">
              <Typography variant="body2" color="inherit" component="span">
                support@thisisalice.com
              </Typography>
            </ExternalLink>
          </Typography>
        </>
      )}
      <Typography variant="caption" component="p" gutterBottom>
        Please remember to whitelist Alice&apos;s ACH IDs with your bank. Alice transfers funds from your
        payroll bank account to your Alice Card funding account. Please note: these are not payments to Alice,
        they are employee benefit contributions. Alice&apos;s ACH IDs are <strong>1800948598</strong>,{' '}
        <strong>4270465600</strong>, and <strong>0000004670</strong>.
      </Typography>
      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" type="submit" fullWidth>
            <FormattedMessage id="common.confirm" />
          </Button>
        }
      />
    </form>
  )
}
