import { UseGqlMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { useCreateOrgFromProvisionalMutation } from './gql/createOrgFromProvisional_gen'
import { OrgOnboardingApiValues, OrgOnboardingFormValues, ProvisionalOrganization } from './types'
import { ProvisionalOrgFragment } from './gql/provisionalOrgs_gen'

const formValuesToApiValues = (values: OrgOnboardingFormValues): OrgOnboardingApiValues => {
  const {
    legal_name,
    payroll_platform_id,
    line_1,
    line_2,
    city_name,
    state_code,
    zipcode,
    action: _action,
    num_locations: _num_locations,
    ...pay_schedule
  } = values
  return {
    legal_name,
    payroll_platform_id: payroll_platform_id || null, // empty string will be coerced to null
    address_attributes: {
      line_1,
      line_2,
      city_name,
      state_code,
      zipcode,
    },
    pay_schedule,
  }
}

export const apiValuesToFormValues = (
  provisionalOrg: ProvisionalOrgFragment
): Omit<Partial<OrgOnboardingFormValues>, 'action'> => {
  const { legalName, address, payrollPlatform, paySchedule, ...unchangedKeys } = provisionalOrg
  const {
    line1: line_1 = '',
    line2: line_2,
    cityName: city_name = '',
    stateCode: state_code = '',
    zipcode = '',
  } = address || {}
  const {
    payrollFrequency: payroll_frequency,
    twiceMonthlyType: twice_monthly_type,
    twiceMonthlyPayday1: twice_monthly_payday_1,
    twiceMonthlyPayday2: twice_monthly_payday_2,
    nextPayday: next_payday,
    lastDayOfPayPeriod: last_day_of_pay_period,
    lastDayOfMonth: last_day_of_month,
  } = paySchedule || {}

  return {
    legal_name: legalName,
    payroll_platform_id: payrollPlatform?.id || '',
    line_1,
    line_2: line_2 || '',
    city_name,
    state_code,
    zipcode,
    num_locations: 1,
    payroll_frequency,
    twice_monthly_type: twice_monthly_type || undefined,
    twice_monthly_payday_1: twice_monthly_payday_1 || undefined,
    twice_monthly_payday_2: twice_monthly_payday_2 || undefined,
    next_payday,
    last_day_of_pay_period,
    last_day_of_month: last_day_of_month || false,
    ...unchangedKeys,
  }
}
const createProvisionalOrg = (values: OrgOnboardingFormValues) =>
  fetchRest<ProvisionalOrganization>(`/api/onboarding/provisional_orgs`, {
    method: 'POST',
    body: JSON.stringify(formValuesToApiValues(values)),
  })

const getUpdateProvisionalOrg = (id: string) => (values: OrgOnboardingFormValues) =>
  fetchRest<ProvisionalOrganization>(`/api/onboarding/provisional_orgs/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(formValuesToApiValues(values)),
  })

/**
 * This hook manages the API calls for the full lifecycle of a provisional org
 * - create
 * - update
 * - 'submit' (convert to full org)
 *
 * It will create or update the provisional on every request, but will only convert to a full org if the action is 'submit'
 * and the user has confirmed that they want to do so. The confirmation is only required when converting to a full org
 * because it is not possible to edit the provisional organization _after_ it has been converted to a full org.
 */
export const useManageProvisionalOrg = (
  id: string | undefined,
  mutationOptions: UseGqlMutationCallbacks<ProvisionalOrganization, OrgOnboardingFormValues> = {}
) => {
  const { mutateAsync: createOrgFromProvisional } = useCreateOrgFromProvisionalMutation() // use mutateAsync so that errors bubble up to parent mutation

  const mutationOptionsWithNotifications = useMutationNotifier({}, mutationOptions)
  return useMutationWithInvalidation(
    async (values) => {
      // if `id` is specified, we are updating an existing provisional org, otherwise we are creating a new one
      const primaryRequest = id ? getUpdateProvisionalOrg(id) : createProvisionalOrg

      const provisionalOrg = await primaryRequest(values)

      if (values.action === 'submit') {
        // can only convert if the API says it's ready
        if (!provisionalOrg.ready_for_conversion) {
          throw new Error('Provisional organization is not ready for conversion')
        }
        await createOrgFromProvisional({ input: { provisionalOrgId: provisionalOrg.id } })
      }
      return provisionalOrg
    },
    mutationOptionsWithNotifications,
    'all'
  )
}
