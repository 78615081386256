import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useConnectedCardsQuery } from '../../gql/connectedCardsQuery_gen'
import {
  ReportMissingAliceCardMutation,
  ReportMissingAliceCardMutationVariables,
  useReportMissingAliceCardMutation,
} from '../gql/reportMissingAliceCard_gen'

type UseReportMissingCardMutationOptions = UseGqlMutationCallbacks<
  ReportMissingAliceCardMutation,
  ReportMissingAliceCardMutationVariables
>
export const useReportMissingCard = (mutationOptions?: UseReportMissingCardMutationOptions) => {
  const { mutateAsync: reportMissingCard } = useReportMissingAliceCardMutation()
  return useMutationWithInvalidation(reportMissingCard, mutationOptions, [useConnectedCardsQuery.getKey()])
}
