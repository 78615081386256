import * as React from 'react'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { InlineMaskedInput, InlineMaskedInputProps } from './InlineMaskedInput'

const _InlineCurrencyInput = (props: InlineMaskedInputProps, ref: React.Ref<HTMLInputElement>) => (
  <InlineMaskedInput
    thousandSeparator
    getInputRef={ref}
    prefix="$"
    isNumericString
    allowNegative={false}
    decimalScale={2}
    {...props}
  />
)
export const InlineCurrencyInput = React.forwardRef(_InlineCurrencyInput)

type InlineCurrencyInputControllerProps<T extends FieldValues> = Omit<
  InlineMaskedInputProps,
  'name' | 'variant'
> &
  Pick<ControllerProps<T>, 'name' | 'control' | 'rules'>
export const InlineCurrencyInputController = <T extends FieldValues>({
  name,
  control,
  rules,
  ...props
}: InlineCurrencyInputControllerProps<T>) => (
  <Controller
    render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
      const isError = Boolean((isTouched || submitCount > 0) && error?.message)
      return (
        <InlineCurrencyInput {...props} {...field} error={isError} helperText={isError && error?.message} />
      )
    }}
    name={name}
    control={control}
    rules={rules}
  />
)
