import {
  composeMutationCallbacks,
  UseGqlMutationCallbacks,
  useMutationOptionsWithInvalidation,
} from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { QueryKey, useMutation } from '@tanstack/react-query'
import {
  DeleteWorkLocationMutation,
  useDeleteWorkLocationMutation,
} from '../../organization/onboarding/gql/deleteWorkLocation_gen'

export type DeleteWorkLocationProps = {
  workLocationId: number
}

type UseDeleteLocationMutationOptions = UseGqlMutationCallbacks<
  DeleteWorkLocationMutation,
  DeleteWorkLocationProps
>

// we actually don't need the orgId to delete a location, but we need it to invalidate the cache
export const useDeleteWorkLocation = (
  invalidationKeys: QueryKey[],
  mutationOptions?: UseDeleteLocationMutationOptions
) => {
  const { notifyError } = useResponseNotification()
  // this basically does some additive composition of callbacks; you're going to get
  // the ones specified here regardless of what you pass in, and what you pass in will
  // take effect *in addition* to these defaults
  const composedMutationOptions = composeMutationCallbacks(mutationOptions, {
    onSuccess: (_data) => {
      // no need to do anything here
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    composedMutationOptions,
    invalidationKeys
  )
  const { mutateAsync: deleteWorkLocation } = useDeleteWorkLocationMutation()

  return useMutation(
    ['deleteWorkLocation'],
    (values) => deleteWorkLocation({ input: values }),
    mutationOptionsWithInvalidation
  )
}
