import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateWorkLocationMutationVariables = Types.Exact<{
  input: Types.CreateWorkLocationInput
}>

export type CreateWorkLocationMutation = {
  __typename?: 'Mutation'
  createWorkLocation?: {
    __typename?: 'CreateWorkLocationPayload'
    workLocation: { __typename?: 'WorkLocation'; id: number }
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateWorkLocationDocument = `
    mutation CreateWorkLocation($input: CreateWorkLocationInput!) {
  createWorkLocation(input: $input) {
    workLocation {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateWorkLocationMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateWorkLocationMutation,
    TError,
    CreateWorkLocationMutationVariables,
    TContext
  >
) => {
  return useMutation<CreateWorkLocationMutation, TError, CreateWorkLocationMutationVariables, TContext>(
    ['CreateWorkLocation'],
    (variables?: CreateWorkLocationMutationVariables) =>
      fetchGql<CreateWorkLocationMutation, CreateWorkLocationMutationVariables>(
        CreateWorkLocationDocument,
        variables
      )(),
    options
  )
}
