import { Box, Container, ContainerProps, ListSubheader, Typography } from '@mui/material'
import * as React from 'react'

type ListSectionProps = ContainerProps & {
  title: string
  action?: React.ReactElement
}
export const ListSection = ({ title, action, ...props }: ListSectionProps) => (
  <>
    <ListSubheader disableGutters>
      <Container>
        <Box justifyContent="space-between" alignItems="center" display="inline-flex" width="100%">
          <Typography variant="h3">{title}</Typography>
          {action}
        </Box>
      </Container>
    </ListSubheader>

    <li>
      <Container {...props} />
    </li>
  </>
)
