import { fetchGql } from '@alice-financial/api'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { EphemeralKeyDocument, EphemeralKeyQuery, EphemeralKeyQueryVariables } from './gql/ephemeralKey_gen'

type EphemeralKey = {
  id: string
  object: string
  associated_objects: Array<{
    id: string
    type: string
  }>
  created: number
  expires: number
  livemode: boolean
  secret: string
}

/**
 * Use this hook to get a type-safe ephemeral key for the issuing card
 */
export const useEphemeralKey = <TData = EphemeralKey, TError = Error>(
  variables: EphemeralKeyQueryVariables,
  options?: UseQueryOptions<EphemeralKey, TError, TData>
) => {
  return useQuery<EphemeralKey, TError, TData>(
    ['TypedEhemeralKey', variables],
    () =>
      fetchGql<EphemeralKeyQuery, EphemeralKeyQueryVariables>(EphemeralKeyDocument, variables)().then(
        (data) => data.employee?.spendingConnectionInfo?.aliceCard?.ephemeralKey
      ),
    options
  )
}
