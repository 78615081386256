import { InlineSkeleton, PII, Typography, TypographyProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AddressFragment } from '../../../graphql/fragments/AddressFragment_gen'
import { useQueryAddress } from './useQueryAddress'

type AddressDisplayProps = TypographyProps & {
  address: Omit<AddressFragment, 'isVerified' | 'isMailing'> | null | undefined
}

export const AddressLoading = (props: TypographyProps) => (
  <Typography {...props}>
    <InlineSkeleton width="8em" />
    <br />
    <InlineSkeleton width="6em" />
    <br />
    <InlineSkeleton width="10em" />
  </Typography>
)

export const AddressDisplay = ({ address, ...props }: AddressDisplayProps) => {
  if (!address) return <AddressLoading {...props} />
  return (
    <Typography {...props}>
      <PII>
        {address.line1}
        <br />
        {address.line2 ? (
          <>
            {address.line2}
            <br />
          </>
        ) : null}
        {address.cityName}, {address.stateCode} {address.zipcode}
      </PII>
    </Typography>
  )
}

export const UserAddressDisplay = (props: Omit<AddressDisplayProps, 'address'>) => {
  const { data: address } = useQueryAddress()
  return <AddressDisplay address={address} {...props} />
}
