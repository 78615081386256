import { UseGqlMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../../../../utils/useMutationNotifier'
import { CreateHostedLinkSessionInput } from '../../../../graphql/generated.types'
import {
  CreateHostedLinkSessionMutation,
  useCreateHostedLinkSessionMutation,
} from './gql/createHostedLinkSession_gen'
import { useEmployeeQuery } from '../../../gql/employee_gen'

type UsePlaidLinkSessionOptions = UseGqlMutationCallbacks<
  CreateHostedLinkSessionMutation,
  Pick<CreateHostedLinkSessionInput, 'existingConnectionId'>
>

/**
 * This hook exposes a mutation that will send the user to a Plaid Hosted Link session.
 * On return, the whole app will reload, with the pending, completed, or failed connection
 * information returned as part of the spending connections data for the user.
 */
export const useNavigateToPlaidLink = (mutationOptions?: UsePlaidLinkSessionOptions) => {
  const intl = useIntl()
  const { data: employeeData } = useEmployeeQuery()
  const employeeId = employeeData?.employee?.id
  const { mutateAsync: createHostedLinkSession } = useCreateHostedLinkSessionMutation({
    onSuccess: ({ createHostedLinkSession }) => {
      if (!createHostedLinkSession?.hostedLinkUrl) throw new Error('Could not start Plaid Link session')
      window.location.assign(createHostedLinkSession.hostedLinkUrl)
    },
  })
  const options = useMutationNotifier(
    { onMutate: intl.formatMessage({ id: 'cards.personal.redirecting_to_plaid' }), onSuccess: false },
    mutationOptions
  )
  const { mutate: navigateToPlaidLink } = useMutation(({ existingConnectionId }) => {
    if (!employeeId) throw new Error('Cannot start Plaid Link session without an employee')
    return createHostedLinkSession({
      input: { employeeId, returnUri: window.location.href, existingConnectionId },
    })
  }, options)
  return navigateToPlaidLink
}
