import { useProvisionalOrgQuery } from './useQueryProvisionalOrg'

type ErrorOnboardingStatus = ['error', undefined]
type UndefinedOnboardingStatus = [undefined, undefined]
type NoProvisionalOrgOnboardingStatus = ['no_user' | 'no_onboarding', undefined]
type CompletedOnboardingStatus = ['completed_onboarding_flow', undefined]
type ProvisionalOrgOnboardingStatus = ['unconverted_provisional_org', number | undefined]
type OnboardingStatusData =
  | ErrorOnboardingStatus
  | UndefinedOnboardingStatus
  | CompletedOnboardingStatus
  | NoProvisionalOrgOnboardingStatus
  | ProvisionalOrgOnboardingStatus

export const isCompletedOnboardingStatus = (
  status: OnboardingStatusData[0]
): status is CompletedOnboardingStatus[0] => Boolean(status === 'completed_onboarding_flow')

export const useOnboardingStatus = (): OnboardingStatusData => {
  const { data: provisionalOrg, userExists, isError: isProvisionalOrgQueryError } = useProvisionalOrgQuery()
  const org = provisionalOrg?.organization

  if (userExists === undefined) return [undefined, undefined]
  if (isProvisionalOrgQueryError) return ['error', undefined]
  if (!userExists) return ['no_user', undefined]
  // if the user already has an organization, they are definitionally done with the
  // start of the onboarding flow
  if (org) return ['completed_onboarding_flow', undefined]

  if (!provisionalOrg) {
    return ['no_onboarding', undefined]
  }
  return ['unconverted_provisional_org', provisionalOrg.id]
}

export const useIsOnboarding = () => {
  const [status] = useOnboardingStatus()
  if (status === undefined) return undefined
  return status !== 'no_onboarding' && status !== 'completed_onboarding_flow'
}
