import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type NotifyAdminKycMutationVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
  adminId: Types.Scalars['Int']['input']
}>

export type NotifyAdminKycMutation = {
  __typename?: 'Mutation'
  notifyAdminKyc?: { __typename?: 'NotifyAdminKYCPayload'; notificationSent?: boolean | null } | null
}

export const NotifyAdminKycDocument = `
    mutation NotifyAdminKYC($orgId: Int!, $adminId: Int!) {
  notifyAdminKyc(input: {orgId: $orgId, adminId: $adminId}) {
    notificationSent
  }
}
    `

export const useNotifyAdminKycMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<NotifyAdminKycMutation, TError, NotifyAdminKycMutationVariables, TContext>
) => {
  return useMutation<NotifyAdminKycMutation, TError, NotifyAdminKycMutationVariables, TContext>(
    ['NotifyAdminKYC'],
    (variables?: NotifyAdminKycMutationVariables) =>
      fetchGql<NotifyAdminKycMutation, NotifyAdminKycMutationVariables>(NotifyAdminKycDocument, variables)(),
    options
  )
}
