import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { QueryKey } from '@tanstack/react-query'

type WorkLocationLogoSubmitValues = {
  logo: File | null
  id: number
}

const submitWorkLocationLogo = ({ logo, id }: WorkLocationLogoSubmitValues) => {
  let body: FormData | string
  const headers: HeadersInit = {}

  if (logo === null) {
    body = JSON.stringify({ logo: null })
    headers['Content-Type'] = 'application/json'
  } else {
    const formData = new FormData()
    formData.append('logo', logo)
    body = formData
    // No need to set Content-Type for FormData, browser will set it automatically
  }

  return fetchRest(`/api/work_locations/${id}`, {
    method: 'PATCH',
    body,
    headers,
  })
}

type UseSubmitWorkLocationLogoMutationOptions = UseRestMutationCallbacks<
  unknown,
  WorkLocationLogoSubmitValues
>

export const useUpdateWorkLocationLogo = (
  mutationOptions: UseSubmitWorkLocationLogoMutationOptions = {},
  invalidationKeys: QueryKey[] = []
) => {
  const intl = useIntl()
  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'organization.onboarding.locations.submission_notice' }),
      onSuccess: false,
    },
    mutationOptions
  )

  return useMutationWithInvalidation(submitWorkLocationLogo, _mutationOptions, invalidationKeys)
}
