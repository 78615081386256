import { Fade, Grid, styled, Typography, TypographyProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import chrisPicUrl from './assets/testimonial-chris.png'
import vergelPicUrl from './assets/testimonial-vergel.png'
import { CarouselSlide } from './CarouselSlide'
import { FormattedMessage } from 'react-intl'

const BlockQuote = styled(Typography)<TypographyProps>(({ theme }) => ({
  position: 'relative',
  fontSize: '0.8em',
  paddingLeft: '1.2em',
  paddingRight: '1em',
  '&::before': {
    content: '"\\201C"',
    fontSize: '3em',
    position: 'absolute',
    top: '-0.5em',
    left: 0,
    color: theme.palette.text.secondary,
  },
  '&::after': {
    content: '"\\201D"',
    fontSize: '3em',
    position: 'absolute',
    bottom: '-1.2em',
    right: '0.5em',
    color: theme.palette.text.secondary,
  },
}))

type TestimonialProps = {
  src: string
  caption: string
  children: React.ReactNode
  reverse?: boolean
}
const Testimonial = ({ src, caption, children, reverse }: TestimonialProps) => (
  <Grid container flexDirection="column" alignItems="center">
    <Grid
      item
      container
      maxWidth={500}
      flexDirection={reverse ? 'row-reverse' : 'row'}
      alignItems="center"
      columnGap={1}
      wrap="nowrap"
    >
      <Grid item>
        <BlockQuote component="blockquote" gutterBottom textAlign="left">
          {children}
        </BlockQuote>
      </Grid>

      <Grid item width={100}>
        <img height={100} width={100} src={src} alt={caption} />
        <Typography variant="caption" component="p" align="center" fontStyle="italic" fontSize="0.6em">
          {caption}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

const useDelay = (delayMs: number, isActive: boolean) => {
  const [delayedActive, setDelayedActive] = React.useState(false)
  React.useEffect(() => {
    if (!isActive) return
    const timeout = setTimeout(() => setDelayedActive(true), delayMs)
    return () => {
      clearTimeout(timeout)
      setDelayedActive(false)
    }
  }, [isActive, delayMs])
  return isActive && delayedActive
}
const TestimonialGraphic = ({ isActive }: { isActive: boolean }) => {
  const activeOne = useDelay(300, isActive)
  const activeTwo = useDelay(500, isActive)
  return (
    <Grid container flexDirection="column" alignItems="center">
      <Fade in={activeOne}>
        <Grid item>
          <Testimonial src={chrisPicUrl} caption="Chris, Holywater">
            <FormattedMessage id="enroll.testimonials.chris" />
          </Testimonial>
        </Grid>
      </Fade>

      <Fade in={activeTwo}>
        <Grid item>
          <Testimonial reverse src={vergelPicUrl} caption="Vergel, Temperance Wine Bar">
            <FormattedMessage id="enroll.testimonials.vergel" />
          </Testimonial>
        </Grid>
      </Fade>
    </Grid>
  )
}

export const Testimonials = ({ isActive }: { isActive: boolean }) => (
  <CarouselSlide
    title={<FormattedMessage id="enroll.testimonials.heading" />}
    graphic={<TestimonialGraphic isActive={isActive} />}
    isActive={isActive}
  >
    <Typography variant="body2" gutterBottom>
      <FormattedMessage id="enroll.testimonials.add_to_paychecks" />
    </Typography>
    <Typography variant="body2">
      <FormattedMessage id="enroll.testimonials.hear_why_heading" />
    </Typography>
  </CarouselSlide>
)
