import { useLogout } from './useLogout'

/**
 * A zero-render logout page that clears local state and returns the user to the
 * root of the application (which will typically redirect to Login)
 */
export const Logout = () => {
  useLogout()
  return null
}
