import { Alert, AlertTitle, Container } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { dateFormat } from '../../../utils/formatters/dateFormat'
import { useEmployeeQuery } from '../../employeeDashboard/gql/employee_gen'
import { useOffboardDate } from './orgOffboardUtils'

export const OffboardedOrgAlert = () => {
  const offboardDate = useOffboardDate()
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  if (!employee) return null
  if (!offboardDate) return null

  const employerName = employee?.employer.marketingFriendlyName
  const offboardDateString = dateFormat.medium(offboardDate)

  const message =
    offboardDate > new Date() ? (
      <FormattedMessage
        id="organization.offboard_pending.alert"
        values={{ offboardDate: offboardDateString }}
      />
    ) : (
      <>
        <AlertTitle>
          <FormattedMessage id="organization.offboarded.heading" values={{ orgName: employerName }} />
        </AlertTitle>
        <FormattedMessage id="organization.offboarded.alert" values={{ offboardDate: offboardDateString }} />
      </>
    )

  return (
    <Container>
      <Alert severity="warning">{message}</Alert>
    </Container>
  )
}
