import * as Types from '../generated.types'

export type PageInfoFragment = {
  __typename?: 'PageInfo'
  startCursor?: string | null
  endCursor?: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
}

export const PageInfoFragmentDoc = `
    fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `
