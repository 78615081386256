import React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Grid,
  TextFieldController,
  TelFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import { prettyTelString } from '../../utils/formatters/telFormat'
import { useCreatePhoneLoginCode } from '../user/useCreatePhoneLoginCode'
import { useLoginByPhoneCode } from '../user/useLoginByPhoneCode'

export const TelVerificationForm = () => {
  const intl = useIntl()
  const [showVerificationCode, setShowVerificationCode] = React.useState(false)
  const [showTelPassword, setShowTelPassword] = React.useState(false)
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: { phoneNumber: '', verificationCode: '', password: '' },
  })

  const tel = watch('phoneNumber')
  const password = watch('password')

  const { mutate: createPhoneCode, isLoading: createLoginCodeIsLoading } = useCreatePhoneLoginCode({
    onSuccess: ({ createPhoneLoginCode }) => {
      setShowTelPassword(Boolean(createPhoneLoginCode?.needsPassword))
      setShowVerificationCode(true)
    },
  })

  const isGetCodeDisabled = createLoginCodeIsLoading === true

  const { mutate: loginByPhone, isLoading: loginByPhoneIsLoading } = useLoginByPhoneCode()

  const isVerifyDisabled = loginByPhoneIsLoading === true || (showTelPassword && password.length < 8)

  const resetTel = () => {
    setShowVerificationCode(false)
    setValue('verificationCode', '')
  }

  const onRequestPhoneCode = handleSubmit((values) => {
    createPhoneCode({ tel: values.phoneNumber })
  })

  const onSubmitPhoneCode = handleSubmit((values) => {
    loginByPhone({ tel: values.phoneNumber, code: values.verificationCode, password: values.password })
  })

  return (
    <>
      {!showVerificationCode && (
        <Box mb={3} component="form" onSubmit={onRequestPhoneCode}>
          <Typography gutterBottom>
            <FormattedMessage id="auth.login.request_code" />
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TelFieldController
                control={control}
                name="phoneNumber"
                label={intl.formatMessage({ id: 'profile.tel_label' })}
                required
                fullWidth
                autoComplete="tel"
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Box component={Grid} item xs={12} sm={6} mt={3} mb={3}>
              <Button variant="contained" type="submit" fullWidth disabled={isGetCodeDisabled}>
                <FormattedMessage id="auth.login.get_code" />
              </Button>
            </Box>
          </Grid>
        </Box>
      )}
      {showVerificationCode && (
        <Box mb={3} component="form" onSubmit={onSubmitPhoneCode}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                <FormattedMessage id="auth.login.code_sent" values={{ tel: tel && prettyTelString(tel) }} />
                <Button variant="text" onClick={resetTel}>
                  <FormattedMessage id="auth.login.get_new_code" />
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldController
                control={control}
                name="verificationCode"
                label={<FormattedMessage id="auth.login.verification_code" />}
                required
                fullWidth
                type="text"
                autoComplete="one-time-code"
                inputProps={{ maxLength: 6 }}
                rules={{
                  required: true,
                  minLength: {
                    value: 6,
                    message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 6 }),
                  },
                }}
              />
            </Grid>
            {showTelPassword && (
              <Grid item xs={12}>
                <TextFieldController
                  control={control}
                  name="password"
                  label={<FormattedMessage id="profile.password_label" />}
                  required
                  fullWidth
                  type="password"
                  autoComplete="current-password"
                  rules={{
                    required: true,
                    minLength: {
                      value: 8,
                      message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 8 }),
                    },
                  }}
                />
              </Grid>
            )}
            <Box component={Grid} item xs={6}>
              <Button variant="contained" type="submit" fullWidth disabled={isVerifyDisabled}>
                <FormattedMessage id="auth.login.do_login" />
              </Button>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  )
}
