import { Collapse, Container, Divider, Grid, List, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl'
import { ConfirmationStatusValue } from '../../graphql/generated.types'
import { useOffboardDate } from '../../organization/offboarding/orgOffboardUtils'
import { EligibleExpensesInfo } from './EligibleExpensesInfo'
import { TxnListItem } from './TransactionListItem'
import { useTransactionsListQuery } from './useTransactionsListQuery'

export const UnconfirmedTxnListItems = ({ divider }: { divider?: boolean }) => {
  const offboardDate = useOffboardDate()
  const {
    data: { txns: unconfirmedTxns },
  } = useTransactionsListQuery({ days: 365 }, ConfirmationStatusValue.Unconfirmed)
  const intl = useIntl()

  const hasConfirmableTransactions =
    unconfirmedTxns.length > 0 && (!offboardDate || offboardDate > new Date())

  return (
    <Collapse in={hasConfirmableTransactions} component="li" unmountOnExit>
      <Container>
        <Typography variant="h3" gutterBottom>
          <Grid container wrap="nowrap" columnGap={2} alignItems="center" component="span">
            <Grid item component="span">
              🚨
            </Grid>
            <Grid item component="span">
              <FormattedPlural
                value={unconfirmedTxns.length}
                one={intl.formatMessage({ id: 'transactions.transaction_list.is_it_for' })}
                other={intl.formatMessage({
                  id: 'transactions.transaction_list.are_these_for',
                })}
              />{' '}
              <EligibleExpensesInfo>
                <FormattedMessage id="transactions.transaction_list.eligible_commuter_expenses" />
              </EligibleExpensesInfo>
            </Grid>
          </Grid>
        </Typography>
      </Container>

      <List disablePadding>
        {unconfirmedTxns.map((txn) => (
          <TxnListItem key={txn.id} txn={txn} />
        ))}
        {divider && (
          <li>
            <Divider variant="fullWidth" sx={{ marginTop: '2em' }} />
          </li>
        )}
      </List>
    </Collapse>
  )
}
