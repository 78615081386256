import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { ListItemButton, ListItemButtonProps } from '@mui/material'
import { Link } from '../navigation'

type ListItemLinkProps = Pick<ListItemButtonProps, 'children' | 'color' | 'sx'> & {
  to: string
  actionIcon?: React.ReactNode
}
/**
 * A variant of ListItemButton - use it the same way, but for list items
 * that need to act like links
 */
export const ListItemLink = ({ children, to, actionIcon, ...buttonProps }: ListItemLinkProps) => (
  <ListItemButton component={Link} to={to} {...buttonProps}>
    {children}
    {actionIcon || <ChevronRightIcon />}
  </ListItemButton>
)
