import * as Types from '../../../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateOmnyCardShipmentMutationVariables = Types.Exact<{
  input: Types.UpdateOmnyCardShipmentInput
}>

export type UpdateOmnyCardShipmentMutation = {
  __typename?: 'Mutation'
  updateOmnyCardShipment?: {
    __typename?: 'UpdateOMNYCardShipmentPayload'
    connection?: { __typename?: 'BankingConnection'; id: number } | null
  } | null
}

export const UpdateOmnyCardShipmentDocument = `
    mutation UpdateOMNYCardShipment($input: UpdateOMNYCardShipmentInput!) {
  updateOmnyCardShipment(input: $input) {
    connection {
      id
    }
  }
}
    `

export const useUpdateOmnyCardShipmentMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOmnyCardShipmentMutation,
    TError,
    UpdateOmnyCardShipmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateOmnyCardShipmentMutation,
    TError,
    UpdateOmnyCardShipmentMutationVariables,
    TContext
  >(
    ['UpdateOMNYCardShipment'],
    (variables?: UpdateOmnyCardShipmentMutationVariables) =>
      fetchGql<UpdateOmnyCardShipmentMutation, UpdateOmnyCardShipmentMutationVariables>(
        UpdateOmnyCardShipmentDocument,
        variables
      )(),
    options
  )
}
