import { List, ListItem, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployerSummaryFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { BankableTypeEnum } from '../../../graphql/generated.types'
import { EntityFunding } from './EntityFunding'
import { isConnected } from './fundingUtils'

const unfundedFirst = (a: EmployerSummaryFragment, b: EmployerSummaryFragment) =>
  isConnected(a.aliceCardFunding) ? 1 : isConnected(b.aliceCardFunding) ? -1 : 0

type PaygroupFundingListProps = {
  paygroups: Array<EmployerSummaryFragment>
  readonly?: boolean
}
/**
 * A list of companies that need to finish connecting payment - i.e. companies with
 * alice_card_funding_status of 'not_connected' or 'pending_verification'
 *
 * This list should always display on the org dashboard in the non-unified banking case, and
 * never display on the org dashboard in the unified banking case.
 *
 * It should only display on the employer dashboard if the employer has already connected banking, i.e.
 * it should not display if the admin still needs to finish connecting the current employer
 *
 */
export const PaygroupFundingList = ({ paygroups, readonly }: PaygroupFundingListProps) => {
  if (paygroups.length === 0) return null

  return (
    <>
      <Typography variant="h4" gutterBottom mt={2}>
        Pay group payroll bank accounts
      </Typography>
      <List sx={{ width: '100%' }}>
        {paygroups.sort(unfundedFirst).map((paygroup) => (
          <ListItem key={paygroup.id} divider>
            <EntityFunding entity={{ type: BankableTypeEnum.Paygroup, ...paygroup }} readonly={readonly} />
          </ListItem>
        ))}
      </List>
    </>
  )
}
