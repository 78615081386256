import React from 'react'
import { useNotify } from './GlobalNotification'

/**
 * A thin wrapper around the `useNotify` hook that provides global notifications. It provides
 * convenience callbacks for submitting a form and notifying about success and failure
 */
export const useResponseNotification = () => {
  const { notify } = useNotify()

  return React.useMemo(
    () => ({
      notifySubmit: (message: string) => notify({ open: true, variant: 'pending', message }),
      notifySuccess: (message: string) => notify({ open: true, variant: 'success', message }),
      notifyError: (message: string) => notify({ open: true, variant: 'error', message }),
      clearNotif: () => notify({ open: false, variant: 'success', message: 'Done' }),
    }),
    [notify]
  )
}
