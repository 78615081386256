import { FormLabel, Grid, Paper, useGridApiContext } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { GrantAdminAccessButton } from './GrantAdminAccessButton'
import { OptInOutButton } from './OptInOutButton'
import { EmployeeRow } from './types'

export const EmployeeListActions = () => {
  const apiRef = useGridApiContext()
  const selected = apiRef.current.getSelectedRows()
  const numSelected = selected.size
  const disabled = numSelected === 0
  const selectedRows = Array.from(selected.values()) as Array<EmployeeRow> // type coercion because inference is very complex here

  return (
    <Paper elevation={2} sx={{ padding: 2, paddingTop: 1.5 }}>
      <FormLabel disabled={disabled}>
        Update {numSelected || false} selected {numSelected === 1 ? 'employee' : 'employees'}
      </FormLabel>
      <Grid container columnGap={1}>
        <Grid item>
          <GrantAdminAccessButton employees={selectedRows} />
        </Grid>
        <Grid item>
          <OptInOutButton employees={selectedRows} />
        </Grid>
      </Grid>
    </Paper>
  )
}
