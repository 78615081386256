import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { UpdateBillingConfigInput } from '../../graphql/generated.types'
import { UpdateBillingConfigMutation, useUpdateBillingConfigMutation } from './gql/updateBillingConfig_gen'

type SetUnifiedBillingVariables = Required<Pick<UpdateBillingConfigInput, 'orgId' | 'hasUnifiedBilling'>>
type UseSetUnifiedBillingMutationOptions = UseGqlMutationCallbacks<
  UpdateBillingConfigMutation,
  SetUnifiedBillingVariables
>

export const useSetUnifiedBilling = (
  invalidationKeys: Array<QueryKey>,
  mutationOptions?: UseSetUnifiedBillingMutationOptions
) => {
  const { mutateAsync: updateBillingConfig } = useUpdateBillingConfigMutation()
  const optionsWithInvalidation = useMutationOptionsWithInvalidation(mutationOptions, invalidationKeys)
  return useMutation(
    ['setUnifiedBilling'],
    (input) => updateBillingConfig({ input }),
    optionsWithInvalidation
  )
}
