import { fetchRest } from '@alice-financial/api/rest/fetchRest'

const EVENTS_PATH = `/api/events`

const regex = /^\/manage\/|\/onboard(\/|$)/
const isManageOrOnboardPath = (path: string) => regex.test(path)

export const trackAdminPageNavigationEvent = (eventData: Record<string, unknown>) => {
  if (!eventData?.path || typeof eventData.path !== 'string' || !isManageOrOnboardPath(eventData.path)) {
    return null
  }
  trackEvent('page_view', eventData)
}

const trackEvent = (eventName: string, eventData: { [key: string]: unknown }) => {
  if (navigator.sendBeacon) {
    const payload = new Blob(
      [
        JSON.stringify({
          event_name: eventName,
          payload: eventData,
        }),
      ],
      { type: 'application/json' }
    )
    const url = `${process.env.ALICE_ORIGIN}${EVENTS_PATH}`
    return navigator.sendBeacon(url, payload)
  } else {
    fetchRest(EVENTS_PATH, {
      method: 'POST',
      body: JSON.stringify({
        event_name: eventName,
        payload: eventData,
      }),
    })
  }
}
