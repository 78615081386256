import { Location, useHref, useLocation } from 'react-router'
import { REDIRECT_PARAM } from '../routes/routeUtils'

/**
 * This hook returns a path to the login page with a redirect param that will return the user
 * to the location where the login was initiated, or an override `redirectLocation` if provided
 */
export const useLoginPath = (redirectLocation?: Location): `/login?${string}` => {
  const currentLocation = useLocation()
  const location = redirectLocation || currentLocation
  const href = useHref(redirectLocation || currentLocation)

  const redirect = location.pathname === '/logout' ? '/login' : href
  const loginSearchParams = new URLSearchParams({ [REDIRECT_PARAM]: redirect })
  return `/login?${loginSearchParams}`
}
