import { datadogRum } from '@datadog/browser-rum'
import { AnalyticsBrowser, Context } from '@segment/analytics-next'
import * as React from 'react'
import { TrackingInfo } from './TrackingProvider'

export type IndentifiableUser = { id?: string }

/**
 * Add checks for requests from known bots to this function to avoid tracking
 * bots as though they were real-life human beans
 */
const checkForBotRequest = () => {
  return window._DATADOG_SYNTHETICS_BROWSER || navigator.userAgent.includes('DatadogSynthetics')
}

declare global {
  interface Window {
    _DATADOG_SYNTHETICS_BROWSER: unknown
  }
}

const WRITE_KEYS = {
  localhost: '2j1u5t8dpnjRuhf4dlYQub1r9DeNec9z', // https://app.segment.com/thisisalice/sources/local-dev-user-web/overview
  sandbox: 'ox2xxm3p5ksUhxUhrxFkewDIDHBsIIg4', // https://app.segment.com/thisisalice/sources/user-web-sandbox/overview
  'benefits-sandbox': 'ZsbhRNzj9iqpkZDpM0bN9DMoGnRIbIH6', // https://app.segment.com/thisisalice/sources/user_web_benefits_sandbox/overview
  app: 'GBO9HZRwpwIFiIEylUIKD1lU7QS3WOxZ', // https://app.segment.com/thisisalice/sources/user-web-prod/overview
}

// Based on defined Segment write keys, find the one that matches the current domain
const getWriteKey = () => {
  const matchingEntry = Object.entries(WRITE_KEYS).find(([subdomain]) =>
    window.location.hostname.startsWith(subdomain)
  )
  return matchingEntry ? matchingEntry[1] : ''
}

type Identify = (userId: string, traits?: Record<string, unknown>) => void
type SupportedAnalyticsCalls = { identify: Identify } & Pick<AnalyticsBrowser, 'track' | 'page' | 'reset'> &
  Pick<typeof datadogRum, 'getUser'>
/**
 * Segment analytics context
 *
 * @see https://github.com/segmentio/analytics-next#-using-as-an-npm-package
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
 */
const AnalyticsContext = React.createContext<SupportedAnalyticsCalls | undefined>(undefined)

type Props = {
  children: React.ReactNode
}
const noopSegmentContext = () => Promise.resolve({} as Context)
const noopVoid = () => Promise.resolve()
const noopDataDogContext: typeof datadogRum.getGlobalContext = () => ({})

export const AnalyticsProvider = ({ children }: Props) => {
  const value = React.useMemo(() => {
    if (checkForBotRequest()) {
      return {
        track: noopSegmentContext,
        page: noopSegmentContext,
        identify: noopSegmentContext,
        reset: noopVoid,
        getUser: noopDataDogContext,
      }
    }
    const analytics = AnalyticsBrowser.load({ writeKey: getWriteKey() })
    // the 'identify' method applies supplied UserID to DataDog tracking as well as Segment
    const identify = (userId: string, traits?: Record<string, unknown>) => {
      if (typeof userId === 'string' && datadogRum.getUser().id !== userId) {
        datadogRum.setUser({
          id: userId,
          adminUrl: `${window.location.origin}/admin/identity_users/${userId}`,
        })
      }
      console.debug('IDENTIFY:', userId, traits)
      // the traits will be sent to Mixpanel as 'super properties'
      // https://segment.com/docs/connections/destinations/catalog/mixpanel/#register-super-properties
      analytics.identify(userId, traits)
    }
    // the 'track' method triggers supplied event name in DataDog tracking as well as Segment
    const track: typeof analytics.track = (eventName, eventProperties, ...args) => {
      console.debug('TRACK:', eventName, eventProperties)
      if (typeof eventName === 'string') {
        datadogRum.addAction(eventName, eventProperties)
      }
      // since segment doesn't have a 'global context', inject relevant values into every 'track' call, e.g. feature flags
      const globalContext = datadogRum.getGlobalContext() as TrackingInfo
      const segmentEventProperties = {
        ...eventProperties,
        ...globalContext.flags,
        source: process.env.SEGMENT_SOURCE_NAME || 'user-web (prod)',
      }
      return analytics.track(eventName, segmentEventProperties, ...args)
    }

    // clear all user data from DataDog and Segment
    const reset: typeof analytics.reset = () => {
      console.debug('RESET')
      datadogRum.clearGlobalContext()
      datadogRum.clearUser()
      return analytics.reset()
    }
    return {
      track,
      page: analytics.page.bind(analytics),
      identify,
      reset,
      getUser: () => datadogRum.getUser(),
    }
  }, [])
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext)
  if (!result) {
    throw new Error('useAnalytics called outside of AnalyticsProvider')
  }
  return result
}

/**
 * Define string-based enum that can be used as first argument to `analytics.track` calls - ideally
 * we should avoid using arbitrary string args
 */
export enum TrackingEvent {
  EnrollmentStart = 'enrollment_start',
  AliceCardRequested = 'AliceCardRequested',
}
