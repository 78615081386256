import * as React from 'react'

type PIIProps = {
  children: React.ReactNode
}

/**
 * Wrapping element that should _always_ be used when displaying PII (Personally Identifiable Information).
 *
 * This element will be used to mask PII in the DOM in sensitive environments, e.g. session replay
 */
export const PII = ({ children }: PIIProps) => <span data-dd-privacy="mask">{children}</span>
