import * as React from 'react'
import { IndexRouteObject, NonIndexRouteObject, RouteObject } from 'react-router'

/**
 * Essentially, a `RouteObject` with no `path`. Note that we have to use the union
 * operator to maintain the correct `index` type
 */
type PathlessRouteObject = (IndexRouteObject | NonIndexRouteObject) & {
  path?: never
}

/**
 * Essentially, a `RouteObject` with a `path` value. Note that we have to use the union
 * operator to maintain the correct `index` type, and we Omit `handle` in order to provide
 * a narrower type definition override for that field
 */
type PathRouteObject = (Omit<IndexRouteObject, 'handle'> | Omit<NonIndexRouteObject, 'handle'>) & {
  path: string
  handle: { pathPattern: string; title: React.ReactNode }
}

export type UserWebRouteObject = PathlessRouteObject | PathRouteObject

const isPathlessRouteObject = (r: RouteObject): r is PathlessRouteObject =>
  !Object.hasOwnProperty.call(r, 'path')
const isPathRouteObject = (r: RouteObject): r is PathRouteObject =>
  Boolean(r.path && r.handle?.pathPattern === r.path)

const isUserWebRouteObject = (r: RouteObject): r is UserWebRouteObject =>
  (isPathlessRouteObject(r) || isPathRouteObject(r)) &&
  (!r.children || r.children.every(isUserWebRouteObject))

/**
 * Our application has some specific requirements around route definitions, which generally must conform
 * to the `RouteObject` shape defined by react-router. This function takes a basic `RouteObject` and
 * injects the required `handle.pathPattern` values for routes that define a `path`.
 *
 * It will throw an error if any route object cannot be normalized
 */
export const normalizeRoute = (route: RouteObject): UserWebRouteObject => {
  if (route.children) {
    route.children = route.children.map(normalizeRoute)
  }
  if (route.path) {
    route.handle = { ...route.handle, pathPattern: route.handle?.pathPattern || route.path }
  }

  if (isUserWebRouteObject(route)) {
    return route
  }
  throw new Error('invalid route definition')
}
