import { ActionLayout, Button, TelFieldController, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { TelephoneFragment } from '../../graphql/fragments/TelephoneFragment_gen'
import { useRequestPhoneVerification } from '../useRequestPhoneVerification'

type SendPhoneVerificationFormValues = {
  tel: string
}
type SendPhoneVerificationProps = {
  tel: TelephoneFragment | undefined | null
  onDone: () => void
  resend?: boolean
}

export const SendPhoneVerificationForm = ({ tel, onDone, resend }: SendPhoneVerificationProps) => {
  const intl = useIntl()
  const {
    mutate: sendPhoneVerificationCode,
    isLoading: isSendingNewCode,
    isSuccess,
  } = useRequestPhoneVerification({ onSuccess: onDone })
  const { handleSubmit, control } = useForm<SendPhoneVerificationFormValues>({
    values: {
      tel: tel?.nationalNumber ?? '',
    },
  })

  const onSubmit = handleSubmit(({ tel: telValue }) => {
    sendPhoneVerificationCode({ tel: telValue })
  })

  const resendCodeDisabled = isSuccess || isSendingNewCode
  const sendLabel = resend
    ? intl.formatMessage({ id: 'enroll.phone_verification.resend_code' })
    : intl.formatMessage({ id: 'enroll.phone_verification.send_code' })

  return (
    <form data-testid="verify-phone" onSubmit={onSubmit}>
      <Typography variant="body2" gutterBottom>
        {resend ? (
          <FormattedMessage id="enroll.phone_verification.check_number" />
        ) : (
          <FormattedMessage id="enroll.phone_verification.need_to_confirm_phone_number" />
        )}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="enroll.phone_verification.edit_and_resend" values={{ sendLabel }} />
      </Typography>

      <TelFieldController
        name="tel"
        label={intl.formatMessage({ id: 'profile.tel_label' })}
        required
        fullWidth
        autoComplete="tel"
        control={control}
        sx={{ marginTop: 2 }}
      />

      <ActionLayout
        primary={
          <Button variant="contained" type="submit" disabled={resendCodeDisabled} fullWidth>
            {sendLabel}
          </Button>
        }
        secondary={
          <Button variant="outlined" onClick={onDone}>
            <FormattedMessage id="common.cancel.never_mind" />
          </Button>
        }
      />
    </form>
  )
}
