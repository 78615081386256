import * as React from 'react'
import { useForm } from 'react-hook-form'
import { dateFormat, dateStringFormat } from '../../utils/formatters/dateFormat'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { UpdateEmploymentStartDateMutationVariables } from './gql/updateEmploymentStartDate_gen'
import { useUpdateEmploymentStartDate } from './useUpdateEmploymentStartDate'

export type CompleteEnrollmentValues = Pick<
  UpdateEmploymentStartDateMutationVariables,
  'employmentStartDate'
> & {
  alreadyStarted: boolean
}

export const useCompleteEnrollmentRequirements = () => {
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  const { mutateAsync: updateEmploymentStartDate, ...mutationState } = useUpdateEmploymentStartDate()
  const employee = enrollmentData?.employee
  const knownStartDate = employee?.employmentStartDate
  const { handleSubmit, ...form } = useForm<CompleteEnrollmentValues>({
    values: {
      employmentStartDate: knownStartDate || '',
      alreadyStarted: !knownStartDate || Boolean(new Date() > new Date(knownStartDate)),
    },
  })
  const [alreadyStarted, startDate] = form.watch(['alreadyStarted', 'employmentStartDate'])
  const { setValue, getValues } = form

  // handle changes to 'already_started' independently of 'employment_start_date'
  React.useEffect(() => {
    // the start date input value is formatted as 'YYYY-MM-DD' - need to format it consistently
    // in order to compare with `today`
    const { employmentStartDate } = getValues()
    const _startDate = employmentStartDate && dateStringFormat.dateFromDateString(employmentStartDate)
    const today = new Date()
    if (alreadyStarted && _startDate && _startDate <= today) return
    if (!alreadyStarted && (!_startDate || _startDate >= today)) return

    if (alreadyStarted) {
      setValue('employmentStartDate', dateFormat.inputVal(today))
    } else {
      setValue('employmentStartDate', '')
    }
  }, [alreadyStarted, setValue, getValues])
  // handle changes to 'employment_start_date' independently of 'already_started'
  React.useEffect(() => {
    if (!startDate) return
    const _alreadyStarted = getValues().alreadyStarted
    const today = dateFormat.inputVal(new Date())
    const expectedAlreadyStarted = startDate <= today
    if (_alreadyStarted !== expectedAlreadyStarted) {
      setValue('alreadyStarted', expectedAlreadyStarted)
    }
  }, [startDate, setValue, getValues])

  return {
    isComplete: Boolean(knownStartDate),
    onSubmit: handleSubmit(async (values) => {
      if (knownStartDate) throw new Error('Start date already set')
      if (!values.employmentStartDate) throw new Error('Start date required')
      if (!employee) throw new Error('Employee not found')

      updateEmploymentStartDate({
        employeeId: employee.id,
        employmentStartDate: values.employmentStartDate,
      })
    }),
    ...form,
    ...mutationState,
  }
}
