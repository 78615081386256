import { useQuery } from '@tanstack/react-query'
import { useCreateIssuingSetupIntentMutation } from '../../externalServices/stripe/gql/createIssuingSetupIntent_gen'
import { useResponseNotification } from '@alice-financial/pretext-ui'

/**
 * This is kind of cheating because we're wrapping a mutation in useQuery, but in this case the mutation
 * is creating a value that we need as soon as the owner is loaded.
 */
export const useIssuingClientSecret = (
  stripeCompanyAccountId: number | null | undefined,
  stripePaymentMethodId: string | null | undefined
) => {
  const { mutateAsync: createSetupIntent } = useCreateIssuingSetupIntentMutation({})
  const { notifyError } = useResponseNotification()
  const genericError = 'Could not configure Stripe payment method. Please refresh and try again.'

  return useQuery(
    ['createIssuingSetupIntent', stripeCompanyAccountId],
    async () => {
      if (!stripeCompanyAccountId) {
        throw new Error(genericError)
      }
      const response = await createSetupIntent({
        input: {
          stripeCompanyAccountId,
          stripePaymentMethodId,
        },
      })
      if (!response.createIssuingSetupIntent?.clientSecret) {
        throw new Error(genericError)
      }
      return response.createIssuingSetupIntent.clientSecret
    },
    {
      enabled: stripeCompanyAccountId != null,
      onError: (error: Error) => {
        notifyError(error.message)
      },
    }
  )
}
useIssuingClientSecret.getKey = (stripeAccountId: string) => ['createIssuingSetupIntent', stripeAccountId]
