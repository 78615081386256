import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ActionLayout,
  Box,
  Button,
  Grid,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useParams } from 'react-router'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { PageSizePagerControls } from '../../../utils/paging/pageSizePaging/PageSizePagerControls'
import { usePageSizePagingContext } from '../../../utils/paging/pageSizePaging/PageSizePagingContext'
import { isNotNull } from '../../../utils/typeUtils'
import { PayPeriodSummaryFragment, usePayPeriodsQuery } from './gql/payPeriods_gen'
import { PayPeriodRecordsList } from './PayPeriodRecordsList'
import { useOrgId } from '../useOrgId'

const startEndString = (start: string | null | undefined, end: string | null | undefined) =>
  `${start} to ${end}`

const PayPeriod = ({ payPeriod }: { payPeriod: PayPeriodSummaryFragment }) => {
  // the 'detail' view for a pay period makes an expensive lookup for pay period records. This component must lazy-load them
  // when the detail view opens
  const lazyLoadPayPeriodRecords = true
  return (
    <Accordion slotProps={{ transition: { unmountOnExit: lazyLoadPayPeriodRecords } }}>
      <AccordionSummary>
        <ListItem component="div" disableGutters disablePadding>
          <Box width="100%" display="flex" justifyContent="space-between" flexWrap="nowrap">
            <Box>
              <Typography
                variant="subtitle1"
                component="h4"
                fontSize="0.8rem"
                fontWeight="bold"
                textOverflow="ellipsis"
                sx={{ textWrap: 'nowrap' }}
              >
                {payPeriod.paygroupInfo.name}
              </Typography>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Pay date: {dateStringFormat.long(payPeriod.payday)}
                  </Typography>
                }
              />
            </Box>
            <Box textAlign="right">
              <Typography
                variant="subtitle1"
                component="h4"
                fontSize="0.8rem"
                fontWeight="bold"
                sx={{ textWrap: 'nowrap' }}
              >
                Pay period {startEndString(payPeriod.startDate, payPeriod.endDate)}
              </Typography>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Deductions: {formatCentsAsDollars(payPeriod.totalEmployeeDeduction, false)}
                  </Typography>
                }
                secondary={
                  Boolean(payPeriod.totalEmployeeReimbursement) && (
                    <Typography variant="caption" component="p" noWrap>
                      Reimbursements: {formatCentsAsDollars(payPeriod.totalEmployeeReimbursement, false)}
                    </Typography>
                  )
                }
              />
            </Box>
          </Box>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <ListItem component="div" disableGutters disablePadding>
          <ListItemIcon></ListItemIcon>
          <Box width="100%" py={1} pr={2}>
            <Grid container spacing={2} justifyContent="space-between" sx={{ paddingBottom: 2 }}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ padding: 2, textAlign: 'center', height: '100%' }}>
                  <Typography variant="subtitle1">Estimated employee savings</Typography>
                  <Typography variant="h1" component="p" color="primary">
                    {formatCentsAsDollars(payPeriod.totalEmployeeSavings, false)}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" fontWeight="normal">
                    {payPeriod.employeeCount} employees
                  </Typography>
                </Paper>
              </Grid>
              {payPeriod.invoice && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ padding: 2, textAlign: 'center', height: '100%' }}>
                    <Typography variant="subtitle1">Invoice amount</Typography>
                    <Typography variant="h1" component="p" color="text.secondary">
                      {formatCentsAsDollars(payPeriod.invoice.feeAmount, false)}
                    </Typography>

                    <Link to={`/manage/billing#${payPeriod.invoice.id}`} fontWeight="bold">
                      <Typography variant="subtitle2" color="primary">
                        view invoice
                        <br />
                        {payPeriod.invoice.reference}
                      </Typography>
                    </Link>
                  </Paper>
                </Grid>
              )}
            </Grid>
            <PayPeriodRecordsList payPeriod={payPeriod} />
            {payPeriod.invoice && (
              <ActionLayout
                autoWidth
                primary={
                  <Button
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={`/manage/billing#${payPeriod.invoice.id}`}
                  >
                    View invoice
                  </Button>
                }
              />
            )}
          </Box>
        </ListItem>
      </AccordionDetails>
    </Accordion>
  )
}

const PayPeriodListPagerControlLabel = ({
  invoices,
}: {
  invoices: Array<NonNullable<PayPeriodSummaryFragment>>
}) => {
  const firstItem = invoices.at(0)
  const lastItem = invoices.at(-1)
  if (!firstItem || !lastItem) return null
  const sameDay = firstItem.payday === lastItem.payday
  return (
    <Typography variant="subtitle1" fontWeight="bold">
      {dateStringFormat.medium(firstItem.payday)}
      {!sameDay && ` - ${dateStringFormat.medium(lastItem.payday)}`}
    </Typography>
  )
}

export const PayPeriodList = () => {
  const { employerId: employerIdParam } = useParams()
  const [paging] = usePageSizePagingContext()
  const employerId = employerIdParam ? parseInt(employerIdParam, 10) : undefined

  const { data: payPeriodData, isLoading } = usePayPeriodsQuery({ orgId: useOrgId(), employerId, ...paging })
  const payPeriods = (payPeriodData?.organization?.payPeriods.nodes || []).filter(isNotNull)
  const pageInfo = payPeriodData?.organization?.payPeriods.pageInfo

  return (
    <>
      {pageInfo && (
        <PageSizePagerControls pageInfo={pageInfo}>
          <PayPeriodListPagerControlLabel invoices={payPeriods} />
        </PageSizePagerControls>
      )}
      {!isLoading && payPeriods.length === 0 && <Typography>No pay periods found</Typography>}
      {payPeriods.map((payPeriod) => (
        <PayPeriod key={payPeriod.id} payPeriod={payPeriod} />
      ))}
      {pageInfo && payPeriods.length > 5 && <PageSizePagerControls pageInfo={pageInfo} />}
    </>
  )
}
