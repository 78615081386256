import { UseRestQueryCallbacks, fetchRest } from '@alice-financial/api'
import { useQuery } from '@tanstack/react-query'
import { UnifiedBankingInstitution } from '../types'

const getBankingInstitution = (institutionId: string | null): Promise<UnifiedBankingInstitution> | null => {
  if (!institutionId) return null
  const searchParams = new URLSearchParams({ id: institutionId })
  // for some reason the existing institution API doesn't return the `id` of the requested institution
  // so we inject the id back into the response to get a complete `BankingInstitution` object from this call
  return fetchRest<Omit<UnifiedBankingInstitution, 'id'>>(`/api/spending/institution?${searchParams}`).then(
    (bank) => ({
      id: parseInt(institutionId, 10),
      ...bank,
    })
  )
}

type UseGetUnifiedBankingInstitutionOptions = UseRestQueryCallbacks<
  UnifiedBankingInstitution | null,
  UnifiedBankingInstitution | null,
  [string, string | null]
> & { initialData?: () => undefined }
export const useGetUnifiedBankingInstitution = (
  id: string | null,
  options?: UseGetUnifiedBankingInstitutionOptions
) =>
  useQuery(['getBank', id], () => getBankingInstitution(id), {
    staleTime: 1000 * 60 * 60 * 24,
    ...options,
  })
