import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateOrgFromProvisionalMutationVariables = Types.Exact<{
  input: Types.CreateOrgFromProvisionalInput
}>

export type CreateOrgFromProvisionalMutation = {
  __typename?: 'Mutation'
  createOrgFromProvisional?: {
    __typename?: 'CreateOrgFromProvisionalPayload'
    organization?: { __typename?: 'Organization'; id: number } | null
  } | null
}

export const CreateOrgFromProvisionalDocument = `
    mutation CreateOrgFromProvisional($input: CreateOrgFromProvisionalInput!) {
  createOrgFromProvisional(input: $input) {
    organization {
      id
    }
  }
}
    `

export const useCreateOrgFromProvisionalMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateOrgFromProvisionalMutation,
    TError,
    CreateOrgFromProvisionalMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateOrgFromProvisionalMutation,
    TError,
    CreateOrgFromProvisionalMutationVariables,
    TContext
  >(
    ['CreateOrgFromProvisional'],
    (variables?: CreateOrgFromProvisionalMutationVariables) =>
      fetchGql<CreateOrgFromProvisionalMutation, CreateOrgFromProvisionalMutationVariables>(
        CreateOrgFromProvisionalDocument,
        variables
      )(),
    options
  )
}
