import { ConnectFinancialAccount, ConnectFinancialAccountTransactions } from '@stripe/react-connect-js'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'

type FinancialAccountProps = {
  org: OrganizationDetailFragment
}
export const FundingAccountTransactions = ({ org }: FinancialAccountProps) => {
  const financialAccount = org.aliceCardProgram.companyAccount?.stripeFinancialAccountId
  if (!financialAccount) return null
  return <ConnectFinancialAccountTransactions financialAccount={financialAccount} />
}

export const FundingAccountBalance = ({ org }: FinancialAccountProps) => {
  const financialAccount = org.aliceCardProgram.companyAccount?.stripeFinancialAccountId
  if (!financialAccount) return null
  return <ConnectFinancialAccount financialAccount={financialAccount} />
}
