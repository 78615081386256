import { Alert, Typography } from '@mui/material'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { useEnrollmentStatus } from '../useEnrollmentStatus'

// Displays welcome back message for re-enrolling users without spending connections.
// To be shown on both spending connections and banking connection pages to handle cases
// where either Alice card or personal card connections are available.
export const ReenrollingWithoutSpendingConnectionsGreeting = () => {
  const enrollmentStatus = useEnrollmentStatus()
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser

  if (enrollmentStatus !== 'must_reenroll_via_spending_connection') return null

  return (
    <div>
      <Typography variant="h2" textAlign="center" gutterBottom color="primary">
        <FormattedMessage
          id="employee_dashboard.main.welcome_back"
          values={{ name: user?.preferredName || user?.firstName }}
        />
      </Typography>

      <Alert severity="info" icon={false} sx={{ mb: 2 }}>
        <Typography>
          <FormattedMessage id="reenroll.landing_spending_connected.select_option_below_to_reenroll" />
        </Typography>
        <Typography variant="caption" color="text.secondary">
          <FormattedMessage id="reenroll.landing.purchases_while_unenrolled_not_eligible" />
        </Typography>
      </Alert>
    </div>
  )
}
