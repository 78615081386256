import { Box, Button, EmailInputController, Grid, Link, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { getServerErrorHandler } from '../../utils/forms/ServerError'
import { AuthFormLayout } from './AuthFormLayout'
import { ForgotPasswordFormValues } from './types'
import { useSubmitForgotPassword } from './useSubmitForgotPassword'

export const ForgotPassword = () => {
  const intl = useIntl()
  const { control, handleSubmit, setError } = useForm<ForgotPasswordFormValues>({
    mode: 'all',
    defaultValues: { email: '' },
  })
  const onError = getServerErrorHandler(setError, ['email'])
  const { mutate: submitForgotPassword, isLoading, isSuccess } = useSubmitForgotPassword({ onError })
  const isSubmitDisabled = isLoading || isSuccess
  const onSubmit = handleSubmit((values) => submitForgotPassword(values))

  return (
    <AuthFormLayout>
      <Typography variant="h1" gutterBottom>
        <FormattedMessage id="auth.forgot_password" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="auth.forgot_password.instructions" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="auth.forgot_password.missing_reset_email" />
      </Typography>
      <form onSubmit={onSubmit}>
        <EmailInputController
          name="email"
          required
          fullWidth
          autoFocus
          control={control}
          placeholder={intl.formatMessage({ id: 'profile.email.placeholder' })}
        />
        <Grid container justifyContent="center">
          <Box component={Grid} item xs={12} sm={9} mt={5} mb={4}>
            <Button variant="contained" type="submit" fullWidth disabled={isSubmitDisabled}>
              <FormattedMessage id="auth.reset_password.request_reset" />
            </Button>
          </Box>
          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold" textAlign="center">
              <Link to="/login">
                <FormattedMessage id="auth.login.sign_in" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </AuthFormLayout>
  )
}
