import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SetLanguagePreferenceMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input']
  language: Types.LanguagePreference
}>

export type SetLanguagePreferenceMutation = {
  __typename?: 'Mutation'
  updateUser?: { __typename?: 'UpdateUserPayload'; user?: { __typename?: 'User'; id: number } | null } | null
}

export const SetLanguagePreferenceDocument = `
    mutation SetLanguagePreference($userId: Int!, $language: LanguagePreference!) {
  updateUser(input: {userId: $userId, languagePreference: $language}) {
    user {
      id
    }
  }
}
    `

export const useSetLanguagePreferenceMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    SetLanguagePreferenceMutation,
    TError,
    SetLanguagePreferenceMutationVariables,
    TContext
  >
) => {
  return useMutation<SetLanguagePreferenceMutation, TError, SetLanguagePreferenceMutationVariables, TContext>(
    ['SetLanguagePreference'],
    (variables?: SetLanguagePreferenceMutationVariables) =>
      fetchGql<SetLanguagePreferenceMutation, SetLanguagePreferenceMutationVariables>(
        SetLanguagePreferenceDocument,
        variables
      )(),
    options
  )
}
