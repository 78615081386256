import {
  Button,
  Divider,
  EmailInputController,
  Grid,
  Link,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { getServerErrorHandler } from '../../utils/forms/ServerError'
import { LoginFormValues } from './types'
import { useSubmitCredentials } from './useSubmitCredentials'

export const PasswordForm = () => {
  const intl = useIntl()
  const { control, handleSubmit, setError } = useForm<LoginFormValues>({
    defaultValues: { email: '', password: '' },
  })
  const onError = getServerErrorHandler(setError, ['email', 'password'])
  const { mutate: submitCredentials, isSuccess, isLoading } = useSubmitCredentials({ onError })
  const isSubmitDisabled = isLoading || isSuccess
  const onSubmit = handleSubmit((values) => submitCredentials(values))

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmailInputController name="email" required fullWidth autoFocus control={control} />
        </Grid>
        <Grid item xs={12}>
          <TextFieldController
            control={control}
            name="password"
            label={<FormattedMessage id="profile.password_label" />}
            required
            fullWidth
            type="password"
            autoComplete="current-password"
            rules={{
              required: intl.formatMessage({ id: 'profile.password.validation.required' }),
              minLength: {
                value: 8,
                message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 8 }),
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="center" sx={{ mt: 3, mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" type="submit" fullWidth disabled={isSubmitDisabled}>
            <FormattedMessage id="auth.login.do_login" />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="bold" gutterBottom textAlign="center">
            <Link to="/forgot-password">
              <FormattedMessage id="auth.forgot_password" />
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
    </form>
  )
}
