import { Divider, List, ListItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { BillableBilling } from './BillableBilling'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'

type OrganizationFundingProps = {
  org: OrganizationDetailFragment
}
export const OrganizationBilling = ({ org }: OrganizationFundingProps) => {
  return (
    <>
      <Divider sx={{ my: 1 }} />
      <List>
        <ListItem disableGutters disablePadding>
          <BillableBilling billable={org} billingSource={org.billing?.billingSource} />
        </ListItem>
      </List>
    </>
  )
}
