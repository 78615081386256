import { Grid, GridToolbarContainer, GridToolbarProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AccountStatusFilter } from './AccountStatusFilter'
import { EmployeeListActions } from './EmployeeListActions'
import { EmployeeSearchFilter } from './EmployeeSearchFilter'
import { PaygroupFilter } from './PaygroupFilter'

export const EmployeeFilter = (props: GridToolbarProps) => {
  const { className, csvOptions, printOptions, excelOptions, showQuickFilter, quickFilterProps, ...other } =
    props
  return (
    <GridToolbarContainer {...other} sx={{ marginBottom: 2 }}>
      <Grid container columnGap={2} wrap="nowrap" width="auto">
        <EmployeeSearchFilter {...quickFilterProps} />
        <AccountStatusFilter />
        <PaygroupFilter />
      </Grid>
      <EmployeeListActions />
    </GridToolbarContainer>
  )
}
