import * as React from 'react'
import { Navigate, useLocation, useParams } from 'react-router'
import { RouteChrome } from '../routes/RouteChrome'
import { useCanonicalOrgIdQuery } from './organization/gql/canonicalOrgId_gen'
import { orgRootPath } from './organization/useOrg'

/**
 * The root of the org context - not used for rendering anything but a 'not found' page. It
 * ensures that the orgIdParam is set by redirecting all requests to the canonical org id route
 *
 * In the future, we can use this component to display a list of available orgs for users
 * who have just logged in but have access to multiple orgs
 */
export const OrgRootRoute = () => {
  const { orgId: orgIdParam } = useParams()
  if (orgIdParam) throw new Error('OrgRoot should not be used in route with orgId param')
  const location = useLocation()
  const { data: canonicalOrgIdData } = useCanonicalOrgIdQuery()
  const canonicalOrg = canonicalOrgIdData?.organization

  if (canonicalOrg === undefined) return null // waiting for org to load
  if (canonicalOrg === null) return <RouteChrome type="admin" notFound />
  const orgRoot = orgRootPath(canonicalOrg.id)

  const pathname = location.pathname.replace(/^\/manage/i, orgRoot)
  return <Navigate to={{ ...location, pathname }} replace />
}
