import { Link, List, ListItem, ListItemButton, ListItemText, Typography } from '@alice-financial/pretext-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { EmployerSummaryFragment } from '../../graphql/fragments/OrganizationFragment_gen'

const PaygroupListItem = ({ payGroup }: { payGroup: EmployerSummaryFragment }) => {
  const employees = payGroup.employees
  return (
    <ListItem key={payGroup.id} disableGutters disablePadding divider>
      <ListItemButton component={Link} to={payGroup.id.toString() || '#'} disabled={!payGroup.inviteCode}>
        <ListItemText
          primary={
            <Typography color="primary" fontWeight="bold">
              {payGroup.name}
            </Typography>
          }
          secondary={
            employees.totalSavings > 0 ? (
              <Typography variant="body2" color="primary">
                <strong>{formatCentsAsDollars(payGroup.employees.totalSavings)}</strong> saved
              </Typography>
            ) : undefined
          }
        />
        <Typography variant="body2" color="text.secondary">
          <strong>{payGroup.employees.enrolledCount}</strong>/{payGroup.employees.reachableCount} enrolled
        </Typography>

        <ChevronRightIcon color="primary" />
      </ListItemButton>
    </ListItem>
  )
}

type PaygroupListProps = {
  payGroups: Array<EmployerSummaryFragment>
}
export const PaygroupList = ({ payGroups }: PaygroupListProps) => {
  return (
    <List dense>
      {payGroups.map((payGroup) => (
        <PaygroupListItem key={payGroup.id} payGroup={payGroup} />
      ))}
    </List>
  )
}
