import { Container, List, ListSubheader, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { EligibleTxnListItems } from '../spending/EligibleTxnListItems'
import { DatePagingContextProvider } from '../../../utils/paging/datePaging/DatePagingContext'
import { UnconfirmedTxnListItems } from '../spending/UnconfirmedTxnListItems'

export const HomepageSpending = () => {
  return (
    <>
      <List disablePadding>
        <UnconfirmedTxnListItems />
      </List>
      <List>
        <ListSubheader>
          <Container>
            <Typography variant="h3" fontWeight="bold">
              <FormattedMessage id="employee_dashboard.main.recent_savings" /> 🤘
            </Typography>
          </Container>
        </ListSubheader>
        <DatePagingContextProvider>
          <EligibleTxnListItems limit={3} hideUnconfirmed />
        </DatePagingContextProvider>
      </List>
    </>
  )
}
