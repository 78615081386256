import { daysBetweenDates, daysFromDate } from '../../dateUtils'
import { dateFormat } from '../../formatters/dateFormat'
import { DatePagingState } from './DatePagingContext'
import {
  DEFAULT_DAYS_SHOWN,
  ONE_DAY_AFTER_TODAY,
} from '../../../app/employeeDashboard/spending/useTransactionsListQuery'

type ConnectionVars = {
  first?: number | null
  after?: string | null
  last?: number | null
  before?: string | null
}

/**
 * The paging state 'endDate' and 'startDate' values refer to timestamps, i.e. before a certain dateime and after
 * certain datetime, while the GraphQL variables refer to page ordering, i.e. items in the list of
 * results that appear before a certain item (i.e. in the future), and items that appear after a certain item (i.e.
 * in the past)
 *
 * This function translates from the date-based definition to the page-based definition. Note that it avoids
 * returning `before & last` API arguments, but instead always converts the supplied 'endDate' and 'startDate' to
 * a `{ first: number, after: string }` object.
 */
export const dateConnectionVars = (pagingState?: DatePagingState): ConnectionVars | undefined => {
  if (!pagingState) return undefined
  const { startDate, endDate, days: maxDays = DEFAULT_DAYS_SHOWN } = pagingState

  // use a valid date range between 'startDate' and 'endDate', or default
  const definesADateRange = startDate && endDate && startDate < endDate

  const daysToShow = definesADateRange ? daysBetweenDates(startDate, endDate) : maxDays

  // convert 'startDate' to the corresponding date in the future, if supplied, otherwise use the 'endDate'
  const afterValueForPaging =
    endDate ||
    (startDate
      ? dateFormat.inputVal(daysFromDate(new Date(startDate), daysToShow + 1))
      : dateFormat.inputVal(ONE_DAY_AFTER_TODAY))

  return {
    first: daysToShow,
    after: afterValueForPaging,
  }
}
