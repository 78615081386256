import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateBillingSetupIntentMutationVariables = Types.Exact<{
  input: Types.CreateBillingSetupIntentInput
}>

export type CreateBillingSetupIntentMutation = {
  __typename?: 'Mutation'
  createBillingSetupIntent?: {
    __typename?: 'CreateBillingSetupIntentPayload'
    stripeAccount?: string | null
    clientSecret?: string | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateBillingSetupIntentDocument = `
    mutation CreateBillingSetupIntent($input: CreateBillingSetupIntentInput!) {
  createBillingSetupIntent(input: $input) {
    stripeAccount
    clientSecret
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateBillingSetupIntentMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateBillingSetupIntentMutation,
    TError,
    CreateBillingSetupIntentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateBillingSetupIntentMutation,
    TError,
    CreateBillingSetupIntentMutationVariables,
    TContext
  >(
    ['CreateBillingSetupIntent'],
    (variables?: CreateBillingSetupIntentMutationVariables) =>
      fetchGql<CreateBillingSetupIntentMutation, CreateBillingSetupIntentMutationVariables>(
        CreateBillingSetupIntentDocument,
        variables
      )(),
    options
  )
}
