import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocaleState } from '../../../../translations/LocaleProvider'
import { getMonthFromDate } from '../../../../utils/dateUtils'
import { formatCentsAsDollars } from '../../../../utils/formatters/moneyFormat'
import { CombinedAliceCardAccount } from '../useQuerySpendingConnections'
import { BalanceCalculationInfo } from './BalanceCalculationInfo'

type AliceCardBalanceDisplayProps = {
  aliceCardAccount: CombinedAliceCardAccount
}
export const AliceCardBalanceDisplay = ({ aliceCardAccount }: AliceCardBalanceDisplayProps) => {
  const [language] = useLocaleState()
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h3" gutterBottom>
          <BalanceCalculationInfo>
            <FormattedMessage id="cards.alice.remaining_balance" />
          </BalanceCalculationInfo>
        </Typography>
        <Typography variant="body2" gutterBottom>
          <FormattedMessage id="cards.alice.save_up_to" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="cards.alice.so_far_this_month"
            values={{
              month: getMonthFromDate(language, aliceCardAccount.balanceMonthStart),
            }}
          />
          :
        </Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FormattedMessage id="cards.alice.spent" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="cards.alice.remaining" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1}>
              <TableCell>
                <FormattedMessage id="benefits.mass_transit" />
              </TableCell>
              <TableCell>{formatCentsAsDollars(aliceCardAccount.usedMassTransit)}</TableCell>
              <TableCell>{formatCentsAsDollars(aliceCardAccount.balanceMassTransit)}</TableCell>
            </TableRow>
            <TableRow key={2}>
              <TableCell>
                <FormattedMessage id="benefits.parking" />
              </TableCell>
              <TableCell>{formatCentsAsDollars(aliceCardAccount.usedParking)}</TableCell>
              <TableCell>{formatCentsAsDollars(aliceCardAccount.balanceParking)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
