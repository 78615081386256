import memoizeOne from 'memoize-one'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { makeArraySearchParams, parseArrayQuerystring } from '../../../../utils/querystring'
import { isCategory, isStatus } from '../txnUtils'
import { Transaction, TxnFilter } from '../types'

const getCurrentFilter = memoizeOne((search: string): TxnFilter => {
  const queryFilter = parseArrayQuerystring(search, ['year', 'pretax_category', 'status'])
  return {
    year: queryFilter.year,
    pretax_category: queryFilter.pretax_category.filter(isCategory),
    status: queryFilter.status.filter(isStatus),
  }
})

const getYearFilter = (values: TxnFilter['year']) => (txn: Transaction) =>
  values.length > 0 ? values.includes(txn.date.getFullYear().toString()) : true
const getCategoryFilter = (values: TxnFilter['pretax_category']) => (txn: Transaction) =>
  values.length > 0 ? values.includes(txn.spendEligibility.pretaxCategory) : true
const getStatusFilter = (values: TxnFilter['status']) => (txn: Transaction) =>
  values.length > 0 ? values.includes(txn.status.value) : true

export const getApplyFilter = memoizeOne((filter: TxnFilter) => {
  const yearFilter = getYearFilter(filter.year)
  const categoryFilter = getCategoryFilter(filter.pretax_category)
  const statusFilter = getStatusFilter(filter.status)
  return (txn: Transaction) => yearFilter(txn) && categoryFilter(txn) && statusFilter(txn)
})

/**
 * A hook that provides state management for the current TransactionList
 * filter. It uses the URL to save filter states so that particular views
 * can be bookmarked
 */
export const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const setFilter = React.useCallback(
    (filter: Partial<TxnFilter>) => {
      const modifiedSearchParams = makeArraySearchParams(filter, searchParams.toString())
      setSearchParams(modifiedSearchParams, { replace: true })
    },
    [searchParams, setSearchParams]
  )
  const filter = getCurrentFilter(location.search)
  const applyFilter = getApplyFilter(filter)

  return { filter, setFilter, applyFilter }
}
