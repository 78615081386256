import {
  composeMutationCallbacks,
  UseGqlMutationCallbacks,
  useMutationOptionsWithInvalidation,
} from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { useOrg } from '../../orgDashboard/organization/useOrg'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { FormSubmissionInput } from './forms/FormManager'
import { useCreateFormSubmissionMutation } from './gql/createFormsSubmission_gen'
import { useUpdateFormSubmissionMutation } from './gql/updateFormsSubmission_gen'
import { useOptionalOrgId } from '../../orgDashboard/useOrgId'

type UseManageFormSubmissionMutationOptions = UseGqlMutationCallbacks<{ id: number }, FormSubmissionInput>

// This hook is meant to take a form state and determine, based on the presence of an id field,
// whether to execute an update or a create mutation for a form submission.

export const useManageFormSubmission = (mutationOptions?: UseManageFormSubmissionMutationOptions) => {
  const { notifyError } = useResponseNotification()
  const composedMutationOptions = composeMutationCallbacks(mutationOptions, {
    onSuccess: (data) => {
      if (!data.id) {
        notifyError('Error updating form submission')
      }
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })

  const optionalOrgId = useOptionalOrgId()
  const invalidationKeys: Array<QueryKey> = [useCurrentUserQuery.getKey()]
  if (optionalOrgId) {
    const key = useOrg.getKey({ orgId: optionalOrgId })
    invalidationKeys.push(key)
  }

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    composedMutationOptions,
    invalidationKeys
  )

  const { mutateAsync: createFormSubmission } = useCreateFormSubmissionMutation()
  const { mutateAsync: updateFormSubmission } = useUpdateFormSubmissionMutation()

  return useMutation(
    ['manageFormSubmission'],
    (values: FormSubmissionInput) => {
      const { answers, status, id, subjectId, subjectType, templateId } = values
      const _createFormSubmission = () => {
        if (!subjectId || !subjectType) {
          throw new Error('Cannot save form: Missing required fields')
        }
        return createFormSubmission({ input: { answers, subjectId, subjectType, templateId, status } }).then(
          (result) => result.createFormSubmission?.submissionId
        )
      }

      const mutationPromise = id
        ? updateFormSubmission({
            input: { id, answers, status },
          }).then((result) => result.updateFormSubmission?.submissionId)
        : _createFormSubmission()

      return mutationPromise.then((formSubmissionId: number | undefined) => {
        if (!formSubmissionId) throw new Error('Could not save response')
        return { id: formSubmissionId }
      })
    },
    mutationOptionsWithInvalidation
  )
}
