import { Button } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployeeAccountStatus } from '../../graphql/generated.types'
import { OptInConfirmation } from './OptInConfirmation'
import { OptOutConfirmation } from './OptOutConfirmation'
import { EmployeeRow } from './types'

type OptInOutButtonProps = {
  employees: Array<EmployeeRow>
}
export const OptInOutButton = ({ employees }: OptInOutButtonProps) => {
  const [open, setOpen] = React.useState(false)
  const disabled = employees.length === 0
  const optInEnabled =
    !disabled && employees.every((ee) => ee.accountStatus === EmployeeAccountStatus.OptedOut)
  const optOutEnabled = !disabled && !optInEnabled
  const onClose = () => setOpen(false)

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        {disabled ? 'Enable/Disable benefits' : optOutEnabled ? 'Disable benefits' : 'Enable benefits'}
      </Button>
      {optOutEnabled ? (
        <OptOutConfirmation open={open} onClose={onClose} employees={employees} />
      ) : (
        <OptInConfirmation open={open} onClose={onClose} employees={employees} />
      )}
    </>
  )
}
