import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SpendingSummaryQueryVariables = Types.Exact<{ [key: string]: never }>

export type SpendingSummaryQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    id: number
    ledger?: {
      __typename?: 'AliceLedger'
      id: number
      commuter?: {
        __typename?: 'CommuterLedger'
        parking?: {
          __typename: 'ParkingLedger'
          current?: {
            __typename?: 'Balance'
            available: number
            spentSoFar: number
            limitationReason?: { __typename?: 'BalanceLimitation'; spendingCap?: number | null } | null
            spendingPeriod: { __typename?: 'TimePeriod'; startDate: string; endDate?: string | null }
          } | null
        } | null
        massTransit?: {
          __typename: 'MassTransitLedger'
          current?: {
            __typename?: 'Balance'
            available: number
            spentSoFar: number
            limitationReason?: { __typename?: 'BalanceLimitation'; spendingCap?: number | null } | null
            spendingPeriod: { __typename?: 'TimePeriod'; startDate: string; endDate?: string | null }
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type CommuterLedgerSummaryFragment = {
  __typename?: 'CommuterLedger'
  parking?: {
    __typename: 'ParkingLedger'
    current?: {
      __typename?: 'Balance'
      available: number
      spentSoFar: number
      limitationReason?: { __typename?: 'BalanceLimitation'; spendingCap?: number | null } | null
      spendingPeriod: { __typename?: 'TimePeriod'; startDate: string; endDate?: string | null }
    } | null
  } | null
  massTransit?: {
    __typename: 'MassTransitLedger'
    current?: {
      __typename?: 'Balance'
      available: number
      spentSoFar: number
      limitationReason?: { __typename?: 'BalanceLimitation'; spendingCap?: number | null } | null
      spendingPeriod: { __typename?: 'TimePeriod'; startDate: string; endDate?: string | null }
    } | null
  } | null
}

export type SpendingSummaryBalanceFragment = {
  __typename?: 'Balance'
  available: number
  spentSoFar: number
  limitationReason?: { __typename?: 'BalanceLimitation'; spendingCap?: number | null } | null
  spendingPeriod: { __typename?: 'TimePeriod'; startDate: string; endDate?: string | null }
}

export const SpendingSummaryBalanceFragmentDoc = `
    fragment SpendingSummaryBalance on Balance {
  available
  spentSoFar
  limitationReason {
    spendingCap
  }
  spendingPeriod {
    startDate
    endDate
  }
}
    `
export const CommuterLedgerSummaryFragmentDoc = `
    fragment CommuterLedgerSummary on CommuterLedger {
  parking {
    __typename
    current {
      ...SpendingSummaryBalance
    }
  }
  massTransit {
    __typename
    current {
      ...SpendingSummaryBalance
    }
  }
}
    `
export const SpendingSummaryDocument = `
    query SpendingSummary {
  employee {
    id
    ledger {
      id
      commuter {
        ...CommuterLedgerSummary
      }
    }
  }
}
    ${CommuterLedgerSummaryFragmentDoc}
${SpendingSummaryBalanceFragmentDoc}`

export const useSpendingSummaryQuery = <TData = SpendingSummaryQuery, TError = Error>(
  variables?: SpendingSummaryQueryVariables,
  options?: UseQueryOptions<SpendingSummaryQuery, TError, TData>
) => {
  return useQuery<SpendingSummaryQuery, TError, TData>(
    variables === undefined ? ['SpendingSummary'] : ['SpendingSummary', variables],
    fetchGql<SpendingSummaryQuery, SpendingSummaryQueryVariables>(SpendingSummaryDocument, variables),
    options
  )
}

useSpendingSummaryQuery.getKey = (variables?: SpendingSummaryQueryVariables) =>
  variables === undefined ? ['SpendingSummary'] : ['SpendingSummary', variables]
