import * as Types from '../generated.types'

import { ErrorFragmentDoc } from './ErrorFragment_gen'
export type BankingAccountFragment = {
  __typename?: 'BankingAccount'
  id: number
  billingSystemId?: string | null
  name?: string | null
  cardType?: Types.BankCardType | null
  accountType?: Types.BankingAccountType | null
  platform: Types.BankingAccountPlatform
  accountLast4?: string | null
  error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
}

export const BankingAccountFragmentDoc = `
    fragment BankingAccount on BankingAccount {
  id
  billingSystemId
  name
  cardType
  accountType
  platform
  accountLast4: last4
  error {
    ...Error
  }
}
    `
