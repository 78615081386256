import { Box, FormLabel, Select, SelectChangeEvent } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployeeAccountStatus } from '../../graphql/generated.types'
import { useFilter } from './useFilter'

const isAccountStatus = (value: string): value is EmployeeAccountStatus =>
  Object.values(EmployeeAccountStatus).includes(value as EmployeeAccountStatus)

export const useAccountStatusFilter = () => {
  return useFilter('account_status', {
    typeGuard: isAccountStatus,
  })
}

const ALL = '_all_'
export const AccountStatusFilter = () => {
  const [accountStatus, updateAccountStatus] = useAccountStatusFilter()
  const onChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value

    if (isAccountStatus(value)) {
      updateAccountStatus(value)
    } else {
      updateAccountStatus(undefined)
    }
  }
  return (
    <Box display="flex" flexDirection="column">
      <FormLabel>Account status</FormLabel>
      <Select
        name="account_status"
        variant="outlined"
        native
        onChange={onChange}
        defaultValue={accountStatus}
      >
        <option value={ALL}>all</option>
        <option value={EmployeeAccountStatus.Enrolled}>Enrolled</option>
        <option value={EmployeeAccountStatus.NotEnrolled}>Not enrolled</option>
        <option value={EmployeeAccountStatus.Ineligible}>Ineligible</option>
        <option value={EmployeeAccountStatus.Suspended}>Suspended</option>
        <option value={EmployeeAccountStatus.Terminated}>Terminated</option>
        <option value={EmployeeAccountStatus.OptedOut}>Opted out</option>
      </Select>
    </Box>
  )
}
