import * as Types from '../generated.types'

import { ErrorFragmentDoc } from './ErrorFragment_gen'
export type PaymentCardFragment = {
  __typename?: 'PaymentCard'
  id: number
  billingSystemId?: string | null
  brand: string
  expMonth: number
  expYear: number
  last4: string
  error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
}

export const PaymentCardFragmentDoc = `
    fragment PaymentCard on PaymentCard {
  id
  billingSystemId
  brand
  expMonth
  expYear
  last4
  error {
    ...Error
  }
}
    `
