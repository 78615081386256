import { ApiErrorResponse } from './types'

/**
 * This error is thrown when an API call fails. It contains the pathname of the
 * API call that failed, which can improve error tracking
 */
export class ApiError extends Error {
  pathname: string

  constructor(message: string, pathname: string) {
    super(message)
    this.pathname = pathname
  }
}
export const formatParamMessage = ([param, message]: [param: string, message: string]): string =>
  `${param}: ${message}`

const formatApiRootExceptionErrorResponse = (errorResponse: ApiErrorResponse | string): string => {
  if (typeof errorResponse === 'string') return errorResponse
  if (typeof errorResponse.error === 'string') return errorResponse.error
  return Object.entries(errorResponse.error).map(formatParamMessage).join(', ')
}

export class ApiRootException extends ApiError {
  details: ApiErrorResponse | string
  constructor(errorResponse: ApiErrorResponse | string, pathname: string) {
    const message = formatApiRootExceptionErrorResponse(errorResponse)
    super(message, pathname)
    this.details = errorResponse
  }
}

export class UnauthorizedError extends ApiError {}
export class SessionExpiredError extends UnauthorizedError {}
export class NotFoundError extends ApiRootException {}
