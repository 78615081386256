import { useResponseNotification } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useEnrollmentEmployerQuery } from './gql/enrollmentEmployer_gen'
import { useIntl } from 'react-intl'

/**
 * This hook wraps `useEnrollmentEmployerQuery` and provides a side effects
 * for handling the response of the employer lookup by user email or employer invite/enrollment code.
 * - If the employer is found and the user email is provided, navigate to the enrollment landing page
 * - If the employer is not found, notify the user and navigate to the 'find enrollment URL by email' page
 */
export const useEnrollmentEmployer = (email?: string) => {
  const intl = useIntl()
  const { notifyError } = useResponseNotification()
  const navigate = useNavigate()
  const { enrollmentCode } = useParams() // can be empty as long as email is provided
  return useEnrollmentEmployerQuery(
    { inviteCode: enrollmentCode, email },
    {
      onSuccess: (data) => {
        const employer = data.enrollmentEmployer
        if (employer) return
        notifyError(intl.formatMessage({ id: 'enroll.find_enrollment_employer.employer_could_not_be_found' }))
        navigate('/enroll') // allow user to try (again) using their email address
      },
      enabled: Boolean(enrollmentCode || email), // must provide one
    }
  )
}

export const useInactiveEmployerRedirect = () => {
  const { data: employerData } = useEnrollmentEmployer()
  const employer = employerData?.enrollmentEmployer
  const { enrollmentCode } = useParams()
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!enrollmentCode || !employer || employer.isActive) return

    navigate(`/enroll/${enrollmentCode}/inactive`)
  }, [employer, navigate, enrollmentCode])
}
