import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateFinchConnectionMutationVariables = Types.Exact<{
  authorizationCode: Types.Scalars['String']['input']
  employerId: Types.Scalars['Int']['input']
}>

export type CreateFinchConnectionMutation = {
  __typename?: 'Mutation'
  createFinchConnection?: {
    __typename?: 'CreateFinchConnectionPayload'
    organization?: {
      __typename?: 'Organization'
      id: number
      employer?: {
        __typename?: 'Employer'
        id: number
        paygroupConfig?: {
          __typename?: 'PaygroupConfig'
          finchConnection?: { __typename?: 'FinchConnection'; id: number } | null
        } | null
      } | null
    } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateFinchConnectionDocument = `
    mutation CreateFinchConnection($authorizationCode: String!, $employerId: Int!) {
  createFinchConnection(
    input: {authorizationCode: $authorizationCode, employerId: $employerId}
  ) {
    organization {
      id
      employer(id: $employerId) {
        id
        paygroupConfig {
          finchConnection {
            id
          }
        }
      }
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateFinchConnectionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFinchConnectionMutation,
    TError,
    CreateFinchConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<CreateFinchConnectionMutation, TError, CreateFinchConnectionMutationVariables, TContext>(
    ['CreateFinchConnection'],
    (variables?: CreateFinchConnectionMutationVariables) =>
      fetchGql<CreateFinchConnectionMutation, CreateFinchConnectionMutationVariables>(
        CreateFinchConnectionDocument,
        variables
      )(),
    options
  )
}
