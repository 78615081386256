import * as React from 'react'
import { useParams } from 'react-router'
import { Authenticate } from '../auth/AuthenticateRoutes'
import { RouteChrome } from '../routes/RouteChrome'
import { OrgAdminRedirect } from './OrgAdminRedirect'
import { useCurrentOrgRootPath, useOrg } from './organization/useOrg'
import { OrgDashboardNavigation } from './OrgDashboardNavigation'
import { OrgRouteTitle } from './OrgRouteTitle'

export const OrgDashboardRouteChrome = () => {
  const orgRootPath = useCurrentOrgRootPath()
  const { orgId: orgIdParam } = useParams()
  const invalidOrgIdParam = Boolean(orgIdParam && !/\d/.test(orgIdParam)) // orgId must be digits
  const org = useOrg()

  return (
    <Authenticate requiredProfile={['organization_admin', 'employer_admin']}>
      <OrgAdminRedirect>
        <RouteChrome
          notFound={org === null || invalidOrgIdParam}
          type="admin"
          root={{ route: orgRootPath, label: 'Home' }}
          Nav={OrgDashboardNavigation}
          pageTitle={<OrgRouteTitle />}
        />
      </OrgAdminRedirect>
    </Authenticate>
  )
}
