import {
  ActionLayout,
  Button,
  CheckboxController,
  FormLabel,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { AliceCardTosLabel } from '../../../enrollment/AliceCardTosLabel'
import { useEmployeeEnrollmentInfoQuery } from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { CardRequestTypeEnum, Exact, RequestAliceCardInput } from '../../../graphql/generated.types'
import { useSearchParamsRedirect } from '../../../routes/routeUtils'
import { AddressInput } from '../../profile/address/AddressInput'
import { addressToAddressInput } from '../../profile/address/useHeadlessAddressEdit'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { AliceCardInfo } from './AliceCardInfo'
import { OrderAliceCardFormValues } from './types'
import { useRequestAliceCard } from './useRequestAliceCard'

/**
 * The form description is dependent on whether the user is requesting an instant card, a physical card, or both.
 */
const RequestFormDescription = ({ scope }: RequestFormProps) => {
  const {
    data: { aliceCardFundingConnected },
  } = useQuerySpendingConnections()

  switch (scope) {
    case 'instant_and_physical':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.request_an_alice_card" />
          </Typography>

          {aliceCardFundingConnected ? (
            <Typography gutterBottom>
              <FormattedMessage id="cards.alice.ordering_your_alice_card" />
            </Typography>
          ) : (
            <FormattedMessage id="cards.alice.ordering_your_alice_card_no_funding" />
          )}
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.ordering_your_physical_card" />
          </Typography>
        </>
      )
    case 'physical':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.order_physical_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.order_physical_card_description" />
          </Typography>
        </>
      )
    case 'instant':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.order_instant_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.ordering_your_alice_card" />
          </Typography>
        </>
      )
    default:
      return null
  }
}

const RequestAliceCardLabel = ({ scope }: RequestFormProps) => {
  switch (scope) {
    case 'instant_and_physical':
      return <FormattedMessage id="cards.alice.request_alice_card_both" />
    case 'physical':
      return <FormattedMessage id="cards.alice.order_physical_card" />
    case 'instant':
      return <FormattedMessage id="cards.alice.order_instant_card" />
    default:
      return null
  }
}

const useFormValues = (cardTypesRequested: CardRequestTypeEnum): OrderAliceCardFormValues => {
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  const tosAccepted = Boolean(enrollmentData?.employee?.spendingConnectionInfo.hasAcceptedAliceCardTos)
  const birthdate = enrollmentData?.currentUser?.birthdate || ''
  const mailingAddress = enrollmentData?.employee?.mailingAddress
  return {
    cardTypesRequested,
    ...addressToAddressInput(mailingAddress),
    tosAccepted,
    birthdate,
  }
}

export type RequestFormProps = { scope: CardRequestTypeEnum }
/**
 * This form handles the request of Instant and Physical Alice Cards.
 * It will ensure that the user's birthdate and address have been
 * populated before submitting.
 */
export const RequestAliceCardForm = ({ scope }: RequestFormProps) => {
  const intl = useIntl()

  const values = useFormValues(scope)
  const {
    handleSubmit,
    control,
    setValue,
    formState: { dirtyFields },
  } = useForm({ values })

  const redirect = useSearchParamsRedirect('/cards/alice')
  const {
    mutate: requestAliceCard,
    isLoading: isRequestingAliceCard,
    isSuccess,
  } = useRequestAliceCard({
    onSuccess: () => redirect(),
  })

  const addressFieldsAreDirty = Boolean(
    dirtyFields.line1 ||
      dirtyFields.line2 ||
      dirtyFields.cityName ||
      dirtyFields.stateCode ||
      dirtyFields.zipcode
  )

  // convenience wrapper around requestAliceCard that extracts birthdate from form values
  const orderAliceCard = ({
    line1,
    line2,
    cityName,
    stateCode,
    zipcode,
    mustVerify,
    ...values
  }: OrderAliceCardFormValues) => {
    const input: Exact<RequestAliceCardInput> = values
    if (addressFieldsAreDirty) {
      const mailingAddress = { line1, line2, cityName, stateCode, zipcode, mustVerify }
      input.mailingAddress = mailingAddress
    }
    requestAliceCard({ input })
  }

  const onSubmit = handleSubmit((values) => orderAliceCard(values))
  const isSubmitDisabled = isRequestingAliceCard || isSuccess

  const onSubmitInstant = () => setValue('cardTypesRequested', CardRequestTypeEnum.Instant)

  return (
    <>
      <RequestFormDescription scope={scope} />
      <Typography gutterBottom>
        <AliceCardInfo>
          <FormattedMessage id="common.learn_more" />
        </AliceCardInfo>
      </Typography>
      <form onSubmit={onSubmit} data-testid="request-alice-card">
        {!values.birthdate && (
          <>
            <FormLabel htmlFor="birthdate">
              <FormattedMessage id="profile.birthdate.label" />
            </FormLabel>
            <TextFieldController
              name="birthdate"
              required
              control={control}
              type="date"
              sx={{ display: 'block' }}
            />
          </>
        )}
        <Typography gutterBottom>
          <FormattedMessage id="profile.index.mailing_address" />
        </Typography>

        <AddressInput control={control} />

        {!values.tosAccepted && (
          <CheckboxController
            color="primary"
            name="tosAccepted"
            control={control}
            rules={{
              validate: (v) =>
                v === true || intl.formatMessage({ id: 'cards.alice.confirm_you_read_the_documents' }),
            }}
            label={<AliceCardTosLabel />}
          />
        )}
        <ActionLayout
          primary={
            <Button
              type="submit"
              name="cardTypesRequested"
              value={scope}
              variant="contained"
              color="primary"
              disabled={isSubmitDisabled}
              fullWidth
            >
              <RequestAliceCardLabel scope={scope} />
            </Button>
          }
          secondary={
            scope === CardRequestTypeEnum.InstantAndPhysical ? (
              <Button
                type="submit"
                name="cardTypesRequested"
                value={CardRequestTypeEnum.Instant}
                variant="outlined"
                color="secondary"
                disabled={isSubmitDisabled}
                onClick={onSubmitInstant}
                fullWidth
              >
                <FormattedMessage id="cards.alice.request_alice_card_instant" />
              </Button>
            ) : (
              <Button variant="outlined" color="secondary" fullWidth component={Link} to="/cards/alice">
                <FormattedMessage id="common.cancel.cancel" />
              </Button>
            )
          }
        />
      </form>
    </>
  )
}
