import { ActionLayout, Button, Typography } from '@alice-financial/pretext-ui'
import { QueryKey } from '@tanstack/react-query'
import * as React from 'react'
import { useAcceptACHMandate } from './useAcceptACHMandate'
import { ConfirmBankAccountSetupResponse } from './useConfirmBankAccount'

type ACHMandateProps<TResponse = unknown> = {
  handleConfirmationResponse: (response: ConfirmBankAccountSetupResponse) => TResponse
  clientSecret: string
  invalidationKeys: Array<QueryKey>
}

/**
 * This component display a mandatory agreement for ACH payments - Stripe must be notified that the account
 * keyed by client secret has accepted the ACH mandate, and then the response is handled by the provided
 * callback, which usually instantiates a corresponding payment method in our back end.
 *
 * Note that the invalidationKeys are required because the clientSecret will no longer be usable after
 * the ACH mandate is accepted
 */
export const ACHMandate = ({
  clientSecret,
  handleConfirmationResponse,
  invalidationKeys,
}: ACHMandateProps) => {
  const { mutate: acceptAndPersist } = useAcceptACHMandate(handleConfirmationResponse, invalidationKeys)
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Acknowledgement of withdrawals <br />
        by Pretax Hero Inc (dba Alice)
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        By clicking [accept], you authorize Pretax Hero Inc, dba This is Alice to debit the bank account
        specified above for any amount owed for charges arising from your use of Pretax Hero&apos;s services
        and/or purchase of products from Pretax Hero, pursuant to Pretax Hero&apos;s website and terms, until
        this authorization is revoked. You may amend or cancel this authorization at any time by providing
        notice to Pretax Hero with 30 (thirty) days notice.
      </Typography>
      <Typography variant="body2" component="p">
        If you use Pretax Hero&apos;s services or purchase additional products periodically pursuant to Pretax
        Hero&apos;s terms, you authorize Pretax Hero to debit your bank account periodically. Payments that
        fall outside of the regular debits authorized above will only be debited after your authorization is
        obtained.
      </Typography>
      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" onClick={() => acceptAndPersist({ clientSecret })}>
            Accept
          </Button>
        }
      />
    </>
  )
}
