import { UseRestMutationCallbacks, composeMutationCallbacks, fetchRest } from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { ResetPasswordFormValues } from './types'

type UseSubmitResetPasswordMutationOptions = UseRestMutationCallbacks<unknown, ResetPasswordFormValues>

export const useSubmitResetPassword = (mutationOptions: UseSubmitResetPasswordMutationOptions = {}) => {
  const intl = useIntl()
  const { resetToken } = useParams()
  const { notifySubmit, notifyError, notifySuccess } = useResponseNotification()
  return useMutation(
    ({ password, password_confirmation }: ResetPasswordFormValues) => {
      notifySubmit(intl.formatMessage({ id: 'auth.reset_password.resetting_password' }))
      return fetchRest(`/password`, {
        method: 'PATCH',
        body: JSON.stringify({ user: { password, password_confirmation, reset_password_token: resetToken } }),
      })
    },
    composeMutationCallbacks(mutationOptions, {
      onError: (error) => notifyError(error.message),
      onSuccess: () => notifySuccess(intl.formatMessage({ id: 'auth.reset_password.success' })),
    })
  )
}
