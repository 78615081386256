import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useOrg } from '../../organization/useOrg'
import { useCreatePaymentMethodConnectionMutation } from './gql/createPaymentMethodConnection_gen'
import { TypedBankableEntity } from './types'
import { useOrgId } from '../../useOrgId'

type UseConnectPaymentMethodMutationOptions = UseGqlMutationCallbacks<unknown, { paymentMethodId: number }>
export const useConnectPaymentMethod = (
  { owner }: { owner: TypedBankableEntity },
  mutationOptions?: UseConnectPaymentMethodMutationOptions
) => {
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(mutationOptions, [
    useOrg.getKey({ orgId: useOrgId() }),
  ])
  const { mutateAsync: createPaymentMethodConnection } = useCreatePaymentMethodConnectionMutation()
  return useMutation(
    ['createPaymentMethodConnection'],
    ({ paymentMethodId }) =>
      createPaymentMethodConnection({ input: { ownerId: owner.id, ownerType: owner.type, paymentMethodId } }),
    mutationOptionsWithInvalidation
  )
}
