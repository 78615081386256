import * as Types from '../../../graphql/generated.types'

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfoFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type InvoicesQueryVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>
  after?: Types.InputMaybe<Types.Scalars['String']['input']>
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>
  before?: Types.InputMaybe<Types.Scalars['String']['input']>
  employerId?: Types.InputMaybe<Types.Scalars['Int']['input']>
}>

export type InvoicesQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    name: string
    billing?: {
      __typename?: 'Billing'
      hasUnifiedBilling: boolean
      invoicesConnection: {
        __typename?: 'InvoiceConnection'
        pageInfo: {
          __typename?: 'PageInfo'
          startCursor?: string | null
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
        }
        nodes?: Array<{
          __typename?: 'Invoice'
          id: number
          reference?: string | null
          ownerName?: string | null
          status: Types.InvoiceStatus
          createdAt: string
          paidDate?: string | null
          startDate?: string | null
          endDate?: string | null
          feeAmount: number
          paidAmount: number
          items: Array<{
            __typename?: 'InvoiceItem'
            id: number
            description?: string | null
            feeAmount: number
          }>
        } | null> | null
      }
    } | null
  } | null
}

export type InvoiceFragment = {
  __typename?: 'Invoice'
  id: number
  reference?: string | null
  ownerName?: string | null
  status: Types.InvoiceStatus
  createdAt: string
  paidDate?: string | null
  startDate?: string | null
  endDate?: string | null
  feeAmount: number
  paidAmount: number
  items: Array<{ __typename?: 'InvoiceItem'; id: number; description?: string | null; feeAmount: number }>
}

export const InvoiceFragmentDoc = `
    fragment Invoice on Invoice {
  id
  reference
  ownerName
  status
  createdAt
  paidDate
  startDate
  endDate
  feeAmount
  paidAmount
  items {
    id
    description
    feeAmount
  }
}
    `
export const InvoicesDocument = `
    query Invoices($orgId: Int!, $first: Int, $after: String, $last: Int, $before: String, $employerId: Int) {
  organization(id: $orgId) {
    id
    name
    billing {
      hasUnifiedBilling
      invoicesConnection(
        first: $first
        after: $after
        last: $last
        before: $before
        employerId: $employerId
      ) {
        pageInfo {
          ...PageInfo
        }
        nodes {
          ...Invoice
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${InvoiceFragmentDoc}`

export const useInvoicesQuery = <TData = InvoicesQuery, TError = Error>(
  variables: InvoicesQueryVariables,
  options?: UseQueryOptions<InvoicesQuery, TError, TData>
) => {
  return useQuery<InvoicesQuery, TError, TData>(
    ['Invoices', variables],
    fetchGql<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, variables),
    options
  )
}

useInvoicesQuery.getKey = (variables: InvoicesQueryVariables) => ['Invoices', variables]
