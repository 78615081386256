import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateFormSubmissionMutationVariables = Types.Exact<{
  input: Types.CreateFormSubmissionInput
}>

export type CreateFormSubmissionMutation = {
  __typename?: 'Mutation'
  createFormSubmission?: {
    __typename?: 'CreateFormSubmissionPayload'
    submissionId: number
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateFormSubmissionDocument = `
    mutation CreateFormSubmission($input: CreateFormSubmissionInput!) {
  createFormSubmission(input: $input) {
    submissionId
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateFormSubmissionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFormSubmissionMutation,
    TError,
    CreateFormSubmissionMutationVariables,
    TContext
  >
) => {
  return useMutation<CreateFormSubmissionMutation, TError, CreateFormSubmissionMutationVariables, TContext>(
    ['CreateFormSubmission'],
    (variables?: CreateFormSubmissionMutationVariables) =>
      fetchGql<CreateFormSubmissionMutation, CreateFormSubmissionMutationVariables>(
        CreateFormSubmissionDocument,
        variables
      )(),
    options
  )
}
