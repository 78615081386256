import { isNotNull } from '../../../utils/typeUtils'
import {
  BillingSource_BankingAccount_Fragment,
  BillingSource_PaymentCard_Fragment,
  BillingSourceFragment,
  OrganizationDetailFragment,
} from '../../graphql/fragments/OrganizationFragment_gen'
import { BillableType, BillingWorkspace } from '../../graphql/generated.types'
import { Billable } from './types'

// An org is allowed to use per-pay-group billing if
// 0. it has previously started connecting billing
// 1. it has more than one pay group
// 2. it has not connected unified (org) billing
export const determineCanUsePaygroupBilling = (org?: OrganizationDetailFragment | null) =>
  Boolean(
    org && (hasStartedBillingConnection(org) || (org.employers.length > 1 && !org.billing?.billingSource))
  )

export const hasBillingConnected = (billable: { billing?: { hasBillingConnected: boolean } | null }) =>
  Boolean(billable.billing?.hasBillingConnected)

export const hasStartedBillingConnection = (org: OrganizationDetailFragment) =>
  hasBillingConnected(org) || org.employers.filter(isNotNull).some(hasBillingConnected)

export const isPaymentCard = (
  billingSource: BillingSourceFragment
): billingSource is BillingSource_PaymentCard_Fragment => billingSource.__typename === 'PaymentCard'
export const isBankingAccount = (
  billingSource: BillingSourceFragment
): billingSource is BillingSource_BankingAccount_Fragment => billingSource.__typename === 'BankingAccount'

export const billingSourceDescriptor = (billingSource: BillingSourceFragment | null | undefined) => {
  if (!billingSource) return ''
  if (isPaymentCard(billingSource)) {
    return `${billingSource.brand} •••• ${billingSource.last4} (exp ${billingSource.expMonth}/${billingSource.expYear})`
  }
  if (isBankingAccount(billingSource)) {
    return `${billingSource.name} … ${billingSource.accountLast4}`
  }
  return 'Unknown billing source'
}

export const isOrg = (billable: Billable): billable is OrganizationDetailFragment =>
  billable.__typename === 'Organization'

export const hasExpiredCard = (billable: Billable | null | undefined): boolean => {
  if (!billable) return false
  const billingSource = billable.billing?.billingSource
  return Boolean(billingSource && isPaymentCard(billingSource) && isExpiredCard(billingSource))
}

export const isExpiredCard = (billingSource: BillingSourceFragment | null | undefined) => {
  if (!billingSource || !isPaymentCard(billingSource)) return false
  const date = new Date()

  return (
    billingSource.expYear < date.getFullYear() ||
    (billingSource.expMonth === date.getFullYear() && billingSource.expMonth < date.getMonth() + 1)
  )
}

// The `error` field can also contain a `code` in addition to `message`, but we don't use `code` currently. So far we expect
// both to present if one is, but have nothing that guarantees or requires that.
export const hasBillingSourceError = (billingSource: BillingSourceFragment | null | undefined) =>
  Boolean(billingSource?.error?.message)

export const getBillables = (org: OrganizationDetailFragment): Array<Billable> => {
  const { billing } = org
  if (billing?.hasUnifiedBilling) return [org]
  return org.employers.filter(isNotNull)
}

export const getBillableType = (billable: Billable): BillableType =>
  isOrg(billable) ? BillableType.Org : BillableType.Paygroup

export const billingWorkspaceRequiresMicrodeposits = (billingWorkspace: BillingWorkspace | undefined) =>
  billingWorkspace === BillingWorkspace.Thisisalice

type BillingProblem =
  | 'not_started'
  | ['missing_source', Array<Billable>]
  | ['expired', Array<Billable>]
  | null

export const getBillingProblem = (org: OrganizationDetailFragment | null | undefined): BillingProblem => {
  if (!org) return null
  if (!hasStartedBillingConnection(org)) return 'not_started'
  const billables = getBillables(org)

  const billablesWithMissingSources = billables.filter((b) => !b.billing?.billingSource)
  if (billablesWithMissingSources.length) return ['missing_source', billablesWithMissingSources]

  const billablesWithExpiredCards = billables.filter(hasExpiredCard)
  if (billablesWithExpiredCards.length) return ['expired', billablesWithExpiredCards]

  return null
}
