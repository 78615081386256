import {
  Box,
  Button,
  IconCard,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@alice-financial/pretext-ui'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import * as React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { MissingTransactionFormValues } from './types'
import { FormattedMessage, useIntl } from 'react-intl'

const IconLayout = styled(Box)({
  gridArea: '1 / 1 / 3 / 2',
  justifySelf: 'center',
  position: 'relative',
  width: '100%',
  height: 80,
  textAlign: 'center',
  alignSelf: 'center',
})
type SubmitReceiptInputProps = {
  register: UseFormRegister<MissingTransactionFormValues>
  value: FileList | null
  reset: () => void
  required?: boolean
}
export const SubmitReceiptInput = ({ register, value, reset, required }: SubmitReceiptInputProps) => {
  const theme = useTheme()
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const imageFile = value && value[0]
  const intl = useIntl()

  if (imageFile) {
    const fileUrl = URL.createObjectURL(imageFile)
    const preview =
      imageFile.type === 'application/pdf' ? (
        <PictureAsPdfIcon color="secondary" fontSize="large" />
      ) : (
        <img src={fileUrl} width="100%" />
      )
    return (
      <>
        <Typography gutterBottom fontWeight="bold">
          <FormattedMessage id="spending.receipt.receipt" />
          {':'}
        </Typography>
        <IconCard>
          <IconLayout>{preview}</IconLayout>
          <Typography>{imageFile.name}</Typography>
          <Box textAlign="right">
            <Button color="error" variant="outlined" size="small" onClick={reset}>
              <FormattedMessage id="common.remove" />
            </Button>
          </Box>
        </IconCard>
      </>
    )
  }
  return (
    <>
      <input
        {...register('image', {
          required: required && intl.formatMessage({ id: 'spending.receipt.image_instructions' }),
        })}
        accept="image/*,application/pdf"
        style={{ display: 'none' }}
        id="receipt-upload"
        multiple
        type="file"
      />
      <label htmlFor="receipt-upload">
        <Button fullWidth variant="outlined" component="span" startIcon={<CloudUploadOutlinedIcon />}>
          <FormattedMessage id="spending.receipt.attach_receipt" />
        </Button>
        <Typography variant="caption" textAlign="center" component="p" gutterBottom>
          <FormattedMessage id="spending.receipt.receipt_attachment_compatibility" />
        </Typography>
        {isMobileWidth && (
          <Button
            fullWidth
            variant="outlined"
            component="span"
            startIcon={<PhotoCameraOutlinedIcon />}
            sx={{ marginTop: '1em' }}
          >
            <FormattedMessage id="spending.receipt.take_a_picture" />
          </Button>
        )}
      </label>
    </>
  )
}
