import * as React from 'react'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { MaskedInput, MaskedInputProps } from './MaskedInput'
import { useIntl } from 'react-intl'

const _TelField = (props: MaskedInputProps, ref: React.Ref<HTMLInputElement>) => (
  <MaskedInput
    inputRef={ref}
    format="+1 (###) ###-####"
    mask="_"
    isNumericString
    placeholder="+1 (555) 555-5555"
    {...props}
    type="tel"
  />
)
export const TelField = React.forwardRef(_TelField)

/**
 * A controlled input for telephone numbers. Note that custom validation rules are not supported.
 * If the phone number is required, just supply the `required` prop.
 */
type TelFieldControllerProps<T extends FieldValues> = Omit<MaskedInputProps, 'name' | 'variant'> &
  Pick<ControllerProps<T>, 'name' | 'control'>
export const TelFieldController = <T extends FieldValues>({
  name,
  control,
  ...props
}: TelFieldControllerProps<T>) => {
  const intl = useIntl()
  return (
    <Controller
      render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
        const isError = Boolean((isTouched || submitCount > 0) && error?.message)
        return (
          <TelField
            {...props}
            {...field}
            error={isError}
            helperText={(isError && error?.message) || props.helperText}
          />
        )
      }}
      name={name}
      control={control}
      rules={{
        required: props.required
          ? intl.formatMessage({ id: 'profile.cellphone.validation.required_length' })
          : undefined,
        pattern: {
          value: /^(?:\d{10})?$/, // 10 digits or empty
          message: intl.formatMessage({ id: 'profile.cellphone.validation.required_length' }),
        },
      }}
    />
  )
}
