export { alpha, darken, lighten, styled, useTheme } from '@mui/material/styles'

// regex that matches all commas that are _not_ inside a matched pair of parens (not nested)
const commaNotInsideParens_re = /,(?![^(]*\))/

/**
 * convert a box shadow style rule to the equivalent _inset_ box shadow by injecting
 * `inset` in the appropriate place. Because box shadow rules can be stacked by appending
 * multiple values with a comma, this function may insert multiple 'inset' keywords
 */
export const insetShadow = (boxShadowValue: string) =>
  `inset ${boxShadowValue.split(commaNotInsideParens_re).join(', inset ')}`
