import {
  Box,
  CircularProgress,
  Container,
  FormLabel,
  FullDialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Tooltip,
  Typography,
} from '@alice-financial/pretext-ui'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AliceCard } from '../../../../../graphql/generated.types'
import { CardProps } from '../../types'
import { useStripeCompanyAccountQuery } from '../gql/stripeAccount_gen'
import { useIssuingElements } from '../useIssuingElements'
import { useShowSecureCardDetails } from './useShowSecureCardDetails'

type DisplayNumberProps = {
  mount: Promise<(domElement: string | HTMLElement) => void> | undefined
  id: string
}
const DisplayNumber = ({ mount, id }: DisplayNumberProps) => {
  const [isMounted, setIsMounted] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  React.useLayoutEffect(() => {
    const currentEl = ref.current
    if (!mount || isMounted || !currentEl) return
    mount.then((mount) => {
      mount(currentEl)
      setIsMounted(true)
    })
  }, [id, mount, isMounted])
  return (
    <Box ref={ref} id={id}>
      <Skeleton width="100%" />
    </Box>
  )
}
type CopyNumberProps = DisplayNumberProps & { isCopied: boolean }
const CopyNumber = ({ mount, id, isCopied }: CopyNumberProps) => {
  const [isMounted, setIsMounted] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  React.useLayoutEffect(() => {
    const currentEl = ref.current
    if (!mount || isMounted || !currentEl) return
    mount.then((mount) => {
      mount(currentEl)
      setIsMounted(true)
    })
  }, [mount, isMounted])
  return (
    <Tooltip
      title={<FormattedMessage id={isCopied ? 'common.copied' : 'common.copy'} />}
      placement="top"
      open={isCopied || undefined}
    >
      <IconButton color="primary" edge="end" aria-label="copy">
        <Box ref={ref} id={id} position="absolute" top={0} bottom={0} left={0} right={0} />
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

type SecureCardDetailIds = {
  displayNumber?: string
  displayExpiry?: string
  displayCvc?: string
  copyNumber?: string
  copyCvc?: string
}
/**
 * hook that attaches click callbacks on the provided IDs
 */
const useSecureCardDetails = (stripeAccount: string, card: AliceCard, ids: SecureCardDetailIds) => {
  const [isNumberCopied, setIsNumberCopied] = React.useState(false)
  const [isCvcCopied, setIsCvcCopied] = React.useState(false)

  const issuingElements = useIssuingElements({
    stripeAccount,
    card,
    onClickNumberCopy: () => setIsNumberCopied(true),
    onClickCvcCopy: () => setIsCvcCopied(true),
  })

  return {
    displayNumber: ids.displayNumber && (
      <DisplayNumber mount={issuingElements?.mountNumber} id={ids.displayNumber} />
    ),
    displayExpiry: ids.displayExpiry && (
      <DisplayNumber mount={issuingElements?.mountExpiry} id={ids.displayExpiry} />
    ),
    displayCvc: ids.displayCvc && <DisplayNumber mount={issuingElements?.mountCvc} id={ids.displayCvc} />,
    copyNumber: ids.copyNumber && (
      <CopyNumber mount={issuingElements?.mountNumberCopy} id={ids.copyNumber} isCopied={isNumberCopied} />
    ),
    copyCvc: ids.copyCvc && (
      <CopyNumber mount={issuingElements?.mountCvcCopy} id={ids.copyCvc} isCopied={isCvcCopied} />
    ),
  }
}
const SecureCardDetail = ({
  label,
  children,
  copyElement,
}: {
  label: string
  children: React.ReactNode
  copyElement?: React.ReactNode
}) => {
  return (
    <ListItem
      divider
      secondaryAction={
        copyElement && (
          <Grid container columnGap={1} wrap="nowrap">
            <Grid item xs={12}>
              {copyElement}
            </Grid>
          </Grid>
        )
      }
    >
      <Box display="grid" gridColumn="1" flex={1}>
        <FormLabel sx={{ fontSize: '0.9rem' }}>{label}</FormLabel>
        {children}
      </Box>
    </ListItem>
  )
}

type SecureCardDetailsProps = {
  card: AliceCard
  stripeAccount: string
}
const SecureCardDetails = ({ card, stripeAccount }: SecureCardDetailsProps) => {
  const intl = useIntl()
  const ids = {
    displayNumber: `full_number_${card.id}`,
    displayExpiry: `full_expiry_${card.id}`,
    displayCvc: `full_cvc_${card.id}`,
    copyNumber: `full_copy_number_${card.id}`,
    copyCvc: `full_copy_cvc_${card.id}`,
  }

  const { displayNumber, displayExpiry, displayCvc, copyNumber, copyCvc } = useSecureCardDetails(
    stripeAccount,
    card,
    ids
  )
  return (
    <List>
      <SecureCardDetail
        label={intl.formatMessage({ id: 'cards.common.card_number' })}
        copyElement={copyNumber}
      >
        {displayNumber}
      </SecureCardDetail>
      <SecureCardDetail label={intl.formatMessage({ id: 'cards.common.expiration_date' })}>
        {displayExpiry}
      </SecureCardDetail>
      <SecureCardDetail
        label={intl.formatMessage({ id: 'cards.common.verification_code' })}
        copyElement={copyCvc}
      >
        {displayCvc}
      </SecureCardDetail>
    </List>
  )
}

/**
 * A modal that pops up when the user indicates that they want to see the secure card details.
 *
 * A modal is used to ensure that the card details are not visible to the user until they explicitly request it,
 * and to make it easy/intuitive to hide the details using the close button or keyboard 'escape' - because the
 * rest of the page is not accessible when the modal is open, the details _must_ be hidden before doing
 * anything else
 */
export const SecureCardDetailsModal = ({ card }: CardProps) => {
  const { data: stripeAccountData } = useStripeCompanyAccountQuery()
  const stripeAccount =
    stripeAccountData?.organization?.aliceCardProgram?.companyAccount?.stripeConnectedAccountId

  const { isOpen, close } = useShowSecureCardDetails(card)
  return (
    <FullDialog
      fullWidth
      title={<Typography variant="h2">Card details</Typography>}
      open={isOpen}
      onClose={close}
    >
      <Container>
        {stripeAccount ? (
          <SecureCardDetails stripeAccount={stripeAccount} card={card} />
        ) : (
          <CircularProgress />
        )}
      </Container>
    </FullDialog>
  )
}
