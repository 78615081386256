import {
  Callout,
  ExternalLink,
  insetShadow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@alice-financial/pretext-ui'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import * as React from 'react'

export const AliceCardProgramSetupHelp = () => {
  const theme = useTheme()
  return (
    <Callout component="div" width="100%" boxShadow={insetShadow(theme.shadows[2])}>
      <Typography variant="h3" gutterBottom>
        Have questions? Need help?
      </Typography>
      <List dense>
        <ListItem disableGutters>
          <ListItemIcon>
            <HelpOutlineOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">
                Have other questions? Contact us at{' '}
                <ExternalLink href="mailto:admin-support@thisisalice.com">
                  admin-support@thisisalice.com
                </ExternalLink>
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Callout>
  )
}
