import * as React from 'react'
import { PRIMARY_FONT_FAMILY } from '@alice-financial/pretext-ui'
import { useQuery } from '@tanstack/react-query'
import { IssuingElements } from '../../../../externalServices/stripe/useLoadStripe'
import { AliceCardFragment } from '../../gql/connectedCardsQuery_gen'
import { useEphemeralKey } from './useEphemeralKey'

const useNonceQuery = (issuingElementsPromise: Promise<IssuingElements>, issuingCard: string) =>
  useQuery(
    ['nonce', issuingCard],
    () =>
      issuingElementsPromise
        .then(({ stripe }) => stripe.createEphemeralKeyNonce({ issuingCard }))
        .then((nonceResult) => nonceResult.nonce),
    { cacheTime: 0 } // always get new nonce
  )

const CARD_DETAIL_STYLE = {
  base: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '16px',
    fontFamily: `'${PRIMARY_FONT_FAMILY}', -apple-system, sans-serif`,
  },
}

export const useIssuingElementsConfig = (
  card: AliceCardFragment,
  issuingElementsPromise: Promise<IssuingElements>
) => {
  if (!card.bankingSystemId) throw new Error('Card is not an issuing card')
  const issuingCard = card.bankingSystemId
  const { data: nonce } = useNonceQuery(issuingElementsPromise, issuingCard)
  const { data: ephemeralKey } = useEphemeralKey(
    { cardId: card.id, nonce: nonce || '' },
    { enabled: Boolean(nonce) }
  )
  const ephemeralKeySecret = ephemeralKey?.secret

  return React.useMemo(
    () => ephemeralKeySecret && { issuingCard, ephemeralKeySecret, nonce, style: CARD_DETAIL_STYLE },
    [issuingCard, ephemeralKeySecret, nonce]
  )
}
