import { UseMutationOptions, QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { ApiRootException, NotFoundError, UnauthorizedError } from '../responseErrors'
import { RestInputErrorException } from './responseErrors'

/**
 * ApiBadInputResponse is the default error shape from GrapeAPI validation errors (Grape::Exceptions::ValidationErrors)
 * However, most REST endpoints should reformat this error into an ApiError
 * (rescue_from Grape::Exceptions::ValidationErrors, with: :validation_error), so you only need to handle
 * this kind of error response if you're calling an endpoint that doesn't do that
 */
export type ApiBadInputResponse = Array<{ params: Array<string>; messages: Array<string> }>

export type RestApiError = ApiRootException | UnauthorizedError | NotFoundError | RestInputErrorException

export type UseRestMutationCallbacks<TData = unknown, TVariables = unknown, TContext = unknown> = Pick<
  UseMutationOptions<TData, RestApiError, TVariables, TContext>,
  'onMutate' | 'onError' | 'onSuccess' | 'onSettled'
>

export type UseRestQueryCallbacks<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Pick<UseQueryOptions<TQueryFnData, RestApiError, TData, TQueryKey>, 'onError' | 'onSuccess' | 'onSettled'>
