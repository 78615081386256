import * as React from 'react'
import { matchPath, useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

// This is an array of react route `path` patterns that are allowed to be served
// in prod - this list MUST align with the routes matched by the nginx reverse proxy
// config for production
const PROD_PATHS: Array<string> = [
  '/',
  '/login',
  '/logout',
  '/forgot-password',
  '/reset-password/*',
  '/beta/*',
  '/enroll/*',
  '/docs/*',
  '/cards/*',
  '/enrollment-flow/*',
  '/spending/*',
  '/dashboard/*',
  '/profile',
  '/onboard/*',
  '/manage/*',
]

const testPathSupport = (path: string) => PROD_PATHS.some((pattern) => Boolean(matchPath(pattern, path)))

type ProdFirewallProps = {
  children: React.ReactNode
}
/**
 * This component watches for route changes and will hard-refresh the page when
 * a route is requested that is not explicitly enabled for production
 */
export const ProdFirewall = ({ children }: ProdFirewallProps) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [isSupportedRoute, setIsSupportedRoute] = React.useState(false)

  React.useEffect(() => {
    // only enabled for production or explicit debug
    if (process.env.DISABLE_PROD_FIREWALL === 'true' || searchParams.get('disable_prod_firewall')) return
    const newRouteIsSupported = testPathSupport(location.pathname)
    if (!newRouteIsSupported) {
      if (isSupportedRoute) setIsSupportedRoute(false)
      console.warn('Location not supported in user-web:', location)

      // debug behavior: crash
      if (process.env.DEBUG_PROD_FIREWALL === 'true') {
        throw new Error(`Location ${location.pathname} is not supported`)
      }
      // prod behavior: hard refresh in order to hit the reverse proxy and (hopefully)
      // load a different app - e.g. Ada or Tilda
      window.location.reload()
      return
    }
    if (!isSupportedRoute) setIsSupportedRoute(true)
  }, [location, isSupportedRoute])

  if (process.env.DISABLE_PROD_FIREWALL !== 'true' && !isSupportedRoute) return null

  return <>{children}</>
}
