import { InlineSkeleton, Link, Section, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { useEmployeeQuery } from '../gql/employee_gen'

type SavingsSummaryProps = {
  rawSavingsAmount: number // in cents
  employerName: string | undefined | null
}
const SavingsSummary = ({ rawSavingsAmount, employerName }: SavingsSummaryProps) => {
  const savings = formatCentsAsDollars(rawSavingsAmount)
  return (
    <Typography gutterBottom fontWeight="normal">
      <FormattedMessage
        id="employee_dashboard.main.pretax_spending_savings_details"
        values={{
          employer: () => employerName || <InlineSkeleton width="7em" />,
          savings: () => (
            <Typography variant="h2" component="span">
              <Link to="/spending">{savings}</Link>
            </Typography>
          ),
        }}
      />
    </Typography>
  )
}

export const HomepageSavingsSection = () => {
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  const rawSavingsAmount = employee?.spendingInfo.totalEstimatedPretaxSavings

  // if savings is not available _or zero_, don't show the section
  if (!rawSavingsAmount) return null

  return (
    <Section>
      <SectionHeading>
        <FormattedMessage id="employee_dashboard.main.savings" />
      </SectionHeading>

      <SavingsSummary
        rawSavingsAmount={rawSavingsAmount}
        employerName={employee?.employer?.marketingFriendlyName}
      />
    </Section>
  )
}
