import { List, Paper } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AliceCardHealthCheckItem, BankingAcccountsHealthCheckItem } from './AccountsHealthcheckItems'
import { RecentSpendingCheckItem } from './RecentSpendingHealthcheckItem'
import { useAliceCardFeatureEnabled } from './../../cards/useQuerySpendingConnections'

/**
 * Simple wrapper around a set of <HealthcheckItem /> elements
 */
export const EmployeeHealthcheck = () => {
  const allowAliceCardRoute = useAliceCardFeatureEnabled()

  return (
    <Paper>
      <List dense disablePadding>
        <RecentSpendingCheckItem />
        <BankingAcccountsHealthCheckItem />
        {allowAliceCardRoute && <AliceCardHealthCheckItem />}
      </List>
    </Paper>
  )
}
