import * as React from 'react'
import ReactDOMClient from 'react-dom/client'
import { AppRoot } from './AppRoot'
import { initGlobals } from './init/globals'

// DO NOT REMOVE
initGlobals()

const app = document.getElementById('app')
if (!app) throw new Error('Root element `#app` not found')
const root = ReactDOMClient.createRoot(app)
root.render(<AppRoot />)
