import * as React from 'react'
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

export { Checkbox }

type CheckboxControllerProps<T extends FieldValues> = Omit<CheckboxProps, 'name'> &
  Pick<ControllerProps<T>, 'name' | 'control' | 'rules'> &
  Pick<FormControlLabelProps, 'label'>
export const CheckboxController = <T extends FieldValues>({
  control,
  name,
  rules,
  label,
  ...props
}: CheckboxControllerProps<T>) => (
  <Controller
    render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
      const isError = Boolean((isTouched || submitCount > 0) && error?.message)
      return (
        <FormControl fullWidth error={isError}>
          <FormControlLabel
            control={
              <Checkbox {...props} {...field} checked={field.value} color={isError ? 'error' : 'primary'} />
            }
            label={
              <>
                {label}
                {isError && <FormHelperText>{error?.message}</FormHelperText>}
              </>
            }
          />
        </FormControl>
      )
    }}
    control={control}
    name={name}
    rules={rules}
  />
)
