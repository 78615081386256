import { useParams } from 'react-router'
import { useOrgId } from '../useOrgId'
import { useOrgDashboardQuery } from './gql/orgDashboard_gen'

// Note: useOrgQuery.getKey, useOrg.getKey, and useOrgDashboardQuery.getKey all refer to the same query data

/**
 * Thin wrapper around the org dashboard useQuery that will read the orgId from the URL
 * inside the org dashboard routes. If you need the query state, use this, otherwise prefer useOrg
 */
export const useOrgQuery = () => {
  const orgId = useOrgId()
  return useOrgDashboardQuery({ orgId })
}

useOrgQuery.getKey = useOrgDashboardQuery.getKey

/**
 * Thin wrapper around the org dashboard query that will read the orgId from the URL
 * inside the org dashboard routes and return the organization.
 * @returns organization, undefined (waiting for org to load), or null (not found)
 */
export const useOrg = () => {
  const { data } = useOrgQuery()
  return data?.organization
}
useOrg.getKey = useOrgQuery.getKey

export const useCurrentOrgRootPath = () => {
  const { orgId: orgIdParam } = useParams()
  if (!orgIdParam) throw new Error('useCurrentOrgRootPath used outside of org context')
  return orgRootPath(orgIdParam)
}

export const orgRootPath = (orgId: number | string) => `/manage/org/${orgId}`
