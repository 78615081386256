import { TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

const DEFAULT_PIN_LENGTH = 4

type PinInputProps = Omit<TextFieldProps, 'variant'>

const _PinInputBase = styled(TextField)(({ theme }) => ({
  width: '12.3rem',
  '& .Mui-error': {
    '& .MuiInputBase-input': {
      color: theme.palette.error.main,
      '&:focus': {
        color: theme.palette.error.main,
      },
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
    '&:before': {
      borderBottom: `2px dashed ${theme.palette.primary.main}`,
    },
  },
  '& .MuiInputBase-input': {
    width: '13rem',
    overflow: 'hidden',
    fontSize: '3rem',
    fontFamily: ['courier new', 'consolas', 'monospace'].join(', '),
    fontWeight: 'bold',
    letterSpacing: '1rem',
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 0, 2, 2),
    boxSizing: 'border-box',
    '&:focus': {
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

/**
 * The number input to be used like any other TextField.
 *
 * We avoid `type="number"` because it introduces a lot of unwanted UI issues.
 * Instead, we set `inputMode` and `pattern` values on the <input>
 *
 * @see https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
 */
const _PinInput = (props: TextFieldProps & { error?: boolean }, ref: React.Ref<HTMLInputElement>) => {
  return (
    <_PinInputBase
      variant="filled"
      autoComplete="off"
      {...props}
      inputProps={{
        maxlength: DEFAULT_PIN_LENGTH,
        minlength: DEFAULT_PIN_LENGTH,
        ...props.inputProps,
        inputMode: 'numeric',
        pattern: '[0-9]*',
        ref,
      }}
    />
  )
}

export const PinInput = React.forwardRef(_PinInput)

type PinInputControllerProps<T extends FieldValues> = Pick<ControllerProps<T>, 'name' | 'control' | 'rules'> &
  Omit<PinInputProps, 'value'>

export const PinInputController = <T extends FieldValues>({
  name,
  control,
  rules,
  helperText,
  inputProps,
  ...props
}: PinInputControllerProps<T>) => {
  // wrap the input props with the specified maxLength rule with a default of 4
  const _inputProps = React.useMemo(
    () => ({
      maxlength: rules?.maxLength || DEFAULT_PIN_LENGTH,
      minlength: rules?.minLength || DEFAULT_PIN_LENGTH,
      ...inputProps,
    }),
    [rules, inputProps]
  )
  const _rules = React.useMemo(
    () => ({
      minLength: DEFAULT_PIN_LENGTH,
      maxLength: DEFAULT_PIN_LENGTH,
      ...rules,
    }),
    [rules]
  )
  return (
    <Controller
      render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
        const _error = ((isTouched || submitCount > 0) && error?.message) || undefined

        return (
          <PinInput
            {...props}
            inputProps={_inputProps}
            {...field}
            error={Boolean(_error)}
            helperText={_error || helperText}
          />
        )
      }}
      name={name}
      control={control}
      rules={_rules}
    />
  )
}
