import { Button } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployeeRole } from '../../graphql/generated.types'
import { EmployeeRow } from './types'
import { useUpdateEmployees } from './updateEmployees'

type GrantAdminAccessButtonProps = {
  employees: Array<EmployeeRow>
}
export const GrantAdminAccessButton = ({ employees }: GrantAdminAccessButtonProps) => {
  const employeeIds = employees.map((e) => e.id)
  const { mutate: updateEmployees } = useUpdateEmployees(employeeIds)

  const disabled =
    employees.length === 0 || employees.some((ee) => ee.role === EmployeeRole.OrganizationAdmin)

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      disabled={disabled}
      onClick={() => updateEmployees({ role: EmployeeRole.OrganizationAdmin })}
    >
      Grant admin access
    </Button>
  )
}
