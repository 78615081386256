import { ActionLayout, Box, Button, Grid, InputLabel } from '@alice-financial/pretext-ui'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { StripeCardNumberElementOptions } from '@stripe/stripe-js'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { getBillableType } from '../../orgDashboard/billing/billingUtils'
import { PaymentCardInputValues, useConnectPaymentCard } from './useConnectPaymentCard'
import { Billable } from '../../orgDashboard/billing/types'

/**
 * Most card data is collected by Stripe's CardElement components and tokenized automatically. However,
 * we must also pass the `orgId` to the API, and `address_zip` will be part of the tokenized data
 * in order to improve fraud detection.
 */

type OrgIdentifier = { orgId: number }
type EmployerIdentifier = { employerId: number }
export type BillableIdentifier = OrgIdentifier | EmployerIdentifier

const INPUT_FIELD_STYLES = {
  base: {
    fontFamily: 'Consolas, monospace',
    '::placeholder': {
      color: '#bbb',
    },
  },
}

/**
 * Since we don't have much styling control over the Stripe CardElement components, we wrap them
 * in a box that kinda sorta looks like our other inputs.
 */
const CardInputWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box py={1} px={1.5} border="1px solid" borderColor="#888" borderRadius={1}>
    {children}
  </Box>
)

type PaymentCardInputProps = { billable: Billable; onSuccess: () => void }
export const PaymentCardInput = ({ billable, onSuccess }: PaymentCardInputProps) => {
  const { mutate: connectPaymentCard, isLoading, isSuccess } = useConnectPaymentCard({ onSuccess })

  const { handleSubmit, register } = useForm<PaymentCardInputValues>({
    defaultValues: { ownerId: billable.id, ownerType: getBillableType(billable), address_zip: '' },
  })

  const cardNumberElementOptions: StripeCardNumberElementOptions = {
    style: INPUT_FIELD_STYLES,
    disableLink: true,
  }

  const isSubmitDisabled = isLoading || isSuccess

  return (
    <form onSubmit={handleSubmit((values) => connectPaymentCard(values))}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={9}>
          <InputLabel htmlFor="cardnumber">
            Card number
            <CardInputWrapper>
              <CardNumberElement options={cardNumberElementOptions} />
            </CardInputWrapper>
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel htmlFor="cardExpiry">
            Expiration date
            <CardInputWrapper>
              <CardExpiryElement options={{ style: INPUT_FIELD_STYLES }} />
            </CardInputWrapper>
          </InputLabel>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={4} sm={3}>
          <InputLabel htmlFor="cvc">
            &nbsp;{/* placeholder is sufficient, but keep label spacing */}
            <CardInputWrapper>
              <CardCvcElement options={{ style: INPUT_FIELD_STYLES }} />
            </CardInputWrapper>
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel htmlFor="address_zip">Billing zip code</InputLabel>
          <input
            type="text"
            style={{ padding: '8px 12px', border: '1px solid #888', borderRadius: '4px' }}
            placeholder="#####"
            {...register('address_zip')}
          />
        </Grid>
      </Grid>

      <ActionLayout
        disablePadding
        primary={
          <Button
            name="action"
            type="submit"
            value="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitDisabled}
          >
            Save payment card
          </Button>
        }
      />
    </form>
  )
}
