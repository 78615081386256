import { createTheme } from '@mui/material'
import { components } from './components'
import { palette } from './palette'
import { typography } from './typography'

export const theme = createTheme({
  palette,
  typography,
  components,
  zIndex: {
    mobileStepper: 500,
    fab: 550,
    speedDial: 550,
    appBar: 600,
    drawer: 700,
    modal: 800,
    snackbar: 900,
    tooltip: 1000,
  },
})
