import { HealthcheckItem, InertHealthcheckItem, InertHealthcheckItemProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useLocation } from 'react-router'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardKycStatus } from '../../graphql/generated.types'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'

const ItemDisplay = (props: InertHealthcheckItemProps) => {
  const location = useLocation()
  if (location.pathname === '/manage/alice-card-program') {
    return <InertHealthcheckItem {...props} />
  }
  return <HealthcheckItem {...props} to="/manage/alice-card-program" />
}

type AliceCardProgramHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const AliceCardProgramHealthcheck = ({ org, todo }: AliceCardProgramHealthcheckProps) => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!mayManageAliceCardProgram) return null
  if (!org) return null
  const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
  const fundingConnected = org.aliceCardProgram.hasFundingConnected
  const hasUnifiedBanking = Boolean(org.aliceCardProgram.hasUnifiedBanking || org.employers.length === 1)

  if (!kycComplete) {
    return (
      <ItemDisplay
        todo={todo}
        status="incomplete"
        primary="Benefits administration setup incomplete"
        secondary="Company representative information needed"
      />
    )
  }
  if (!fundingConnected) {
    return (
      <ItemDisplay
        todo={todo}
        status="incomplete"
        primary="Benefits administration setup incomplete"
        secondary={
          hasUnifiedBanking
            ? 'Alice Card funding account not connected'
            : 'Alice Card funding accounts not connected'
        }
      />
    )
  }
  return (
    <ItemDisplay
      status="complete"
      primary={
        hasUnifiedBanking ? 'Alice Card funding account connected' : 'Alice Card funding accounts connected'
      }
      secondary="Alice Card issuing is active"
    />
  )
}
