import { Alert, AlertProps, AlertTitle } from '@alice-financial/pretext-ui'
import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { TrnKey } from '../../../translations/types'

type FinchConnectResult = 'completed' | 'iframe_closed' | 'error_ocurred'
type AlertVariantsMap = Record<FinchConnectResult, [AlertProps['severity'], TrnKey]>

const ALERT_CONFIG: AlertVariantsMap = {
  completed: ['success', 'payroll.finch.authentication_completed'],
  error_ocurred: ['error', 'payroll.finch.authentication_error_ocurred'],
  iframe_closed: ['warning', 'payroll.finch.authentication_iframe_closed'],
}

const isFinchConfirmationCodeValid = (finchConnectCode: string): finchConnectCode is FinchConnectResult =>
  finchConnectCode in ALERT_CONFIG

/**
 * This component renders a confirmation message alert after they complete the Finch authentication process
 * and return to the admin dashboard with a 'finch_connect' search param.
 */
export const FinchConnectResultAlert = () => {
  const [searchParams] = useSearchParams()
  const finchConnectCode = searchParams.get('finch_connect')

  if (typeof finchConnectCode !== 'string' || !finchConnectCode) return null
  if (!isFinchConfirmationCodeValid(finchConnectCode)) {
    datadogRum.addError(new Error('Invalid finch connect confirmation code received'), { finchConnectCode })

    return null
  }
  const [severity, messageId] = ALERT_CONFIG[finchConnectCode]

  return (
    <Alert severity={severity}>
      <AlertTitle>
        <FormattedMessage id={messageId} />
      </AlertTitle>
    </Alert>
  )
}
