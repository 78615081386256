import * as React from 'react'
import { useIssuingElementsStripe } from '../../../../externalServices/stripe/useLoadStripe'
import { AliceCardFragment } from '../../gql/connectedCardsQuery_gen'
import { useIssuingElementsConfig } from './useIssuingElementsConfig'

type IssuingElementsData = {
  stripeAccount: string
  card: AliceCardFragment
  onClickNumberCopy?: () => void
  onClickCvcCopy?: () => void
}
export const useIssuingElements = ({
  stripeAccount,
  card,
  onClickNumberCopy,
  onClickCvcCopy,
}: IssuingElementsData) => {
  const issuingElementsStripe = useIssuingElementsStripe({ stripeAccount })
  const config = useIssuingElementsConfig(card, issuingElementsStripe)
  return React.useMemo(() => {
    if (!config) return null
    const getElements = issuingElementsStripe.then(({ elements }) => elements)

    return {
      mountNumber: getElements
        .then((elements) => elements.create('issuingCardNumberDisplay', config))
        .then((element) => element.mount),
      mountExpiry: getElements
        .then((elements) => elements.create('issuingCardExpiryDisplay', config))
        .then((element) => element.mount),
      mountCvc: getElements
        .then((elements) => elements.create('issuingCardCvcDisplay', config))
        .then((element) => element.mount),
      mountNumberCopy: getElements
        .then((elements) =>
          elements.create('issuingCardCopyButton', {
            toCopy: 'number',
            style: { base: { lineHeight: '44px', fontSize: '44px' } }, // 44px is approximately the height of the button
          })
        )
        .then((element) => (domElement: string | HTMLElement) => {
          element.mount(domElement)
          onClickNumberCopy && element.on('click', onClickNumberCopy)
        }),
      mountCvcCopy: getElements
        .then((elements) =>
          elements.create('issuingCardCopyButton', {
            toCopy: 'cvc',
            style: { base: { lineHeight: '44px', fontSize: '44px' } }, // 44px is approximately the height of the button
          })
        )
        .then((element) => (domElement: string | HTMLElement) => {
          element.mount(domElement)
          onClickCvcCopy && element.on('click', onClickCvcCopy)
        }),
    }
  }, [issuingElementsStripe, config, onClickNumberCopy, onClickCvcCopy])
}
