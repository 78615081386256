import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { VerifyPhoneInput } from '../graphql/generated.types'
import { useVerifyPhoneMutation, VerifyPhoneMutation } from './gql/verifyPhone_gen'

type UseSubmitPhoneVerificationOptions = UseGqlMutationCallbacks<VerifyPhoneMutation, VerifyPhoneInput>

/**
 * This endpoint will supply a _new_, logged-in user cookie. Do not call this mutation
 * unless you are ready to handle a logged-in user state.
 */
export const useSubmitPhoneVerification = (mutationOptions?: UseSubmitPhoneVerificationOptions) => {
  const intl = useIntl()
  const { mutateAsync: verifyPhone } = useVerifyPhoneMutation()
  const optionsWithNotifications = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'enroll.phone_verification.sending_verification_code' }),
    },
    mutationOptions
  )
  return useMutationWithInvalidation(
    ({ confirmationCode }) => verifyPhone({ input: { confirmationCode } }),
    optionsWithNotifications,
    'all'
  )
}
