import * as Types from '../../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateFinicityConnectUrlMutationVariables = Types.Exact<{
  input: Types.CreateFinicityConnectUrlInput
}>

export type CreateFinicityConnectUrlMutation = {
  __typename?: 'Mutation'
  createFinicityConnectUrl?: {
    __typename?: 'CreateFinicityConnectUrlPayload'
    connectUrl: string
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateFinicityConnectUrlDocument = `
    mutation CreateFinicityConnectUrl($input: CreateFinicityConnectUrlInput!) {
  createFinicityConnectUrl(input: $input) {
    connectUrl
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateFinicityConnectUrlMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFinicityConnectUrlMutation,
    TError,
    CreateFinicityConnectUrlMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateFinicityConnectUrlMutation,
    TError,
    CreateFinicityConnectUrlMutationVariables,
    TContext
  >(
    ['CreateFinicityConnectUrl'],
    (variables?: CreateFinicityConnectUrlMutationVariables) =>
      fetchGql<CreateFinicityConnectUrlMutation, CreateFinicityConnectUrlMutationVariables>(
        CreateFinicityConnectUrlDocument,
        variables
      )(),
    options
  )
}
