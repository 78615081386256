import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

export type DatePagingState = { endDate?: string; startDate?: string; days?: number }
const PARAM_START_DATE = 'start_date'
const PARAM_END_DATE = 'end_date'

const pagingStateFromParams = (searchParams: URLSearchParams): DatePagingState => {
  const endDate = searchParams.get(PARAM_END_DATE) || undefined
  const startDate = searchParams.get(PARAM_START_DATE) || undefined
  return { endDate, startDate }
}

type DatePagingContextType = readonly [DatePagingState, (pagingState: DatePagingState) => void] | undefined
/**
 * The DatePagingContext allows any child element to access the current pagination arguments
 * that can be used for `useBankingTransactionsList` - this is mainly used for the TxnPager
 * to access `setPaging` setter
 */
const DatePagingContext = React.createContext<DatePagingContextType>(undefined)

type PagingContextProviderProps = {
  children: React.ReactNode
}
export const DatePagingContextProvider = ({ children }: PagingContextProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setPaging = React.useCallback(
    (pagingState: DatePagingState) => {
      const { endDate, startDate } = pagingStateFromParams(searchParams)
      if (pagingState.startDate === startDate && pagingState.endDate === endDate) return

      if (pagingState.startDate) {
        searchParams.set(PARAM_START_DATE, pagingState.startDate)
      } else {
        searchParams.delete(PARAM_START_DATE)
      }
      if (pagingState.endDate) {
        searchParams.set(PARAM_END_DATE, pagingState.endDate)
      } else {
        searchParams.delete(PARAM_END_DATE)
      }
      setSearchParams(searchParams)
    },
    [setSearchParams, searchParams]
  )
  const pagingState: DatePagingContextType = [pagingStateFromParams(searchParams), setPaging]

  return <DatePagingContext.Provider value={pagingState}>{children}</DatePagingContext.Provider>
}

export const useDatePagingContext = () => {
  const context = React.useContext(DatePagingContext)
  if (!context) throw new Error('useDatePagingContext called outside DatePagingContextProvider')
  return context
}
