import * as React from 'react'
import { useNavigate } from 'react-router'
import { NotFound } from '../routes/NotFound'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'
import { AvailableProfileTypes, UserProfileType, useRequiredProfileType } from '../user/useProfileType'
import { useLoginPath } from './useLoginPath'

type AuthenticateProps = {
  children: React.ReactNode
  requiredProfile?: UserProfileType | AvailableProfileTypes
}

/**
 * A component that will redirect to the login page as soon as it
 * determines that the viewer is _not_ logged in.
 *
 * Currently it will still render its children before the user is determined
 * - it is expected that every view can handle its own 'loading'/empty
 * state appropriately
 */
export const Authenticate = ({ children, requiredProfile = 'user' }: AuthenticateProps) => {
  const { data: currentUserData, isLoading } = useCurrentUserQuery()
  const requiredProfileOptions: AvailableProfileTypes =
    typeof requiredProfile === 'string' ? [requiredProfile] : requiredProfile
  const authorizedUserProfileType = useRequiredProfileType(...requiredProfileOptions)

  const navigate = useNavigate()
  const loginPath = useLoginPath()
  const isAuthenticated = Boolean(currentUserData?.currentUser?.id)

  // As soon as we know the user is not authenticated, redirect to login
  React.useEffect(() => {
    if (isLoading || isAuthenticated) return

    navigate(loginPath, { replace: true })
  }, [navigate, isAuthenticated, loginPath, isLoading])

  if (isLoading || authorizedUserProfileType === undefined) return null
  if (!isAuthenticated || !authorizedUserProfileType) return <NotFound /> // could use '403 Forbidden' in this case

  return <>{children}</>
}
