import { Container } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { HowAliceWorks } from './HowAliceWorks'
import { StartBar } from './StartBar'

export const EnrollmentLanding = () => {
  return (
    <Container
      sx={(theme) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
          paddingBottom: 0,
        },
      })}
    >
      <HowAliceWorks />

      <StartBar />
    </Container>
  )
}
