import { useQuery } from '@tanstack/react-query'
import { Connect, ConnectOptions } from 'connect-web-sdk'
import { UseCardConnectorConfig } from './types'
import { useCreateFinicityConnection } from './useCreateFinicityConnection'
import { useCreateFinicityConnectUrl } from './useCreateFinicityConnectUrl'

/**
 * This hook initializes the Finicity Connect instance, which is the `iframe`-based
 * widget provided by Finicity to connect a bank transaction feed to a user. The hook
 * consumer can start the connection flow by calling the returned function with the
 * desired banking institution ID
 *
 * There are a two steps needed to trigger the flow
 * 1. When an institution is selected for linking, call the Alice REST API to
 *    generate an institution-specific linking URL
 * 2. Open the Connect iframe using the generated URL and callbacks
 */

export const useFinicityConnect = (
  institutionPlatformId: string,
  { onSuccess, onError, onCancel }: UseCardConnectorConfig,
  connectOptions?: ConnectOptions
) => {
  const { mutateAsync: createFinicityConnectUrl } = useCreateFinicityConnectUrl()
  const { mutate: createFinicityConnection } = useCreateFinicityConnection({ onSuccess, onError })
  const onDone = () => createFinicityConnection({ institutionPlatformId })

  return useQuery(['finicityConnectUrl', institutionPlatformId], () =>
    createFinicityConnectUrl({ institutionPlatformId }).then((finicityConnectUrl) => {
      Connect.launch(
        finicityConnectUrl,
        {
          onDone,
          onCancel,
          onError: (err) => {
            // consider triggering custom DataDog errors here with contextual info
            console.error('Runtime error during Finicity Connect', err)
            onError()
          },
          onUser: (event) => console.info('Finicity Connect:', event.data),
        },
        connectOptions
      )
    })
  )
}
