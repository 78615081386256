import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateAliceCardProgramMutationVariables = Types.Exact<{
  input: Types.UpdateAliceCardProgramInput
}>

export type UpdateAliceCardProgramMutation = {
  __typename?: 'Mutation'
  updateAliceCardProgram?: {
    __typename?: 'UpdateAliceCardProgramPayload'
    organization?: { __typename?: 'Organization'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const UpdateAliceCardProgramDocument = `
    mutation UpdateAliceCardProgram($input: UpdateAliceCardProgramInput!) {
  updateAliceCardProgram(input: $input) {
    organization {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useUpdateAliceCardProgramMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAliceCardProgramMutation,
    TError,
    UpdateAliceCardProgramMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateAliceCardProgramMutation,
    TError,
    UpdateAliceCardProgramMutationVariables,
    TContext
  >(
    ['UpdateAliceCardProgram'],
    (variables?: UpdateAliceCardProgramMutationVariables) =>
      fetchGql<UpdateAliceCardProgramMutation, UpdateAliceCardProgramMutationVariables>(
        UpdateAliceCardProgramDocument,
        variables
      )(),
    options
  )
}
