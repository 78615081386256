import { Box, FormLabel } from '@alice-financial/pretext-ui'
import { Loader } from '@googlemaps/js-api-loader'
import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

type PlaceSearchBarProps = {
  onSuccess: (place: google.maps.places.PlaceResult) => void
}

const useAutocomplete = () => {
  return useQuery(
    ['placesAutocomplete'],
    async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyC2WGkYFx8TWzun-eQiUa8DYwvfRVfUaU0',
        version: 'weekly',
        libraries: ['maps'],
      })

      const { Autocomplete } = await loader.importLibrary('places')
      return Autocomplete
    },
    { staleTime: Infinity }
  )
}

export const PlaceSearchBar: React.FC<PlaceSearchBarProps> = ({ onSuccess }) => {
  const [query, setQuery] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const input = inputRef.current
  const { data: Autocomplete } = useAutocomplete()
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (!input) return
    if (!Autocomplete) return

    const autocomplete = new Autocomplete(inputRef.current)

    input?.addEventListener('keydown', handleKeyDown)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      onSuccess(place)
      if (place.formatted_address) setQuery(place.formatted_address) // ensure full address remains in box on form refresh
    })

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete)
      input?.removeEventListener('keydown', handleKeyDown)
    }
  }, [Autocomplete, onSuccess, input])

  return (
    <Box mb={2} className="location-search-bar">
      <FormLabel>Look up address</FormLabel>
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="e.g. 123 Main St"
      />
    </Box>
  )
}
