import { GqlInputErrorException, getGqlInputErrorHandler } from '@alice-financial/api'
import { ActionLayout, Button, Grid, TextFieldController } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Control, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCurrentUserQuery } from '../../../../../user/gql/currentUser_gen'
import { OMNYCredentials, useReconnectOMNY } from './useOMNYConnect'

type ConnectionDetailsFormProps = {
  control: Control<OMNYCredentials>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  primary: React.ReactNode
  secondary?: React.ReactNode
}

const ConnectionDetailsForm = ({ control, onSubmit, primary, secondary }: ConnectionDetailsFormProps) => {
  const intl = useIntl()
  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            name="username"
            label="OMNY login email"
            rules={{ required: intl.formatMessage({ id: 'common.validation.required' }) }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            type="password"
            name="password"
            label="OMNY password"
            autoComplete="none"
            rules={{ required: intl.formatMessage({ id: 'profile.password.validation.required' }) }}
            fullWidth
          />
        </Grid>
      </Grid>
      <ActionLayout mt={1} mb={8} primary={primary} secondary={secondary} />
    </form>
  )
}

export const ReconnectOMNYForm = ({ connectionId }: { connectionId: number }) => {
  const { data: currentUserData } = useCurrentUserQuery()
  const { handleSubmit, control, setError } = useForm<OMNYCredentials>({
    defaultValues: { username: currentUserData?.currentUser?.email || '', password: '' },
  })
  const gqlInputErrorHandler = getGqlInputErrorHandler(setError)
  const {
    mutate: connectOmnyCard,
    isLoading,
    isSuccess,
  } = useReconnectOMNY({
    onError: (error) => {
      if (error instanceof GqlInputErrorException) gqlInputErrorHandler(error)
    },
  })
  const onSubmit = handleSubmit(({ username, ...values }) =>
    connectOmnyCard({ input: { username, ...values, connectionId } })
  )

  const isSubmitDisabled = isLoading || isSuccess

  return (
    <ConnectionDetailsForm
      control={control}
      onSubmit={onSubmit}
      primary={
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitDisabled}>
          <FormattedMessage id="connections.reconnect.label" />
        </Button>
      }
    />
  )
}
