import {
  Box,
  Button,
  Callout,
  Container,
  ExternalLink,
  Link,
  Paper,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { useEmployeeEnrollmentInfoQuery } from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { ReenrollingWithoutSpendingConnectionsGreeting } from '../../../enrollment/reenrollment/ReenrollingWithoutSpendingConnectionsGreeting'
import {
  AliceCardIssuingPlatform,
  CardRequestTypeEnum,
  EmployeeEnrollmentRequirementStatus,
} from '../../../graphql/generated.types'
import { PageBody } from '../../../routes/PageBody'
import { AddressForm } from '../../profile/address/AddressForm'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { AliceCardBalanceDisplay } from './AliceCardBalanceDisplay'
import {
  DISPLAY_ACCOUNT_STATUSES,
  findActiveCardAccount,
  isAliceCardAccountBalanceGated,
  useAliceCardRequestScope,
} from './aliceCardUtils'
import { CardDisplay } from './CardDisplay'
import { useEnrolledToday } from '../../../enrollment/useEnrollmentStatus'

/**
 * The 'Alice Card homepage' - displays available cards,
 */
export const AliceCardList = () => {
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const enrolledToday = useEnrolledToday()
  const completeLink = enrolledToday ? '/enroll/summary' : '/'

  const employee = employeeEnrollmentData?.employee
  const requirements = employee?.enrollmentInfo.requirements
  const spendingConnectionIncomplete =
    requirements?.spendingConnection === EmployeeEnrollmentRequirementStatus.Incomplete

  const {
    data: { aliceCardAccounts, bankingConnections, aliceCardFundingConnected },
    isLoading,
  } = useQuerySpendingConnections()
  const { data: requestScope, isFetching } = useAliceCardRequestScope()
  const displayAccounts =
    aliceCardAccounts?.filter((account) => DISPLAY_ACCOUNT_STATUSES.includes(account.status)) || []

  // Balance gated accounts are only a 'problem' if the org doesn't have funding connected,
  // in which case we can't be confident that the user will ever be able to get a card
  const hasBalanceGatedAccount = displayAccounts.some(isAliceCardAccountBalanceGated)

  const activeAliceCardAccount = findActiveCardAccount(aliceCardAccounts)
  const hasCards = Boolean(
    activeAliceCardAccount &&
      activeAliceCardAccount.aliceCards.filter(
        (card) => card.issuingPlatform === AliceCardIssuingPlatform.Stripe
      ).length > 0
  )

  if (isLoading) {
    return (
      <PageBody>
        <Container>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.loading" />
          </Typography>
        </Container>
      </PageBody>
    )
  }

  // If no cards have been requested or provisioned, go straight to the dedicated request page
  if (requestScope === CardRequestTypeEnum.InstantAndPhysical && !isFetching) {
    return <Navigate to="request" replace />
  }

  return (
    <PageBody>
      <Container>
        <ReenrollingWithoutSpendingConnectionsGreeting />
        {hasBalanceGatedAccount && !aliceCardFundingConnected && (
          // indefinite balance gating notice
          <Callout gutterBottom>
            <FormattedMessage id="cards.alice.balance_gated_account_info" />
          </Callout>
        )}

        {(hasCards || aliceCardFundingConnected) && <CardDisplay aliceCardAccount={activeAliceCardAccount} />}

        {activeAliceCardAccount && aliceCardFundingConnected && !spendingConnectionIncomplete && (
          <>
            <Paper sx={{ my: 2 }}>
              <Box p={2}>
                <AddressForm
                  title={
                    <Typography variant="h3" gutterBottom>
                      <FormattedMessage id="cards.personal.billing_address" />
                    </Typography>
                  }
                ></AddressForm>
              </Box>
            </Paper>

            <AliceCardBalanceDisplay aliceCardAccount={activeAliceCardAccount} />
          </>
        )}
        <Box minWidth={270} maxWidth={340} my={2} mx="auto">
          {spendingConnectionIncomplete || !aliceCardFundingConnected ? (
            <Button
              variant={bankingConnections.length === 0 ? 'contained' : 'outlined'}
              color="primary"
              fullWidth
              component={Link}
              to="/cards/personal"
            >
              <FormattedMessage id="cards.personal.connect_cards_label" />
            </Button>
          ) : (
            <Button variant="contained" color="primary" fullWidth component={Link} to={completeLink}>
              <FormattedMessage id="common.done" />
            </Button>
          )}
        </Box>
      </Container>

      <Box maxWidth={400} marginY={5} marginX="auto">
        <Typography gutterBottom>
          <Button
            component={ExternalLink}
            target="_blank"
            href="https://www.thisisalice.com/privacy-policy/"
            fullWidth
          >
            <FormattedMessage id="common.learn_more_about_security" />
          </Button>
        </Typography>
      </Box>
    </PageBody>
  )
}
