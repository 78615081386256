import * as React from 'react'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { InlineMaskedInput, InlineMaskedInputProps } from './InlineMaskedInput'

const _InlineTelInput = (props: InlineMaskedInputProps, ref: React.Ref<HTMLInputElement>) => (
  <InlineMaskedInput
    inputRef={ref}
    format="+1 (###) ###-####"
    mask="_"
    isNumericString
    placeholder="+1 (555) 555-5555"
    initialWidth="9em"
    {...props}
    type="tel"
  />
)
export const InlineTelInput = React.forwardRef(_InlineTelInput)

type InlineTelInputControllerProps<T extends FieldValues> = Omit<InlineMaskedInputProps, 'name' | 'variant'> &
  Pick<ControllerProps<T>, 'name' | 'control' | 'rules'>
export const InlineTelInputController = <T extends FieldValues>({
  name,
  control,
  rules,
  ...props
}: InlineTelInputControllerProps<T>) => (
  <Controller
    render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
      const isError = Boolean((isTouched || submitCount > 0) && error?.message)
      return (
        <InlineTelInput
          {...props}
          {...field}
          error={isError}
          helperText={(isError && error?.message) || props.helperText}
        />
      )
    }}
    name={name}
    control={control}
    rules={rules}
  />
)
