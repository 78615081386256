type NamedPerson = { firstName?: string | null; lastName?: string | null; preferredName?: string | null }

const CAPTIAL_LETTER_RE = /[A-Z]/

export const getInitials = ({ firstName, lastName, preferredName }: NamedPerson) => {
  const firstInitial = (preferredName || firstName || '')[0] || ''
  const matchFirstCapitalLetterInSurname = CAPTIAL_LETTER_RE.exec(lastName || '')
  const lastNameCapitalInitial = matchFirstCapitalLetterInSurname ? matchFirstCapitalLetterInSurname[0] : '' // this doesn't handle surnames like 'de la Rosa' or 'van der Waals' very well
  const lastNameFirstLetter = lastName ? lastName[0] : ''
  return `${firstInitial}${lastNameCapitalInitial || lastNameFirstLetter}`.toUpperCase()
}

export const getFullName = ({ firstName, lastName, preferredName }: NamedPerson) => {
  return `${preferredName || firstName || ''} ${lastName || ''}`
}
