import { ApiError, formatParamMessage } from '../responseErrors'
import { ApiBadInputResponse } from './types'

export const isBadInput = (resBody?: unknown): resBody is ApiBadInputResponse =>
  Array.isArray(resBody) && resBody.length > 0 && Boolean(resBody[0].params && resBody[0].messages)

export class RestInputErrorException extends ApiError {
  inputErrors: ApiBadInputResponse
  constructor(inputErrors: ApiBadInputResponse, pathname: string) {
    const message = inputErrors
      .map(({ params, messages }) => formatParamMessage([params.join(', '), messages.join(', ')]))
      .join(', ')
    super(message, pathname)
    this.inputErrors = inputErrors
  }
}
