import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum'

import { USER_ID_PARAM } from '../app/telemetry/TrackUserId'
// @ts-expect-error - build-version needs to be a JS file, not TS, so we expect undefined exports
import { getVersion } from '../../scripts/build-version'

const env = process.env.APP_ENV || 'local'

export const initDataDog = () => {
  const initialUrl = new URL(window.location.href)
  const initialUserId = initialUrl.searchParams.get(USER_ID_PARAM)
  if (process.env.NODE_ENV === 'test') return
  if (env === 'local') return // comment out this line to enable local debugging of the DataDog agent itself

  datadogRum.init({
    applicationId: 'a9706d7a-1638-471c-8100-a1b4aa378f08',
    clientToken: 'pub2a8ec5e224bf55224db66f534447bd25',
    site: 'datadoghq.com',
    service: 'user-web',
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: getVersion(),
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackViewsManually: true, // views will be tracked by custom view tracker
    defaultPrivacyLevel: ['staging', 'development'].includes(env)
      ? DefaultPrivacyLevel.ALLOW
      : DefaultPrivacyLevel.MASK_USER_INPUT, // mask all user input with asterisks
  })

  if (initialUserId) {
    datadogRum.setUser({ id: initialUserId })
  }

  datadogRum.startSessionReplayRecording() // this could be triggered only for logged-in users in the future
}
