// List of mapping codes from https://developer.tryfinch.com/integrations/providers
// (keys are for the platform names in our system and values are the Finch codes).
// Using `as const` to make TS know this is an unchanging const object so that
// it can infer the object exact values when using `ValueOf` below.
// These are the platforms that
// a) as of today support rosters and benefits pulling via Finch (see https://developer.tryfinch.com/integrations/providers)
// b) we don't have a custom integration for (except Paylocity)
export const PAYROLL_PLATFORM_FINCH_CODES = {
  adp_run: 'adp_run',
  gusto: 'gusto',
  paycom: 'paycom',
  // (not one we're planning to migrate to Finch right now actually, since we have our own integration, but just in case we get better Paylocity pricing via Finch soon)
  paylocity: 'paylocity',
  adp_workforce: 'adp_workforce_now',
} as const

type ValueOf<T> = T[keyof T]
export type PayrollPlatformFinchCode = ValueOf<typeof PAYROLL_PLATFORM_FINCH_CODES>
export type PayrollPlatformAliceCode = keyof typeof PAYROLL_PLATFORM_FINCH_CODES
