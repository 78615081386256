import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type PayrollInstructionsCompleteMutationVariables = Types.Exact<{
  input: Types.PayrollInstructionsCompleteInput
}>

export type PayrollInstructionsCompleteMutation = {
  __typename?: 'Mutation'
  payrollInstructionsComplete?: {
    __typename?: 'PayrollInstructionsCompletePayload'
    organization?: {
      __typename?: 'Organization'
      id: number
      payrollConnection: { __typename?: 'PayrollConnection'; payrollInstructionsCompletedAt?: string | null }
    } | null
  } | null
}

export const PayrollInstructionsCompleteDocument = `
    mutation PayrollInstructionsComplete($input: PayrollInstructionsCompleteInput!) {
  payrollInstructionsComplete(input: $input) {
    organization {
      id
      payrollConnection {
        payrollInstructionsCompletedAt
      }
    }
  }
}
    `

export const usePayrollInstructionsCompleteMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    PayrollInstructionsCompleteMutation,
    TError,
    PayrollInstructionsCompleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    PayrollInstructionsCompleteMutation,
    TError,
    PayrollInstructionsCompleteMutationVariables,
    TContext
  >(
    ['PayrollInstructionsComplete'],
    (variables?: PayrollInstructionsCompleteMutationVariables) =>
      fetchGql<PayrollInstructionsCompleteMutation, PayrollInstructionsCompleteMutationVariables>(
        PayrollInstructionsCompleteDocument,
        variables
      )(),
    options
  )
}
