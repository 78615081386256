import { Container, InfoDrawer, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEnrollmentEmployer } from '../../../enrollment/useEnrollmentEmployer'
import { ContactSupport } from '../../ContactSupport'
import { useEmployeeQuery } from '../../gql/employee_gen'
import { CommuterList } from '../../spending/EligibleExpensesInfo'

const ANCHOR_NAME = 'alice-card-info'

const InfoContent = () => {
  const { data: employerData } = useEnrollmentEmployer()
  const { data: employeeData } = useEmployeeQuery()
  const employer = employeeData?.employee?.employer || employerData?.enrollmentEmployer
  const companyName = employer?.marketingFriendlyName

  return (
    <>
      <Container sx={{ mt: 4 }}>
        <Typography variant="h3" gutterBottom>
          <FormattedMessage id="cards.alice.info.how_save.heading" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="cards.alice.info.how_save.explanation" />
        </Typography>
        <Typography variant="h3" gutterBottom>
          <FormattedMessage id="cards.alice.info.where_to_use.heading" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="cards.alice.info.where_to_use.explanation" />
        </Typography>
        <CommuterList />
        <Typography variant="h3" gutterBottom>
          <FormattedMessage id="cards.alice.info.who_can_use.heading" />
        </Typography>
        <ul>
          <Typography component="li">
            <FormattedMessage id="cards.alice.info.who_can_use.explanation.1" values={{ companyName }} />
          </Typography>
          <Typography component="li">
            <FormattedMessage id="cards.alice.info.who_can_use.explanation.2" />
          </Typography>
        </ul>
        <Typography variant="h3" gutterBottom>
          <FormattedMessage id="cards.alice.info.how_to_use.heading" />
        </Typography>
        <ul>
          <Typography component="li">
            <FormattedMessage id="cards.alice.info.how_to_use.explanation.1" />
          </Typography>
          <Typography component="li">
            <FormattedMessage id="cards.alice.info.how_to_use.explanation.2" />
          </Typography>
        </ul>
      </Container>
      <ContactSupport />
    </>
  )
}

type AliceCardInfoProps = {
  children: React.ReactNode
}
export const AliceCardInfo = ({ children }: AliceCardInfoProps) => {
  return (
    <InfoDrawer anchorName={ANCHOR_NAME} infoContent={<InfoContent />}>
      {children}
    </InfoDrawer>
  )
}
