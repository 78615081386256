import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateOrgAdminMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeInput
}>

export type CreateOrgAdminMutation = {
  __typename?: 'Mutation'
  createEmployee?: {
    __typename?: 'CreateEmployeePayload'
    employee?: {
      __typename?: 'Employee'
      id: number
      user: { __typename?: 'User'; id: number; firstName?: string | null; lastName?: string | null }
    } | null
  } | null
}

export const CreateOrgAdminDocument = `
    mutation CreateOrgAdmin($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    employee {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
}
    `

export const useCreateOrgAdminMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<CreateOrgAdminMutation, TError, CreateOrgAdminMutationVariables, TContext>
) => {
  return useMutation<CreateOrgAdminMutation, TError, CreateOrgAdminMutationVariables, TContext>(
    ['CreateOrgAdmin'],
    (variables?: CreateOrgAdminMutationVariables) =>
      fetchGql<CreateOrgAdminMutation, CreateOrgAdminMutationVariables>(CreateOrgAdminDocument, variables)(),
    options
  )
}
