import * as Types from '../generated.types'

export type AddressFragment = {
  __typename?: 'AddressDisplay'
  isVerified: boolean
  isMailing: boolean
  line1: string
  line2?: string | null
  cityName: string
  stateCode: string
  zipcode: string
}

export const AddressFragmentDoc = `
    fragment Address on AddressDisplay {
  isVerified
  isMailing
  line1
  line2
  cityName
  stateCode
  zipcode
}
    `
