import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { EmailPreferencesInput } from '../../graphql/generated.types'
import { AdminEmployeeFragment, useAdminEmployeeProfileQuery } from './gql/adminEmployeeProfile_gen'
import {
  UpdateEmailPreferencesMutation,
  useUpdateEmailPreferencesMutation,
} from './gql/updateEmailPreferences_gen'

const emailPrefValuesToInput = (
  emailPreferences: AdminEmployeeFragment['emailPreferences']
): EmailPreferencesInput => {
  return {
    monthly_finance: emailPreferences.monthlyFinance,
    per_invoice: emailPreferences.perInvoice,
    per_pba_withdrawal: emailPreferences.perPbaWithdrawal,
  }
}

type UseUpdateEmailPreferencesOptions = UseGqlMutationCallbacks<
  UpdateEmailPreferencesMutation,
  AdminEmployeeFragment['emailPreferences']
>
export const useUpdateEmailPreferences = (
  employeeId: number,
  mutationOptions?: UseUpdateEmailPreferencesOptions
) => {
  const optionsWithNotifs = useMutationNotifier({}, mutationOptions)
  const optionsWithInvalidation = useMutationOptionsWithInvalidation(optionsWithNotifs, [
    useAdminEmployeeProfileQuery.getKey(),
  ])
  const { mutateAsync } = useUpdateEmailPreferencesMutation()
  return useMutation(
    ['updateEmailPreferences', employeeId],
    (values) => mutateAsync({ employeeId, emailPreferences: emailPrefValuesToInput(values) }),
    optionsWithInvalidation
  )
}
