import { useSearchParams } from 'react-router-dom'

type UseFilterOptions<TValue extends string> = {
  typeGuard: (value: string) => value is TValue
  override?: TValue
}
/**
 * Create typesafe querystring-based filter value state. The value is treated as a CSV.
 * This hook will return the current value of the querystring filter as a list of values
 * and a function to update the filter value.
 */
export const useFilter = <TValue extends string>(
  param: string,
  { typeGuard, override }: UseFilterOptions<TValue>
): [TValue | undefined, (newValue: TValue | undefined) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  // override will fix to a particular value and prevent updates
  if (override) return [override, (_: TValue | undefined) => undefined] as const

  const paramValue = searchParams.get(param) || ''
  const filterValue = typeGuard(paramValue) ? paramValue : undefined
  const updateParams = (newFilterValue: TValue | undefined) => {
    if (newFilterValue) {
      searchParams.set(param, newFilterValue.trim())
    } else {
      searchParams.delete(param)
    }
    setSearchParams(searchParams)
  }
  return [filterValue, updateParams] as const
}
