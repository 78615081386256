import { Box, Divider, ExternalLink, Typography, useResponseNotification } from '@alice-financial/pretext-ui'

import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHelpScout } from '../auth/helpScout/beacon'
import { useAnalytics } from '../telemetry/AnalyticsProvider'

export const useGetHelp = () => {
  const intl = useIntl()
  const analytics = useAnalytics()
  const helpScoutBeacon = useHelpScout()
  const { notifyError } = useResponseNotification()
  return {
    openHelp: () => {
      analytics.track('contact_support.open')
      if (!helpScoutBeacon) {
        notifyError(intl.formatMessage({ id: 'contact_support.contact.in_app_unavailable' }))
        return
      }
      helpScoutBeacon('open')
    },
  }
}

export const ContactSupport = () => {
  return (
    <>
      <Box mt={5} mb={4} component={Divider} />
      <Box textAlign="center">
        <Typography variant="body2" gutterBottom>
          <FormattedMessage id="contact_support.contact.label" />
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body2">
            <FormattedMessage id="contact_support.text_us_at" />
            &nbsp;
          </Typography>
          <Typography variant="caption" color="text.secondary" component="p">
            <ExternalLink href="sms:+18884314355">(888) 431-4355</ExternalLink>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body2">
            <FormattedMessage id="contact_support.email_us_at" />
            &nbsp;
          </Typography>
          <Typography variant="caption" color="text.secondary" component="p">
            <ExternalLink href="mailto:support@thisisalice.com">support@thisisalice.com</ExternalLink>
          </Typography>
        </Box>
      </Box>
    </>
  )
}
