import { Divider, List, ListItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { BankableTypeEnum } from '../../../graphql/generated.types'
import { EntityFunding } from './EntityFunding'

type OrganizationFundingProps = {
  org: OrganizationDetailFragment
  readonly?: boolean
}
export const OrganizationFunding = ({ org, readonly }: OrganizationFundingProps) => {
  return (
    <>
      <Divider sx={{ my: 1 }} />
      <List>
        <ListItem disableGutters disablePadding>
          <EntityFunding entity={{ type: BankableTypeEnum.Org, ...org }} readonly={readonly} />
        </ListItem>
      </List>
    </>
  )
}
