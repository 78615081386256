import { ApiRootException } from '@alice-financial/api'
import { Typography } from '@mui/material'
import * as React from 'react'
import { FieldErrors, FieldValues, UseFormSetError } from 'react-hook-form'

/**
 * When the API returns an error response, we need to inject that error into the form state managed
 * by react-hook-form (useForm). This function receives the `setError` method from `useForm`, and returns
 * callback that can be passed to a mutation hook's `onError` option.
 *
 * The errors that are handled by this callback are only top-level ApiRootException instances - other
 * errors (usually field-level input errors) should be handled separately, e.g. by `getGqlInputErrorHandler`.
 *
 * @example
 *
 * // when all caught errors should be injected into the `root.serverError` field
 * const MyCoolForm = () => {
 *   const { handleSubmit, setError } = useForm()
 *   const serverErrorHandler = getServerErrorHandler(setError)
 *   const { mutate: postValues } = usePostValuesMutation({ onError: serverErrorHandler })
 *   const onSubmit = handleSubmit(values => postValues(values))
 *
 *   return <form onSubmit={onSubmit}>...</form>
 * }
 *
 * // or
 *
 * // when general BE errors should be set on multiple fields
 * const MyCoolForm = () => {
 *   const { handleSubmit, setError, register } = useForm()
 *   const serverErrorHandler = getServerErrorHandler(setError, ['firstName', 'lastName'])
 *   const { mutate: postValues } = usePostValuesMutation({ onError: serverErrorHandler })
 *   const onSubmit = handleSubmit(values => postValues(values))
 *
 *   return (
 *     <form onSubmit={onSubmit}>
 *      <input {...register('firstName')} />
 *      <input {...register('lastName')} />
 *     </form>
 *   )
 * }
 */
export const getServerErrorHandler =
  <TFieldValues extends FieldValues>(
    setError: UseFormSetError<TFieldValues>,
    fieldnames: Array<Parameters<typeof setError>[0]> = ['root.serverError']
  ) =>
  (err: Error) => {
    if (!(err instanceof ApiRootException)) return
    fieldnames.forEach((field) =>
      setError(field, { type: 'server', message: err.message || 'Please review' })
    )
  }
export const ServerError = <TFieldValues extends FieldValues>({
  errors,
}: {
  errors: FieldErrors<TFieldValues>
}) => {
  if (!errors.root?.serverError) return null
  return <Typography color="error">{errors.root.serverError.message}</Typography>
}
