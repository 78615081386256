import { Button, Container, FullDialog, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployeeAccountStatusOverride } from '../../graphql/generated.types'
import { EmployeeRow } from './types'
import { useUpdateEmployees } from './updateEmployees'

type OptInConfirmationProps = {
  open?: boolean
  onClose: () => void
  employees: Array<EmployeeRow>
}
export const OptInConfirmation = ({ open = false, onClose, employees }: OptInConfirmationProps) => {
  const employeeIds = employees.map((e) => e.id)
  const { mutate: updateEmployees } = useUpdateEmployees(employeeIds, { onSuccess: onClose })
  const onConfirm = () => updateEmployees({ accountStatus: EmployeeAccountStatusOverride.Suspended })

  const employeeLabel = employees.length > 1 ? 'employees' : 'employee'

  return (
    <FullDialog
      open={open}
      title={<Typography variant="h2">Confirm opt in (enable benefits)</Typography>}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Confirm re-enabling benefits {employees.length > 1 && `for ${employees.length} employees`}
          </Button>
        </>
      }
    >
      <Container>
        <Typography variant="body2" gutterBottom>
          You&apos;re about to opt-in the selected {employees.length > 1 ? 'employees' : 'employee'}.
        </Typography>
        <Typography variant="body2" gutterBottom>
          The {employeeLabel} will temporarily transition to the <em>Suspended</em> status while our system
          looks up their latest data from Payroll and assigns them the correct status.
        </Typography>
        <Typography variant="body2" gutterBottom>
          If the {employeeLabel} {employees.length > 1 ? 'are' : 'is'} found active in Payroll, they&apos;ll
          be able to use Alice.
        </Typography>
      </Container>
    </FullDialog>
  )
}
