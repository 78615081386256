import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps
} from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import * as React from 'react'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

/**
 * Apologies for the excessive generics arguments in this file - essentially we
 * have to define a wrapper around a wrapper of Autocomplete, and each layer has
 * to pass through the 4 generic arguments required by MUI's Autocomplete -
 * it's ugly but necessary to match the expected interface of the underlying component
 */

type CustomAutocompleteProps = {
  initialWidth?: CSSProperties['width']
}
type AutocompleteProps<
  TOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo> &
  CustomAutocompleteProps

// this must be a named function in order to pass into `React.forwardRef` without
// `missing displayName` warning
const _Autocomplete = <
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  {
    initialWidth = '8rem',
    ...props
  }: AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<unknown>
) => (
  <MuiAutocomplete<TOption, Multiple, DisableClearable, FreeSolo>
    sx={{
      '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
        width: initialWidth
      }
    }}
    ref={ref}
    {...props}
  />
)

/**
 * Small wrapper around MuiAutocomplete that applies an initialWidth style,
 * commonly used to set the width of the autocomplete to suit the width
 * of the items in the option list
 */
export const Autocomplete = React.forwardRef(_Autocomplete)

type AutocompleteControllerProps<
  TFieldValues extends FieldValues,
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo> &
  Pick<ControllerProps<TFieldValues>, 'control' | 'name' | 'rules'>

export const AutocompleteController = <
  TFieldValues extends FieldValues,
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  control,
  name,
  rules,
  ...props
}: AutocompleteControllerProps<
  TFieldValues,
  TOption,
  Multiple,
  DisableClearable,
  FreeSolo
>) => {
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete<TOption, Multiple, DisableClearable, FreeSolo>
          isOptionEqualToValue={
            (option, value) =>
              // for some reason the selected option and current value never pass a reference
              // equality check (===), so default to a quick and dirty deep equality check
              // - override if needed by passing in `isOptionEqualToValue` prop
              JSON.stringify(option) === JSON.stringify(value) // contro
          }
          {...props}
          {...field}
          onChange={(_e, value) => field.onChange(value)}
        />
      )}
      name={name}
      control={control}
      rules={rules}
    />
  )
}
