import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import {
  RegisterEmployeeMutation,
  RegisterEmployeeMutationVariables,
  useRegisterEmployeeMutation,
} from './gql/registerEmployee_gen'

export type UseRegisterEmployeeOptions = UseGqlMutationCallbacks<
  RegisterEmployeeMutation,
  RegisterEmployeeMutationVariables
>
export const useRegisterEmployee = (mutationOptions?: UseRegisterEmployeeOptions) =>
  useRegisterEmployeeMutation(useMutationOptionsWithInvalidation(mutationOptions, 'all'))
