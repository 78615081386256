import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EmployerDetailQueryVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>
  inviteCode?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type EmployerDetailQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    payrollConnection: {
      __typename?: 'PayrollConnection'
      isFinchEnabled: boolean
      payrollPlatform?: { __typename?: 'PayrollPlatform'; code: string } | null
    }
    employer?: {
      __typename?: 'Employer'
      id: number
      marketingFriendlyName?: string | null
      inviteCode: string
      name?: string | null
      paygroupConfig?: {
        __typename?: 'PaygroupConfig'
        finchConnection?: { __typename?: 'FinchConnection'; id: number; isActive: boolean } | null
      } | null
    } | null
  } | null
}

export type EmployerDetailFragment = {
  __typename?: 'Employer'
  id: number
  marketingFriendlyName?: string | null
  inviteCode: string
  name?: string | null
  paygroupConfig?: {
    __typename?: 'PaygroupConfig'
    finchConnection?: { __typename?: 'FinchConnection'; id: number; isActive: boolean } | null
  } | null
}

export type EmployeeSummaryFragment = {
  __typename?: 'Employee'
  id: number
  user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; email?: string | null }
}

export const EmployerDetailFragmentDoc = `
    fragment EmployerDetail on Employer {
  id
  marketingFriendlyName
  inviteCode
  name
  paygroupConfig {
    finchConnection {
      id
      isActive
    }
  }
}
    `
export const EmployeeSummaryFragmentDoc = `
    fragment EmployeeSummary on Employee {
  id
  user {
    firstName
    lastName
    email
  }
}
    `
export const EmployerDetailDocument = `
    query EmployerDetail($orgId: Int!, $id: Int, $inviteCode: String) {
  organization(id: $orgId) {
    id
    payrollConnection {
      payrollPlatform {
        code
      }
      isFinchEnabled
    }
    employer(id: $id, inviteCode: $inviteCode) {
      ...EmployerDetail
    }
  }
}
    ${EmployerDetailFragmentDoc}`

export const useEmployerDetailQuery = <TData = EmployerDetailQuery, TError = Error>(
  variables: EmployerDetailQueryVariables,
  options?: UseQueryOptions<EmployerDetailQuery, TError, TData>
) => {
  return useQuery<EmployerDetailQuery, TError, TData>(
    ['EmployerDetail', variables],
    fetchGql<EmployerDetailQuery, EmployerDetailQueryVariables>(EmployerDetailDocument, variables),
    options
  )
}

useEmployerDetailQuery.getKey = (variables: EmployerDetailQueryVariables) => ['EmployerDetail', variables]
