import { UseGqlMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../../../utils/useMutationNotifier'
import { CreateFinicityConnectUrlInput } from '../../../../graphql/generated.types'
import { useEmployeeQuery } from '../../../gql/employee_gen'
import { useCreateFinicityConnectUrlMutation } from './gql/createFinicityConnectUrl_gen'

type UseCreateFinicityConnectUrlOptions = UseGqlMutationCallbacks<
  string,
  Omit<CreateFinicityConnectUrlInput, 'employeeId'>
>

export const useCreateFinicityConnectUrl = (mutationOptions?: UseCreateFinicityConnectUrlOptions) => {
  const { data: employeeData } = useEmployeeQuery()
  const employeeId = employeeData?.employee?.id
  const { mutateAsync: createFinicityConnectUrl } = useCreateFinicityConnectUrlMutation()
  const optionsWithNotification = useMutationNotifier({}, mutationOptions)

  return useMutation(({ institutionPlatformId }) => {
    if (!employeeId) throw new Error('Employee not found')
    return createFinicityConnectUrl({ input: { employeeId, institutionPlatformId } }).then((response) => {
      const connectUrl = response.createFinicityConnectUrl?.connectUrl
      if (!connectUrl) throw new Error('Failed to create Finicity Connect URL')
      return connectUrl
    })
  }, optionsWithNotification)
}
