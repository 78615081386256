import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ReenrollEmployeeMutationVariables = Types.Exact<{
  input: Types.ReenrollEmployeeInput
}>

export type ReenrollEmployeeMutation = {
  __typename?: 'Mutation'
  reenrollEmployee?: { __typename?: 'ReenrollEmployeePayload'; success: boolean } | null
}

export const ReenrollEmployeeDocument = `
    mutation ReenrollEmployee($input: ReenrollEmployeeInput!) {
  reenrollEmployee(input: $input) {
    success
  }
}
    `

export const useReenrollEmployeeMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<ReenrollEmployeeMutation, TError, ReenrollEmployeeMutationVariables, TContext>
) => {
  return useMutation<ReenrollEmployeeMutation, TError, ReenrollEmployeeMutationVariables, TContext>(
    ['ReenrollEmployee'],
    (variables?: ReenrollEmployeeMutationVariables) =>
      fetchGql<ReenrollEmployeeMutation, ReenrollEmployeeMutationVariables>(
        ReenrollEmployeeDocument,
        variables
      )(),
    options
  )
}
