import * as Types from '../generated.types'

import { BankingAccountFragmentDoc } from './BankingAccountFragment_gen'
import { ErrorFragmentDoc } from './ErrorFragment_gen'
export type BankingTransactionFragment = {
  __typename?: 'BankingTransaction'
  id: string
  amount: number
  date: string
  estimatedSavings: number
  name: string
  merchantName?: string | null
  spendEligibility: {
    __typename?: 'SpendEligibility'
    confirmedPartialAmount?: number | null
    pretaxCategory: Types.PretaxCategory
    autoConfirmedAt?: string | null
    isDisconfirmable?: boolean | null
    confirmationStatus: {
      __typename?: 'ConfirmationStatus'
      updatedAt?: string | null
      value: Types.ConfirmationStatusValue
    }
  }
  account:
    | {
        __typename: 'BankingAccount'
        id: number
        billingSystemId?: string | null
        name?: string | null
        cardType?: Types.BankCardType | null
        accountType?: Types.BankingAccountType | null
        platform: Types.BankingAccountPlatform
        accountLast4?: string | null
        error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
      }
    | { __typename: 'CashPaymentAccount' }
  reimbursementClaim?: {
    __typename?: 'ReimbursementClaim'
    approvalStatus: Types.ReimbursementClaimApprovalStatus
    reviewedAt?: string | null
  } | null
  reimbursementStatus?: { __typename?: 'ReimbursementStatus'; isFullyReimbursed: boolean } | null
}

export type ReimbursementClaimFragment = {
  __typename?: 'ReimbursementClaim'
  approvalStatus: Types.ReimbursementClaimApprovalStatus
  reviewedAt?: string | null
}

export const ReimbursementClaimFragmentDoc = `
    fragment ReimbursementClaim on ReimbursementClaim {
  approvalStatus
  reviewedAt
}
    `
export const BankingTransactionFragmentDoc = `
    fragment BankingTransaction on BankingTransaction {
  id
  amount
  date
  estimatedSavings
  name
  merchantName
  spendEligibility {
    confirmationStatus {
      updatedAt
      value
    }
    confirmedPartialAmount
    pretaxCategory
    autoConfirmedAt
    isDisconfirmable
  }
  account {
    __typename
    ...BankingAccount
  }
  reimbursementClaim {
    ...ReimbursementClaim
  }
  reimbursementStatus {
    isFullyReimbursed
  }
}
    `
