import {
  AliceCardFundingFragment,
  PaymentMethodConnectionFragment,
  PaymentMethodFragment,
} from '../../../graphql/fragments/OrganizationFragment_gen'
import { PaymentMethodStatus } from '../../../graphql/generated.types'

export const isCurrentPaymentMethodConnection = (pmc: PaymentMethodConnectionFragment) => pmc.isCurrent

export const getCurrentPaymentMethodConnection = (aliceCardFunding: AliceCardFundingFragment) =>
  (aliceCardFunding.paymentMethodConnections || []).find(isCurrentPaymentMethodConnection)

export const isPaymentMethodActive = (pmc: PaymentMethodConnectionFragment | null | undefined) =>
  Boolean(pmc && pmc.paymentMethod.status === PaymentMethodStatus.Active)

export const isConnected = (aliceCardFunding: AliceCardFundingFragment) =>
  getCurrentPaymentMethodConnection(aliceCardFunding)?.paymentMethod.status === PaymentMethodStatus.Active

const CONNECTABLE_STATUSES = [PaymentMethodStatus.Active, PaymentMethodStatus.PendingVerification]
export const isConnectable =
  (currentPaymentMethod: PaymentMethodFragment | undefined) => (paymentMethod: PaymentMethodFragment) =>
    currentPaymentMethod?.id !== paymentMethod.id && CONNECTABLE_STATUSES.includes(paymentMethod.status)
