import { useParams } from 'react-router'

export const useOptionalOrgId = (): number | undefined => {
  const { orgId } = useParams()
  return orgId ? parseInt(orgId) : undefined
}

export const useOrgId = (): number => {
  const orgId = useOptionalOrgId()
  if (!orgId) throw new Error('orgId parameter is required')
  return orgId
}
