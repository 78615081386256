import { Box, Button, FullDialog, Typography } from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocationForm } from './WorkLocationForm'

type ManageWorkLocationProps = {
  org: OrganizationDetailFragment
}

export const ManageWorkLocation: React.FC<ManageWorkLocationProps> = ({ org }) => {
  const [open, setOpen] = React.useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant={'contained'}
        color="primary"
        size="small"
        endIcon={<AddIcon />}
        sx={{ textWrap: 'nowrap' }}
        onClick={onOpen}
        disabled={open}
      >
        Add location
      </Button>
      <FullDialog open={open} onClose={onClose} title={<Typography variant="h2">Add location</Typography>}>
        <Box p={2}>
          <WorkLocationForm org={org} onSuccess={onClose} />
        </Box>
      </FullDialog>
    </>
  )
}
