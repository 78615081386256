import { Box, Button, Grid, Link, styled, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { TrackingEvent, useAnalytics } from '../../telemetry/AnalyticsProvider'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { useEnrollmentEmployer } from '../useEnrollmentEmployer'

const StartButton = () => {
  const analytics = useAnalytics()
  const { data: employerData } = useEnrollmentEmployer()
  const employer = employerData?.enrollmentEmployer

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      disabled={!employer}
      sx={{ maxWidth: '12em' }}
      component={Link}
      to="register"
      onClick={() => analytics.track(TrackingEvent.EnrollmentStart)}
      data-testid="start-enrollment"
      data-dd-action-name="start-enrollment"
    >
      <FormattedMessage id="common.start_enrollment" />
    </Button>
  )
}

const ButtonLayout = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    display: 'flex',
    boxShadow: 'none',
  },
}))

export const StartBar = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser

  return (
    <ButtonLayout>
      <Grid container justifyContent="center" alignItems="center" spacing={1} width="100%">
        {!user && (
          <Grid item flexGrow={1} xs={7} sm={12}>
            <Typography variant="caption" component="p" textAlign="center" fontSize="0.9rem">
              <FormattedMessage id="enroll.register.if_account_then_sign_in" />
            </Typography>
          </Grid>
        )}
        <Grid item xs={5} sm={12} textAlign="center">
          <StartButton />
        </Grid>
      </Grid>
    </ButtonLayout>
  )
}
