import { fetchCsrf } from '@alice-financial/api'
import { initDataDog } from './datadog'

/**
 * All global app initialization side effects should go in this function, which
 * should be called before the application itself starts or is rendered.
 *
 * Examples:
 * - third party script initialization
 * - global event listeners that are independent of the lifetime of the application
 */
export const initGlobals = () => {
  initDataDog() // user session analytics, error tracking
  fetchCsrf() // get a fresh CSRF token on startup - this doesn't block rendering but will likely resolve before first render
}
