import {
  DataGrid,
  DataGridProps,
  ExternalLink,
  GridPaginationModel,
  GridRenderCellParams,
  GridValidRowModel,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedNumber } from 'react-intl'
import { Navigate } from 'react-router'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { useOrg } from '../organization/useOrg'
import { PayGroupPayPeriod, PayPeriodPayGroup, usePayGroupPayPeriodsQuery } from './usePayrollReportPeriods'

export interface PayPeriodRow extends GridValidRowModel {
  payday: string
  start_date: string
  end_date: string
  payroll_deadline: string
  period: string
  deductions: number
  reports: Array<PayPeriodPayGroup>
}
const payGroupLabel = (payGroup: PayPeriodPayGroup) => {
  if (payGroup.employee_count > 0) {
    return payGroup.name
  }
  return `${payGroup.name} (n/a)`
}

const reportUrl = (payPeriod: PayPeriodRow, paygroupId: number) => {
  const { payday, start_date, end_date, payroll_deadline } = payPeriod
  const params = new URLSearchParams({
    payday,
    start_date,
    end_date,
    payroll_deadline,
    pay_group_id: paygroupId.toString(),
  })
  return `/employer/organizations/download_payroll_report?${params}`
}
const PayGroupCell = ({
  payPeriod,
  payGroups,
}: {
  payPeriod: PayPeriodRow
  payGroups: Array<PayPeriodPayGroup> | undefined
}) => {
  if (!payGroups) return null
  return (
    <Typography
      variant="caption"
      component="ul"
      lineHeight="inherit"
      sx={{ listStyleType: 'none', paddingInlineStart: 0 }}
    >
      {payGroups.map((payGroup) => (
        <li key={payGroup.id}>
          <ExternalLink href={reportUrl(payPeriod, payGroup.id)}>{payGroupLabel(payGroup)}</ExternalLink>
        </li>
      ))}
    </Typography>
  )
}

const payPeriodRow = (payGroupPayPeriod: PayGroupPayPeriod): PayPeriodRow => {
  return {
    payday: payGroupPayPeriod.payday,
    start_date: payGroupPayPeriod.start_date,
    end_date: payGroupPayPeriod.end_date,
    payroll_deadline: payGroupPayPeriod.payroll_deadline,
    period: `${dateStringFormat.short(payGroupPayPeriod.start_date)} - ${dateStringFormat.short(payGroupPayPeriod.end_date)}`,
    deductions: payGroupPayPeriod.employee_deductions_dollars,
    reports: payGroupPayPeriod.pay_groups,
  }
}
const usePayrollReportTable = (): Partial<DataGridProps> => {
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({ pageSize: 10, page: 0 })
  const { data: payrollReportPeriodsData, isLoading } = usePayGroupPayPeriodsQuery(paginationModel)
  const countFromApi = payrollReportPeriodsData?.count
  const rowCountRef = React.useRef(countFromApi || 0)
  const rowCount = React.useMemo(() => {
    if (countFromApi !== undefined) {
      rowCountRef.current = countFromApi
    }
    return rowCountRef.current
  }, [countFromApi])
  const rows = payrollReportPeriodsData?.pay_periods.map(payPeriodRow) || []
  const onPaginationModelChange = setPaginationModel

  return {
    rows,
    loading: isLoading,
    paginationModel,
    onPaginationModelChange,
    rowCount,
    getRowId: (row: PayPeriodRow) => `${row.payday}${row.start_date}${row.end_date}${row.payroll_deadline}`,
  }
}

const columns = [
  {
    field: 'payday',
    headerName: 'Pay date',
    sortable: false,
    filterable: false,
    width: 150,
    renderCell: (params: GridRenderCellParams<PayPeriodRow, string>) =>
      params.value && dateStringFormat.short(params.value),
  },
  {
    field: 'period',
    headerName: 'Pay period',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'deductions',
    headerName: 'Deductions',
    sortable: false,
    filterable: false,
    width: 150,
    renderCell: (params: GridRenderCellParams<PayPeriodRow, number>) => (
      <FormattedNumber style="currency" currency="USD" value={params.value || 0} />
    ),
  },
  {
    field: 'reports',
    headerName: 'Report',
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<PayPeriodRow, Array<PayPeriodPayGroup>>) => (
      <PayGroupCell payPeriod={params.row} payGroups={params.value} />
    ),
  },
]

/**
 * Payroll reports are only relevant for particular payroll platforms that
 * are not sufficiently automated. Only display nav when the platform permits
 */
export const PayrollReportsPage = () => {
  const dataProps = usePayrollReportTable()
  const org = useOrg()
  if (!org?.payrollConnection.payrollPlatform?.permitsAdminReports) return <Navigate to="/manage" />

  return (
    <PageBody maxWidth={800}>
      <DataGrid
        autoHeight
        density="compact"
        paginationMode="server"
        {...dataProps}
        columns={columns}
        disableColumnMenu
        pageSizeOptions={[10, 20, 50]}
        slotProps={{ pagination: { SelectProps: { native: true } } }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />
    </PageBody>
  )
}
