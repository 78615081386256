import { Box, Container, styled } from '@alice-financial/pretext-ui'
import * as React from 'react'

const DocRoot = styled(Box)(({ theme }) => ({
  '& h1': {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    lineHeight: 1.1,
    fontSize: '1.4rem',
  },
  '& ol': {
    padding: 0,
    counterReset: 'item',
    '& > li': {
      counterIncrement: 'item',
      '&::marker': {
        fontWeight: 'bold',
      },
    },
    '& ol': {
      padding: '0 0 0 2rem',
      '& > li': {
        display: 'block',
        '&:before': {
          float: 'left',
          marginLeft: '-3.3rem',
          width: '3rem',
          display: 'block',
          textAlign: 'right',
          fontWeight: 'bold',
          content: 'counters(item, ".") " "',
        },
      },
    },
  },
  '& li': {},
  '& a': {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  '& table': {
    '& thead': {
      fontWeight: 'bold',
      '& td': {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        textAlign: 'left',
      },
    },
    '& td': {
      padding: theme.spacing(1, 1, 0),
    },
    '& p': {
      margin: theme.spacing(0, 0, 1),
    },
  },
}))

type DocDisplayProps = {
  // an HTML string, usually imported using bundle-inlining, e.g. `import content from 'bundle-text:./myCoolDoc.html'`
  docHtml: string
}
export const DocDisplay = ({ docHtml }: DocDisplayProps) => (
  <Container sx={{ padding: '0 2rem' }}>
    <DocRoot
      dangerouslySetInnerHTML={{
        __html: docHtml,
      }}
    />
  </Container>
)
