import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateAliceCardPaymentMethodMutationVariables = Types.Exact<{
  input: Types.CreatePaymentMethodInput
}>

export type CreateAliceCardPaymentMethodMutation = {
  __typename?: 'Mutation'
  createAliceCardPaymentMethod?: {
    __typename?: 'CreatePaymentMethodPayload'
    organization?: { __typename?: 'Organization'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateAliceCardPaymentMethodDocument = `
    mutation CreateAliceCardPaymentMethod($input: CreatePaymentMethodInput!) {
  createAliceCardPaymentMethod(input: $input) {
    organization {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateAliceCardPaymentMethodMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateAliceCardPaymentMethodMutation,
    TError,
    CreateAliceCardPaymentMethodMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateAliceCardPaymentMethodMutation,
    TError,
    CreateAliceCardPaymentMethodMutationVariables,
    TContext
  >(
    ['CreateAliceCardPaymentMethod'],
    (variables?: CreateAliceCardPaymentMethodMutationVariables) =>
      fetchGql<CreateAliceCardPaymentMethodMutation, CreateAliceCardPaymentMethodMutationVariables>(
        CreateAliceCardPaymentMethodDocument,
        variables
      )(),
    options
  )
}
