import { Container, ListItem, styled, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { AliceCard, BankingConnection } from '../../graphql/generated.types'
import { findActiveCardAccount, isAliceCardAccountBalanceGated } from '../cards/alice/aliceCardUtils'
import { getBestAliceCard, getBestBankingConnection } from '../cards/spendingConnectionUtils'
import { useQuerySpendingConnections } from '../cards/useQuerySpendingConnections'
import { useEmployeeQuery } from '../gql/employee_gen'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { daysFromDate } from '../../../utils/dateUtils'
import { isConnected } from '../cards/personal/connect/bankingConnectionUtils'

const EmptyTxnListMessage = styled(Typography)({ margin: '1rem 2rem' })

const getEmptyMessage = (
  bestAliceCard: Pick<AliceCard, 'status'> | undefined,
  isBalanceGated: boolean,
  bestBankConnection: Pick<BankingConnection, 'status'> | undefined
): FormatjsIntl.Message['ids'] => {
  const hasConnectedPersonalCard = Boolean(bestBankConnection && isConnected(bestBankConnection))
  const hasAliceCard = Boolean(bestAliceCard) || isBalanceGated
  if (!hasAliceCard) {
    if (!hasConnectedPersonalCard) {
      return 'transactions.empty_transaction_list.no_card_connected'
    }
    return 'transactions.empty_transaction_list.no_transactions_but_personal_card'
  }
  if (bestAliceCard?.status === 'active') {
    return 'transactions.empty_transaction_list.no_eligible_transactions'
  }
  if (bestAliceCard?.status === 'inactive' || isBalanceGated) {
    if (hasConnectedPersonalCard) {
      return 'transactions.empty_transaction_list.no_transactions_but_has_cards'
    }
    if (isBalanceGated) return 'transactions.empty_transaction_list.no_transactions_but_has_gated_alice_card'
    return 'transactions.empty_transaction_list.no_transactions_but_has_alice_card'
  }
  // alice terminated
  if (hasConnectedPersonalCard) {
    return 'transactions.empty_transaction_list.no_transactions_but_personal_card_only'
  }
  return 'transactions.empty_transaction_list.no_transactions_and_no_cards'
}
type EmptyTxnListProps = {
  filtered?: boolean
}
export const EmptyTxnListItems = ({ filtered }: EmptyTxnListProps) => {
  const { data: employeeData } = useEmployeeQuery()
  const enrollmentDate = employeeData?.employee?.enrollmentInfo?.enrollmentDate
  const {
    data: { aliceCardAccounts, bankingConnections },
  } = useQuerySpendingConnections()
  const activeCardAccount = findActiveCardAccount(aliceCardAccounts)
  const isBalanceGated = Boolean(aliceCardAccounts?.find(isAliceCardAccountBalanceGated))
  const bestAliceCard = getBestAliceCard(activeCardAccount?.aliceCards)
  const bestBankConnection = getBestBankingConnection(bankingConnections)
  const fiveDaysSinceEnrollment =
    enrollmentDate && daysFromDate(dateStringFormat.dateFromDateString(enrollmentDate), 5)
  const hasBeenFiveDaysSinceEnrollment = fiveDaysSinceEnrollment ? fiveDaysSinceEnrollment < new Date() : true

  return (
    <ListItem>
      <Container>
        {filtered ? (
          <EmptyTxnListMessage variant="body2" fontWeight="bold" color="text.disabled">
            <FormattedMessage id="transactions.empty_transaction_list.no_transactions_for_filter" />
          </EmptyTxnListMessage>
        ) : (
          <>
            {enrollmentDate && (
              <EmptyTxnListMessage variant="body2" fontWeight="bold" color="text.disabled">
                <FormattedMessage
                  id="transactions.empty_transaction_list.benefits_started"
                  values={{ benefitsStartDate: dateStringFormat.long(enrollmentDate) }}
                />
              </EmptyTxnListMessage>
            )}
            {hasBeenFiveDaysSinceEnrollment && (
              <EmptyTxnListMessage variant="body2" fontWeight="bold" color="text.disabled">
                <FormattedMessage id={getEmptyMessage(bestAliceCard, isBalanceGated, bestBankConnection)} />
              </EmptyTxnListMessage>
            )}
          </>
        )}
      </Container>
    </ListItem>
  )
}
