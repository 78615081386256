import { useProvisionalOrgsQuery } from './gql/provisionalOrgs_gen'

/**
 * Hook to query provisional orgs.
 * @param id - The id of the provisional org to query - the first provisional org will be returned if no id is provided.
 * @returns The provisional org with the given id, or the first provisional org if no id is provided.
 */
export const useProvisionalOrgQuery = (id?: number) => {
  const { data: provisionalOrgsData, ...rest } = useProvisionalOrgsQuery()

  const currentUser = provisionalOrgsData?.currentUser
  const provisionalOrgs = currentUser?.provisionalOrganizations
  const provisionalOrg = provisionalOrgs?.find((org) => org.id === id) || provisionalOrgs?.[0]

  return { data: provisionalOrg, userExists: provisionalOrgsData ? Boolean(currentUser) : undefined, ...rest }
}

useProvisionalOrgQuery.getKey = useProvisionalOrgsQuery.getKey
