import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { SupportedLangCode } from '../../translations/types'
import { FeatureFlagValue, UtmSearchParams } from './types'
import { EmployeeAccountStatus } from '../graphql/generated.types'
/**
 * A provider that handles tracking-related information, primarily integrating
 * with DataDog's global context
 */

export type TrackingInfo = {
  utmParams?: UtmSearchParams
  flags?: Record<string, FeatureFlagValue>
  language?: SupportedLangCode
  accountStatuses?: Array<EmployeeAccountStatus>
}

type TrackingSetter = <T extends keyof TrackingInfo>(key: T, property: TrackingInfo[T]) => void
const TrackingContext = React.createContext<readonly [TrackingInfo, TrackingSetter] | undefined>(undefined)

type Props = {
  children: React.ReactNode
}
export const TrackingContextProvider = ({ children }: Props) => {
  const value = datadogRum.getGlobalContext()
  const [globalContext, setGlobalContext] = React.useState(value)

  const setter = React.useCallback<TrackingSetter>((prop, value) => {
    datadogRum.setGlobalContextProperty(prop, value)
    setGlobalContext(datadogRum.getGlobalContext())
  }, [])

  const contextValue = React.useMemo(() => [globalContext, setter] as const, [globalContext, setter])

  return <TrackingContext.Provider value={contextValue}>{children}</TrackingContext.Provider>
}

/**
 * Expose the tracking context to the application
 *
 * @example
 *
 * const MyCoolComponent = () => {
 *   const [trackingContext, setTrackingProperty] = useTrackingContext()
 *
 *   const utmParams = trackingContext.utmParams
 * }
 */
export const useTrackingContext = () => {
  const result = React.useContext(TrackingContext)
  if (!result) {
    throw new Error('useTrackingContext called outside of AnalyticsProvider')
  }
  return result
}
