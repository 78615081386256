import { FormControl, FormControlProps, Typography, TypographyProps } from '@mui/material'
import * as React from 'react'

type FieldsetProps<D extends React.ElementType = 'fieldset', P = NonNullable<unknown>> = Omit<
  FormControlProps<D, P>,
  'component'
>
export const Fieldset = (props: FieldsetProps) => (
  <FormControl component="fieldset" {...props} fullWidth sx={{ display: 'block' }} />
)

// 'any' is used here because the props are passed through to the underlying <Typography />
type LegendProps<D extends React.ElementType = 'legend', P = NonNullable<unknown>> = Omit<
  TypographyProps<D, P>,
  'component'
>
export const Legend = (props: LegendProps) => (
  <Typography fontWeight="bold" component="legend" lineHeight={1.2} {...props} />
)
