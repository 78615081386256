import {
  EmployeeAccountStatus,
  EmployeeEnrollmentRequirementStatus as _Status,
} from '../graphql/generated.types'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'

const { Complete } = _Status

export const useReenrollmentStatus = () => {
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const employee = employeeEnrollmentData?.employee
  if (!employee) return undefined

  const { accountStatus, requirements, enrollmentDate } = employee.enrollmentInfo

  if (accountStatus === EmployeeAccountStatus.NotEnrolled) {
    // if the user is not enrolled, but has completed _all_ enrollment requirements,
    // they must confirm re-enrollment (e.g., if an employee was terminated at _another org_
    // and quickly hired at the current org)
    const isComplete = (r: _Status) => r === Complete
    const { __typename, ...allRequirements } = requirements
    const allRequirementsMet = Object.values(allRequirements).every(isComplete)
    if (allRequirementsMet) return 'must_reenroll_by_confirming'

    // if the only missing requirement is spending connection and they have previously enrolled,
    // they can enroll via spending connection (e.g. EE was terminated and then re-hired
    // at the _same org_ but took a while so their spending connections got disconnected)
    const { spendingConnection, ...otherRequirements } = allRequirements
    const allOtherRequirementsMet = Object.values(otherRequirements).every(isComplete)
    if (allOtherRequirementsMet && enrollmentDate) return 'must_reenroll_via_spending_connection'

    // otherwise, the other enrollment states can handle enrollment
  }
  return undefined
}
