/**
 * a human-readbale URL-safe separator for querystring values
 * - note that values should not contain this character or parsing may break
 */
const SEPARATOR = '__'

/**
 * For the given object-based parameters, build a URLSearchParams that contains
 * human-readable array values, optionally merging with an existing querystring
 *
 * If you do _not_ have array values, use URLSearchParams.set/append directly,
 * e.g.
 *
 * const searchParams = new URLSearchParams(location.search)
 * searchParams.set('mycoolkey', 'mycoolvalue')
 *
 * or
 *
 * const searchParams = newURLSearchParams({ mycoolkey: 'mycoolvalue' })
 */
export const makeArraySearchParams = (
  newParams: Record<string, undefined | Array<string>>,
  querystring = ''
) => {
  const searchParams = new URLSearchParams(querystring)
  Object.entries(newParams).forEach(([key, value]) => {
    const paramVal = Array.isArray(value) ? value.join(SEPARATOR) : value
    if (paramVal) {
      searchParams.set(key, paramVal)
    } else {
      searchParams.delete(key)
    }
  })
  return searchParams
}

const isExpectedKey = <T extends string>(value: string, options: Array<T>): value is T =>
  options.includes(value as T)

/**
 * Intended for use with querystrings that have been encoded using `makeArraySearchParams`,
 * where the parameter values should be parsed as an array of string values.
 *
 * Note that the returned object will have array values for _all_ provided keys, including
 * empty arrays for any keys that are not in the provided querystring
 */
export const parseArrayQuerystring = <T extends string = string>(
  querystring: string,
  keys: Array<T>
): Record<T, Array<string>> => {
  const fullQueryString = new URLSearchParams(querystring)
  const parsedParams = Object.fromEntries(keys.map((k) => [k, [] as Array<string>])) as Record<
    T,
    Array<string>
  >
  fullQueryString.forEach((value, key) => {
    if (!isExpectedKey(key, keys)) return
    parsedParams[key] = value.split(SEPARATOR)
  })
  return parsedParams
}
