import * as Types from '../../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateHostedLinkSessionMutationVariables = Types.Exact<{
  input: Types.CreateHostedLinkSessionInput
}>

export type CreateHostedLinkSessionMutation = {
  __typename?: 'Mutation'
  createHostedLinkSession?: { __typename?: 'CreateHostedLinkSessionPayload'; hostedLinkUrl: string } | null
}

export const CreateHostedLinkSessionDocument = `
    mutation CreateHostedLinkSession($input: CreateHostedLinkSessionInput!) {
  createHostedLinkSession(input: $input) {
    hostedLinkUrl
  }
}
    `

export const useCreateHostedLinkSessionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateHostedLinkSessionMutation,
    TError,
    CreateHostedLinkSessionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateHostedLinkSessionMutation,
    TError,
    CreateHostedLinkSessionMutationVariables,
    TContext
  >(
    ['CreateHostedLinkSession'],
    (variables?: CreateHostedLinkSessionMutationVariables) =>
      fetchGql<CreateHostedLinkSessionMutation, CreateHostedLinkSessionMutationVariables>(
        CreateHostedLinkSessionDocument,
        variables
      )(),
    options
  )
}
