import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateEmployeesMutationVariables = Types.Exact<{
  employeeIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']
  role?: Types.InputMaybe<Types.EmployeeRole>
  accountStatus?: Types.InputMaybe<Types.EmployeeAccountStatusOverride>
}>

export type UpdateEmployeesMutation = {
  __typename?: 'Mutation'
  updateEmployees?: { __typename?: 'UpdateEmployeesPayload'; success: boolean } | null
}

export const UpdateEmployeesDocument = `
    mutation UpdateEmployees($employeeIds: [Int!]!, $role: EmployeeRole, $accountStatus: EmployeeAccountStatusOverride) {
  updateEmployees(
    input: {employeeIds: $employeeIds, role: $role, accountStatus: $accountStatus}
  ) {
    success
  }
}
    `

export const useUpdateEmployeesMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<UpdateEmployeesMutation, TError, UpdateEmployeesMutationVariables, TContext>
) => {
  return useMutation<UpdateEmployeesMutation, TError, UpdateEmployeesMutationVariables, TContext>(
    ['UpdateEmployees'],
    (variables?: UpdateEmployeesMutationVariables) =>
      fetchGql<UpdateEmployeesMutation, UpdateEmployeesMutationVariables>(
        UpdateEmployeesDocument,
        variables
      )(),
    options
  )
}
