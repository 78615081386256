import { useSearchParams } from 'react-router-dom'
import { AliceCardFragment } from '../../../gql/connectedCardsQuery_gen'

/**
 * This hook leverages the querystring to determine whether to show the secure card details modal - this allows
 * the details modal to be bookmarked or shared by support
 */
export const useShowSecureCardDetails = (card?: AliceCardFragment) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const newSearchParams = new URLSearchParams(searchParams)
  newSearchParams.set('ac_details', card?.id.toString() || '')
  return {
    openLink: `?${newSearchParams}`,
    close: () => {
      newSearchParams.delete('ac_details')
      setSearchParams(newSearchParams)
    },
    isOpen: Boolean(
      card && searchParams.has('ac_details') && searchParams.get('ac_details') === card.id.toString()
    ),
  }
}
