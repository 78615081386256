import { Button, Container, FullDialog, Grid, ListSubheader, Typography } from '@alice-financial/pretext-ui'
import FilterListIcon from '@mui/icons-material/FilterList'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeGetUniqueValues } from '../../../../utils/functional'
import { PageInfoFragment } from '../../../graphql/fragments/PageInfoFragment_gen'
import { getNumberOfSpecifiedFilters } from '../txnUtils'
import { Transaction, TxnFilter } from '../types'
import { TxnFilterSelect } from './TxnFilterSelect'
import { DatePagerControls } from '../../../../utils/paging/datePaging/DatePagerControls'
import { getApplyFilter } from './useFilter'

const CLEAR_FILTER: TxnFilter = {
  year: [],
  pretax_category: [],
  status: [],
}

type FilterDialogTitleProps = {
  filter: TxnFilter
  txns: Array<Transaction>
}
const FilterDialogTitle = ({ filter, txns }: FilterDialogTitleProps): React.ReactElement => {
  const applyFilter = getApplyFilter(filter)
  const matchingTxnCount = txns.filter(applyFilter).length
  return (
    <>
      <Typography variant="h2">
        <FormattedMessage id="common.filter" />
      </Typography>
      <Typography variant="body2">
        {matchingTxnCount} <FormattedMessage id="transactions.matching_transactions" />
      </Typography>
    </>
  )
}

type FilterActionsProps = {
  filter: TxnFilter
  setFilter: (filter: Partial<TxnFilter>) => void
  onClose: () => void
}
const FilterActions = ({ filter, setFilter, onClose }: FilterActionsProps) => (
  <>
    <Button
      color="secondary"
      disabled={getNumberOfSpecifiedFilters(filter) === 0}
      onClick={() => {
        setFilter(CLEAR_FILTER)
        onClose()
      }}
    >
      <FormattedMessage id="common.clear_all" />
    </Button>
    <Button
      variant="contained"
      onClick={() => {
        setFilter(filter)
        onClose()
      }}
    >
      <FormattedMessage id="common.apply" />
    </Button>
  </>
)

const getAvailableYears = makeGetUniqueValues((txn: Transaction) => txn.date.getFullYear().toString())
const getAvailableStatuses = makeGetUniqueValues((txn: Transaction) => txn.status.value)
const getAvailableCategories = makeGetUniqueValues((txn: Transaction) => txn.spendEligibility.pretaxCategory)

type TxnFilterDisplayProps = {
  filter: TxnFilter
  setFilter: (filter: Partial<TxnFilter>) => void
  txns: Array<Transaction>
  pageInfo: PageInfoFragment
}
/**
 * An interactive widget used to select a set of filters that the
 * user would like applied to the full set of transactions passed
 * in through `txns`.
 *
 * This widget sets an _internal_/pending filter which can then be
 * applied to the parent through the supplied `setFilter` - it doesn't
 * set the parent filter 'live'. This also means that closing the dialog
 * without clicking 'Apply' is essentially the same as 'clearing' the
 * pending filter - the parent filter state will not be affected.
 *
 * _note_: the `txns` array should be the _unfiltered_ transactions
 * list, not the filtered one.
 */
export const TxnFilterDisplay = ({ filter, setFilter, txns, pageInfo }: TxnFilterDisplayProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [pendingFilter, setPendingFilter] = React.useState<TxnFilter>(filter)
  React.useEffect(() => {
    // re-set the 'pending' filter to the parent filter state _every_ time the
    // parent state changes
    setPendingFilter(filter)
  }, [filter, setPendingFilter, isOpen])

  const onClose = () => setIsOpen(false)
  const actions = <FilterActions filter={pendingFilter} setFilter={setFilter} onClose={onClose} />
  const numberOfFilters = getNumberOfSpecifiedFilters(filter)
  const intl = useIntl()
  const availableYears = getAvailableYears(txns)
  const availableStatuses = getAvailableStatuses(txns)
  const availableCategories = getAvailableCategories(txns)

  const filtersAreAvailable =
    availableYears.length > 1 || availableStatuses.length > 1 || availableCategories.length > 1

  return (
    <ListSubheader disableGutters>
      <Container sx={{ padding: 0 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <DatePagerControls pageInfo={pageInfo} />
          </Grid>
          {filtersAreAvailable && (
            <>
              <Grid item xs={12} sm={4}>
                <Grid container justifyContent="space-between" wrap="nowrap">
                  <Grid item>
                    <Button
                      variant="text"
                      disabled={numberOfFilters === 0}
                      onClick={() => {
                        setFilter(CLEAR_FILTER)
                        onClose()
                      }}
                    >
                      <FormattedMessage id="common.clear_filters" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={txns.length === 0}
                      startIcon={<FilterListIcon />}
                      onClick={() => setIsOpen(true)}
                    >
                      <FormattedMessage id="common.filter" />{' '}
                      {numberOfFilters > 1 ? `(${numberOfFilters})` : null}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <FullDialog
                open={isOpen}
                onClose={onClose}
                title={<FilterDialogTitle filter={pendingFilter} txns={txns} />}
                actions={actions}
                justifyActions="space-between"
              >
                <Container>
                  <TxnFilterSelect
                    title={intl.formatMessage({ id: 'common.year' })}
                    valuesToShow={pendingFilter.year}
                    setValuesToShow={(year) => setPendingFilter({ ...pendingFilter, year })}
                    possibleValues={availableYears}
                  />
                  <TxnFilterSelect
                    title={intl.formatMessage({ id: 'common.status' })}
                    valuesToShow={pendingFilter.status}
                    setValuesToShow={(status) => setPendingFilter({ ...pendingFilter, status })}
                    possibleValues={availableStatuses}
                  />
                  <TxnFilterSelect
                    title={intl.formatMessage({ id: 'transactions.category' })}
                    valuesToShow={pendingFilter.pretax_category}
                    setValuesToShow={(pretax_category) =>
                      setPendingFilter({ ...pendingFilter, pretax_category })
                    }
                    possibleValues={availableCategories}
                  />
                </Container>
              </FullDialog>
            </>
          )}
        </Grid>
      </Container>
    </ListSubheader>
  )
}
