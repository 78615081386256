import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as React from 'react'
import { NotFoundError, SessionExpiredError, UnauthorizedError } from './responseErrors'

// Our fetcher will throw custom errors for unrecoverable errors - these are errors
// that won't be fixed by retrying the request
const errorIsUnrecoverable = (error: unknown) =>
  error instanceof UnauthorizedError || error instanceof NotFoundError || error instanceof SessionExpiredError

const DATA_STALE_TIME = 1000 * 60 * 10 // 10 minutes
const DEFAULT_RETRIES = 2

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DATA_STALE_TIME, // 10 minute lazy refetch by default
      retry: (failureCount, error) => !errorIsUnrecoverable(error) && failureCount < DEFAULT_RETRIES,
    },
  },
})

type ApiClientProviderProps = {
  children: React.ReactNode
}
/**
 * Wrap the application with this component in order for nested components to use
 * React Query for API calls
 */
export const ApiClientProvider = ({ children }: ApiClientProviderProps) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)
