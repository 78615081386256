import {
  ActionLayout,
  Alert,
  AlertTitle,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, useNavigate, useParams } from 'react-router'
import { PageBody } from '../../../../routes/PageBody'
import { BankingConnectionFragment } from '../../gql/connectedCardsQuery_gen'
import { useQuerySpendingConnections } from '../../useQuerySpendingConnections'
import {
  canBeReconnected,
  getConnectInstitutionPath,
  getIsConnectedToInstitution,
  isAvailable,
  useReconnect,
} from './bankingConnectionUtils'
import { OMNYConnectionDetail } from './omny/OMNYConnectionDetail'
import { useDisconnect } from './useDisconnectInstitution'
import { useGetUnifiedBankingInstitution } from './useGetBankingInstitution'

type ReconnectionAlertProps = {
  existingConnection: BankingConnectionFragment
}
const ReconnectionAlert = ({ existingConnection }: ReconnectionAlertProps) => {
  const reconnect = useReconnect()
  const { status } = existingConnection
  if (!canBeReconnected(status)) return null
  return (
    <Alert
      severity="warning"
      action={
        <Button variant="contained" color="primary" onClick={() => reconnect(existingConnection)}>
          <FormattedMessage id="connections.reconnect.label" />
        </Button>
      }
    >
      <AlertTitle>Connection error</AlertTitle>
      Reconnect to ensure we don&apos;t miss any eligible spending
    </Alert>
  )
}

export const ConnectionDetailRoute = () => {
  const { bankingInstitutionId } = useParams()
  if (!bankingInstitutionId) throw new Error('No banking institution ID available') // this shouldn't ever fire

  const isOmny = bankingInstitutionId === 'omny'
  if (isOmny) return <OMNYConnectionDetail />
  return <BankingConnectionDetail bankingInstitutionId={bankingInstitutionId} />
}

/**
 * A CRUD-oriented component that will render the details of an existing connected card/banking
 * institution or trigger the card connect flow if the url-provided ID is not currently
 * connected to a user.
 */
const BankingConnectionDetail = ({ bankingInstitutionId }: { bankingInstitutionId: string }) => {
  const navigate = useNavigate()
  const { mutateAsync: disconnect } = useDisconnect({ onSuccess: () => navigate('../../personal') })

  const { data: unifiedBankingInstitution } = useGetUnifiedBankingInstitution(bankingInstitutionId)
  // load user's existing connections to see if any match the selected institution
  const {
    data: { bankingConnections },
    isLoading: connectionsLoading,
  } = useQuerySpendingConnections()
  const existingConnection = bankingConnections.find(getIsConnectedToInstitution(bankingInstitutionId))

  if (connectionsLoading) return null
  if (!unifiedBankingInstitution) return null // LOADING or something bad happened

  const connectPath = getConnectInstitutionPath({
    unifiedInstitutionId: unifiedBankingInstitution.id,
    platform: unifiedBankingInstitution.platform,
  })

  if (!existingConnection || !isAvailable(existingConnection)) {
    return <Navigate to={connectPath} replace />
  }

  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom>
          {existingConnection.institution?.name}
        </Typography>
        <ReconnectionAlert existingConnection={existingConnection} />
        <Typography variant="h3" component="h2" gutterBottom>
          <FormattedMessage id="employee_dashboard.main.accounts" />
        </Typography>
        <Paper>
          <List dense>
            {existingConnection.bankingAccounts.map((account) => (
              <ListItem key={account.id} divider>
                <ListItemText primary={account.name} secondary={account.last4} />
              </ListItem>
            ))}
          </List>
        </Paper>

        <ActionLayout
          primary={
            <Button fullWidth variant="outlined" color="error" onClick={() => disconnect(existingConnection)}>
              <FormattedMessage id="connections.disconnect.label" />
            </Button>
          }
        />
      </Container>
    </PageBody>
  )

  return null
}
