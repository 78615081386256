import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type LoginByPhoneCodeMutationVariables = Types.Exact<{
  input: Types.LoginByPhoneCodeInput
}>

export type LoginByPhoneCodeMutation = {
  __typename?: 'Mutation'
  loginByPhoneCode?: {
    __typename?: 'LoginByPhoneCodePayload'
    success: boolean
    errorCode?: Types.TelLoginErrorCode | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const LoginByPhoneCodeDocument = `
    mutation LoginByPhoneCode($input: LoginByPhoneCodeInput!) {
  loginByPhoneCode(input: $input) {
    success
    errorCode
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useLoginByPhoneCodeMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<LoginByPhoneCodeMutation, TError, LoginByPhoneCodeMutationVariables, TContext>
) => {
  return useMutation<LoginByPhoneCodeMutation, TError, LoginByPhoneCodeMutationVariables, TContext>(
    ['LoginByPhoneCode'],
    (variables?: LoginByPhoneCodeMutationVariables) =>
      fetchGql<LoginByPhoneCodeMutation, LoginByPhoneCodeMutationVariables>(
        LoginByPhoneCodeDocument,
        variables
      )(),
    options
  )
}
