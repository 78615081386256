import { InlineInputController, Paper, SelectController } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Path, UseFormReturn } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { usStates } from '../../../../data/usStates'
import { AddressInput as AddressInputType } from '../../../graphql/generated.types'
import { OrgOnboardingApiValues } from '../../../organization/onboarding/types'

// must pass in `underscore` if the parent form needs the underscore version of the field name (i.e. `line_1`)
type AddressFormValues = AddressInputType | OrgOnboardingApiValues['address_attributes']

type AddressInputProps<TFieldValues extends AddressFormValues> = {
  control: UseFormReturn<TFieldValues>['control']
  underscore?: boolean
  disabled?: boolean
}

/**
 * A styled, organized set of inputs for an address form. Note that the names
 * of the input fields _must_ correspond to the expected field names of the parent form,
 * i.e. the parent form must derive from `AddressFormValues`.
 *
 * The `as Path<TFieldValues>` is a type coercion to avoid a type error where TS cannot
 * infer that the field names _are_ part of the TFieldValues type. You should
 * _not_ have to supply a generic argument for this component - it will be inferred
 * from the `control` that is pass in.
 */
export const AddressInput = <TFieldValues extends AddressFormValues>({
  control,
  disabled,
  underscore,
}: AddressInputProps<TFieldValues>) => {
  const intl = useIntl()

  return (
    <Paper
      elevation={0}
      sx={(theme) => ({ padding: 1.5, border: `1px solid ${theme.palette.primary.main}` })}
    >
      <InlineInputController
        disabled={disabled}
        initialWidth="25ch"
        control={control}
        placeholder={intl.formatMessage({ id: 'profile.address.street_address' })}
        rules={{ required: intl.formatMessage({ id: 'common.validation.required' }) }}
        name={underscore ? ('line_1' as Path<TFieldValues>) : ('line1' as Path<TFieldValues>)}
      />
      <br />
      <InlineInputController
        disabled={disabled}
        initialWidth="25ch"
        control={control}
        placeholder={intl.formatMessage({ id: 'profile.address.optional' })}
        name={underscore ? ('line_2' as Path<TFieldValues>) : ('line2' as Path<TFieldValues>)}
      />
      <br />
      <InlineInputController
        disabled={disabled}
        initialWidth="10ch"
        control={control}
        placeholder={intl.formatMessage({ id: 'profile.address.city' })}
        rules={{ required: intl.formatMessage({ id: 'common.validation.required' }) }}
        name={underscore ? ('city_name' as Path<TFieldValues>) : ('cityName' as Path<TFieldValues>)}
      />
      ,{' '}
      <SelectController
        disabled={disabled}
        name={underscore ? ('state_code' as Path<TFieldValues>) : ('stateCode' as Path<TFieldValues>)}
        control={control}
        rules={{ required: intl.formatMessage({ id: 'common.validation.required' }) }}
        placeholder={intl.formatMessage({ id: 'profile.address.state' })}
      >
        <option value="" disabled></option>
        {usStates.map(({ code }) => (
          <option key={code} value={code}>
            {code}
          </option>
        ))}
      </SelectController>{' '}
      <InlineInputController
        disabled={disabled}
        initialWidth="11ch"
        control={control}
        rules={{
          pattern: {
            value: /^\d{5}$/,
            message: intl.formatMessage({ id: 'common.validation.length' }, { length: 5 }),
          },
          required: intl.formatMessage({ id: 'common.validation.required' }),
        }}
        placeholder={intl.formatMessage({ id: 'profile.address.zipcode' })}
        name={'zipcode' as Path<TFieldValues>}
      />
    </Paper>
  )
}
