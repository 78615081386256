import { PageBody as _PageBody, BoxProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { ContactSupport } from '../employeeDashboard/ContactSupport'

export const PageBody = ({ children, ...boxProps }: BoxProps) => {
  return (
    <_PageBody {...boxProps}>
      {children}
      <ContactSupport />
    </_PageBody>
  )
}
