import { styled } from '@mui/material/styles'

export const StepList = styled('ol')(({ theme }) => {
  const fontSize = '1.5em'
  return {
    counterReset: 'step-counter',
    listStyle: 'none',
    paddingLeft: `calc(${fontSize} * 2)`,
    paddingRight: `calc(${fontSize} * 2)`,

    ' > li': {
      counterIncrement: 'step-counter',
      position: 'relative',
      marginBottom: '0.8em',
      '&::before': {
        content: 'counter(step-counter)',
        color: theme.palette.primary.main,
        fontFamily: 'sans-serif',
        fontSize,
        fontWeight: 'bold',
        position: 'absolute',
        left: `calc(-1 * ${fontSize} - 0.7em)`,
        lineHeight: 1.1,
        width: fontSize,
        height: fontSize,
        top: 0,
        textAlign: 'right',
      },
    },
  }
})
