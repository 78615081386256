import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SetBankingAccountMutationVariables = Types.Exact<{
  input: Types.SetBankingAccountInput
}>

export type SetBankingAccountMutation = {
  __typename?: 'Mutation'
  setBankingAccount?: {
    __typename?: 'SetBankingAccountPayload'
    bankingAccount?: { __typename?: 'BankingAccount'; id: number } | null
  } | null
}

export const SetBankingAccountDocument = `
    mutation SetBankingAccount($input: SetBankingAccountInput!) {
  setBankingAccount(input: $input) {
    bankingAccount {
      id
    }
  }
}
    `

export const useSetBankingAccountMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    SetBankingAccountMutation,
    TError,
    SetBankingAccountMutationVariables,
    TContext
  >
) => {
  return useMutation<SetBankingAccountMutation, TError, SetBankingAccountMutationVariables, TContext>(
    ['SetBankingAccount'],
    (variables?: SetBankingAccountMutationVariables) =>
      fetchGql<SetBankingAccountMutation, SetBankingAccountMutationVariables>(
        SetBankingAccountDocument,
        variables
      )(),
    options
  )
}
