import type { DataGridProps } from '@mui/x-data-grid'
import * as React from 'react'
export { GridToolbarContainer } from '@mui/x-data-grid/components/containers/GridToolbarContainer'
export { GridToolbarQuickFilter } from '@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter'
export { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext'

/**
 * Lazy-loaded wrapper around MUI DataGrid - it is a heavy dependency, so will not be included in the root bundle
 */
const MuiDataGrid = React.lazy(() =>
  import('@mui/x-data-grid').then((module) => ({ default: module.DataGrid }))
)

export const DataGrid = (props: DataGridProps) => (
  <React.Suspense fallback={null}>
    <MuiDataGrid
      {...props}
      slotProps={{
        baseCheckbox: { size: props.density === 'compact' ? 'small' : undefined },
        ...props.slotProps,
      }}
    />
  </React.Suspense>
)
