import * as React from 'react'
import { Outlet } from 'react-router'
import { StripeConnectComponentsProvider } from '../../externalServices/stripe/StripeConnectComponentsProvider'
import { useOrg } from '../organization/useOrg'

export const EnableConnectComponents = () => {
  const org = useOrg()
  if (!org) return null

  return (
    <StripeConnectComponentsProvider orgId={org.id}>
      <Outlet />
    </StripeConnectComponentsProvider>
  )
}
