import { Container, InfoDrawer, useInfoDrawerControl } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { HowAliceWorks } from '../../enrollment/landing/HowAliceWorks'

const ANCHOR_NAME = 'how-alice-works-info'
type HowAliceWorksInfoProps = {
  children: React.ReactNode
}
export const HowAliceWorksInfo = ({ children }: HowAliceWorksInfoProps) => {
  const { onClose } = useInfoDrawerControl(ANCHOR_NAME)
  return (
    <InfoDrawer
      anchorName={ANCHOR_NAME}
      infoContent={
        <Container sx={{ mt: 4 }}>
          <HowAliceWorks disableNav onComplete={onClose} />
        </Container>
      }
    >
      {children}
    </InfoDrawer>
  )
}
