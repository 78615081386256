import { Box, SectionHeading } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useMayManageAliceCardProgram } from '../aliceCardProgram/aliceCardProgramUtils'
import { ConnectedPaymentMethods } from '../aliceCardProgram/ConnectedPaymentMethods'

type AliceCardProgramSectionProps = {
  org?: OrganizationDetailFragment | null
}

export const AliceCardProgramSection = ({ org }: AliceCardProgramSectionProps) => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!org || !mayManageAliceCardProgram) return null
  if (!org.aliceCardProgram.hasFundingConnected) return null
  return (
    <Box my={4}>
      <SectionHeading id="alice-card-program" to="/manage/alice-card-program" gutterBottom>
        Benefits Administration Account
      </SectionHeading>
      <ConnectedPaymentMethods org={org} />
    </Box>
  )
}
