import * as React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { Chip, ChipProps } from '@mui/material'

type ToggleChipProps = ChipProps & {
  label: string
  onChange: (selected: boolean) => void
  selected?: boolean
}

/**
 * A toggle/checkbox-like element that uses a `Chip` component for display.
 * The toggle state can be controlled with the `selected` prop, or it can
 * be 'uncontrolled' - letting the 'selected' state be managed internally.
 * The parent must pass in an 'onChange' callback to access toggle state.
 */
export const ToggleChip = ({
  color = 'primary',
  label,
  onChange,
  selected,
  onClick,
  icon,
  ...props
}: ToggleChipProps) => {
  const [internalSelected, setInternalSelected] = React.useState(selected)
  const isChecked = selected ?? internalSelected
  const handleClick: ChipProps['onClick'] = (...args) => {
    if (onClick) onClick(...args)
    onChange(!isChecked)
    setInternalSelected(!isChecked)
  }
  return (
    <Chip
      className="toggleChip"
      color={isChecked ? color : undefined}
      icon={isChecked ? <CheckIcon /> : icon}
      onClick={handleClick}
      label={label}
      aria-label={label}
      aria-pressed={selected ? 'true' : 'false'}
      {...props}
    />
  )
}
