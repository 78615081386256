import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SetLaunchDateMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationInput
}>

export type SetLaunchDateMutation = {
  __typename?: 'Mutation'
  updateOrganization?: {
    __typename?: 'UpdateOrganizationPayload'
    organization?: { __typename?: 'Organization'; id: number; commutingLaunchDate?: string | null } | null
  } | null
}

export const SetLaunchDateDocument = `
    mutation SetLaunchDate($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    organization {
      id
      commutingLaunchDate
    }
  }
}
    `

export const useSetLaunchDateMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<SetLaunchDateMutation, TError, SetLaunchDateMutationVariables, TContext>
) => {
  return useMutation<SetLaunchDateMutation, TError, SetLaunchDateMutationVariables, TContext>(
    ['SetLaunchDate'],
    (variables?: SetLaunchDateMutationVariables) =>
      fetchGql<SetLaunchDateMutation, SetLaunchDateMutationVariables>(SetLaunchDateDocument, variables)(),
    options
  )
}
