import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateEmploymentStartDateMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input']
  employmentStartDate: Types.Scalars['ISO8601Date']['input']
}>

export type UpdateEmploymentStartDateMutation = {
  __typename?: 'Mutation'
  updateEmployee?: {
    __typename?: 'UpdateEmployeePayload'
    employee?: { __typename?: 'Employee'; id: number; employmentStartDate?: string | null } | null
  } | null
}

export const UpdateEmploymentStartDateDocument = `
    mutation UpdateEmploymentStartDate($employeeId: Int!, $employmentStartDate: ISO8601Date!) {
  updateEmployee(
    input: {employeeId: $employeeId, employmentStartDate: $employmentStartDate}
  ) {
    employee {
      id
      employmentStartDate
    }
  }
}
    `

export const useUpdateEmploymentStartDateMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateEmploymentStartDateMutation,
    TError,
    UpdateEmploymentStartDateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateEmploymentStartDateMutation,
    TError,
    UpdateEmploymentStartDateMutationVariables,
    TContext
  >(
    ['UpdateEmploymentStartDate'],
    (variables?: UpdateEmploymentStartDateMutationVariables) =>
      fetchGql<UpdateEmploymentStartDateMutation, UpdateEmploymentStartDateMutationVariables>(
        UpdateEmploymentStartDateDocument,
        variables
      )(),
    options
  )
}
