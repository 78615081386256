import { Container } from '@mui/material'
import * as React from 'react'
import { useOrg } from '../../../orgDashboard/organization/useOrg'
import { OrderForm } from './OrderForm'

export const OrderFormPage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <Container maxWidth="md">
      <OrderForm org={org} />
    </Container>
  )
}
