import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type RequestAliceCardMutationVariables = Types.Exact<{
  input: Types.RequestAliceCardInput
}>

export type RequestAliceCardMutation = {
  __typename?: 'Mutation'
  requestAliceCard?: {
    __typename?: 'RequestAliceCardPayload'
    employee?: {
      __typename?: 'Employee'
      id: number
      spendingConnectionInfo: { __typename?: 'SpendingConnectionInfo'; hasAcceptedAliceCardTos: boolean }
    } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const RequestAliceCardDocument = `
    mutation RequestAliceCard($input: RequestAliceCardInput!) {
  requestAliceCard(input: $input) {
    employee {
      id
      spendingConnectionInfo {
        hasAcceptedAliceCardTos
      }
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useRequestAliceCardMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<RequestAliceCardMutation, TError, RequestAliceCardMutationVariables, TContext>
) => {
  return useMutation<RequestAliceCardMutation, TError, RequestAliceCardMutationVariables, TContext>(
    ['RequestAliceCard'],
    (variables?: RequestAliceCardMutationVariables) =>
      fetchGql<RequestAliceCardMutation, RequestAliceCardMutationVariables>(
        RequestAliceCardDocument,
        variables
      )(),
    options
  )
}
