import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CanonicalOrgIdQueryVariables = Types.Exact<{ [key: string]: never }>

export type CanonicalOrgIdQuery = {
  __typename?: 'Query'
  organization?: { __typename?: 'Organization'; id: number } | null
}

export const CanonicalOrgIdDocument = `
    query CanonicalOrgId {
  organization {
    id
  }
}
    `

export const useCanonicalOrgIdQuery = <TData = CanonicalOrgIdQuery, TError = Error>(
  variables?: CanonicalOrgIdQueryVariables,
  options?: UseQueryOptions<CanonicalOrgIdQuery, TError, TData>
) => {
  return useQuery<CanonicalOrgIdQuery, TError, TData>(
    variables === undefined ? ['CanonicalOrgId'] : ['CanonicalOrgId', variables],
    fetchGql<CanonicalOrgIdQuery, CanonicalOrgIdQueryVariables>(CanonicalOrgIdDocument, variables),
    options
  )
}

useCanonicalOrgIdQuery.getKey = (variables?: CanonicalOrgIdQueryVariables) =>
  variables === undefined ? ['CanonicalOrgId'] : ['CanonicalOrgId', variables]
