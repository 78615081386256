import * as React from 'react'
import { useEmployeeQuery } from '../employeeDashboard/gql/employee_gen'
import { Navigate, useParams } from 'react-router'
import { EmployeeRole } from '../graphql/generated.types'

type EmployerAdminRedirectProps = {
  children: React.ReactNode
}
/**
 * This component ensures that only organization admins have access to org-level dashboard
 * Employer admins may only access the employer dashboard at a route with an :employerId param
 */
export const OrgAdminRedirect = ({ children }: EmployerAdminRedirectProps) => {
  const { employerId } = useParams()
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  if (!employee) return null

  if (employee.role === EmployeeRole.NonAdmin) {
    return <Navigate to="/" />
  }
  // @deprecated handling of employer-only admin
  if (employee.role === EmployeeRole.EmployerAdmin) {
    if (!employerId || employerId !== employee.employer.id.toString()) {
      return <Navigate to={`/manage/${employee.employer.id}`} />
    }
  }

  return <>{children}</>
}
