import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import {
  UpdateEmploymentStartDateMutation,
  UpdateEmploymentStartDateMutationVariables,
  useUpdateEmploymentStartDateMutation,
} from './gql/updateEmploymentStartDate_gen'
import { useEmployeeQuery } from '../employeeDashboard/gql/employee_gen'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'

export const useUpdateEmploymentStartDate = (
  mutationOptions?: UseGqlMutationCallbacks<
    UpdateEmploymentStartDateMutation,
    UpdateEmploymentStartDateMutationVariables
  >
) =>
  useUpdateEmploymentStartDateMutation(
    useMutationOptionsWithInvalidation(mutationOptions, [
      useEmployeeEnrollmentInfoQuery.getKey(),
      useEmployeeQuery.getKey(),
    ])
  )
