import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../../utils/useMutationNotifier'
import { useEmployeeEnrollmentInfoQuery } from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { TrackingEvent, useAnalytics } from '../../../telemetry/AnalyticsProvider'
import { useEmployeeQuery } from '../../gql/employee_gen'
import { useConnectedCardsQuery } from '../gql/connectedCardsQuery_gen'
import { RequestAliceCardMutationVariables, useRequestAliceCardMutation } from './gql/requestAliceCard_gen'

type UseRequestAliceCardMutationOptions = UseGqlMutationCallbacks<
  unknown,
  RequestAliceCardMutationVariables,
  unknown
>
/**
 * This is a wrapper around the `useRequestAliceCard` mutation that accepts an
 * argument for what type of card to request/provision
 */
export const useRequestAliceCard = (mutationOptions: UseRequestAliceCardMutationOptions = {}) => {
  const analytics = useAnalytics()
  const { mutateAsync: requestAliceCard } = useRequestAliceCardMutation(mutationOptions)
  const mutationOptionsWithNotifications = useMutationNotifier({}, mutationOptions)

  const _mutationOptions: typeof mutationOptionsWithNotifications = {
    ...mutationOptionsWithNotifications,
    onMutate: (values) => {
      analytics.track(TrackingEvent.AliceCardRequested, {
        provisioning_scope: values.input.cardTypesRequested,
      })
      return mutationOptions.onMutate ? mutationOptions.onMutate(values) : undefined
    },
  }
  return useMutationWithInvalidation(requestAliceCard, _mutationOptions, [
    useConnectedCardsQuery.getKey(),
    useEmployeeEnrollmentInfoQuery.getKey(),
    useEmployeeQuery.getKey(),
  ])
}
