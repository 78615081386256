const _longFormat: Intl.DateTimeFormatOptions = { dateStyle: 'long' }
const long = Intl.DateTimeFormat('en-US', _longFormat).format // 'MMMM DD, YYYY'

const _monthYearFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
}
const monthYear = Intl.DateTimeFormat('en-US', _monthYearFormat).format
const _shortFormat: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
}
const short = Intl.DateTimeFormat('en-US', _shortFormat).format

const _mediumFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
}
const medium = Intl.DateTimeFormat('en-US', _mediumFormat).format // 'Jan 1'
const _mediumNoYearFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
}
const mediumNoYear = Intl.DateTimeFormat('en-US', _mediumNoYearFormat).format // 'Jan 1'

const _MMYY: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  year: '2-digit',
}
const MMYY = Intl.DateTimeFormat('en-US', _MMYY).format

/**
 * some REST API date fields (e.g. user.birthdate) expect a date value in the form 'MM/DD/YYYY', but
 * the <input type="date" /> returns 'YYYY-MM-DD'.
 *
 * Note: other fields (e.g. user.employment_start_date in the REST API PATCH /user call) require YYYY-MM-DD, so triple-check your payloads
 */
const monthFirstFromInputVal = (inputVal: string): `${string}/${string}/${string}` => {
  const [yyyy, mm, dd] = inputVal.split('-')
  return `${mm}/${dd}/${yyyy}`
}

const inputValFromApiVal = (apiVal: string): `${string}-${string}-${string}` => {
  const [mm, dd, yyyy] = apiVal.split('/')
  return `${yyyy}-${mm}-${dd}`
}

/**
 * `dateString` can be any date-only string, e.g. YYYY-MM-DD or MM/DD/YYYY
 */
const dateFromDateString = (dateString: string): Date => {
  const _formattedDateString =
    dateString.split('/').length === 3 ? inputValFromApiVal(dateString) : dateString
  const offsetDate = new Date(_formattedDateString)
  // shift the parsed Date into UTC so that formatters will work consistently
  const correctedDate = new Date(offsetDate.getTime() + offsetDate.getTimezoneOffset() * 60 * 1000)
  return correctedDate
}

export const dateStringFormat = {
  dateFromDateString,
  long: (dateString: string) => long(dateFromDateString(dateString)),
  medium: (dateString: string) => medium(dateFromDateString(dateString)),
  mediumNoYear: (dateString: string) => mediumNoYear(dateFromDateString(dateString)),
  short: (dateString: string) => short(dateFromDateString(dateString)),
  monthYear: (dateString: string) => monthYear(dateFromDateString(dateString)),
  MMYY: (dateString: string) => MMYY(dateFromDateString(dateString)),
  monthFirstFromInputVal,
  inputValFromApiVal,
}

const _fullDayFormat: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
// returns 'Monday, January 1, 2024'
const fullDay = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', _fullDayFormat).format(date)
}

/**
 * Date formatting helpers. Be careful! Many systems at Alice use date values with
 * no timezone information, which can confuse date-time formatters that typically
 * treat 'no timezone information' as 'UTC', leading to unexpected offsets in the output.
 * When using `short`, `medium`, and `long`, you should pass in dates constructed
 * from a timezone-aware source, or construct a `date` from a `dateString` using the
 * supplied `dateFromDateString` helper.
 */
export const dateFormat = {
  long,
  medium,
  mediumNoYear,
  short,
  fullDay,
  // <input type="date" /> values require 'yyyy-mm-dd' format, which can be sliced from an ISOString
  inputVal: (date: Date) => {
    const offset = date.getTimezoneOffset()
    const offsetCorrectedDate = new Date(date.getTime() - offset * 60 * 1000)
    return offsetCorrectedDate.toISOString().slice(0, 10) // 'YYYY-MM-DD'
  },
}
