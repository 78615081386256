import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type VerifyPhoneMutationVariables = Types.Exact<{
  input: Types.VerifyPhoneInput
}>

export type VerifyPhoneMutation = {
  __typename?: 'Mutation'
  verifyPhone?: {
    __typename?: 'VerifyPhonePayload'
    success: boolean
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const VerifyPhoneDocument = `
    mutation VerifyPhone($input: VerifyPhoneInput!) {
  verifyPhone(input: $input) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useVerifyPhoneMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<VerifyPhoneMutation, TError, VerifyPhoneMutationVariables, TContext>
) => {
  return useMutation<VerifyPhoneMutation, TError, VerifyPhoneMutationVariables, TContext>(
    ['VerifyPhone'],
    (variables?: VerifyPhoneMutationVariables) =>
      fetchGql<VerifyPhoneMutation, VerifyPhoneMutationVariables>(VerifyPhoneDocument, variables)(),
    options
  )
}
