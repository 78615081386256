import { Alert, Grid, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate } from 'react-router'
import { useOrg } from '../organization/useOrg'
import { FundingAccountBalance, FundingAccountTransactions } from './FundingAccountComponents'
import { FundingAccountInfo } from './funding/fundingAccount/FundingAccountInfo'

export const FundingAccountPage = () => {
  const org = useOrg()
  if (!org) return null

  if (!org.aliceCardProgram.hasFundingConnected) {
    // This page is _only_ for orgs that have connected funding - they can connect funding on the Program page
    return <Navigate to="/manage/alice-card-program" replace />
  }

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Alice Card Funding Account</Typography>
      <Grid container columnGap={2} wrap="nowrap" sx={{ marginTop: 2, marginBottom: 3 }}>
        <Grid item minWidth="25%">
          <FundingAccountBalance org={org} />
        </Grid>
        <Alert severity="info" icon={false}>
          <Typography variant="caption" component="p" gutterBottom>
            <em>
              Transfers made to the Alice Card Funding Account are not Alice&apos;s fees, and the funds do not
              belong to Alice &ndash; they belong to your company&apos;s commuter benefit plan.
            </em>
          </Typography>
          <FundingAccountInfo>
            <Typography variant="caption" component="p" color="primary" fontWeight="bold">
              What is this account? How is it funded?
            </Typography>
          </FundingAccountInfo>
        </Alert>
      </Grid>
      <FundingAccountTransactions org={org} />
    </PageBody>
  )
}
