import { TextField } from '@mui/material'
import * as React from 'react'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { InlineInputProps } from './types'

// this must be a named function in order to pass into `React.forwardRef` without
// `missing displayName` warning
const _InlineInput = (
  { initialWidth = '6rem', ...props }: InlineInputProps,
  ref: React.Ref<HTMLInputElement>
) => {
  const wrappingDiv = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    // load value into element's `dataset` attribute in order to dynamically resize to
    // fit value using CSS { content: attr(data-value) ' '; }
    if (!wrappingDiv.current) return

    const inputVal = props.value as string
    const value = props.type === 'password' ? Array(inputVal.length).fill('*').join('') : inputVal
    wrappingDiv.current.dataset.value = value
  }, [props.value, props.type])

  return (
    <TextField
      sx={{ minWidth: initialWidth }}
      ref={wrappingDiv}
      variant="standard"
      {...props}
      inputProps={{ ...props.inputProps, size: 1, ref }}
    />
  )
}

/**
 * This component accepts a forwarded ref, e.g. from the Controller in InlineInputController,
 * which is then passed along to the underlying `<input />`
 */
export const InlineInput = React.forwardRef(_InlineInput)

type InlineInputControllerProps<TValues extends FieldValues, TName extends Path<TValues>> = Omit<
  InlineInputProps,
  'name'
> &
  Pick<ControllerProps<TValues, TName>, 'name' | 'control' | 'rules'> & {
    disableErrorMessage?: boolean
  }
export const InlineInputController = <TValues extends FieldValues, TName extends Path<TValues>>({
  name,
  control,
  rules,
  helperText,
  disableErrorMessage,
  ...props
}: InlineInputControllerProps<TValues, TName>) => (
  <Controller
    render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
      const isError = Boolean((isTouched || submitCount > 0) && error?.message)
      return (
        <InlineInput
          {...props}
          {...field}
          error={isError}
          helperText={(!disableErrorMessage && isError && error?.message) || helperText}
        />
      )
    }}
    name={name}
    control={control}
    rules={rules}
  />
)

type InlineEmailInputControllerProps<TValues extends FieldValues, TName extends Path<TValues>> = Omit<
  InlineInputControllerProps<TValues, TName>,
  'type'
> & { required?: boolean }
/**
 * Thin wrapper around InlineInputController that sets `type="email"` and adds validation
 * for email format - `req
 */
export const InlineEmailInputController = <TValues extends FieldValues, TName extends Path<TValues>>({
  rules,
  required,
  ...props
}: InlineEmailInputControllerProps<TValues, TName>) => {
  const intl = useIntl()
  return (
    <InlineInputController<TValues, TName>
      autoComplete="email"
      {...props}
      rules={{
        required: required && intl.formatMessage({ id: 'profile.email.validation.required' }),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: intl.formatMessage({ id: 'profile.email.validation.invalid_format' }),
        },
        ...rules,
      }}
    />
  )
}
