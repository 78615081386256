const { version: packageVersion } = require('../package.json')
const amplifyBuild = (process.env.AWS_JOB_ID || 'dev').replace(/^0+/, '') // clear leading zeroes
const version = packageVersion.replace(/\.[0-9]+$/, `.${amplifyBuild}`) // replace patch version with build version

/**
 * @returns {string}
 */
exports.getVersion = () => version

if (require.main) {
  console.log(version)
}
