import { Theme } from '@mui/material'
export { Container, Grid, Divider, Box, Hidden } from '@mui/material'
export type { BoxProps, GridProps, ContainerProps } from '@mui/material'

export const iconGridStyle = ({ theme }: { theme: Theme }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '60px 1fr 20%',
  alignItems: 'start',
  gridColumnGap: theme.spacing(),
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '30px 1fr 20%',
  },
})
