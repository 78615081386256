import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type SearchBankingInstitutionsQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']['input']>
  preferredPlatform?: Types.InputMaybe<Types.BankingConnectionPlatform>
}>

export type SearchBankingInstitutionsQuery = {
  __typename?: 'Query'
  searchBankingInstitutions?: Array<{
    __typename?: 'UnifiedInstitution'
    id: number
    name: string
    preferredPlatform: Types.BankingConnectionPlatform
    logoUrl?: string | null
  }> | null
}

export const SearchBankingInstitutionsDocument = `
    query SearchBankingInstitutions($name: String, $preferredPlatform: BankingConnectionPlatform) {
  searchBankingInstitutions(name: $name, preferredPlatform: $preferredPlatform) {
    id
    name
    preferredPlatform
    logoUrl
  }
}
    `

export const useSearchBankingInstitutionsQuery = <TData = SearchBankingInstitutionsQuery, TError = Error>(
  variables?: SearchBankingInstitutionsQueryVariables,
  options?: UseQueryOptions<SearchBankingInstitutionsQuery, TError, TData>
) => {
  return useQuery<SearchBankingInstitutionsQuery, TError, TData>(
    variables === undefined ? ['SearchBankingInstitutions'] : ['SearchBankingInstitutions', variables],
    fetchGql<SearchBankingInstitutionsQuery, SearchBankingInstitutionsQueryVariables>(
      SearchBankingInstitutionsDocument,
      variables
    ),
    options
  )
}

useSearchBankingInstitutionsQuery.getKey = (variables?: SearchBankingInstitutionsQueryVariables) =>
  variables === undefined ? ['SearchBankingInstitutions'] : ['SearchBankingInstitutions', variables]
