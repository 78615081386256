import EditIcon from '@mui/icons-material/Edit'
import SaveAlt from '@mui/icons-material/SaveAlt'
import * as React from 'react'
import { Button, ButtonProps, LoadingButton } from '../inputs'
import { FormattedMessage } from 'react-intl'

export type ActionButtonProps = Omit<ButtonProps, 'href'> & { suffix?: React.ReactNode }
export const EditButton = ({ suffix, ...props }: ActionButtonProps) => (
  <Button color="primary" startIcon={<EditIcon />} size="small" variant="outlined" {...props}>
    <FormattedMessage id="common.edit" />
    {suffix && <> {suffix}</>}
  </Button>
)

type LoadingButtonProps = ActionButtonProps & { loading?: boolean }
export const SubmitButton = ({ suffix, children, ...props }: LoadingButtonProps) => (
  <LoadingButton
    loadingPosition="start"
    startIcon={<SaveAlt />}
    variant="contained"
    type="submit"
    size="small"
    {...props}
  >
    {children || <FormattedMessage id="common.save" />}
    {suffix && <> {suffix}</>}
  </LoadingButton>
)

export const CancelButton = (props: ButtonProps) => (
  <Button color="secondary" variant="text" size="small" {...props}>
    <FormattedMessage id="common.cancel.cancel" />
  </Button>
)
