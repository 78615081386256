import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import {
  ReenrollEmployeeMutation,
  ReenrollEmployeeMutationVariables,
  useReenrollEmployeeMutation,
} from './gql/reenrollEmployee_gen'

type UseReenrollMutationOptions = UseGqlMutationCallbacks<
  ReenrollEmployeeMutation,
  ReenrollEmployeeMutationVariables
>
export const useReenroll = (mutationOptions?: UseReenrollMutationOptions) => {
  const { mutateAsync: reenrollEmployee } = useReenrollEmployeeMutation()

  return useMutationWithInvalidation(reenrollEmployee, mutationOptions, 'all')
}
