import * as Types from '../../../graphql/generated.types'

import {
  BankingTransactionFragmentDoc,
  ReimbursementClaimFragmentDoc,
} from '../../../graphql/fragments/BankingTransactionFragment_gen'
import { BankingAccountFragmentDoc } from '../../../graphql/fragments/BankingAccountFragment_gen'
import { ErrorFragmentDoc } from '../../../graphql/fragments/ErrorFragment_gen'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ConfirmTransactionsMutationVariables = Types.Exact<{
  input: Types.ConfirmTransactionsInput
}>

export type ConfirmTransactionsMutation = {
  __typename?: 'Mutation'
  confirmTransactions?: {
    __typename?: 'ConfirmTransactionsPayload'
    bankingTransactions?: Array<{
      __typename?: 'BankingTransaction'
      id: string
      amount: number
      date: string
      estimatedSavings: number
      name: string
      merchantName?: string | null
      spendEligibility: {
        __typename?: 'SpendEligibility'
        confirmedPartialAmount?: number | null
        pretaxCategory: Types.PretaxCategory
        autoConfirmedAt?: string | null
        isDisconfirmable?: boolean | null
        confirmationStatus: {
          __typename?: 'ConfirmationStatus'
          updatedAt?: string | null
          value: Types.ConfirmationStatusValue
        }
      }
      account:
        | {
            __typename: 'BankingAccount'
            id: number
            billingSystemId?: string | null
            name?: string | null
            cardType?: Types.BankCardType | null
            accountType?: Types.BankingAccountType | null
            platform: Types.BankingAccountPlatform
            accountLast4?: string | null
            error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
          }
        | { __typename: 'CashPaymentAccount' }
      reimbursementClaim?: {
        __typename?: 'ReimbursementClaim'
        approvalStatus: Types.ReimbursementClaimApprovalStatus
        reviewedAt?: string | null
      } | null
      reimbursementStatus?: { __typename?: 'ReimbursementStatus'; isFullyReimbursed: boolean } | null
    }> | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const ConfirmTransactionsDocument = `
    mutation ConfirmTransactions($input: ConfirmTransactionsInput!) {
  confirmTransactions(input: $input) {
    bankingTransactions {
      ...BankingTransaction
    }
    inputErrors {
      argument
      message
    }
  }
}
    ${BankingTransactionFragmentDoc}
${BankingAccountFragmentDoc}
${ErrorFragmentDoc}
${ReimbursementClaimFragmentDoc}`

export const useConfirmTransactionsMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    ConfirmTransactionsMutation,
    TError,
    ConfirmTransactionsMutationVariables,
    TContext
  >
) => {
  return useMutation<ConfirmTransactionsMutation, TError, ConfirmTransactionsMutationVariables, TContext>(
    ['ConfirmTransactions'],
    (variables?: ConfirmTransactionsMutationVariables) =>
      fetchGql<ConfirmTransactionsMutation, ConfirmTransactionsMutationVariables>(
        ConfirmTransactionsDocument,
        variables
      )(),
    options
  )
}
