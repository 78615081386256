import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { BankingConnectionFragment, useConnectedCardsQuery } from '../../gql/connectedCardsQuery_gen'
import {
  DisconnectConnectionMutation,
  useDisconnectConnectionMutation,
} from '../../gql/disconnectConnection_gen'

type DisconnectMutationOptions = UseGqlMutationCallbacks<
  DisconnectConnectionMutation | void,
  BankingConnectionFragment
>

/**
 * This call sends an async _request_ to disconnect the instituion, but the institution may not
 * immediately be marked as "customer_disconnected"
 */
export const useDisconnect = (mutationOptions?: DisconnectMutationOptions) => {
  const intl = useIntl()
  const { mutateAsync: disconnectConnection } = useDisconnectConnectionMutation()
  return useMutationWithInvalidation(
    (connection) => {
      const disconnectConfirmed = confirm(
        intl.formatMessage(
          { id: 'connections.disconnect.are_you_sure' },
          { institutionName: connection.institution?.name }
        )
      )
      if (disconnectConfirmed) return disconnectConnection({ input: { connectionId: connection.id } })
      return Promise.resolve()
    },
    mutationOptions,
    [useConnectedCardsQuery.getKey()]
  )
}
