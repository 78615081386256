import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateFormSubmissionMutationVariables = Types.Exact<{
  input: Types.UpdateFormSubmissionInput
}>

export type UpdateFormSubmissionMutation = {
  __typename?: 'Mutation'
  updateFormSubmission?: {
    __typename?: 'UpdateFormSubmissionPayload'
    submissionId: number
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const UpdateFormSubmissionDocument = `
    mutation UpdateFormSubmission($input: UpdateFormSubmissionInput!) {
  updateFormSubmission(input: $input) {
    submissionId
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useUpdateFormSubmissionMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateFormSubmissionMutation,
    TError,
    UpdateFormSubmissionMutationVariables,
    TContext
  >
) => {
  return useMutation<UpdateFormSubmissionMutation, TError, UpdateFormSubmissionMutationVariables, TContext>(
    ['UpdateFormSubmission'],
    (variables?: UpdateFormSubmissionMutationVariables) =>
      fetchGql<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>(
        UpdateFormSubmissionDocument,
        variables
      )(),
    options
  )
}
