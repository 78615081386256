import { Grid, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAddressComponents } from './useAddressComponents'

type AddressFormProps = {
  title?: React.ReactNode
  children?: React.ReactNode
}
export const AddressForm = ({
  title = (
    <Typography variant="h2">
      <FormattedMessage id="profile.index.mailing_address" />
    </Typography>
  ),
  children,
}: AddressFormProps) => {
  const { onSubmit, action, content, error } = useAddressComponents()
  return (
    <form onSubmit={onSubmit}>
      <Grid container justifyContent="space-between" alignItems="center">
        {title}
        <Grid>{action}</Grid>
      </Grid>

      {children}
      {content}
      {error}
    </form>
  )
}
