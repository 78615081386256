import { HealthcheckItem, InertHealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'

type OrgLogoHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const OrgLogoHealthcheck = ({ org, todo }: OrgLogoHealthcheckProps) => {
  if (!org) return null
  if (org.onboardingState.isAlreadyLaunched) return null
  const logoRequirementSatisfied =
    org.onboardingState.isLogoPresent ||
    (org.workLocations.length > 0 && org.workLocations.every((location) => location?.logo))

  return logoRequirementSatisfied ? (
    <InertHealthcheckItem
      primary="Logo uploaded"
      secondary="Enrollment materials will include a logo your employees recognize"
      status="complete"
      todo={todo}
    />
  ) : (
    <HealthcheckItem
      primary="Organization logo required"
      secondary="Enrollment materials need a logo your employees recognize"
      status="incomplete"
      to="/manage/locations"
      todo={todo}
    />
  )
}
