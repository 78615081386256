import {
  Container,
  InfoDrawer,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from '@alice-financial/pretext-ui'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import SubwayIcon from '@mui/icons-material/Subway'
import TrainIcon from '@mui/icons-material/Train'
import TramIcon from '@mui/icons-material/Tram'
import * as React from 'react'
import { ContactSupport } from '../ContactSupport'
import { FormattedMessage } from 'react-intl'

const ANCHOR_NAME = 'eligible-expenses-info'

const CommuterListRoot = styled(List)({ columnCount: 2, maxWidth: '24rem', margin: '1rem auto' })
const getCommuterExpenses = () =>
  [
    { labelId: 'spending.commuter_expenses.subway', Icon: SubwayIcon },
    { labelId: 'spending.commuter_expenses.train', Icon: TrainIcon },
    { labelId: 'spending.commuter_expenses.bus', Icon: DirectionsBusIcon },
    { labelId: 'spending.commuter_expenses.ferry', Icon: DirectionsBoatFilledIcon },
    { labelId: 'spending.commuter_expenses.streetcar', Icon: TramIcon },
    { labelId: 'spending.commuter_expenses.light_rail', Icon: DirectionsRailwayIcon },
    { labelId: 'spending.commuter_expenses.rideshare', Icon: DirectionsCarIcon },
    { labelId: 'spending.commuter_expenses.parking_meter', Icon: LocalParkingIcon },
  ] as const

export const CommuterList = () => {
  return (
    <CommuterListRoot>
      {getCommuterExpenses().map(({ labelId, Icon }) => (
        <ListItem key={labelId}>
          <ListItemText primaryTypographyProps={{ fontSize: '0.9em', whiteSpace: 'nowrap' }}>
            <Icon fontSize="small" color="primary" sx={{ verticalAlign: 'middle' }} /> &nbsp;
            <FormattedMessage id={labelId} />
          </ListItemText>
        </ListItem>
      ))}
    </CommuterListRoot>
  )
}

const InfoContent = () => (
  <>
    <Container>
      <Typography gutterBottom>
        <FormattedMessage id="spending.eligible_expenses.eligible_expenses_and_the_irs" />
      </Typography>
      <CommuterList />
    </Container>
    <ContactSupport />
  </>
)

type EligibleExpensesInfoProps = {
  children: React.ReactNode
}
export const EligibleExpensesInfo = ({ children }: EligibleExpensesInfoProps) => {
  return (
    <InfoDrawer
      title={
        <Typography variant="h2">
          <FormattedMessage id="spending.eligible_expenses.whats_is_an_eligible_expense" />
        </Typography>
      }
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent />}
    >
      {children}
    </InfoDrawer>
  )
}
