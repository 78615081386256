import * as Types from '../generated.types'

export type TelephoneFragment = {
  __typename?: 'Telephone'
  nationalNumber: string
  isSmsConfirmed?: boolean | null
  isConfirmationCodeSent?: boolean | null
}

export const TelephoneFragmentDoc = `
    fragment Telephone on Telephone {
  nationalNumber
  isSmsConfirmed
  isConfirmationCodeSent
}
    `
