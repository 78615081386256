import {
  AliceCard,
  AliceCardAccountStatus,
  AliceCardPlatform,
  AliceCardProvisioningStatus,
  AliceCardStatus,
  AliceCardType,
  CardRequestTypeEnum,
} from '../../../graphql/generated.types'
import { AliceCardFragment } from '../gql/connectedCardsQuery_gen'
import { CombinedAliceCardAccount, useQuerySpendingConnections } from '../useQuerySpendingConnections'

export const DISPLAY_ACCOUNT_STATUSES: Array<AliceCardAccountStatus | undefined> = [
  AliceCardAccountStatus.Active,
  AliceCardAccountStatus.BalanceGated,
]

export const formatExpiryDate = (expiryDate: AliceCard['expiryDate']) => {
  const date = new Date(expiryDate)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = (date.getFullYear() % 100).toString().padStart(2, '0')
  return `${month}/${year}`
}

export const isIssued = (c: Pick<AliceCardFragment, 'status' | 'reportedMissingAt'>) =>
  !c.reportedMissingAt && [AliceCardStatus.Inactive, AliceCardStatus.Active].includes(c.status)

type MaybeAvailableAliceCard = Pick<AliceCardFragment, 'status' | 'reportedMissingAt' | 'cardType'>
const getAvailableCard =
  (cardType: AliceCardType) =>
  <TCard extends MaybeAvailableAliceCard>(cards?: Array<TCard>) =>
    cards && cards.find((c) => c.cardType === cardType && isIssued(c))

/**
 * This function checks to make sure an _issued_ instant card is in the list of passed-in cards, e.g.
 * to check that a list of the user's Alice Cards contains a usable Instant Card
 */
export const getAvailableInstantAliceCard = getAvailableCard(AliceCardType.Instant)
export const getAvailablePhysicalAliceCard = getAvailableCard(AliceCardType.Physical)

/**
 * Boolean check for whether a card is 'dead' - i.e. no longer usable
 */
export const isCardDead = (card?: Pick<AliceCardFragment, 'status' | 'reportedMissingAt'>) =>
  Boolean(
    card &&
      (card.status === AliceCardStatus.Terminated ||
        card.status === AliceCardStatus.Canceled ||
        card.reportedMissingAt)
  )

/**
 * Determine what kind of Alice card request is allowed based on what cards are passed in.
 * Essentially, if the user already has an issued Instant card, they can't request a new one,
 * and if they already have an issued Physical card, they can't request a new one.
 *
 * If there are no cards, they can request both - see unit tests for more examples
 */
export const useAliceCardRequestScope = (platform = AliceCardPlatform.Stripe) => {
  const {
    data: { aliceCardAccounts },
    ...fetchStatus
  } = useQuerySpendingConnections()
  const aliceCardAccount = findActiveCardAccount(aliceCardAccounts, platform)
  if (!aliceCardAccount) return { data: CardRequestTypeEnum.InstantAndPhysical, ...fetchStatus }
  const { cardProvisioningStatus } = aliceCardAccount

  const mayRequestPhysicalCard = cardProvisioningStatus.physical === null
  const mayRequestInstantCard = cardProvisioningStatus.instant === null

  if (mayRequestPhysicalCard && mayRequestInstantCard) {
    return { data: CardRequestTypeEnum.InstantAndPhysical, ...fetchStatus }
  }
  if (mayRequestInstantCard) return { data: CardRequestTypeEnum.Instant, ...fetchStatus }
  if (mayRequestPhysicalCard) return { data: CardRequestTypeEnum.Physical, ...fetchStatus }

  return { data: null, ...fetchStatus }
}

export const findActiveCardAccount = (
  aliceCardAccounts: Array<CombinedAliceCardAccount> | undefined,
  platform = AliceCardPlatform.Stripe
) =>
  aliceCardAccounts?.find(
    (account) =>
      [AliceCardAccountStatus.Active, AliceCardAccountStatus.BalanceGated].includes(account.status) &&
      account.platform === platform
  )

export const isPhysicalCardRequestPending = (aliceCardAccount: CombinedAliceCardAccount | undefined) =>
  aliceCardAccount?.status === AliceCardAccountStatus.Active &&
  aliceCardAccount.cardProvisioningStatus.physical === AliceCardProvisioningStatus.Requested

export const isInstantCardConnectionPending = (aliceCardAccount: CombinedAliceCardAccount | undefined) =>
  aliceCardAccount?.cardProvisioningStatus.instant === AliceCardProvisioningStatus.Requested

export const isAliceCardAccountBalanceGated = (aliceCardAccount: CombinedAliceCardAccount | undefined) =>
  aliceCardAccount?.status === AliceCardAccountStatus.BalanceGated
