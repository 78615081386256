import { Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { ErrorDisplay } from '../routes/ErrorDisplay'
import { useCurrentProfileType } from '../user/useProfileType'

/**
 * The root of the application - in general, there won't be much for users to
 * do on this page, but it will have logic for redirecting where appropriate
 *
 * The return type guarantees that this component will return _something_ for
 * all defined profile types
 */
export const Home = (): React.ReactElement => {
  const userProfileType = useCurrentProfileType()
  switch (userProfileType) {
    case undefined:
      return <></> // loading case - empty fragment is ok
    case 'provisional_admin':
      return <Navigate to="/onboard" replace />
    case 'organization_admin':
    case 'employer_admin':
      // /manage will handle any further redirects related to admin profile type
      return <Navigate to="/manage" replace />
    case 'user':
    case 'employee':
      return <Navigate to="/dashboard" replace />
    case null:
      return (
        <ErrorDisplay>
          <Typography gutterBottom>
            <FormattedMessage id="home.couldnt_find_your_employment_info" />
          </Typography>
        </ErrorDisplay>
      )
  }
}
