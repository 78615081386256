import { Container, Paper, styled, Theme, useMediaQuery } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { REDIRECT_PARAM } from '../routes/routeUtils'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'

const FormContainer = styled(Container)(({ theme }) => ({
  // a bit of extra vertical padding for this one-off layout
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(3),
  maxWidth: '30rem !important',
}))

type AuthFormLayoutProps = {
  children: React.ReactNode
}
export const AuthFormLayout = ({ children }: AuthFormLayoutProps) => {
  const isMobileWidth = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const { data: currentUserData } = useCurrentUserQuery()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const redirect = searchParams.get(REDIRECT_PARAM) || '/'

  // this current doesn't handle querystring params in the redirect
  if (currentUserData?.currentUser && location.pathname !== redirect) {
    return <Navigate to={redirect} replace />
  }

  return (
    <FormContainer>
      <Paper elevation={isMobileWidth ? 0 : 2} sx={{ padding: isMobileWidth ? '0.5em' : '2em' }}>
        {children}
      </Paper>
    </FormContainer>
  )
}
