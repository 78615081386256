import { alpha, Box, Grid, Link, LinkProps, styled, Typography } from '@alice-financial/pretext-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'

export const CardConnectLink = styled(Link)(({ theme }) => ({
  display: 'block',
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  height: '100%',
  textDecoration: 'none',
  transition: theme.transitions.create(['background-color', 'box-shadow']),
  '&:hover': {
    background: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
    boxShadow: theme.shadows[3],
  },
}))

export const LabelWithChevron = ({ children }: { children: React.ReactNode }) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    component={Typography}
    color="text.primary"
  >
    {children}
    <ChevronRightIcon color="primary" />
  </Grid>
)

export type CardConnectCardProps = {
  label: React.ReactNode
  to: string
  children?: React.ReactNode
  Card?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
} & LinkProps
/**
 * A utility component for rendering a card that displays info for connecting
 * an Alice Card or personal card on the card connect page
 */
export const SpendingConnectCard = ({ label, to, Card, children, ...props }: CardConnectCardProps) => (
  <CardConnectLink to={to} {...props}>
    <Grid container justifyContent="space-between" sx={{ mb: '0.8em' }}>
      {label}
    </Grid>
    {Card && (
      <Box mb={2} maxWidth={400}>
        <Card width="100%" />
      </Box>
    )}
    {children}
  </CardConnectLink>
)
