import { Typography } from '@alice-financial/pretext-ui'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import SavingsIcon from '@mui/icons-material/Savings'
import CreditCardIllustration from 'jsx:./assets/credit-card-illustration.svg'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { prettyTelString } from '../../../../utils/formatters/telFormat'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { FeatureItem, FeatureList } from '../FeatureList'
import { CardConnectCardProps, LabelWithChevron, SpendingConnectCard } from '../SpendingConnectCard'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { PERSONAL_CARDS_ROUTE } from './BankingConnections'
import { isAvailable } from './connect/bankingConnectionUtils'
import { BankingConnectionList } from './BankingConnectionList'

const CardRoot = ({ label, children }: Pick<CardConnectCardProps, 'label' | 'children'>) => {
  return (
    <SpendingConnectCard
      data-testid="connect-personal"
      to={PERSONAL_CARDS_ROUTE}
      label={<LabelWithChevron>{label}</LabelWithChevron>}
      Card={CreditCardIllustration}
    >
      {children}
    </SpendingConnectCard>
  )
}

export const BankingConnectionConnectCard = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser
  const {
    data: { bankingConnections },
  } = useQuerySpendingConnections()
  const availableConnections = bankingConnections.filter(isAvailable)
  const connectionsCount = availableConnections.length || 0

  const features: Array<FeatureItem> = [
    { Icon: CreditCardIcon, messageId: 'cards.personal.feature_normal_spending' },
    { Icon: DirectionsBusIcon, messageId: 'cards.personal.feature_eligible_transaction_scanning' },
    { Icon: SavingsIcon, messageId: 'cards.personal.feature_adaptive_benefits' },
  ]

  if (connectionsCount > 0) {
    const label =
      connectionsCount > 1 ? (
        <FormattedMessage id="connections.connection_count_plural" values={{ connectionsCount }} />
      ) : (
        <FormattedMessage id="connections.connection_count_singular" />
      )
    return (
      <SpendingConnectCard label={<LabelWithChevron>{label}</LabelWithChevron>} to="/cards/personal">
        <BankingConnectionList bankingConnections={availableConnections} />
        {user?.tel && (
          <Typography variant="body2">
            <FormattedMessage
              id="connections.will_text_when_eligible_txn"
              values={{ tel: prettyTelString(user.tel.nationalNumber) }}
            />
          </Typography>
        )}
      </SpendingConnectCard>
    )
  }
  return (
    <CardRoot label={<FormattedMessage id="connections.all_connections.personal_cards.heading" />}>
      <FeatureList features={features} />
    </CardRoot>
  )
}
