import {
  AliceCard,
  AliceCardStatus,
  BankingAccountPlatform,
  BankingConnection,
  BankingConnectionStatus,
} from '../../graphql/generated.types'

type AliceCardWithStatus = Pick<AliceCard, 'status'>
export const getBestAliceCard = <TCard extends AliceCardWithStatus>(
  aliceCards?: Array<TCard>
): TCard | undefined => {
  if (!aliceCards) return undefined
  return (
    aliceCards.find((c) => c.status === AliceCardStatus.Active) ||
    aliceCards.find((c) => c.status === AliceCardStatus.Inactive) ||
    aliceCards.find((c) => c.status === AliceCardStatus.Canceled) ||
    aliceCards.find((c) => c.status === AliceCardStatus.Terminated)
  )
}

type BankingConnectionWithStatus = Pick<BankingConnection, 'status'>
export const getBestBankingConnection = <TConnection extends BankingConnectionWithStatus>(
  connections: Array<TConnection>
): TConnection | undefined => {
  return (
    connections.find((c) => c.status === BankingConnectionStatus.Connected) ||
    connections.find((c) => c.status === BankingConnectionStatus.Pending) ||
    connections.find((c) => c.status === BankingConnectionStatus.ConnectionError) ||
    connections.find((c) => c.status === BankingConnectionStatus.CustomerDisconnected) ||
    connections.find((c) => c.status === BankingConnectionStatus.SystemDisconnected)
  )
}

export const platformDisplayName: Record<BankingAccountPlatform, string> = {
  [BankingAccountPlatform.Omny]: 'OMNY card', // no longer supported, but it exists
  [BankingAccountPlatform.Finicity]: 'Mastercard',
  [BankingAccountPlatform.Plaid]: 'Plaid',
  [BankingAccountPlatform.Quovo]: 'Quovo',
  [BankingAccountPlatform.Offline]: 'Offline',
  [BankingAccountPlatform.AliceCardStripe]: 'Alice Card',
  [BankingAccountPlatform.AliceCardSynapse]: 'Alice Card',
  [BankingAccountPlatform.Stripe]: 'Stripe',
}
