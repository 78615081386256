import React from 'react'
import { Typography, ExternalLink } from '@alice-financial/pretext-ui'
import { FormattedMessage } from 'react-intl'

export const AliceCardTosLabel = () => {
  return (
    <Typography variant="body2">
      <FormattedMessage
        id="cards.alice.i_have_read_agreements"
        values={{
          'stripe-card-agreement': () => (
            <ExternalLink target="_blank" href="https://stripe.com/issuing/e-sign-disclosure/legal">
              <FormattedMessage id="cards.alice.stripe-card-agreement" />
            </ExternalLink>
          ),
          'stripe-card-user-terms': () => (
            <ExternalLink
              target="_blank"
              href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
            >
              <FormattedMessage id="cards.alice.stripe-card-user-terms" />
            </ExternalLink>
          ),
          'apple-pay-terms': () => (
            <ExternalLink
              target="_blank"
              href="https://stripe.com/legal/issuing/celtic/apple-payment-platform-program-manager-customer-terms-and-conditions"
            >
              <FormattedMessage id="cards.alice.apple-pay-terms" />
            </ExternalLink>
          ),
        }}
      />
    </Typography>
  )
}
