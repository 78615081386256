/**
 * A collection of type-safe functional programming helpers - implement type-safe
 * helpers here instead of importing utility dependencies like lodash
 */

import memoizeOne from 'memoize-one'

// DON'T re-implement any built-in JS methods, e.g. `map`, `reduce`, `filter`

export const groupBy = <T, K extends string | number | symbol>(list: Array<T>, getKey: (item: T) => K) =>
  list.reduce(
    (acc, currentItem) => {
      const group = getKey(currentItem)
      if (!acc[group]) acc[group] = []
      acc[group].push(currentItem)
      return acc
    },
    {} as Record<K, Array<T>>
  )

/**
 * This generic higher-order function applies a selector to a list of items,
 * ultimately returning a list of the unique values returned by the selector.
 * In the future we might want to accept an optional comparator function
 * to have a more flexible 'equality check'
 */
export const makeGetUniqueValues = <TItem, TValue>(getValue: (item: TItem) => TValue) =>
  memoizeOne((items: Array<TItem>) =>
    items.reduce<Array<TValue>>((acc, item) => {
      const value = getValue(item)
      if (!acc.includes(value)) acc.push(value)
      return acc
    }, [])
  )
