import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/PriorityHigh'
import { CircularProgress, SnackbarProps } from '@mui/material'
import { styled, Theme, useTheme } from '@mui/material/styles'
import * as React from 'react'
import { Snackbar } from '../../feedback'
import { Box } from '../../layout'
import { Paper } from '../../surfaces'

type NotifIconProps = {
  color: string
  children: React.ReactElement
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const NotifIconInner = styled(Box)(({ theme }) => {
  const diameter = parseInt(theme.spacing(6), 10)
  return {
    boxShadow: theme.shadows[2],
    position: 'relative',
    margin: '0 auto',
    padding: theme.spacing(),
    bottom: -diameter / 2,
    width: diameter,
    height: diameter,
    textAlign: 'center',
    fontSize: (diameter * 2) / 3,
    lineHeight: 1,
    borderRadius: '100%',
    color: theme.palette.common.white,
    transition: theme.transitions.create('background-color'),
  }
})
/**
 * a circular icon that container that spills over the bottom of the parent div
 */
const NotifIcon = ({ color, onClick, children }: NotifIconProps) => {
  return (
    <Box textAlign="center" position="absolute" bottom={0} left={0} right={0}>
      <NotifIconInner sx={{ backgroundColor: color }} onClick={onClick}>
        {children}
      </NotifIconInner>
    </Box>
  )
}

export type StatusVariant = 'success' | 'error' | 'pending'

const STATUS_PROPS: Record<StatusVariant, (theme: Theme) => { color: string; hideDuration?: number }> = {
  success: (theme: Theme) => ({
    color: theme.palette.primary.main,
    hideDuration: 4000,
  }),
  error: (theme: Theme) => ({ color: theme.palette.error.main }),
  pending: (theme: Theme) => ({ color: theme.palette.grey[300] }),
}
type StatusProps = SnackbarProps & {
  variant: StatusVariant
}
/**
 * This is a notification/alert that does not block the rest of the UI and is
 * intended to show the status of some action, typically an API call. Use the
 * `open` prop to open the notification - the content should typically be
 * text _only_
 *
 * @see https://mui.com/material-ui/api/snackbar/
 */
export const StatusNotification = (props: StatusProps) => {
  const theme = useTheme()
  const { color, hideDuration } = STATUS_PROPS[props.variant](theme)
  return (
    <Snackbar
      role={props.variant === 'error' ? 'alert' : 'status'}
      autoHideDuration={hideDuration}
      {...props}
    >
      <Box p={4} component={Paper} elevation={4}>
        {props.children}
        <NotifIcon color={color} onClick={(e) => props.onClose && props.onClose(e, 'escapeKeyDown')}>
          <>
            {props.variant === 'error' && <ErrorIcon fontSize="inherit" color="inherit" />}
            {props.variant === 'success' && <CheckIcon fontSize="inherit" color="inherit" />}
            {props.variant === 'pending' && <CircularProgress size="100%" color="inherit" thickness={5} />}
          </>
        </NotifIcon>
      </Box>
    </Snackbar>
  )
}
