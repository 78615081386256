import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTrackingContext } from './TrackingProvider'
import { UtmSearchParams } from './types'

export type UseUtmParamsCallback = (utmParams: UtmSearchParams) => void

const UTM_PARAM_KEYS: Array<keyof UtmSearchParams> = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term',
]

const isUtmParam = (key: string | null): key is keyof UtmSearchParams =>
  UTM_PARAM_KEYS.includes(key as keyof UtmSearchParams)

// as long as one key exists, we're good
const isUtmObject = (obj: UtmSearchParams): obj is UtmSearchParams => Object.keys(obj).length > 0

const extractUtmParams = (searchParams: URLSearchParams): UtmSearchParams | undefined => {
  const utmParams = UTM_PARAM_KEYS.reduce<UtmSearchParams>((acc, reqParam) => {
    const value = searchParams.get(reqParam)
    if (value && isUtmParam(reqParam)) acc[reqParam] = value
    return acc
  }, {})
  if (!isUtmObject(utmParams)) return // ignore result if the result isn't meaningful
  return utmParams
}
/**
 * 'utm' parameters are sent as querystring params from email/marketing campaigns
 * or DMLs. There is a strict set of supported keys.
 *
 * This hook allows the caller to handle any parsed utm parameters using a callback.
 * Internally, the hook will ensure that the parameters are stored in the analytics
 * session context.
 *
 * Note that this hook will return a new value on _every_ querystring change
 */
const useUtmParams = () => {
  const [searchParams] = useSearchParams()
  const utmParams = React.useMemo(() => extractUtmParams(searchParams), [searchParams])
  return utmParams
}

export const UTM_PARAM_TRACKING_PROPERTY = 'utmParams'

/**
 * This component will ensure that the `trackingContext.utmParams` value is set
 * when utm querystring parameters appear (usually on first load of the app)
 */
export const TrackUtmParams = () => {
  const utmParams = useUtmParams()
  const [globalContext, setTrackingProperty] = useTrackingContext()

  React.useEffect(() => {
    if (!utmParams || globalContext.utmParams) return // _only_ use the first-set value

    setTrackingProperty(UTM_PARAM_TRACKING_PROPERTY, utmParams)
  }, [setTrackingProperty, utmParams, globalContext.utmParams])

  return null
}
