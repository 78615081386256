import { Divider, Grid, List, Typography } from '@mui/material'
import * as React from 'react'
import { isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { ManageWorkLocation } from './ManageWorkLocation'
import { WorkLocationItem } from './WorkLocationItem'
import { WorkLocation } from '../../graphql/generated.types'

type WorkLocationListProps = {
  org: OrganizationDetailFragment
}

export const WorkLocationList: React.FC<WorkLocationListProps> = ({ org }: WorkLocationListProps) => {
  const locations = org.workLocations.filter(isNotNull).sort((a, b) => {
    const displayName = (location: WorkLocation) => location.nickname || location.name
    return displayName(a).localeCompare(displayName(b))
  })

  return (
    <div>
      <Divider sx={{ marginBottom: 1 }} />
      <List>
        {locations.length > 0 ? (
          locations.map((location) => <WorkLocationItem key={location.id} org={org} location={location} />)
        ) : (
          <Typography variant="h3" color="primary">
            We couldn&apos;t find any locations.
          </Typography>
        )}
      </List>
      <Divider sx={{ marginBottom: 1 }} />
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm="auto">
          <Typography variant="h3" color="primary">
            Add a Location
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <ManageWorkLocation org={org} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
    </div>
  )
}
