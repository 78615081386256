import * as React from 'react'
import NumberInput, { NumberFormatProps } from 'react-number-format'
import { InlineInput } from './InlineInput'
import { InlineInputProps } from './types'

export type InlineMaskedInputProps = Omit<NumberFormatProps, 'type' | 'size'> & InlineInputProps
/**
 * Base component for constructing inputs that follow particular formats - in general,
 * you should not need to render this component directly, but instead provide a wrapper
 * that 'standardizes' the mask for things like currency, phone numbers, etc.
 */
export const InlineMaskedInput = ({ onChange, name, ...props }: InlineMaskedInputProps) => (
  <NumberInput
    // InlineInput's `color` props is a subset of string
    // @ts-expect-error the customInput prop is broadening the `color` type to `string`
    customInput={InlineInput}
    onValueChange={({ value: v }, { event }) =>
      onChange &&
      onChange({
        ...event,
        target: { name, value: v } as unknown as HTMLInputElement,
      } as React.ChangeEvent<HTMLInputElement>)
    }
    name={name}
    {...props}
    type="text"
  />
)
