import { useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { Button, InertHealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { getServerErrorHandler } from '../../../utils/forms/ServerError'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useOrg } from '../../orgDashboard/organization/useOrg'
import { usePayrollInstructionsCompleteMutation } from './gql/payrollInstructionsComplete_gen'

type PayrollInstructionsCompleteProps = {
  orgId: number
}

const PayrollInstructionsCompleteButton: React.FC<PayrollInstructionsCompleteProps> = ({ orgId }) => {
  const { setError } = useForm()
  const onError = getServerErrorHandler(setError)
  const mutationOptions = useMutationOptionsWithInvalidation({ onError }, [useOrg.getKey({ orgId })])
  const {
    mutate: payrollInstructionsComplete,
    isLoading,
    isSuccess,
  } = usePayrollInstructionsCompleteMutation(mutationOptions)

  const handleClick = () => payrollInstructionsComplete({ input: { orgId } })

  return (
    <Button size="small" onClick={handleClick} variant="contained" disabled={isLoading || isSuccess}>
      <FormattedMessage id="organization.onboarding.payroll_instructions_complete" />
    </Button>
  )
}

type PayrollConnectionHealthCheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const PayrollConnectionHealthCheck = ({ org, todo }: PayrollConnectionHealthCheckProps) => {
  if (!org) return null
  const onboardingState = org.onboardingState
  if (onboardingState.isAlreadyLaunched) return null
  if (!onboardingState.isDoneWithPayrollInstructions) {
    return (
      <InertHealthcheckItem
        todo={todo}
        primary="Check your inbox for payroll setup instructions"
        secondary="When you've linked Alice to your payroll system, click on 'Payroll Connection Ready'."
        status="incomplete"
        action={<PayrollInstructionsCompleteButton orgId={org.id} />}
      />
    )
  }
  if (!onboardingState.isPayrollConnected) {
    return (
      <InertHealthcheckItem
        primary="Payroll information submitted"
        secondary="Our team is currently reviewing your payroll information"
        status="pending"
        todo={todo}
      />
    )
  }
  return (
    <InertHealthcheckItem
      primary="Payroll connected"
      secondary="Alice is connected to your payroll"
      status="complete"
      todo={todo}
    />
  )
}
