import { datadogRum } from '@datadog/browser-rum'
import { useStripe } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'

class CancelConnection extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'CancelConnection'
  }
}
const trackingContext = {
  source: 'StripeJS',
  req: 'collectBankAccountForSetup',
}
const trackConnectBankAccountError = (error?: { message?: string }, context?: Record<string, unknown>) => {
  datadogRum.addError(error || 'Unexpected setupIntent status', { ...trackingContext, ...context })
}
const trackConnectBankAccountEvent = (event: string, context?: Record<string, unknown>) => {
  datadogRum.addAction(event, { ...trackingContext, ...context })
}

const collectBankAccountForSetup = (
  stripe: Stripe,
  clientSecret: string,
  billingDetails: { name: string; email: string }
) => {
  return stripe
    .collectBankAccountForSetup({
      clientSecret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
    })
    .then((res) => {
      if (res.setupIntent?.status === 'requires_payment_method') {
        trackConnectBankAccountEvent('aborted bank connection')
        throw new CancelConnection()
      } else if (res.setupIntent?.status === 'requires_confirmation') {
        trackConnectBankAccountEvent('setupintent requires_confirmation')
      } else {
        trackConnectBankAccountEvent(res.setupIntent?.status || 'unknown status', { res })
        // we shouldn't get status == "success" here yet, ACH mandate acceptance is required first
        // this can happen if we reuse a setup intent that already succeeded, so we should prevent that from happening
        throw res.error || new Error('Unexpected setupIntent status')
      }
    })
    .catch((error) => {
      if (!(error instanceof CancelConnection)) trackConnectBankAccountError(error)
      throw error
    })
}

type CollectBankAccountForSetupMutationOptions = UseMutationOptions<void, unknown, { clientSecret: string }>
export const useCollectBankAccountForSetupMutation = (
  mutationOptions?: CollectBankAccountForSetupMutationOptions
) => {
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser
  const stripe = useStripe()

  return useMutation(
    ['connectBankAccount'],
    ({ clientSecret }) => {
      if (!stripe) throw new Error('Missing Stripe configuration, please refresh the page')
      if (!user) throw new Error('Missing user information')

      return collectBankAccountForSetup(stripe, clientSecret, {
        name: [user.firstName, user.lastName].join(' ').trim(),
        email: user.email || '',
      })
    },
    mutationOptions
  )
}
