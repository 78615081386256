import { Box, Button, Link, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  AliceCardIssuingPlatform,
  AliceCardProvisioningStatus,
  AliceCardType,
} from '../../../graphql/generated.types'
import { CombinedAliceCardAccount } from '../useQuerySpendingConnections'
import { AliceCardInfo } from './AliceCardInfo'
import { AliceCardItem } from './AliceCardItem'

type InstantCardDisplayProps = {
  aliceCardAccount: CombinedAliceCardAccount | undefined
}
export const CardDisplay = ({ aliceCardAccount }: InstantCardDisplayProps) => {
  if (!aliceCardAccount) return null
  const cards = aliceCardAccount.aliceCards.filter(
    (card) => card.issuingPlatform === AliceCardIssuingPlatform.Stripe
  )
  const instantCard = cards.find((card) => card.cardType === AliceCardType.Instant)
  const physicalCard = cards.find((card) => card.cardType === AliceCardType.Physical)

  const cardProvisioningStatus = aliceCardAccount?.cardProvisioningStatus
  const { instant: instantProvisioningStatus = null, physical: physicalProvisioningStatus = null } =
    cardProvisioningStatus || {}

  const secondaryAction =
    physicalProvisioningStatus === null ? (
      <Button component={Link} to="request" variant="outlined" fullWidth>
        <FormattedMessage id="cards.alice.order_physical_card_label" />
      </Button>
    ) : null

  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        <FormattedMessage id="cards.alice.list.heading" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.list.summary" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.list.summary_2" />
      </Typography>
      <Typography gutterBottom>
        <AliceCardInfo>
          <FormattedMessage id="common.learn_more" />
        </AliceCardInfo>
      </Typography>

      {(instantProvisioningStatus === AliceCardProvisioningStatus.Requested ||
        physicalProvisioningStatus === AliceCardProvisioningStatus.Requested) && (
        <Typography gutterBottom>
          <em>
            <FormattedMessage id="cards.alice.balance_gated_request_processing" />
          </em>
        </Typography>
      )}

      <Box minWidth={270} maxWidth={340} my={2} mx="auto">
        {instantProvisioningStatus !== null && (
          <AliceCardItem card={instantCard} requestedCardType={AliceCardType.Instant} />
        )}
        {physicalProvisioningStatus !== null && (
          <AliceCardItem card={physicalCard} requestedCardType={AliceCardType.Physical} />
        )}
        {secondaryAction && <Box mt={8}>{secondaryAction}</Box>}
      </Box>
    </>
  )
}
