import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type AdminEmployeeProfileQueryVariables = Types.Exact<{ [key: string]: never }>

export type AdminEmployeeProfileQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    id: number
    role: Types.EmployeeRole
    emailPreferences: {
      __typename?: 'EmailPreferences'
      monthlyFinance: boolean
      perInvoice: boolean
      perPbaWithdrawal: boolean
    }
    employer: {
      __typename?: 'Employer'
      id: number
      name?: string | null
      organization?: { __typename?: 'Organization'; id: number; name: string } | null
    }
  } | null
}

export type AdminEmployeeFragment = {
  __typename?: 'Employee'
  id: number
  role: Types.EmployeeRole
  emailPreferences: {
    __typename?: 'EmailPreferences'
    monthlyFinance: boolean
    perInvoice: boolean
    perPbaWithdrawal: boolean
  }
  employer: {
    __typename?: 'Employer'
    id: number
    name?: string | null
    organization?: { __typename?: 'Organization'; id: number; name: string } | null
  }
}

export const AdminEmployeeFragmentDoc = `
    fragment AdminEmployee on Employee {
  id
  role
  emailPreferences {
    monthlyFinance
    perInvoice
    perPbaWithdrawal
  }
  employer {
    id
    name
    organization {
      id
      name
    }
  }
}
    `
export const AdminEmployeeProfileDocument = `
    query AdminEmployeeProfile {
  employee {
    ...AdminEmployee
  }
}
    ${AdminEmployeeFragmentDoc}`

export const useAdminEmployeeProfileQuery = <TData = AdminEmployeeProfileQuery, TError = Error>(
  variables?: AdminEmployeeProfileQueryVariables,
  options?: UseQueryOptions<AdminEmployeeProfileQuery, TError, TData>
) => {
  return useQuery<AdminEmployeeProfileQuery, TError, TData>(
    variables === undefined ? ['AdminEmployeeProfile'] : ['AdminEmployeeProfile', variables],
    fetchGql<AdminEmployeeProfileQuery, AdminEmployeeProfileQueryVariables>(
      AdminEmployeeProfileDocument,
      variables
    ),
    options
  )
}

useAdminEmployeeProfileQuery.getKey = (variables?: AdminEmployeeProfileQueryVariables) =>
  variables === undefined ? ['AdminEmployeeProfile'] : ['AdminEmployeeProfile', variables]
