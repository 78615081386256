import {
  Callout,
  Collapse,
  Container,
  ExternalLink,
  Typography,
  useResponseNotification,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, useParams } from 'react-router'
import { AddressDisplay } from '../../../employeeDashboard/profile/address/AddressDisplay'
import { useOnboardingStatus } from '../useOnboardingStatus'
import { useProvisionalOrgQuery } from '../useQueryProvisionalOrg'
import { CompanyInfoForm } from './CompanyInfoForm'

export const CompanyInfoStep = () => {
  const { provisionalOrgId } = useParams()
  const { notifyError } = useResponseNotification()
  const [onboardingStatus] = useOnboardingStatus()
  const { data: onboardingProvisionalOrg, isError: isProvisionalOrgError } = useProvisionalOrgQuery(
    provisionalOrgId ? parseInt(provisionalOrgId, 10) : undefined
  )
  const createdOrg = onboardingProvisionalOrg?.organization
  const ready = onboardingStatus === 'no_onboarding' || Boolean(onboardingProvisionalOrg)
  const disabled = onboardingStatus === undefined

  React.useEffect(() => {
    if (isProvisionalOrgError) {
      notifyError("There was a problem loading your organization's onboarding status.")
    }
  }, [notifyError, isProvisionalOrgError])

  const canManageProvisionalOrg = Boolean(ready && !createdOrg)

  // If the user has a provisional org but we are not on the org's onboarding page,
  // redirect to the org's onboarding page
  if (onboardingProvisionalOrg && provisionalOrgId !== onboardingProvisionalOrg.id.toString()) {
    return <Navigate to={`/onboard/${onboardingProvisionalOrg.id}`} replace />
  }

  if (isProvisionalOrgError) return null // this is bad, but we're using `notifyError` to inform user

  return (
    <Container>
      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="organization.onboarding.company_info_heading" />
      </Typography>
      {!ready && (
        <Typography gutterBottom>
          <FormattedMessage id="common.flow.please_complete_previous" />
        </Typography>
      )}
      {createdOrg && (
        <>
          <Typography>
            <strong>{onboardingProvisionalOrg?.legalName}</strong>
          </Typography>
          <AddressDisplay address={onboardingProvisionalOrg?.address} />
          <Callout variant="body2" gutterBottom sx={{ marginTop: 2 }}>
            Organization info has been submitted. Please{' '}
            <ExternalLink href="mailto:admin-support@thisisalice.com">contact support</ExternalLink> to make
            changes.
          </Callout>
        </>
      )}
      <Collapse in={canManageProvisionalOrg}>
        {canManageProvisionalOrg && (
          <CompanyInfoForm provisionalOrg={onboardingProvisionalOrg} disabled={disabled} />
        )}
      </Collapse>
    </Container>
  )
}
