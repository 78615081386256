import {
  Box,
  FullDialog,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@alice-financial/pretext-ui'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import React, { useState } from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocation } from '../../graphql/generated.types'
import { useOrg } from '../organization/useOrg'
import { useDeleteWorkLocation } from './useDeleteWorkLocation'
import { WorkLocationForm } from './WorkLocationForm'

type WorkLocationItemProps = {
  org: OrganizationDetailFragment
  location: WorkLocation
}

export const WorkLocationItem: React.FC<WorkLocationItemProps> = ({ org, location }) => {
  const { mutate: deleteLocation } = useDeleteWorkLocation([useOrg.getKey({ orgId: org.id })])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const enrollmentMaterialsZipUrl = location?.enrollmentMaterials?.zippedMaterialsUrl

  const handleEditClick = () => {
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  return (
    <ListItem divider>
      <ListItemIcon>
        <LocationOnIcon color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" color="primary" fontWeight="bold">
            {location.nickname ? location.nickname : location.name}
          </Typography>
        }
        secondary={
          <Typography variant="caption">
            {location.nickname ? `${location.name},` : null} {location.address?.line1}{' '}
            {location.address?.line2}, {location.address?.cityName}, {location.address?.stateCode}{' '}
            {location.address?.zipcode}
          </Typography>
        }
      />
      <Grid flexDirection="column" alignItems="center">
        {location.logo?.thumbnailUrl && (
          <img
            src={location.logo?.thumbnailUrl}
            alt="Logo"
            width="100%"
            style={{ maxHeight: 50, objectFit: 'contain' }}
          />
        )}
      </Grid>
      {enrollmentMaterialsZipUrl && (
        <Tooltip title="Download enrollment materials" placement="top">
          <IconButton href={enrollmentMaterialsZipUrl}>
            <PermMediaOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Edit location information" placement="top">
        <IconButton onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete location" placement="top">
        <IconButton
          color="error"
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this location?')) {
              deleteLocation({ workLocationId: location.id })
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <FullDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        title={<Typography variant="h2">Edit location</Typography>}
      >
        <Box p={2}>
          <WorkLocationForm org={org} initialData={location} onSuccess={handleDialogClose} disableLookup />
        </Box>
      </FullDialog>
    </ListItem>
  )
}
