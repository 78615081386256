import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { DocRoute } from './DocRoute'

const FETCH_ORIGIN = process.env.ALICE_ORIGIN || ''
const useQueryHtml = (url: string) => useQuery(['html', url], () => fetch(url).then((res) => res.text()))

type FetchDocRouteProps = { src: string }
/**
 * If the HTML for the document is already served from a publicly-accessible URL,
 * this route component can be used to load it asynchronously and render it
 * as a regular `<DocRoute />` element.
 */
export const FetchDocRoute = ({ src }: FetchDocRouteProps) => {
  const fullyQualifiedUrl = src.startsWith('http') ? src : `${FETCH_ORIGIN}${src}`
  const { data: docHtml } = useQueryHtml(fullyQualifiedUrl)
  if (!docHtml) return null

  return <DocRoute docHtml={docHtml} />
}
