import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { Outlet, useParams } from 'react-router'

export const TrackEnrollmentCode = () => {
  const { enrollmentCode } = useParams()
  React.useEffect(() => {
    if (!enrollmentCode) return
    const currentEnrollmentCode = datadogRum.getGlobalContext().enrollment_code
    if (currentEnrollmentCode === enrollmentCode) return
    datadogRum.setGlobalContextProperty('enrollment_code', enrollmentCode)
  }, [enrollmentCode])

  return <Outlet />
}
