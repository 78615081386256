import * as React from 'react'
import { Navigate, Outlet } from 'react-router'
import { useAliceCardFeatureEnabled } from '../useQuerySpendingConnections'

/**
 * A React Router route handler that ensures child routes are only displayed if the user's
 * org has enabled Alice Cards
 */

export const AliceCardEnabled = () => {
  const isAliceCardFeatureEnabled = useAliceCardFeatureEnabled()

  if (isAliceCardFeatureEnabled === undefined) return null // wait for API data that determines route validity
  if (!isAliceCardFeatureEnabled) return <Navigate to="personal" replace />

  return <Outlet />
}
