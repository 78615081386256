const FORMATTING_CHARS = /^\+1|\D*/g // leading "+1" and any other non-digit characters

export const TEN_DIGIT_NUM = /^\d{10}$/

export const cleanTelString = (tel: string) => tel.replace(FORMATTING_CHARS, '')

export const prettyTelString = (tel: string) => {
  if (tel.includes('#') || tel.includes('ext')) return tel // extensions and special 'press # to continue' won't be formatted
  const clean = cleanTelString(tel)
  if (!TEN_DIGIT_NUM.test(clean)) return tel // we can't format a non-ten-digit number, so just return as-is
  return `+1 (${clean.slice(0, 3)}) ${clean.slice(3, 6)}-${clean.slice(6)}`
}
