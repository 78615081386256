import { PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { EmployeeList } from './EmployeeList'

export const EmployeeListPage = () => {
  // don't return early if employees is empty, we want to show the empty state and start loading the grid component

  return (
    <PageBody maxWidth={1600}>
      <Typography variant="h1" gutterBottom>
        Employees
      </Typography>

      <EmployeeList />
    </PageBody>
  )
}
