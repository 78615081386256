import * as React from 'react'
import { Box, BoxProps, Grid } from '../layout'

type ActionLayoutProps = {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  autoWidth?: boolean
  disablePadding?: boolean
} & BoxProps
export const ActionLayout = ({
  primary,
  secondary,
  autoWidth,
  disablePadding,
  ...boxProps
}: ActionLayoutProps) => (
  <Box
    mt={2}
    mb={disablePadding ? undefined : 3}
    component={Grid}
    container
    spacing={2}
    flexDirection={primary ? 'row-reverse' : 'row'}
    justifyContent="flex-start"
    {...boxProps}
  >
    {primary && (
      <Box
        textAlign="right"
        component={Grid}
        item
        xs={12}
        sm={autoWidth ? 'auto' : 5}
        columnGap={1}
        display="flex"
        justifyContent="flex-end"
      >
        {primary}
      </Box>
    )}
    {secondary && (
      <Box textAlign="center" component={Grid} item xs={12} sm="auto">
        {secondary}
      </Box>
    )}
  </Box>
)
