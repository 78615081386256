import { Location, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export const REDIRECT_PARAM = 'redirect_to' // this param is used by both Ada and user-web *DO NOT CHANGE*

export const locationToRedirectToParams = (location: Location): string => {
  const searchParams = new URLSearchParams({ redirect_to: `${location.pathname}${location.search}` })
  return `?${searchParams}`
}

export const useReturnLinkTo = (targetUrl: string = '') => {
  const location = useLocation()
  return `${targetUrl}${locationToRedirectToParams(location)}`
}

export const useSearchParamsRedirectPath = (fallbackPath = '/') => {
  const [searchParams] = useSearchParams()
  return searchParams.get(REDIRECT_PARAM) || fallbackPath
}
/**
 *
 * This hook returns a function that will redirect to the path specified by the `redirect_to` param
 * in the querystring, or the supplied fallback path if no querystring redirect path appears
 */
export const useSearchParamsRedirect = (fallbackPath = '/') => {
  const redirect = useSearchParamsRedirectPath(fallbackPath)
  const [redirectRoot, search] = redirect.split('?')
  if (!redirectRoot) throw new Error('Redirect path is required')
  // collect all search params after `?` in redirect
  const searchParams = new URLSearchParams(search || '')
  const navigate = useNavigate()
  return (params: URLSearchParams = new URLSearchParams()) => {
    const mergedParams = mergeSearchParams(searchParams, params)
    if (mergedParams.size === 0) return navigate(redirectRoot)
    navigate(`${redirectRoot}?${mergedParams}`)
  }
}

const mergeSearchParams = (a: URLSearchParams, b: URLSearchParams) => {
  const merged = new URLSearchParams(a.toString())
  b.forEach((value, key) => {
    merged.set(key, value)
  })
  return merged
}
