import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'
import { useAnalytics } from './AnalyticsProvider'
import { UTM_PARAM_TRACKING_PROPERTY } from './TrackUtmParams'
import { useTrackingContext } from './TrackingProvider'
import { useEmployeeQuery } from '../employeeDashboard/gql/employee_gen'

export const USER_ID_PARAM = 'user_id'

/**
 * Read any `user_id` param that appears in the querystring
 */
const useParamUserId = () => {
  const [searchParams] = useSearchParams()
  const paramUserId = searchParams.get(USER_ID_PARAM)
  const [userId, setUserId] = React.useState(paramUserId)
  React.useEffect(() => {
    if (!paramUserId || paramUserId === userId) return
    setUserId(userId)
  }, [paramUserId, userId])
  return userId
}

/**
 * Track the user ID as soon as it is available. Currently there are two sources
 * for this data
 *
 * 1. Some of our marketing-related emails will provide the user ID as a querystring
 *    param alongside UTM params - this user ID can be used in logged-out views
 * 2. User API response will have the correct logged-in user ID, which should
 *    always override a querystring-derived ID
 */
export const TrackUserId = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  const [globalContext] = useTrackingContext()
  const utmParams = globalContext[UTM_PARAM_TRACKING_PROPERTY]
  const paramUserId = useParamUserId()
  const analytics = useAnalytics()
  const helpScoutSecureBeaconToken = currentUserData?.currentUser?.helpScoutSecureBeaconToken

  const employeeIdentifiers = React.useMemo(() => {
    const employeeId = employee?.id
    const employerId = employee?.employer?.id
    const orgId = employee?.employer?.organization?.id

    return { employeeId, employerId, orgId }
  }, [employee?.employer?.id, employee?.employer?.organization?.id, employee?.id])

  React.useEffect(() => {
    const { id: currentAnalyticsUserId } = analytics.getUser()

    const loggedInUserId = currentUserData?.currentUser?.id.toString()
    const email = currentUserData?.currentUser?.email
    const userIdToTrack = loggedInUserId || paramUserId
    // only track id the first time a it is available
    if (!userIdToTrack || currentAnalyticsUserId === userIdToTrack) return

    console.debug('identify', userIdToTrack, { ...utmParams, ...employeeIdentifiers })
    analytics.identify(userIdToTrack, { ...utmParams, ...employeeIdentifiers })

    if (typeof email === 'string' && typeof helpScoutSecureBeaconToken === 'string') {
      const beaconProps = { email, signature: helpScoutSecureBeaconToken }
      window.Beacon && window.Beacon('identify', beaconProps)
    }
  }, [analytics, currentUserData, helpScoutSecureBeaconToken, paramUserId, utmParams, employeeIdentifiers])

  return null
}
