import { Grid, Link, Typography, useInfoDrawerControl } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { AuthFormLayout } from './AuthFormLayout'
import { PasswordForm } from './PasswordForm'
import { TelVerificationForm } from './TelVerificationForm'

export const Login = () => {
  const {
    onClose: hidePasswordForm,
    openTarget: passwordFormLink,
    open: showPasswordForm,
  } = useInfoDrawerControl('password')
  return (
    <AuthFormLayout>
      <Typography variant="h1" gutterBottom>
        <FormattedMessage id="auth.login.sign_in" />
      </Typography>
      {showPasswordForm ? <PasswordForm /> : <TelVerificationForm />}

      <Grid container justifyContent="center">
        <Typography variant="body2" fontWeight="bold" gutterBottom textAlign="center">
          {showPasswordForm ? (
            <Link to="#" onClick={hidePasswordForm}>
              <FormattedMessage id="auth.login.login_with_phone" />
            </Link>
          ) : (
            <Link to={passwordFormLink}>
              <FormattedMessage id="auth.login.login_with_password" />
            </Link>
          )}
        </Typography>

        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="bold" gutterBottom textAlign="center">
            <Link to="/enroll">
              <FormattedMessage id="enroll.enroll_using_email" />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </AuthFormLayout>
  )
}
