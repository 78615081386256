import { Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import aliceChatGifUrl from './assets/alice-chat.gif'
import { CarouselSlide, SlideImage } from './CarouselSlide'
import { FormattedMessage } from 'react-intl'

export const TextUs = ({ isActive }: { isActive: boolean }) => (
  <CarouselSlide
    title={<FormattedMessage id="enroll.how_it_works.text_us_back.heading" />}
    graphic={<SlideImage src={aliceChatGifUrl} />}
    isActive={isActive}
  >
    <Typography variant="body2" gutterBottom>
      <FormattedMessage id="enroll.how_it_works.text_us_back.send_confirmation_request" />
    </Typography>
    <Typography variant="body2">
      <FormattedMessage id="enroll.how_it_works.text_us_back.respond" />
    </Typography>
  </CarouselSlide>
)
