import { Box, FormControlLabel, Radio, RadioGroup } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useMutationNotifier } from '../../../../utils/useMutationNotifier'
import { useOrgQuery } from '../../organization/useOrg'
import { useSetUnifiedFunding } from '../funding/useSetUnifiedFunding'

export const UnifiedFundingForm = () => {
  const { data: orgData, isFetching: isUpdatingOrg } = useOrgQuery()
  const org = orgData?.organization
  const { mutate: setUnifiedFunding, isLoading: isSaving } = useSetUnifiedFunding(
    { orgId: org?.id },
    useMutationNotifier()
  )
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) =>
    setUnifiedFunding({ hasUnifiedBanking: value === 'true' })

  if (!org) return null
  const fundingStarted = (org.aliceCardFunding.availablePaymentMethods || []).length > 0
  if (fundingStarted) return null // may not change selection after funding has started

  const currentUnifiedFundingValue = Boolean(org.aliceCardProgram.hasUnifiedBanking)
  const disabled = isUpdatingOrg || isSaving

  return (
    <Box mb={2}>
      <RadioGroup value={currentUnifiedFundingValue} onChange={handleChange}>
        <FormControlLabel
          disabled={disabled}
          value={false}
          control={<Radio />}
          label="Each pay group uses its own account"
        />
        <FormControlLabel
          disabled={disabled}
          value={true}
          control={<Radio />}
          label="All pay groups use one account"
        />
      </RadioGroup>
    </Box>
  )
}
