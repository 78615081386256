import { Grid, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

type ImageProps = {
  src: string
  alt?: string
  height?: number
}
export const SlideImage = ({ src, alt, height }: ImageProps) => (
  <img style={{ maxWidth: '100%', maxHeight: height || 300 }} src={src} alt={alt} />
)

type SlideProps = {
  isActive: boolean
  title: React.ReactNode
  children: React.ReactNode
  graphic: React.ReactNode
}
export const CarouselSlide = ({ title, children, graphic, isActive }: SlideProps) => (
  <Grid
    container
    className={isActive ? 'active-slide' : undefined}
    flexDirection="column"
    sx={{ paddingTop: 2 }}
    height="100%"
  >
    <Typography variant="h2" textAlign="center" gutterBottom>
      {title}
    </Typography>
    <Grid item>{children}</Grid>
    {graphic && (
      <Grid item flex={1} alignContent="center" textAlign="center">
        {graphic}
      </Grid>
    )}
  </Grid>
)

export const useActiveSlide = (disableNav?: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchStep = searchParams.get('step') || '0'
  const searchStepInt = parseInt(searchStep, 10)
  const [internalStep, setInternalStep] = React.useState(0)
  if (disableNav) {
    return [internalStep, setInternalStep] as const
  }
  const setSearchStep = (setter: number | ((prev: number) => number)) => {
    const newStep = typeof setter === 'number' ? setter : setter(searchStepInt)
    if (newStep === 0) {
      searchParams.delete('step')
    } else {
      searchParams.set('step', newStep.toString())
    }
    setSearchParams(searchParams)
  }

  return [parseInt(searchStep, 10), setSearchStep] as const
}
