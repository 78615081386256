import { Container, PageBody } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate } from 'react-router'
import { useAliceCardRequestScope } from './aliceCardUtils'
import { RequestAliceCardForm } from './RequestAliceCardForm'
import { CardRequestTypeEnum } from '../../../graphql/generated.types'

export const AliceCardRequest = () => {
  const { data: requestScope, isFetching } = useAliceCardRequestScope()
  if (!requestScope && !isFetching) return <Navigate to="/cards/alice" replace />
  return (
    <PageBody>
      <Container>
        <RequestAliceCardForm scope={requestScope || CardRequestTypeEnum.InstantAndPhysical} />
      </Container>
    </PageBody>
  )
}
