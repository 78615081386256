import { Button, Container, styled } from '@alice-financial/pretext-ui'
import PrintIcon from '@mui/icons-material/Print'
import * as React from 'react'
import { PageBody } from '../routes/PageBody'
import { DocDisplay } from './DocDisplay'

const ButtonPosition = styled(Container)({
  position: 'sticky',
  top: 0,
  textAlign: 'right',
  paddingTop: '0.5em',
  '@media print': {
    display: 'none',
  },
})

const PrintButton = () => (
  <Button variant="contained" onClick={() => window.print()} endIcon={<PrintIcon />}>
    Print
  </Button>
)

type DocRouteProps = {
  docHtml: string
}
/**
 * A route that will display a document. If `docName` is provided, a logged-in user will be able
 * to email the document to themselves.
 */
export const DocRoute = ({ docHtml }: DocRouteProps) => {
  return (
    <PageBody>
      <ButtonPosition>{<PrintButton />}</ButtonPosition>
      <DocDisplay docHtml={docHtml} />
    </PageBody>
  )
}
