import { FormControl, FormHelperText, Select, SelectChangeEvent, SelectProps } from '@mui/material'
import * as React from 'react'
import { Controller, ControllerProps, FieldPathValue, FieldValues, Path, PathValue } from 'react-hook-form'

export { Select }
export type { SelectChangeEvent }

const intToString = (value: number | undefined): string =>
  value === undefined || isNaN(value) ? '' : value.toString()
const eventToInt = (e: SelectChangeEvent<unknown>): number => {
  if (typeof e.target.value !== 'string') return 0
  const output = parseInt(e.target.value, 10)
  return isNaN(output) ? 0 : output
}
export const valueAsNumberTransform = { input: intToString, output: eventToInt }

type SelectControllerProps<TValues extends FieldValues, TName extends Path<TValues>> = SelectProps<
  TValues[TName]
> &
  Pick<ControllerProps<TValues, TName>, 'control' | 'name' | 'rules'> & {
    transform?: {
      input: (value: FieldPathValue<TValues, TName>) => string
      output: (e: SelectChangeEvent<unknown>) => FieldPathValue<TValues, TName>
    }
  } & {
    formControlProps?: React.ComponentProps<typeof FormControl>
  }

// A function that takes a value that could be null or undefined and returns a stringified version of it
const stringify = <T extends object | number | boolean>(value: T | null | undefined): string =>
  value === null || value === undefined ? '' : value.toString()

/**
 * A wrapper around the MUI Select component
 *
 * **IMPORTANT** for optimal accessibility, the rendered `<Select>` will be a native HTML
 * `<select>` element, which means that you _must_ supply native `<option>` children rather
 * than `<MenuItem>`, and the values must be handled as strings
 *
 * @see https://mui.com/material-ui/react-select/#native-select
 */
export const SelectController = <TValues extends FieldValues, TName extends Path<TValues>>({
  name,
  control,
  rules,
  transform,
  fullWidth,
  ...props
}: SelectControllerProps<TValues, TName>) => {
  return (
    <Controller
      render={({ field, fieldState }) => {
        const value = (transform && transform.input(field.value)) || stringify(field.value)
        return (
          <FormControl fullWidth={fullWidth}>
            <Select
              {...props}
              {...field}
              onChange={transform ? (e) => field.onChange(transform.output(e)) : field.onChange}
              value={value as PathValue<TValues, TName>}
              error={Boolean(fieldState.error)}
              native
            />
            {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        )
      }}
      name={name}
      control={control}
      rules={rules}
    />
  )
}
