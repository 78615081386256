import { Button, Container, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { useEmployeeQuery } from '../../employeeDashboard/gql/employee_gen'
import { PageBody } from '../../routes/PageBody'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { useEnrollmentStatus } from '../useEnrollmentStatus'
import { useReenroll } from './useReenrollment'

export const ReenrollmentForm = () => {
  const { data: employeeData } = useEmployeeQuery()
  const employeeId = employeeData?.employee?.id
  const { mutate: reenroll, isLoading, isSuccess } = useReenroll()
  const disabled = isLoading || isSuccess
  if (!employeeId) return null
  return (
    <div data-testid="reenroll-form">
      <Button
        variant="contained"
        onClick={() => reenroll({ input: { employeeId } })}
        disabled={disabled}
        color="primary"
        size="large"
      >
        <FormattedMessage id="reenroll.landing.submit_reenrollment" />
      </Button>

      <Typography variant="caption" component="p" color="text.secondary" textAlign="center" sx={{ mt: 1 }}>
        <em>
          <FormattedMessage id="reenroll.landing.purchases_while_unenrolled_not_eligible" />
        </em>
      </Typography>
    </div>
  )
}

// This component is the one to show to EEs if hired within 30 days of termination
// at another org, so spending stays active, and just needs confirmation.
export const ReenrollmentConfirmation = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser
  const enrollmentStatus = useEnrollmentStatus()

  if (enrollmentStatus === undefined) return null
  if (enrollmentStatus !== 'must_reenroll_by_confirming') return <Navigate to="/enroll" />

  return (
    <PageBody>
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography variant="h2" textAlign="center" gutterBottom color="primary">
          <FormattedMessage
            id="employee_dashboard.main.welcome_back"
            values={{ name: user?.preferredName || user?.firstName }}
          />
        </Typography>

        <Typography variant="body1" gutterBottom>
          <FormattedMessage id="reenroll.landing.to_reenroll_instruction" />
        </Typography>

        <ReenrollmentForm />
      </Container>
    </PageBody>
  )
}
