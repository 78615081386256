import { Box, Button, Grid, Link, TextFieldController, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { getServerErrorHandler } from '../../utils/forms/ServerError'
import { AuthFormLayout } from './AuthFormLayout'
import { ResetPasswordFormValues } from './types'
import { useSubmitResetPassword } from './useSubmitResetPassword'

export const ResetPassword = () => {
  const intl = useIntl()
  const { control, handleSubmit, watch, setError } = useForm<ResetPasswordFormValues>({
    mode: 'all',
    defaultValues: { password: '', password_confirmation: '' },
  })
  const password = watch('password')
  const onError = getServerErrorHandler(setError, ['password', 'password_confirmation'])
  const { mutate: submitResetPassword, isLoading } = useSubmitResetPassword({ onError })
  const isSubmitDisabled = isLoading // ignore isSuccess because technically you can reset immediately after resetting
  const onSubmit = handleSubmit((values) => submitResetPassword(values))

  return (
    <AuthFormLayout>
      <Typography variant="h1" gutterBottom>
        <FormattedMessage id="auth.reset_password.heading" />
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldController
              control={control}
              label={<FormattedMessage id="auth.reset_password.new_password_label" />}
              name="password"
              type="password"
              autoComplete="new-password"
              autoFocus
              fullWidth
              rules={{
                required: intl.formatMessage({ id: 'profile.password.validation.required' }),
                minLength: {
                  value: 8,
                  message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 8 }),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldController
              control={control}
              name="password_confirmation"
              autoComplete="new-password"
              label={<FormattedMessage id="profile.password_confirmation_label" />}
              type="password"
              required
              fullWidth
              rules={{
                validate: (value) =>
                  value === password || intl.formatMessage({ id: 'auth.reset_password.validation.no_match' }),
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Box component={Grid} item xs={12} sm={6} mt={5} mb={3}>
            <Button variant="contained" type="submit" fullWidth disabled={isSubmitDisabled}>
              <FormattedMessage id="auth.reset_password.request_reset" />
            </Button>
          </Box>
          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold" textAlign="center">
              <Link to="/login">
                <FormattedMessage id="auth.login.sign_in" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </AuthFormLayout>
  )
}
