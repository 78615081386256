import { Box, BoxProps, styled, Typography } from '@mui/material'
import AliceWordmarkWhiteIcon from 'jsx:../../assets/alice-wordmark-white.svg'
import * as React from 'react'
import { Link } from '../../navigation'

// Modifying the footer's content/height? Make sure to update `paddingBottom` at
// libraries/pretext-ui/theme/components.tsx (look up for "space for global
// absolutely-positioned footer").

const FooterLayout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(4, 4),
  textAlign: 'center',
  color: theme.palette.getContrastText(theme.palette.primary.main),
  position: 'absolute',
  bottom: 0,
}))

export type FooterProps = BoxProps & {
  homepagePath: string
  children: React.ReactNode
}
export const Footer = ({ homepagePath, children, ...boxProps }: FooterProps) => (
  <FooterLayout {...boxProps}>
    <Link to={homepagePath}>
      <AliceWordmarkWhiteIcon width={120} />
    </Link>
    {children}
  </FooterLayout>
)

export const FooterText = styled(Typography)({
  fontSize: '0.8rem',
  color: 'inherit',
})
