import {
  Box,
  BoxProps,
  Button,
  Container,
  MobileStepper,
  Theme,
  useMediaQuery,
} from '@alice-financial/pretext-ui'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import SwipeableViews from 'react-swipeable-views'
import { useActiveSlide } from './slides/CarouselSlide'
import { CashBack } from './slides/CashBack'
import { Landing } from './slides/Landing'
import { Spend } from './slides/Spend'
import { Testimonials } from './slides/Testimonials'
import { TextUs } from './slides/TextUs'

const NextButton = ({
  activeStep,
  numSteps,
  onClick,
  disableNav,
}: {
  activeStep: number
  numSteps: number
  onClick?: () => void
  disableNav?: boolean
}) => {
  const isLastStep = activeStep === numSteps - 1
  const isFirstStep = activeStep === 0
  const labelId = isFirstStep ? 'common.how_it_works' : isLastStep ? 'common.done' : 'common.next'
  return (
    <Button
      sx={{ opacity: isLastStep && !disableNav ? 0 : 1 }}
      size="small"
      onClick={onClick}
      disabled={!disableNav && isLastStep}
    >
      <FormattedMessage id={labelId} />
      <ChevronRightIcon />
    </Button>
  )
}

/**
 * Compone that renders a carousel using MUI's Stepper component to cycle through
 * images and text describing how the product works. Each section will have a title,
 * description, and image.
 */
export const HowAliceWorks = ({
  disableNav,
  onComplete,
  ...props
}: BoxProps & { onComplete?: () => void; disableNav?: boolean }) => {
  const [activeStep, setActiveStep] = useActiveSlide(disableNav)
  const isMobileWidth = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const numSteps = 5
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === numSteps - 1
  return (
    <Box display="flex" {...props}>
      <Box width="100%" maxWidth="90vw" flexGrow={1}>
        <SwipeableViews index={activeStep} onChangeIndex={setActiveStep} enableMouseEvents>
          <Landing isActive={activeStep === 0} />
          <Spend isActive={activeStep === 1} />
          <TextUs isActive={activeStep === 2} />
          <CashBack isActive={activeStep === 3} />
          <Testimonials isActive={activeStep === 4} />
        </SwipeableViews>
        <Container
          component={MobileStepper}
          steps={numSteps}
          position={isMobileWidth ? 'bottom' : 'static'}
          activeStep={activeStep}
          nextButton={
            <NextButton
              activeStep={activeStep}
              numSteps={numSteps}
              onClick={isLastStep ? onComplete : () => setActiveStep((step) => step + 1)}
              disableNav={disableNav}
            />
          }
          backButton={
            <Button
              size="small"
              onClick={() => setActiveStep((step) => step - 1)}
              disabled={isFirstStep}
              aria-hidden={isFirstStep}
              sx={{ opacity: isFirstStep ? 0 : 1 }}
            >
              <ChevronLeftIcon />
              {/* even though it's invisible on first step, we want it to match width of 'next' button, so we use the same label */}
              <FormattedMessage id={activeStep === 0 ? 'common.how_it_works' : 'common.back'} />
            </Button>
          }
          sx={{ padding: 0, display: 'flex', marginBottom: 9 }}
        />
      </Box>
    </Box>
  )
}
