import { Paper, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useCountUp } from 'react-countup'
import { FormattedMessage } from 'react-intl'
import { CarouselSlide } from './CarouselSlide'

const ESTIMATED_ANNUAL_SAVINGS = 1512

const CashBackGraphic = () => {
  const countUpRef = React.useRef(null)
  useCountUp({
    ref: countUpRef,
    start: 0,
    end: ESTIMATED_ANNUAL_SAVINGS,
    delay: 0.5, // sec
    duration: 2,
    separator: '',
  })
  return (
    <>
      <Paper
        elevation={0}
        sx={(theme) => ({
          textAlign: 'center',
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          paddingY: theme.spacing(1.5),
          paddingX: '2%',
          maxWidth: 420,
          minWidth: 300,
          margin: '0 auto',
        })}
      >
        <Typography variant="body2" fontSize="1.1em" color="inherit" fontWeight="bold">
          <FormattedMessage id="enroll.how_it_works.save_up_to" />
        </Typography>

        <Typography
          variant="h2"
          fontSize="3.8em"
          fontWeight="bold"
          letterSpacing="0.05em"
          color="inherit"
          fontFamily="Menlo, Monaco, courier, monospace"
          margin="0 auto"
          textAlign="center"
        >
          $<span ref={countUpRef} />
          <Typography
            variant="h2"
            color="inherit"
            fontWeight="inherit"
            fontSize="2.2rem"
            component="sup"
            letterSpacing="normal"
          >
            00
          </Typography>
        </Typography>
      </Paper>
      <Typography variant="body2" color="primary" fontWeight="bold" fontFamily="inherit">
        *<FormattedMessage id="enroll.how_it_works.if_you_commute" />
      </Typography>
    </>
  )
}
export const CashBack = ({ isActive }: { isActive: boolean }) => (
  <CarouselSlide
    title={<FormattedMessage id="enroll.how_it_works.cash_back.heading" />}
    graphic={isActive && <CashBackGraphic />}
    isActive={isActive}
  >
    <Typography variant="body2" gutterBottom>
      <FormattedMessage id="enroll.how_it_works.cash_back.protect_from_tax" />
    </Typography>
    <Typography variant="body2">
      <FormattedMessage id="enroll.how_it_works.cash_back.you_save" />
    </Typography>
  </CarouselSlide>
)
