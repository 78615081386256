import { Box, Button, Grid, Typography } from '@alice-financial/pretext-ui'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import * as React from 'react'
import { dateFormat, dateStringFormat } from '../../formatters/dateFormat'
import { PageInfoFragment } from '../../../app/graphql/fragments/PageInfoFragment_gen'
import { DatePagingState, useDatePagingContext } from './DatePagingContext'

type DatePagerControlsProps = {
  pageInfo: PageInfoFragment
}
export const DatePagerControls = ({ pageInfo }: DatePagerControlsProps) => {
  const [_paging, setPaging] = useDatePagingContext()

  if (!pageInfo.endCursor || !pageInfo.startCursor) return null

  const pastPage: DatePagingState = { endDate: pageInfo.endCursor } // end cursor is the oldest result in the paging context
  const futurePage: DatePagingState = { startDate: pageInfo.startCursor } // start cursor is most recent result in the paging context

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-around"
        component={Typography}
        fontWeight="bold"
        textAlign="center"
      >
        <Button onClick={() => setPaging(pastPage)}>
          <ArrowBackIosIcon fontSize="small" />
        </Button>
        <Box whiteSpace="nowrap" component="span">
          {dateStringFormat.mediumNoYear(pageInfo.endCursor)} -{' '}
          {dateStringFormat.mediumNoYear(pageInfo.startCursor)}
        </Box>
        <Button
          disabled={pageInfo.startCursor >= dateFormat.inputVal(new Date())}
          onClick={() => setPaging(futurePage)}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </Grid>
    </>
  )
}
