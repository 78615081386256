import * as React from 'react'
import { useAddAdminAndNotifyKyc, UseRequestKYCHelpOptions } from './useRequestKYCHelp'
import {
  ActionLayout,
  Button,
  EmailInputController,
  Grid,
  TextFieldController,
} from '@alice-financial/pretext-ui'
import { useIntl } from 'react-intl'

export const AddNewAdminForm = ({ org, onSuccess }: UseRequestKYCHelpOptions) => {
  const intl = useIntl()
  const { onSubmit, control } = useAddAdminAndNotifyKyc({ org, onSuccess })
  return (
    <form onSubmit={onSubmit}>
      <Grid container wrap="wrap" spacing={1}>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            name="firstName"
            autoComplete="off"
            label={intl.formatMessage({ id: 'profile.first_name_label' })}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            name="lastName"
            autoComplete="off"
            label={intl.formatMessage({ id: 'profile.last_name_label' })}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <EmailInputController
            name="email"
            fullWidth
            autoFocus
            required
            control={control}
            placeholder={intl.formatMessage({ id: 'profile.email.placeholder' })}
          />
        </Grid>
      </Grid>
      <ActionLayout
        sx={{ my: 0 }}
        primary={
          <Button fullWidth variant="contained" type="submit">
            Invite
          </Button>
        }
      />
    </form>
  )
}
