import { ApiError, RestApiError, composeMutationCallbacks } from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { UseMutationOptions } from '@tanstack/react-query'
import React from 'react'
import { useIntl } from 'react-intl'

type NotifConfig = { onMutate?: string | false; onError?: string | false; onSuccess?: string | false }
/**
 * This hook applies a common pattern for notifying the user about the state of a
 * mutation triggered from a react-query `useMutation` hook. The caller may supply
 * a set of custom mutation callback notification messages or rely on the default
 * 'Submitting...' and response error formatters, and may optionally supply a set
 * of custom `mutationOptions` that will be merged into the returned `mutationOptions`
 *
 * any callback messages is set to `false`, the notification for that callback will be skipped.
 *
 * The returned value should be used as the second argument to a `useMutation` hook, e.g.
 *
 * @example
 *
 * const myCoolMutationOptions = useMutationNotifier()
 * const { mutate } = useMutation(() => fetch('/api/cool', { method: 'POST' }), myCoolMutationOptions)
 *
 * // or
 *
 * const myCoolMutationOptions = useMutationNotifier({
 *  onMutate: 'Sending cool info...',
 *  onError: 'Something bad happened',
 *  onSuccess: 'We done did it again!'
 * })
 * const { mutate } = useMutation(() => fetch('/api/cool', { method: 'POST' }), myCoolMutationOptions)
 */
export const useMutationNotifier = <
  TData = unknown,
  TError extends ApiError = RestApiError,
  TVariables = unknown,
  TContext = unknown,
>(
  notifConfig: NotifConfig = {},
  mutationOptions: UseMutationOptions<TData, TError, TVariables, TContext> = {}
): typeof mutationOptions => {
  const intl = useIntl()
  const resNotif = useResponseNotification()
  return React.useMemo(
    () =>
      composeMutationCallbacks(mutationOptions, {
        onMutate: () =>
          notifConfig.onMutate === false
            ? undefined
            : resNotif.notifySubmit(notifConfig.onMutate || intl.formatMessage({ id: 'common.submitting' })),
        onError: (error) =>
          notifConfig.onError === false
            ? undefined
            : resNotif.notifyError(notifConfig.onError || error.message),
        onSuccess: () =>
          notifConfig.onSuccess === false
            ? resNotif.clearNotif()
            : resNotif.notifySuccess(notifConfig.onSuccess || intl.formatMessage({ id: 'common.success' })),
      }),
    [resNotif, notifConfig, mutationOptions, intl]
  )
}
