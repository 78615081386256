import { Button } from '@alice-financial/pretext-ui'
import React from 'react'

type LogoUploadButtonProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
export const _LogoUploadButton = (
  { id, children, ...inputProps }: LogoUploadButtonProps,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <>
      <input
        {...inputProps}
        ref={ref}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: 'none' }}
        id={`upload-logo-${id}`}
        type="file"
      />
      <Button variant="contained" component="label" htmlFor={`upload-logo-${id}`}>
        {children}
      </Button>
    </>
  )
}

export const LogoUploadButton = React.forwardRef(_LogoUploadButton)
