import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { SubmitOnboardingDocumentsInput } from '../../graphql/generated.types'
import { useOrg } from '../../orgDashboard/organization/useOrg'
import {
  SubmitOnboardingDocumentsMutation,
  useSubmitOnboardingDocumentsMutation,
} from './gql/submitOnboardingDocuments_gen'

type UseSubmitOnboardingDocumentsOptions = UseGqlMutationCallbacks<
  SubmitOnboardingDocumentsMutation,
  SubmitOnboardingDocumentsInput
>

export const useSubmitOnboardingDocuments = (
  orgId: number,
  mutationOptions?: UseSubmitOnboardingDocumentsOptions
) => {
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    useMutationNotifier({}, mutationOptions),
    [useOrg.getKey({ orgId })]
  )

  const { mutateAsync: submitDocuments } = useSubmitOnboardingDocumentsMutation()

  return useMutation(
    ['submitOnboardingDocuments'],
    (values: SubmitOnboardingDocumentsInput) => submitDocuments({ input: values }),
    mutationOptionsWithInvalidation
  )
}
