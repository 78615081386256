import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { RestUser } from '../../user/types'

export type AdminSignupFormValues = {
  first_name: string
  last_name: string
  preferred_name: string
  email: string
  tel: string
  password: string
  password_confirmation: string
  accept_legal: boolean // not currently supported by the API
  accept_employer_tos: boolean // not currently supported by the API
}

export const useSignup = (
  _mutationOptions?: UseRestMutationCallbacks<RestUser, AdminSignupFormValues, void>
) => {
  const mutationOptions = useMutationNotifier({}, _mutationOptions)
  return useMutationWithInvalidation(
    (values) =>
      fetchRest('/api/onboarding/create_user', {
        method: 'POST',
        body: JSON.stringify(values),
      }),
    mutationOptions,
    'all' // effectively a login - reset all queries
  )
}
